import { Config } from 'src/config/ConfigManager'
import AxiosInstance from 'src/api/axios'

import { FieldUpdate } from 'src/features/cart/components/AvailabilityErrors'
import { runInAction } from 'mobx'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import {
  PartAvailabilityRequest,
  PartAvailabilityResponse,
  PartInfoResponse,
  Prices,
} from './ValidationInterfaces'
import { ShoppingCartProduct } from '../models/ShoppingCartModels'
import {
  AvailabilityError,
  AvailabilityErrorType,
  ProductLocationModel,
  ProductModel,
} from '../models/ProductModel'

const apiEndpointPartBase = Config.urls.part

export const buildAvailabilityRequest = (
  product: ProductModel,
  quantityRequested: number,
  location: ProductLocationModel
): PartAvailabilityRequest => {
  return {
    parts: [
      {
        lineNo: 0,
        lineCode: product.lineCode,
        manufacturerName: product.manufacturerName,
        partNumber: product.partNumber,
        brand: product.brand,
        description: product.description,
        quantityRequested,
        unitOfMeasure: location?.unitOfMeasure,
        unitCorePrice: location?.coreCost,
        unitCostPrice: location?.cost,
        isPriceOverride: true,
        priceOverrideMsg: '',
        locationId: location?.locationId,
        locationDescription: location?.called,
      },
    ],
  }
}

export const fetchProduct = async (
  partsInfo: PartAvailabilityRequest
): Promise<PartInfoResponse> => {
  const endpoint = `${apiEndpointPartBase}/part/availability`
  const res = await AxiosInstance.post<PartAvailabilityResponse>(
    endpoint,
    partsInfo
  )
  return res.data?.parts?.[0] // The endpoint retuns an array but it should only contain one part
}

export const validatePrices = (
  oldLocation: ProductLocationModel,
  newLocation: ProductLocationModel
): Array<AvailabilityError> => {
  const availabilityErrors: Array<AvailabilityError> = []
  const priceUpdates = collectPriceUpdates(oldLocation, newLocation)

  if (priceUpdates.length > 0)
    availabilityErrors.push({
      errorType: AvailabilityErrorType.PRICE_OVERRIDE,
      fieldUpdates: priceUpdates,
    })

  return availabilityErrors
}

const buildUpdate = (
  field: string,
  oldValue: string,
  newValue: string
): FieldUpdate => {
  return {
    field,
    oldValue,
    newValue,
  }
}

const collectPriceUpdates = (
  location: ProductLocationModel,
  updatedLocation: ProductLocationModel
): Array<FieldUpdate> => {
  const updates: Array<FieldUpdate> = []
  const oldCost: string = location.cost?.toString() ?? '0'
  const oldCoreCost: string = location.coreCost?.toString() ?? '0'
  const oldCoreList: string = location.coreList?.toString() ?? '0'
  const oldListPrice: string = location.list?.toString() ?? '0'

  const newCost: string = updatedLocation.cost?.toString() ?? '0'
  const newCoreCost: string = updatedLocation.coreCost?.toString() ?? '0'
  const newCoreList: string = updatedLocation.coreList?.toString() ?? '0'
  const newListPrice: string = updatedLocation.list?.toString() ?? '0'

  if (oldCost !== newCost)
    updates.push(buildUpdate(Prices.COST, oldCost, newCost))

  if (oldCoreCost !== newCoreCost)
    updates.push(buildUpdate(Prices.CORE, oldCoreCost, newCoreCost))

  if (oldCoreList !== newCoreList)
    updates.push(buildUpdate(Prices.CORE_LIST, oldCoreList, newCoreList))

  if (oldListPrice !== newListPrice)
    updates.push(buildUpdate(Prices.LIST, oldListPrice, newListPrice))

  return updates
}

export const buildAvailabilityError = (
  errorType: AvailabilityErrorType,
  fieldUpdates?: Array<FieldUpdate>
): AvailabilityError => {
  const availabilityError: AvailabilityError = { errorType }
  if (fieldUpdates) availabilityError.fieldUpdates = fieldUpdates
  return availabilityError
}

export const validateProductPrices = async (
  product: ShoppingCartProduct
): Promise<void> => {
  const locationId = product.orderSelections?.[0]?.locationId
  const quantity = product.orderSelections?.[0]?.quantityRequested

  const originalLocation = product.location.find(
    (l) => l.locationId === locationId
  )

  const updatedProduct: ShoppingCartProduct =
    await StoreInstances.cart.updatePartInfo(
      product,
      quantity,
      originalLocation
    )

  const newLocation = updatedProduct.location.find(
    (l) => l.locationId.toString() === locationId.toString()
  )

  runInAction(() => {
    const priceValidations = validatePrices(originalLocation, newLocation)
    if (priceValidations.length > 0)
      product.location = [...updatedProduct.location]
    priceValidations.forEach((val) => {
      product.availabilityError = val
    })
  })
}
