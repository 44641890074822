import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { NavigationItemsWrapper } from '../CommonMenuItems'
import { MenuItem, IMenuItem } from './MenuItem'

const AstPunchOutMenuItems = observer((): ReactElement => {
  const menuItems: IMenuItem[] = [
    { text: 'partsCatalog', path: '/astPartsCatalog' },
  ]

  if (import.meta.env.VITE_FEATURE_QUOTES_ENABLED === 'true') {
    menuItems.push({ text: 'quotes', path: '/quotes' })
  }

  if (import.meta.env.VITE_FEATURE_ORDER_DETAILS_ENABLED === 'true') {
    menuItems.push({ text: 'orders', path: '/orderHistory' })
  }

  if (import.meta.env.VITE_FEATURE_LISTS_ENABLED === 'true') {
    menuItems.push({ text: 'lists', path: '/lists' })
  }

  return (
    <NavigationItemsWrapper>
      {menuItems.map((menuItem, idx) => {
        const key = `desktop-menu-item-${idx}`
        return <MenuItem key={key} {...menuItem} />
      })}
    </NavigationItemsWrapper>
  )
})

export default AstPunchOutMenuItems
