import { VITE_EXPERIAN_AUTOCHECK_BASE_URL } from './constants'

export class Urls {
  public base: string

  public baseOp: string

  public experianAutocheck: string

  public part: string

  public partext: string

  public catalog: string

  public order: string

  public list: string

  public vehicle: string

  public embedded: string

  public usingProxy: boolean

  public lostSales: string

  public userData: string

  public promotion: string

  public quote: string

  public attributes: string

  public sayt: string

  public security: string

  public shipment: string

  private static catalogVersion = '/catalog/1.1'
  private static partVersion = '/part/1.1'
  private static partExtVersion = '/partext/1.1'
  private static orderVersion = '/order/1.1'
  private static vehicleVersion = '/vehicle/1.1'
  private static userDataVersion = '/userdata/1.1'
  private static embeddedVersion = '/embedded/1.1'
  private static tokenVersion = '/token/1.1'
  private static lostSalesVersion = '/lost-sale/1.1'
  private static listVersion = '/list/1.1'
  private static promotionVersion = '/promotion/1.1'
  private static quoteVersion = '/quote/1.1'
  private static attributesVersion = '/attributes/1.0'
  private static saytVersion = '/sayt/1.0.1'
  private static shipmentVersion = '/shipment/1.1'

  constructor() {
    this.usingProxy = import.meta.env.VITE_USING_PROXY === 'true'
    this.base = this.usingProxy ? '' : import.meta.env.VITE_API_BASE_URL
    this.baseOp = this.usingProxy ? '' : import.meta.env.VITE_API_BASE_OP_URL
    this.part = this.base + Urls.partVersion
    this.partext = this.base + Urls.partExtVersion
    this.catalog = this.base + Urls.catalogVersion
    this.order = this.base + Urls.orderVersion
    this.vehicle = this.base + Urls.vehicleVersion
    this.embedded = this.baseOp + Urls.embeddedVersion
    this.lostSales = this.base + Urls.lostSalesVersion
    this.list = this.base + Urls.listVersion
    this.userData = this.base + Urls.userDataVersion
    this.promotion = this.base + Urls.promotionVersion
    this.quote = this.base + Urls.quoteVersion
    this.experianAutocheck = VITE_EXPERIAN_AUTOCHECK_BASE_URL
    this.attributes = this.base + Urls.attributesVersion
    this.sayt = this.baseOp + Urls.saytVersion
    this.security = this.baseOp + Urls.tokenVersion
    this.shipment = this.base + Urls.shipmentVersion
  }
}
