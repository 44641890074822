export interface ContactUsInfo {
  name: string
  email: string
  subject: string
  message: string
  priority?: PriorityType
  screenName?: string
}

export interface IContactUsResponse {
  acknowledged: boolean
}

export interface ContactAdminInterface {
  screenName: string
  email: string
  phoneWithExt: string
}
export interface ContactUsFormErrorsInterface {
  name: boolean
  email: boolean
  subject: boolean
  message: boolean
}

export enum PriorityType {
  urgent = 'Urgent',
  today = 'Today',
  tomorrow = 'Tomorrow',
}
export interface PassWordResetError {
  message: string
}
