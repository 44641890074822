import { IdNamePair } from '../models/KeyValuePair'
import { ProductModel } from '../models/ProductModel'
import { Vehicle } from '../models/Vehicles'

export interface Product {
  id?: number
  brandId?: number
  brand?: string
  subBrand?: string
  comment?: string
  description?: string
  lastUsedOn?: string /* date */
  lineCode?: string
  orderNumber?: string
  partNumber?: string
  isStocked?: true
  stockQty?: number
  createdOn?: string /* date */
  updatedOn?: string /* date */
}

interface Group extends IdNamePair<number, string> {
  items: Array<Product>
}

interface List extends IdNamePair<number, string> {
  items?: Array<Group>
}

export interface Category extends IdNamePair<number, string> {
  lists: Array<List>
}

export type Categories = Array<Category>

export interface ResponseCategoriesAndLists {
  categories: Categories
}

export interface CheckedListParts extends Product {
  [x: string]: unknown
  checked: boolean
}

export interface ReviewList {
  id: number
  partName: string
  brand: string
  partNumber: string
  yourCost: string
  total: string
  availability: string
  availabilityCount: string
  qty: number
}

export interface ChangeLocation {
  id: number
  partName: string
  availability: string
  order?: string
  delivery?: string
  status: string
  storeName?: string
}

export interface ValidateListPart extends ProductModel {
  id: number
  validationStatus: string
}
export interface ValidateListParts {
  parts?: Array<ValidateListPart>
}

export interface CartOption {
  label: string
  value: Vehicle
}

export interface ValidatePartsRequest {
  partIds: Array<number>
}

export enum ValidStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  VALID_INSUFFICIENT_QTY = 'VALID_INSUFFICIENT_QTY',
}

export interface RequestBodyNewList {
  name?: string
  note?: string
  categoryId?: number
}
export interface ResponseNewList {
  name?: string
  note?: string
  id?: number
  groups?: Array<{
    id: number
  }>
}

export interface RequestBodyNewGroup {
  name: string
}

export interface ResponseListDetails extends ResponseNewList {
  category?: IdNamePair<number, string>
  groups?: Array<IdNamePair<number, string>>
}

export interface ListResponseError {
  message: string
}

export interface GroupPart extends Product {
  isSelected: boolean
}

export interface GroupPartRequest {
  start: number
  limit: number
  sortBy?: string
  sortOrder?: string
}

export interface GroupPartResponse {
  totalCount?: 0
  items?: Array<GroupPart>
}

export interface AddGroupPart {
  lineCode: string
  partNumber: string
  stockQty: number
  comment: string
}

export interface AddPartToList {
  lineCode: string
  partNumber: string
  stockQty: number
  comment: string
  partDescription: string
  handleListLocation: () => void
  handleClose: () => void
}

export interface DeleteGroupParts {
  partIds: Array<number>
}

export interface MoveGroupParts {
  partIds: Array<number>
}

export interface UpdateGroupPart extends AddGroupPart {
  id: number
}

export interface ListImportDetails {
  isWdAdmin?: boolean
  wdOrgId?: number
  message?: string
  userId?: number
  uuid?: string
  orgId?: number
  listId?: number
  isValidOrg?: boolean
  failureParts?: number
  successParts?: number
  timestamp?: string
  status?: string
}

export interface VerifyStockedPartsRequest {
  partNumber: string
  lineCode: string
}

export interface VerifyStockedPartsResponse {
  partNumber: string
  lineCode: string
  isStocked: boolean
}
