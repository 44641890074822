import { VehicleWidgetStore } from '../features/search/VehicleSearch/store/VehicleWidgetStore'
import { ShoppingCart } from './cart/ShoppingCart'

import { ListsStore } from './lists/ListsStore'
import { PartsCatalog } from './partsCatalog/PartsCatalog'
import { BannerPromotionStore } from './promotion/BannerPromotionStore'
import { QuoteStore } from './quote/QuoteStore'
import { CustomizeSearchStore } from './search/CustomizeSearchStore'
import { SearchStore } from './search/SearchStore'
import { SpecificConditionsStore } from './specificConditions/SpecificConditionsStore'
import { UiStateStore } from './uiState/UiStateStore'
import { UserStore } from './user/UserStore'
import { VehicleSpecificationStore } from './vehicleSpecification/VehicleSpecificationStore'
import { LaborStore } from './labor/LaborStore'
import { AstPunchOutStore } from './astPunchOut/AstPunchOutStore'
import { PartsCatalogAPI } from './partsCatalog/api/PartsCatalogAPI'
import { JobsCatalogStore } from './jobs/JobsStore'
import { RmaStore } from 'src/store/rma/rmaStore.ts'

let initialized = false

class StoreInstancesContainer {
  public uiStore: UiStateStore

  public searchStore: SearchStore

  public customizeSearchStore: CustomizeSearchStore

  public partsCatalog: PartsCatalog

  public laborStore: LaborStore

  public astPunchOutStore: AstPunchOutStore

  public specificConditions: SpecificConditionsStore

  public cart: ShoppingCart

  public vehicleWidget: VehicleWidgetStore

  public userStore: UserStore

  public listsStore: ListsStore

  public bannerPromotionStore: BannerPromotionStore

  public quoteStore: QuoteStore

  public vehicleSpecificationStore: VehicleSpecificationStore

  public jobsCatalog: JobsCatalogStore

  public rmaStore: RmaStore

  init = (): void => {
    if (initialized) {
      return
    }
    this.uiStore = new UiStateStore()
    this.partsCatalog = new PartsCatalog(new PartsCatalogAPI())
    this.laborStore = new LaborStore()
    this.astPunchOutStore = new AstPunchOutStore()
    this.searchStore = new SearchStore()
    this.specificConditions = new SpecificConditionsStore()
    this.userStore = new UserStore()
    this.customizeSearchStore = new CustomizeSearchStore()
    this.cart = new ShoppingCart()
    this.listsStore = new ListsStore()
    this.vehicleWidget = new VehicleWidgetStore()
    this.bannerPromotionStore = new BannerPromotionStore()
    this.quoteStore = new QuoteStore()
    this.vehicleSpecificationStore = new VehicleSpecificationStore()
    this.jobsCatalog = new JobsCatalogStore()
    this.rmaStore = new RmaStore()
    this.searchStore.initReactions()
    this.cart.initReactions()

    initialized = true
  }
}

export const StoreInstances = new StoreInstancesContainer()
