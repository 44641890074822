export enum PartsBasketAction {
  checkAvailabilityRequest = 'checkAvailabilityRequest',
  checkAvailabilityResponse = 'checkAvailabilityResponse',
  goShoppingRequest = 'goShoppingRequest',
  goShoppingResponse = 'goShoppingResponse',
  placeOrderRequest = 'placeOrderRequest',
  placeOrderResponse = 'placeOrderResponse',
}
export const partsBasketResponseType = {
  [PartsBasketAction.goShoppingResponse]: '1',
  [PartsBasketAction.goShoppingRequest]: '1',
  [PartsBasketAction.checkAvailabilityResponse]: '2',
  [PartsBasketAction.checkAvailabilityRequest]: '2',
  [PartsBasketAction.placeOrderResponse]: '3',
  [PartsBasketAction.placeOrderRequest]: '3',
}

export enum LineType {
  part = 'part',
  labor = 'labor',
  specification = 'specification',
  graph = 'graph',
}

export enum CartButtonValues {
  TRANSFER = 'transfer',
  ORDER = 'order',
  BOTH = 'both',
}

export interface IGoShoppingParams {
  partnerId: string
  sellerId: string
  buyerId: string
  goShoppingUrl?: string
  useDataIsland?: string
  localInventory?: string
  width?: string // Ignoring this as V3 is responsive.
  height?: string // Ignoring this as V3 is responsive.
  partsBasket: string
  // Used to handle displaying the order and transfer buttons in checkout.
  cartButton?: CartButtonValues
  showHeader?: string // Need to check how this is being used.
  HOOK_URL?: string
  service?: string
  checkAvailabilityRedirect?: string
}

export interface IParsedGoShoppingParams {
  sellerId: string
  buyerId: string
  partnerId: string
  goShoppingUrl?: string
  useDataIsland: boolean
  localInventory: boolean
  width?: string // Ignoring this as V3 is responsive.
  height?: string // Ignoring this as V3 is responsive.
  partsBasket: string
  // Used to handle displaying the order and transfer buttons in checkout.
  cartButton?: CartButtonValues
  showHeader?: string // Need to check how this is being used.
  HOOK_URL?: string
  service?: string
  checkAvailabilityRedirect?: boolean
}
