import { IPartnerExternal } from '../types/PartnerExternal'
import { IBasePartnerCommunicationManager } from './index'
import { LocalInventoryMessage, PartsBin } from '../types/localInventory'
import { partsBasketToSearchResults } from '../utils/localInventoryUtils'
import { ProductModel } from 'src/store/models/ProductModel'

declare const external: IPartnerExternal

class BasePartnerCommunicationManager
  implements IBasePartnerCommunicationManager
{
  protected readonly targetOrigin: string

  protected localInventoryCallBack?: (data: ProductModel[]) => void

  constructor() {
    this.targetOrigin = '*'
    this.handlePostMessage = this.handlePostMessage.bind(this)
    this.init()
  }

  protected init(): void {
    window.addEventListener('message', this.handlePostMessage)
  }

  protected handlePostMessage(e: MessageEvent): void {
    if (e.origin === window.origin) {
      return
    }
    const data = typeof e.data === 'object' ? e.data : JSON.parse(e.data)
    if (data.fromLocalInventory) {
      const partsData: PartsBin = JSON.parse(data.partsData)
      if (this.localInventoryCallBack) {
        this.localInventoryCallBack(partsBasketToSearchResults(partsData))
      }
    }
  }

  public cartCallBack(data: string): void {
    const params = {
      cartData: true,
      partsData: data,
    }
    this.messageParent(params)

    if (external && external.PostData) {
      external.PostData(data)
    }
    // Data island case.
    const partsBasketElement: HTMLInputElement = document.getElementById(
      'partsBasket'
    ) as HTMLInputElement
    partsBasketElement.value = ''
    partsBasketElement.value = btoa(data)
  }

  protected messageParent = (data: unknown): void => {
    window.parent.postMessage(
      typeof data !== 'string' ? JSON.stringify(data) : data,
      this.targetOrigin
    )
  }

  public cancelCallBack(): void {
    const params = {
      cancel: true,
    }

    this.messageParent(params)
    if (external && external.CloseApp) {
      external.CloseApp()
    }
  }

  public getLocalInventory(
    results: LocalInventoryMessage,
    cb: (data: ProductModel[]) => void
  ): void {
    this.messageParent(results)
    this.localInventoryCallBack = cb
  }
}

export default BasePartnerCommunicationManager
