import { BaseServiceProvider } from './BaseServiceProvider'

interface ICognitoUser {
  userName: string
  password: string
}

interface ICognitoUserResponse {
  message: string
  userId: string
  statusCode: number
  status: string
}

class AllianceConnectServiceProvider extends BaseServiceProvider {
  constructor() {
    super(
      import.meta.env.VITE_ALLIANCE_CONNECT_API_URL,
      '/o/aapa_jax_rest_services',
      '',
      'organization'
    )
  }

  createCognitoUser = async (reqData: ICognitoUser): Promise<void> => {
    await this.post<ICognitoUserResponse>('createUser', reqData)
  }
}

export default new AllianceConnectServiceProvider()
