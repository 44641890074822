import { observer } from 'mobx-react-lite'
import { FC, Suspense, ComponentProps } from 'react'
import { Redirect, Route } from 'react-router-dom'
import HeaderPerAppMode from 'src/features/navigation/header/HeaderPerAppMode'
import { useUserStore } from '../store/user/UserStore'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import Footer from 'src/routes/Footer'

export const RouteRequiresLogin: FC<ComponentProps<typeof Route>> = observer(
  ({ children, ...props }) => {
    const userStore = useUserStore()

    if (userStore.redirectToLogin) {
      return <Redirect to="/login" />
    }

    return (
      <Route {...props}>
        <HeaderPerAppMode />
        <Suspense
          fallback={
            <Wrapper>
              <Spinner animation="border" />
            </Wrapper>
          }
        >
          {children}
        </Suspense>
        <Footer />
      </Route>
    )
  }
)

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
`
