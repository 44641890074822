export enum CatalogLookupType {
  YMME = 0,
  VIN = 5,
  PLATE = 11,
  INTERCHANGE = 4,
  AST_MATCH = 1,
  AST_NO_MATCH = 0,
}

class CatalogIndex {
  index: number

  constructor() {
    this.index = 0
  }

  setIndex = (index: CatalogLookupType) => {
    this.index = index
  }
}

export const CatalogIndexTracker = new CatalogIndex()
