/**
 * enum to maintain the keys used by to the app to store data in local/session storage.
 */
export enum StorageKeys {
  /**
   * The key used to store the origin of the auto login request.
   * Used to redirect the user back on logout.
   * Need to cleat the storage on logout.
   */
  AUTO_LOGIN_ORIGIN_KEY = 'auto-login-origin',

  /**
   * The key used to store whether the user is in impersonating state.
   * Need to cleat the storage on logout.
   */
  AUTO_LOGIN_IMPERSONATION_KEY = 'auto-login-impersonated',

  LICENSE_PLATE_STATE_KEY = 'license-plate-state',
}

export const VITE_EXPERIAN_AUTOCHECK_BASE_URL =
  'https://www.autocheck.com/vehiclehistory/?siteID=7500'

export const SENTRY_DSN =
  'https://8508dada5dc4613c5e779662a4077beb@o4505784096391168.ingest.sentry.io/4505786669924352'
