import {
  Categories,
  CategoryGroups,
  GroupPartTypes,
  PartType,
} from 'src/store/models/PartsCatalogStoreModels'
import { CatalogNode } from '../../../store/partsCatalog/CatalogNode'
import { CatalogNodeType } from '../../../store/partsCatalog'
import { PartsCatalog } from 'src/store/partsCatalog/PartsCatalog'
import { IdValuePair } from 'src/store/models/KeyValuePair'
import { Vehicle } from 'src/store/models/Vehicles'

export interface SelectionsInterface {
  mode: Mode
  setMode: (m: Mode) => void
}

export const enum Mode {
  ADD_SELECTIONS,
  DISPLAY_SELECTIONS,
  UPDATE_SELECTIONS,
}

export interface CheckboxSelectionsProps {
  title: string
  type: CatalogNodeType
  groups?: CategoryGroups | GroupPartTypes
  list?: Categories
  loading: boolean
  selectCount?: number
  partsCatalogStore: PartsCatalog
  showGfxButtons?: boolean
}

export interface CheckboxListProps {
  list?: Categories | Array<PartType>
  allSelected?: boolean
  mixedSelected?: boolean
  direct?: boolean
  groupId?: string
  showGFX?: boolean
  type: CatalogNodeType
}

export interface CheckboxGroupsProps {
  groups?: CatalogNode[]
  type: CatalogNodeType
  loading?: boolean
  partsCatalogStore: PartsCatalog
}

export interface PartTypeItem extends IdValuePair<string> {
  isConsolidated?: boolean
}

export interface IdGroupsItem extends IdValuePair<string> {
  //ID Value Pair in this `IdGroupsItem` referts to the category to which the below items(groups) belongs to.
  items: Array<IdValuePair<string>>
}

export interface IPartItem extends IdValuePair<string> {
  //ID Value Pair in this `IPartItem` referts to the group to which the below items(parts) belongs to.
  items: Array<PartTypeItem>
}

export interface IdEngine {
  id: string
  value: string
}

export type IdsEnginesResponse = Array<IdEngine>

export type IdGroupsResponse = Array<IdGroupsItem>

export type IPartItemResponse = Array<IPartItem>

export interface CheckboxClick {
  value: string
  checked: boolean
}
export type CheckboxClickList = Array<CheckboxClick>
export interface CheckboxListGroupProps {
  group: CatalogNode
  onChange: (string) => void
  type: CatalogNodeType
  showGFX?: boolean
  partsCatalogStore: PartsCatalog
}

export interface RequestBody {
  categories?: []
  groups?: []
  partTypes?: []
  vehicle?: Vehicle
}

/* Jobs */

interface Job {
  jobCode: string
  jobDescription: string
  jobId: string
  jobName: string
}
interface CategoryJobItem {
  categoryId: string
  categoryName: string
  items: Job[]
}

interface GroupJobItem {
  jobId: string
  jobName: string
  terminologies: Array<IdValuePair<string> & { groupId: string }>
}
export type JobsResponse = Array<CategoryJobItem>
export type JobTerminologiesResponse = Array<GroupJobItem>
