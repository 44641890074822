import { ReactElement, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import defaultLogoUrl from 'src/assets/images/logo.png'
import { LogoImage } from 'src/features/navigation/TopNavigation/components/CommonMenuItems'
import { I18n, Translate } from 'src/i18n'
import AllianceConnectServiceProvider from 'src/services/AllianceConnectServiceProvider'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { useUserStore } from 'src/store/user/UserStore'
import { Button, TextInput } from 'src/ui-components'
import { Hyperlink } from 'src/ui-components/util/Hyperlink'
import styled from 'styled-components'

const LoginScreen = (): ReactElement => {
  const userStore = useUserStore()
  const [username, setUsername] = useState('')
  const [pass, setPass] = useState('')
  const [loading, setLoading] = useState(false)
  const [logoUrl] = useState(defaultLogoUrl)
  const history = useHistory()

  const { refetch } = useQuery(
    ['createCognitoUser'],
    () => userStore.handleLogin(username, pass),
    {
      retry: 4,
      enabled: false,
      onSuccess: () => {
        history.push('/home')
      },
    }
  )

  async function signIn() {
    try {
      setLoading(true)
      await userStore.handleLogin(username, pass)
      history.push('/home')
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      if (error.message === 'Password attempts exceeded') {
        StoreInstances.uiStore.displayErrorNotification(
          'Wait a couple of minutes to sign in again'
        )
      } else {
        AllianceConnectServiceProvider.createCognitoUser({
          userName: username,
          password: pass,
        })
          .then(() => {
            setLoading(true)
            refetch()
          })
          .catch(() =>
            StoreInstances.uiStore.displayErrorNotification(error.message)
          )
      }
    } finally {
      setLoading(false)
    }
  }

  const checkForEnter = (e) => {
    if (e.key === 'Enter') {
      signIn()
    }
  }

  const handleForgotPassword = async (): Promise<void> => {
    history.push('/sendCode')
  }

  return (
    <Wrapper>
      <Content>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <LogoImageWrapper
              src={logoUrl}
              alt={Translate('myPlaceForPartsLogo')}
            />
            <h3>
              <I18n name="login" />
            </h3>
            <TextInput
              placeholder="username"
              label="screenName"
              value={username}
              onChange={(value) => setUsername(value)}
              width="100%"
            />
            <TextInput
              placeholder="password"
              label="password"
              type="password"
              value={pass}
              onChange={(value) => setPass(value)}
              onKeyPress={checkForEnter}
              width="100%"
            />
            <LoginButton onClick={() => signIn()} text="login" />
            <ForgotPasswordWrapper>
              <Hyperlink action={handleForgotPassword} underlined={false}>
                <I18n name="forgotPassword" textReplacement={['?']} />
              </Hyperlink>
            </ForgotPasswordWrapper>
          </>
        )}
      </Content>
    </Wrapper>
  )
}

export default LoginScreen

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const Content = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoginButton = styled(Button)`
  width: 100%;
`

const LogoImageWrapper = styled(LogoImage)`
  margin-bottom: 1.5rem;
`

const ForgotPasswordWrapper = styled.div`
  padding-top: 1.5rem;
`
