import { ReactElement } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { breakpoints } from 'src/theme/breakpoints'

export const BannerAdsHeader = (): ReactElement => {
  const history = useHistory()
  const pathNaname = history.location.pathname
  const anchorRef =
    'https://ads.myplace4parts.com/www/delivery/ck.php?n=a6187aee&amp;cb=INSERT_RANDOM_NUMBER_HERE'
  const imgSource =
    'https://ads.myplace4parts.com/www/delivery/avw.php?zoneid=27&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a6187aee'
  const anchorRefMobile =
    'http://ads.myplace4parts.com/www/delivery/ck.php?n=a35b96f3&amp;cb=INSERT_RANDOM_NUMBER_HERE'

  const imgSourceMobile =
    'http://ads.myplace4parts.com/www/delivery/avw.php?zoneid=29&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a35b96f3'
  return (
    <Wrapper>
      {pathNaname !== '/searchResults' && (
        <>
          <Iframe
            id="a4b874a2"
            name="a4b874a2"
            src="https://ads.myplace4parts.com/www/delivery/afr.php?refresh=15&amp;zoneid=27&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            frameBorder="0"
            scrolling="no"
            width="1400"
            height="80"
          >
            <a href={anchorRef}>
              <Image src={imgSource} alt="banner" />
            </a>
          </Iframe>
          <IframeMobile
            id="a05c7ff8"
            name="a05c7ff8"
            src="https://ads.myplace4parts.com/www/delivery/afr.php?refresh=15&amp;zoneid=29&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            frameBorder="0"
            scrolling="no"
            width="358"
            height="50"
          >
            <a href={anchorRefMobile}>
              <Image src={imgSourceMobile} alt="banner" />
            </a>
          </IframeMobile>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    margin-top: 60px;
  }
  @media print {
    display: none;
  }
`
const Iframe = styled.iframe`
  border: 0;
  @media (width < ${breakpoints.laptop}px) {
    display: none;
  }
`

const IframeMobile = styled.iframe`
  border: 0;
  @media (min-width: ${breakpoints.laptop}px) {
    display: none;
  }
`

const Image = styled.img`
  border: 0;
`
