import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import {
  SearchOptions,
  SearchPreference,
} from 'src/features/customizeSearch/interfaces'

interface TabOption {
  idx: number
  title: string
}

export const SEARCH_PREFERENCE_VERSION = '1.0'

export class CustomizeSearchStore {
  searchPreferences: Array<SearchPreference> = []

  copyOfSearchPreferences: Array<SearchPreference> = []

  selectedTabTitle: string

  private defaultPreferences: SearchPreference[] = [
    { id: SearchOptions.vehicle_ymme },
    { id: SearchOptions.license_plate },
    { id: SearchOptions.vin },
  ]

  constructor() {
    makeAutoObservable(this)
    this.setSearchPreferences(this.defaultPreferences)
    this.selectedTabTitle = this.searchPreferences[0].id
  }

  getVisibleSearchPreferences = (): Array<SearchPreference> => {
    return this.copyOfSearchPreferences.filter((sp) => !sp?.hidden)
  }

  getHiddenSearchPreferences = (): Array<SearchPreference> => {
    return this.copyOfSearchPreferences.filter((sp) => sp?.hidden)
  }

  setSearchPreferences = (preferences: Array<SearchPreference>): void => {
    const newPreferences = !preferences?.[0]?.id
      ? this.defaultPreferences
      : preferences

    this.searchPreferences = newPreferences
    this.copyOfSearchPreferences = [...newPreferences]
    this.selectedTabTitle = newPreferences[0].id
  }

  addLicensePlateOption = (): void => {
    if (this.isLicensePlateOptionAvailable()) return

    this.searchPreferences?.push({
      id: SearchOptions.license_plate,
    })
    this.copyOfSearchPreferences?.push({
      id: SearchOptions.license_plate,
    })
  }

  isLicensePlateOptionAvailable = (): boolean => {
    return (
      this.searchPreferences?.findIndex(
        (sP) => sP.id === SearchOptions.license_plate
      ) >= 0
    )
  }

  removeLicensePlateOption = (): void => {
    const removedPref = this.searchPreferences.filter(
      (sp) => sp.id !== SearchOptions.license_plate
    )
    this.setSearchPreferences(removedPref)
  }

  setSelectedTab = (tabTitle: string): void => {
    this.selectedTabTitle = tabTitle
  }

  getSelectedTab = (): TabOption => {
    const idx = this.searchPreferences.findIndex(
      (option) => option.id === this.selectedTabTitle
    )
    return {
      idx,
      title: this.searchPreferences[idx]?.id,
    }
  }

  setCopyOfSearchPreferences = (preferences: Array<SearchPreference>): void => {
    this.copyOfSearchPreferences = preferences
  }

  resetCopy = (): void => {
    this.copyOfSearchPreferences = [...this.searchPreferences]
  }
}

export const CustomizeSearchContext = createContext<
  CustomizeSearchStore | undefined
>(undefined)

export const useCustomizeSearchStore = (): CustomizeSearchStore => {
  const customizeSearchStore = useContext(CustomizeSearchContext)
  if (!customizeSearchStore) {
    throw new Error(
      'No CustomizeSearchContext.Provider found when calling useCustomizeSearchStore.'
    )
  }
  return customizeSearchStore
}
