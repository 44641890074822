import { ReactElement, useState } from 'react'
import { RadioGroup, Select } from 'src/ui-components'
import { useSearchStore } from 'src/store/search/SearchStore'
import styled from 'styled-components'
import { GfxConditionType, GfxSpecificCondition } from 'src/api/gfx/interfaces'
import { observer } from 'mobx-react-lite'
import { QuestionRef } from './QuestionRef'
import { QuestionState } from './GfxSpecificConditionsModal'

const DROPDOWN_REPLACEMENT_THRESHOLD = 4

interface GfxQuestionProps {
  type: GfxConditionType
  question: GfxSpecificCondition
  questionList: Array<QuestionState>
  onChange: (questionCode: string, answerCode: string) => void
  useDropdownReplacement?: boolean
}

export const GfxQuestion = observer((props: GfxQuestionProps): ReactElement => {
  const searchStore = useSearchStore()
  const { type, question, questionList, onChange, useDropdownReplacement } =
    props
  const options = question.gfxConditions.map((q) => ({
    id: q.code,
    value: q.label,
  }))
  const answer = searchStore.getGfxAnswer(type, question.code)

  const [hasAnswered, setHasAnswered] = useState(answer !== undefined)
  const answerOption = options.find((o) => o.id === answer)
  const useDropdown =
    useDropdownReplacement && options.length > DROPDOWN_REPLACEMENT_THRESHOLD

  const handleAnswer = (selected) => {
    onChange(question.code, selected.id)
    setHasAnswered(true)
  }

  return (
    <QuestionRef
      questionList={questionList}
      hasAnswered={hasAnswered}
      questionId={question.code}
    >
      <QuestionLabel>{question.label}</QuestionLabel>
      {!useDropdown ? (
        <RadioGroup
          id={question.code}
          name={question.label}
          options={options}
          onChange={handleAnswer}
          initialValue={answer}
        />
      ) : (
        <Select
          options={options}
          onSelect={handleAnswer}
          selectedOption={answerOption}
        />
      )}
    </QuestionRef>
  )
})

const QuestionLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-family: Roboto, serif;
  margin-top: 15px;
  margin-bottom: 5px;
`
