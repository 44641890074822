export interface SaveOrUpdateAttributeRequest {
  uuid: string
  domId: number
  domType: number
  attrValue: number
}
export interface SaveOrUpdateAttributeResponse {
  status?: number
  message?: number
}

export enum UuidType {
  /**
   * To update user preferences so to persist stock filter selection
   */
  PARTS_USER_FILTER_LEVEL = 'PARTS_USER_FILTER_LEVEL',
  /**
   * To update user preferences when labor rates are updated.
   */
  LABOR_RATE = 'LABOR_RATE',
}

export enum OrgType {
  LOC = 'loc',
  REG = 'reg',
  WD = 'wd',
}
export enum DomType {
  /**
   * location
   */
  LOC = '4',
  /**
   * store
   */
  REG = '3',
  /**
   * Warehouse
   */
  WD = '2',
}
