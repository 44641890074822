import {
  ArrowDropDown,
  ArrowDropUp,
  DragIndicator,
  RemoveRedEye,
  VisibilityOff,
} from '@mui/icons-material'
import { ReactElement } from 'react'
import { Translate } from 'src/i18n'
import styled from 'styled-components'
import { SearchOptions, SearchPreference } from '../interfaces'

interface SearchPreferencesProps {
  index: number
  item: SearchPreference
  showIndex?: boolean
  onHideElement: (item: SearchPreference) => void
}

export const SearchPreferenceItem = (
  props: SearchPreferencesProps
): ReactElement => {
  const { item, index, showIndex, onHideElement } = props
  return (
    <Wrapper>
      {showIndex && !item.hidden && <Index>{index}</Index>}
      <ItemBox>
        <DragIcon>
          <ArrowUpIcon />
          <DragIndicator />
          <ArrowDownIcon />
        </DragIcon>
        <Title {...{ ...props }}>
          {Translate(item.id)} {!item.hidden && index === 1 && ' (default)'}
        </Title>
        {!item.hidden && item.id !== SearchOptions.vehicle_ymme && (
          <RemoveRedEyeIcon onClick={() => onHideElement(item)} />
        )}
        {item.hidden && item.id !== SearchOptions.vehicle_ymme && (
          <VisibilityOffIcon onClick={() => onHideElement(item)} />
        )}
      </ItemBox>
    </Wrapper>
  )
}

SearchPreferenceItem.defaultProps = {
  showIndex: true,
}

const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  padding-bottom: 1.5rem;
`

const Index = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #797979;
  mix-blend-mode: normal;
  opacity: 0.9;
`

const DragIcon = styled.div`
  display: flex;
  flex-direction: column;
  color: #adadaa;
`

const ArrowUpIcon = styled(ArrowDropUp)`
  position: relative;
  top: 0.5rem;
  color: #d3d2cf;
`

const ArrowDownIcon = styled(ArrowDropDown)`
  position: relative;
  bottom: 0.5rem;
  color: #d3d2cf;
`

const RemoveRedEyeIcon = styled(RemoveRedEye)`
  color: #adadaa;
`

const VisibilityOffIcon = styled(VisibilityOff)`
  color: #adadaa;
`

const Title = styled.div<SearchPreferencesProps>`
  flex-grow: 1;
  text-align: start;
  padding-left: 0.2rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(p) => (p.item.hidden ? '#adadaa' : '#050505')};
  mix-blend-mode: normal;
  opacity: 0.9;
`

const ItemBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 10px;
  width: 520px;
  height: 64px;
  background: #fafafa;
  border: 1px solid #e5e4e1;
  border-radius: 4px;
`
