import { IMenuItem } from 'src/features/navigation/TopNavigation/components/MenuItemLinks/MenuItem'
import { useSearchStore } from 'src/store/search/SearchStore'

export const useMenuItems = (): IMenuItem[] => {
  const searchStore = useSearchStore()

  const handleClickPartsCatalog = () => {
    searchStore.setShowVehicleTransition(false)
  }

  const menuItems: IMenuItem[] = [
    { text: 'home', path: '/home' },
    {
      text: 'partsCatalog',
      path: '/partsCatalog',
      onClick: handleClickPartsCatalog,
    },
    { text: 'vehicles', path: '/vehicleHistory' },
  ]

  if (import.meta.env.VITE_FEATURE_LISTS_ENABLED === 'true') {
    menuItems.push({ text: 'lists', path: '/lists' })
  }

  if (import.meta.env.VITE_FEATURE_ORDER_DETAILS_ENABLED === 'true') {
    menuItems.push({ text: 'orders', path: '/orderHistory' })
  }

  if (import.meta.env.VITE_FEATURE_QUOTES_ENABLED === 'true') {
    menuItems.push({ text: 'quotes', path: '/quotes' })
  }

  return menuItems
}
