import { ProductModel } from 'src/store/models/ProductModel'
import { LocalInventoryMessage } from '../types/localInventory'
import { partsBasketToSearchResults } from '../utils/localInventoryUtils'
import { IBasePartnerCommunicationManager } from '.'
import { IPartnerExternal } from '../types/PartnerExternal'

// declare const CefSharp: any
declare const external: IPartnerExternal

class COSTARCommunicationManager implements IBasePartnerCommunicationManager {
  public cancelCallBack(): void {
    // COSTAR is handling cancel based on click event.
    // CefSharp.PostMessage('Cancel')
  }

  public cartCallBack(data: string): void {
    const partsBasketElement: HTMLInputElement = document.getElementById(
      'partsBasket'
    ) as HTMLInputElement
    // if (!partsBasketElement) {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    //   ;(window as any).partsBasket = {
    //     value: btoa(data),
    //   }
    //   return
    // }
    partsBasketElement.value = ''
    partsBasketElement.value = btoa(data)
    // CefSharp.PostMessage(`PartsBasket=${btoa(data)}`)
  }

  public getLocalInventory(
    results: LocalInventoryMessage,
    cb: (data: ProductModel[]) => void
  ): void {
    const promise: Promise<unknown> = external.getPrices
      ? external.getPrices(JSON.stringify(results))
      : Promise.resolve('{}')

    promise
      .then((value) => {
        if (value) {
          cb(
            partsBasketToSearchResults(
              typeof value === 'string' ? JSON.parse(value) : value
            )
          )
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
        // eslint-disable-next-line no-console
        console.log('Error receiving from COSTAR')
      })
  }
}

export default COSTARCommunicationManager
