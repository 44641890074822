import { FieldUpdate } from 'src/features/cart/components/AvailabilityErrors'
import { IdValuePair } from './KeyValuePair'
import { LineType } from 'src/api/embedded/types/partsBasketSpec'

export interface ProductModel {
  type?: LineType
  isGfx?: boolean
  lineNo?: number
  status?: string
  partNumber?: string
  lineCode?: string
  catLineCode?: string
  c2cURL?: string
  description?: string
  extendedDescription?: string
  orderNumber?: number
  fromYear?: string
  perCarQty?: number
  info?: string
  header?: string
  manufacturerName?: string
  group?: IdValuePair<string>
  images?: Array<ImageLink>
  partImages?: Array<ImageLink>
  brand?: string
  brandName?: string
  brandUrl?: string
  subBrand?: string
  subBrandName?: string
  partDescriptionId?: number
  caProp65?: string
  allianceProductId?: string
  location?: Array<ProductLocationModel>
  replacementParts?: Array<ProductModel>
  alternateParts?: Array<ProductModel>
  terminologyId?: number
  gfxPartGreen?: boolean

  // for parts type graph
  allianceterminologyId?: string
  weight?: number
  width?: number
  height?: number
  genericDescriptionId?: number
  unAnsweredSpecificConditions?: string[]
  isStocked?: boolean
  length?: number
}

export enum AvailabilityErrorType {
  NOT_TRANSFERRED,
  CANT_BE_FULFILLED, // Can't be fulfilled from any location
  CANT_BE_FULFILLED_FROM_SELECTED_LOCATION, // Can't be fulfilled from selected location, but available in other locations.
  OUT_OF_STOCK_ALL_LOCATIONS, // Can be fulfilled from the primary location, but takes more time
  PRICE_OVERRIDE,
  MIN_QTY_CHANGE,
  LOCATION_OVERRIDE,
}

export interface AvailabilityError {
  errorType: AvailabilityErrorType
  fieldUpdates?: Array<FieldUpdate>
}

export interface AvailableStores {
  partNumber?: string
  location?: Array<ProductLocationModel>
}

export interface ModelFitment {
  model: string
  perCarQty: number
  years: string
  totalApps: number
}
export interface EngineFitment {
  engine: string
  models: Array<ModelFitment>
  perCarQty: number
  totalApps: number
  years: string
}
export interface MakeFitment {
  make: string
  perCarQty: number
  totalApps: number
  years: string
  engines: Array<EngineFitment>
}

export interface VehicleFitment {
  makes: Array<MakeFitment>
}

export interface PartVehicles {
  groups: Array<IdValuePair<string>>
  manufacturers: Array<string>
  vehicles: Array<VehicleFitment>
}

export interface VehicleFitmentHeader {
  image?: string
  partNumber?: string
  lineCode?: string
  description?: string
}

export interface QuantityPriceBreakHeader {
  image?: string
  partNumber?: string
  brand?: string
  description?: string
}

export interface ProductLocationModel {
  locationId: string
  seqNo?: number
  locType: string
  called: string
  schedule?: string
  warrantyCode?: string
  warrantyDescription?: string
  unitOfMeasure?: string
  buyIncrement?: number
  minQty?: number
  displayMinQty?: number
  buyQty?: number
  packNumUnits?: number
  qtyAvailable: number
  list: number
  cost: number
  coreList: number
  coreCost: number
  otherPrices?: Array<Price>
  priceBreaks?: Array<PriceBreak>
  originalBuyIncrement?: number
  originalUom?: string
  // TODO: remove the temporary next two fields after confirming the API map
  order?: string
  snCalled?: string
  estimatedDelivery?: string
  isSelected: boolean
  locationStatus?: string
}

export interface Price {
  priceCodeName: string
  priceValue: number
}

export interface PriceBreak {
  minQty: number
  maxQty: number
  unitCost: number
}

interface ImageLink {
  type: ImageType
  url: string
}

export enum ImageType {
  THUMBNAIL = 'thumbnail',
  LARGE = 'large',
}
