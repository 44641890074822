import { ShoppingCart } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'

export const ShoppingCartMenuIcon = observer(
  (props: { qty: number }): ReactElement => {
    const { qty } = props
    return (
      <CartIconWrapper>
        <IconButton aria-label="ShoppingCart">
          <ShoppingCartIcon style={{ fontSize: 33 }} />
          <ShoppingCartIconMobile style={{ fontSize: 25 }} />
        </IconButton>
        {qty > 0 && <CartQuantity>{qty}</CartQuantity>}
      </CartIconWrapper>
    )
  }
)

export const ShoppingCartMenu = observer(
  (props: { qty: number }): ReactElement => {
    const { qty } = props
    return (
      <NavLink to="/cart">
        <ShoppingCartMenuIcon qty={qty} />
      </NavLink>
    )
  }
)

export const NavigationItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

export const LogoImage = styled.img`
  width: 167px;
  height: 65px;
  margin-left: 15px;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    width: 101.17px;
    height: 37.42px;
    margin-left: 0px;
  }
`

export const BlackBackground = styled.div`
  height: 100%;
  width: 65%;
  background-color: #000;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: ${breakpoints.laptopLarge}px) {
    width: 92%;
  }
`

export const RotatedBlackLine = styled.div`
  width: 28px;
  height: 100%;
  margin-left: -14px;
  transform: skewY(-15deg) rotate(15deg);
  background-color: #000000;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

export const WhiteBackground = styled.div`
  height: 100%;
  width: 35%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 2rem;
  margin-right: 30px;
  align-items: center;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    gap: 0;
    background-color: #000;
    margin-right: 10px;
  }
`

export const WhiteBackgroundEnd = styled(WhiteBackground)`
  justify-content: flex-end;
  cursor: auto;
`

export const CartIconWrapper = styled.div`
  display: inline-block;
  position: relative;
`

export const CartQuantity = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  font-size: 14px;
  font-family: Oswald, serif;
  height: 24px;
  width: 24px;
  background-color: ${(p) => p.theme.colors.primary};
  color: #000;
  text-decoration: none;
  text-align: center;
`

export const ShoppingCartIcon = styled(ShoppingCart)`
  color: ${defaultTheme.colors.black};
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

export const ShoppingCartIconMobile = styled(ShoppingCart)`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    color: ${defaultTheme.colors.white};
    display: block;
  }
`
