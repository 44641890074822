import { sortBy } from 'lodash-es'
import {
  ProductLocationModel,
  ProductModel,
} from 'src/store/models/ProductModel'
import { SearchResultsResponse } from 'src/store/models/SearchModels'
import {
  AlternateLocation,
  LocalInventoryMessage,
  Part,
  PartsBin,
} from '../types/localInventory'

const ORIGINAL_PART_KEY = 'v3-original-product'
const ORIGINAL_PART_LOCATIONS_KEY = 'v3-original-product-locations'

const getPrimaryLocation = (
  locations: ProductLocationModel[]
): ProductLocationModel => {
  return locations.find((loc) => loc.locationId === '100') || locations[0]
}

export const partsBasketToSearchResults = (
  partsData: PartsBin
): ProductModel[] => {
  try {
    return partsData.part.map(inventoryPartToProduct)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(partsData)
    // eslint-disable-next-line no-console
    console.log('Failed to convert the partsBasket to products')
    // eslint-disable-next-line no-console
    console.log(e)
    return []
  }
}

export const searchResultsToPartsBasket = (
  results: SearchResultsResponse
): LocalInventoryMessage => {
  return {
    localInventory: true,
    partsData: {
      responseType: null,
      schemaVersion: null,
      orderNumber: null,
      part: results.parts.map(productToInventoryPart),
      ponumber: null,
      searchQualifier: {
        searchTxt: '',
        vehicleQualifier: {},
      },
    },
  }
}

const inventoryPartToProduct = (part: Part): ProductModel => {
  const { attribute, alternateLocation } = part
  const originalPartLocations =
    attribute && attribute.length > 0
      ? attribute.find((attr) => attr.name === ORIGINAL_PART_LOCATIONS_KEY)
          .value
      : undefined

  const originalLocations: ProductLocationModel[] = JSON.parse(
    originalPartLocations || '[]'
  )

  let locations = []

  // We moved one location to the part while transferring.
  // If the length is one less than the original length, the host didn't add its location for this part.
  // Reuse the original locations.
  if (alternateLocation.length === originalLocations.length - 1) {
    locations = originalLocations
  } else {
    let localLocation: ProductLocationModel
    // The local location id should always be less than 100.
    // If the location id in the part is less than 100,
    // The host updated the location details in the part and pushed the existing location to alternateLocation.
    if (Number(part.locationId) < 100) {
      localLocation = getHostLocationFromPartOrLocation(part)
    } else {
      localLocation = getHostLocationFromPartOrLocation(
        alternateLocation.find((location) => Number(location.locationId) < 100)
      )
    }

    let sortedLocations = sortBy(
      [localLocation, ...originalLocations],
      'locationId'
    )

    // Update the `isSelected` in local location only if the quantity available is greated than min order quantity.
    if (localLocation.qtyAvailable >= localLocation.displayMinQty) {
      sortedLocations = sortedLocations.map((productLocation) => ({
        ...productLocation,
        isSelected: false,
      }))
      sortedLocations[0] = { ...sortedLocations[0], isSelected: true }
    }

    locations = sortedLocations
  }

  const originalPartDetails =
    attribute && attribute.length > 0
      ? attribute.find((attr) => attr.name === ORIGINAL_PART_KEY).value
      : undefined

  return {
    ...JSON.parse(originalPartDetails || '{}'),
    ...part,
    lineCode: part.manufacturerCode,
    isGfx: part.gfx,
    location: locations,
    alternateParts: part.alternatePart.map(inventoryPartToProduct),
  }
}

// TODO: check if we can merge this and `orderRespItemToPBItem`.
const productToInventoryPart = (part: ProductModel): Part => {
  const { location, alternateParts, ...rest } = part
  const primaryLocation = productLocationToPartLocation(
    getPrimaryLocation(location)
  )
  const alternateLocation = location
    .filter((loc) => loc.locationId !== primaryLocation.locationId)
    .map(productLocationToPartLocation)

  return {
    ...rest,
    alternateLocation,
    manufacturerCode: part.lineCode,
    partCategory: '',
    quantityAvailable: primaryLocation.quantityAvailable,
    unitCorePrice: primaryLocation.unitCorePrice,
    unitCostPrice: primaryLocation.unitCostPrice,
    unitListPrice: primaryLocation.unitListPrice,
    unitListCore: primaryLocation.unitListCore,
    priceOverride: false,
    priceOverrideMsg: '',
    locationId: primaryLocation.locationId,
    locationDescription: primaryLocation.locationDescription,
    locationViewOnly: primaryLocation.locationViewOnly,
    minOrderQty: primaryLocation.minOrderQty,
    qtyBuyIncr: primaryLocation.qtyBuyIncr,
    unitOfMeasure: primaryLocation.unitOfMeasure,
    alternatePart: alternateParts.map(productToInventoryPart),
    brandId: 0,
    partId: '0',
    buyQty: primaryLocation.buyQty,
    isSelected: primaryLocation.isSelected,
    gfx: part.isGfx,
    partNumber: part.partNumber || '',
    brand: part.brand || '',
    description: part.description || '',
    status: part.status || '',
    attribute: [
      {
        name: ORIGINAL_PART_KEY,
        value: JSON.stringify(rest),
      },
      {
        name: ORIGINAL_PART_LOCATIONS_KEY,
        value: JSON.stringify(location),
      },
    ],
  }
}

const getHostLocationFromPartOrLocation = (
  part: Part | AlternateLocation
): ProductLocationModel => {
  return {
    called: part.locationDescription,
    coreCost: part.unitCorePrice,
    coreList: part.unitListCore,
    cost: part.unitCostPrice,
    isSelected: false,
    list: part.unitListPrice,
    locationId: part.locationId,
    qtyAvailable: part.quantityAvailable,
    buyIncrement: part.qtyBuyIncr,
    locType: '',
    buyQty: part.buyQty,
    displayMinQty: part.minOrderQty,
    estimatedDelivery: '',
    minQty: part.minOrderQty,
    originalBuyIncrement: part.qtyBuyIncr,
    originalUom: part.unitOfMeasure,
    otherPrices: [],
    unitOfMeasure: part.unitOfMeasure,
  }
}

const productLocationToPartLocation = (
  location: ProductLocationModel
): AlternateLocation => {
  return {
    ...location,
    quantityAvailable: location.qtyAvailable,
    locationDescription: location.called,
    locationViewOnly: false,
    minOrderQty: location.minQty,
    qtyBuyIncr: location.buyIncrement,
    isBuyDirect: true,
    unitCorePrice: location.coreCost,
    unitCostPrice: location.cost,
    unitListPrice: location.list,
    unitListCore: location.coreList,
    isSelected: location.isSelected,
    altBuyIncr: false,
    unitOfMeasure: location.unitOfMeasure,
    buyQty: location.buyQty,
  }
}
