import NiceModal from '@ebay/nice-modal-react'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { ReactElement } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { IS_INTEGRATED_VERSION } from './config/ConfigManager'
import history from './helpers/history'
import { Routes } from './main/Routes'
import { DiscardAndStartNewOrder } from './store/cart/DiscardAndStartNewOrder'
import DuplicateVehicleModal from './store/cart/DuplicateVehicleModal'
import { defaultTheme } from './theme'
import './theme/bootstrap-overrides.css'
import { ModalNotification, ToastNotification } from './ui-components'
import DDSUnavialbleDrawer from './features/orderHistory/components/ddsAdvancedTracker/DDSUnavialableDrawer'

const queryClient = new QueryClient()

function App(): ReactElement {
  const muiTheme = createTheme()

  return (
    <>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={defaultTheme}>
          <QueryClientProvider client={queryClient}>
            <Router history={history}>
              <NiceModal.Provider>
                <div>
                  <Routes />
                </div>
                <ToastNotification />
                <ModalNotification />
                <DuplicateVehicleModal />
                <DiscardAndStartNewOrder />
                <DDSUnavialbleDrawer />
              </NiceModal.Provider>
            </Router>
          </QueryClientProvider>
        </ThemeProvider>
      </MuiThemeProvider>

      {/* Form used to transfer data to host integrations. */}
      {IS_INTEGRATED_VERSION && (
        <form id="partsBasketForm">
          <input type="hidden" id="partsBasket" name="partsBasket" value="" />
        </form>
      )}
    </>
  )
}

export default App
