import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { SimpleModal } from 'src/ui-components'
import { Translate } from 'src/i18n'

export enum DiscardAndStartNewOrderActions {
  START_NEW = 'START_NEW',
  CANCEL = 'CANCEL',
}

export const DiscardAndStartNewOrder = observer((): ReactElement => {
  const uiStore = useUiStateStore()
  const { promise } = uiStore.discardAndStartNewOrderModalParams || {}

  return (
    <SimpleModal
      title="startNewOrder"
      primaryActionText="discardAndStartNew"
      secondaryActionText="cancel"
      onPrimaryAction={() => {
        promise?.resolve(DiscardAndStartNewOrderActions.START_NEW)
      }}
      onSecondaryAction={() =>
        promise?.resolve(DiscardAndStartNewOrderActions.CANCEL)
      }
      onCancel={() => promise?.resolve(DiscardAndStartNewOrderActions.CANCEL)}
      disablePrimaryButton={false}
      show={uiStore.showDiscardAndStartNewOrderModal}
    >
      {Translate('discardAndStartNewCartMessage')}
    </SimpleModal>
  )
})
