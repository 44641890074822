import { PartsRequest } from 'src/api/part/interface'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import {
  AstSearchResultsRequest,
  GFXPartsBinRequest,
  InterchangeRequest,
  LinesManufacturer,
  PartDetails,
  PartDetailsParams,
  PartDetailsRequest,
  PartVehiclesResponse,
  PartsBinRequest,
  SearchRequestBase,
  SearchResultsRequest,
  SearchResultsResponse,
} from 'src/store/models/SearchModels'
import { UserAttributeKey } from 'src/store/user/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'
import CatalogServiceProvider from './CatalogServiceProvider'

import authManager from '../api/security/Auth'
import { AxiosRequestConfig } from 'axios'
import {
  IS_INTEGRATED_VERSION,
  MYPLACE_SERVICE_NAME,
} from 'src/config/ConfigManager'
import { CatalogIndexTracker } from 'src/api/metrics/CatalogIndexTracker'
import { defaultCredentials } from 'src/aesInit'
import { EmbeddedCommunicationsManager } from 'src/api/embedded/EmbeddedCommunicationsManager'

class PartServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'part', '1.1', '')
  }

  byPartsApi = async (
    reqBody: PartsRequest
  ): Promise<SearchResultsResponse> => {
    const resp = await this.post<SearchResultsResponse>(
      'part/byParts',
      reqBody,
      {
        params: {
          catalogId: 99,
        },
      }
    )
    return resp.data
  }

  partDomain = () =>
    StoreInstances?.userStore?.getUserAttribute(UserAttributeKey.partDomain)

  getGeneralSearchResults = async (
    endpointFullUrl: string,
    reqData: SearchResultsRequest
  ): Promise<SearchResultsResponse> => {
    const resp = await this.post<SearchResultsResponse>(
      endpointFullUrl,
      reqData,
      {
        params: {
          partDomain: this.partDomain(),
        },
      }
    )
    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  public beforeEachRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      params: {
        catalogId:
          StoreInstances.searchStore?.currentVehicle?.type?.id === undefined
            ? 111
            : StoreInstances.searchStore.currentVehicle.type.id,
        covListId: StoreInstances.userStore.preferences?.mclOrgId,
        countryId: StoreInstances.userStore.country.countryCode,
        buyerId:
          IS_INTEGRATED_VERSION &&
          EmbeddedCommunicationsManager.integrationInfo.allowAnyBuyer
            ? EmbeddedCommunicationsManager.getPartsBasketCreds().buyerId
            : undefined,
        ...config.params,
      },
      headers: {
        'Service-Name': MYPLACE_SERVICE_NAME,
        'Catalog-Index': CatalogIndexTracker.index,
        'Partner-Name': IS_INTEGRATED_VERSION
          ? EmbeddedCommunicationsManager.getPartsBasketCreds().partnerId
          : defaultCredentials.partnerId,
        ...config.headers,
      },
    }
  }

  getSearchResults = async (
    reqData: SearchResultsRequest
  ): Promise<SearchResultsResponse> => {
    const endpointFullUrl = `part/byNavigation`
    const resp = await this.getGeneralSearchResults(endpointFullUrl, reqData)
    return resp
  }

  getDiagramsSearch = async (
    partsBinId: string
  ): Promise<SearchResultsResponse> => {
    const endpointFullUrl = `bin/${partsBinId}/diagramparts`
    const resp = await this.get<SearchResultsResponse>(endpointFullUrl, {
      params: {
        partDomain: this.partDomain(),
      },
    })

    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getAstPunchOutSearchResults = async (
    reqData: AstSearchResultsRequest
  ): Promise<SearchResultsResponse> => {
    const endpointFullUrl = `part/byASTNavigation`

    const resp = await this.post<SearchResultsResponse>(
      endpointFullUrl,
      reqData,
      {
        params: {
          catalogId: 7,
        },
        headers: {
          Authorization: await authManager.getCurrentToken(),
        },
      }
    )
    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getSearchResultsByText = async (
    reqData: SearchRequestBase,
    searchText: string,
    queryOverrides?: Record<string, string>
  ): Promise<SearchResultsResponse> => {
    const searchBy = encodeURIComponent(searchText)
    const resp = await this.post<SearchResultsResponse>(
      `part/bySearchTxt/${searchBy}`,
      reqData,
      {
        params: {
          ...queryOverrides,
        },
      }
    )

    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getPartsBinResults = async (
    partsBinId: string,
    reqData: PartsBinRequest,
    queryOverrides?: Record<string, string>
  ): Promise<SearchResultsResponse> => {
    const resp = await this.post<SearchResultsResponse>(
      `bin/${partsBinId}/part`,
      reqData,
      {
        params: {
          ...queryOverrides,
        },
      }
    )

    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getGfxPartsBinResults = async (
    partsBinId: string,
    reqData: GFXPartsBinRequest
  ): Promise<SearchResultsResponse> => {
    const resp = await this.post<SearchResultsResponse>(
      `bin/${partsBinId}/gfxpart`,
      reqData
    )

    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getInterchangeResults = async (
    reqData: InterchangeRequest
  ): Promise<SearchResultsResponse> => {
    const resp = await this.post<SearchResultsResponse>(
      `part/interchange`,
      reqData
    )

    await CatalogServiceProvider.handleMissedImages(resp.data)
    return resp.data
  }

  getPartVehicles = async (
    partsBinId: string,
    lineCode: number
  ): Promise<PartVehiclesResponse> => {
    const resp = await this.get<PartVehiclesResponse>(
      `bin/${partsBinId}/${lineCode}/vehicles`
    )
    return resp.data
  }

  clearPartsBinCache = async (partsBinId: string): Promise<void> => {
    try {
      this.delete(`bin/${partsBinId}`)
    } catch (e) {
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log('failed to delete partsBin cache')
    }
  }

  getLinesManufacturers = async (
    partsBinId: string
  ): Promise<LinesManufacturer[]> => {
    const resp = await this.post<LinesManufacturer[]>(
      `part/${partsBinId}/manufacturers`
    )
    return resp.data
  }

  getPartDetails = async (
    reqData: PartDetailsRequest,
    params: PartDetailsParams
  ): Promise<PartDetails> => {
    const resp = await this.post<PartDetails>(`part/detail`, reqData, {
      params: {
        allianceProductId: params.allianceProductId,
        lineCode: params.lineCode,
        partNumber: params.partNumber,
      },
    })
    return resp.data
  }
}

export default new PartServiceProvider()
