import {
  DirectionsCar,
  ExpandLess,
  ExpandMore,
  Close,
} from '@mui/icons-material'

import { ReactElement } from 'react'
import { Translate } from 'src/i18n'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import {
  useVehicleWidgetStore,
  VehicleWidgetDisplayState,
} from '../../VehicleSearch/store/VehicleWidgetStore'
import { defaultTheme } from 'src/theme'
import { useSearchStore } from 'src/store/search/SearchStore'

interface VehicleInfoProps {
  year?: string
  model?: string
  engine?: string
  onClick: () => void
  open: boolean
}

const VehicleInfo = (props: VehicleInfoProps): ReactElement => {
  const { year, model, engine, open, onClick } = props

  const searchStore = useSearchStore()
  const { currentVehicle } = searchStore

  const subModel = currentVehicle.vehicleSpecs?.find(
    (obj) => obj.label === 'Series'
  )?.value

  const primaryText =
    year && model ? `${year} ${model}` : Translate('addVehicle')

  const secondaryText =
    year && model ? `${engine}` : Translate('selectAVehicle')

  const vehicleWidgetStore = useVehicleWidgetStore()
  const headerText =
    vehicleWidgetStore.displayState === VehicleWidgetDisplayState.edit && open
      ? Translate('editVehicle')
      : `${year} ${model}`

  return (
    <>
      <VehicleRow
        onClick={onClick}
        aria-controls="collapse-vehicle-widget"
        aria-expanded={open}
      >
        <DirectionsCarIcon />
        <VehicleInfoContainer>
          <Row>
            <MainText>{primaryText}</MainText>
            {!open ? <ExpandMore /> : <ExpandLess />}
          </Row>
          {subModel?.length > 0 && subModel && (
            <SubmodelText>{subModel}</SubmodelText>
          )}
          <SecondaryText>{secondaryText}</SecondaryText>
        </VehicleInfoContainer>
      </VehicleRow>

      <RowMobile
        onClick={() =>
          vehicleWidgetStore.setIsDrawerOpened(
            !vehicleWidgetStore.isDrawerOpened
          )
        }
        aria-controls="collapse-vehicle-widget"
        aria-expanded={open}
      >
        <VehicleInfoContainer>
          <InnerRowMobile>
            <DirectionsCarIcon />

            {year && model ? (
              <FlexWrapper>
                <MainText>{headerText}</MainText>

                <InnerRowMobile>
                  <SubmodelTextMobile>
                    {subModel && subModel}
                    {subModel && ' | '}
                    {secondaryText && (
                      <SecondaryTextMobile>{secondaryText}</SecondaryTextMobile>
                    )}
                  </SubmodelTextMobile>
                </InnerRowMobile>
              </FlexWrapper>
            ) : (
              <MainText>{primaryText}</MainText>
            )}
          </InnerRowMobile>
          <InnerRowMobile>
            {!open ? (
              <ExpandMore />
            ) : (
              <Cancel>
                <CloseIcon />
                <CancelText>Cancel</CancelText>{' '}
              </Cancel>
            )}
          </InnerRowMobile>
        </VehicleInfoContainer>
      </RowMobile>
    </>
  )
}

VehicleInfo.defaultProps = {
  year: '',
  model: '',
}

const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
  @media print {
    display: none;
  }
`

const VehicleRow = styled(Row)`
  width: 282px;
`

const RowMobile = styled.div`
  display: none;

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: flex;
    border: 1px solid black;
    border-radius: 3px;
    width: 100%;
    height: 56px;
    padding: 10px 11px 11px 12px;
    font-size: 18px;
    cursor: pointer;
  }
`

const InnerRowMobile = styled.div`
  display: flex;
  cursor: pointer;
`

const VehicleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding-left: 15px;

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    flex-direction: row;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

const MainText = styled.div`
  align-items: left;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  min-width: 87px;
`

const SecondaryText = styled.div`
  align-items: left;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
`

const SecondaryTextMobile = styled.span`
  color: ${defaultTheme.colors.columnBorder};
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const SubmodelText = styled.div`
  color: ${defaultTheme.colors.almostBlack};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
const SubmodelTextMobile = styled.span`
  color: ${defaultTheme.colors.black};
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DirectionsCarIcon = styled(DirectionsCar)`
  font-size: 40px;
  margin-left: -6px;
  font-family: 'Roboto';

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    font-size: 22px;
    margin: 3px 11px 0px 0px;
  }
`

const Cancel = styled.div`
  color: ${defaultTheme.colors.link};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const CloseIcon = styled(Close)`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 2px;
`

const CancelText = styled.span`
  margin: 12px 0px 0px 4.75px;
`

export default VehicleInfo
