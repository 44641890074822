import { Snackbar, SnackbarOrigin, Slide } from '@mui/material'
import { breakpoints } from 'src/theme/breakpoints'
import { CheckCircle, Error } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import { ReactElement, useState } from 'react'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Alert } from 'react-bootstrap'
import { Link } from 'src/ui-components'
import { zIndex } from 'src/theme/zIndex'
import { I18n } from '../../i18n'
import { TransitionProps } from '@mui/material/transitions'

const SlideTransition = (
  props: TransitionProps & { children: React.ReactElement<unknown> }
) => {
  return <Slide {...props} direction="down" />
}

export enum ToastNotificationVariant {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export interface ToastNotificationProps {
  open: boolean
  variant?: ToastNotificationVariant
  message: string
  messageParams?: Array<string | number>
  link?: Link
  time?: number
  onClose?: () => void
}

interface StyledAlertProps {
  variant: ToastNotificationVariant
}

export const ToastNotification = observer((): ReactElement => {
  const { toastNotification, hideToastNotification } = useUiStateStore()
  const [vertical] = useState<SnackbarOrigin['vertical']>('top')
  const [horizontal] = useState<SnackbarOrigin['horizontal']>('center')

  const handleClose = () => {
    hideToastNotification()
  }

  const handleClickedLink = () => {
    toastNotification.link?.action()
    hideToastNotification()
  }

  const getToastNotificationIcon = (variant: ToastNotificationVariant) => {
    switch (variant) {
      case ToastNotificationVariant.SUCCESS:
        return <CheckCircle />
      case ToastNotificationVariant.WARNING:
        return <WarningIcon />
      case ToastNotificationVariant.ERROR:
        return <Error />
      default:
        return <InfoIcon />
    }
  }

  const variant = toastNotification.variant || ToastNotificationVariant.INFO

  return (
    <SnackbarComponent
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical, horizontal }}
      open={toastNotification.open}
      autoHideDuration={toastNotification.time || 3000}
      onClose={handleClose}
    >
      <StyledAlert onClose={handleClose} variant={variant}>
        <Row>
          {getToastNotificationIcon(variant)}
          <Text>
            <I18n
              name={toastNotification.message}
              textReplacement={toastNotification.messageParams}
            />
          </Text>
        </Row>
        {toastNotification.link && (
          <StyledAction onClick={handleClickedLink}>
            {toastNotification.link.text}
          </StyledAction>
        )}
      </StyledAlert>
    </SnackbarComponent>
  )
})

const StyledAction = styled.span`
  padding-left: 3rem;
  text-decoration: underline;
  align-content: end;
  white-space: nowrap;
  cursor: pointer;
`

const Text = styled.span`
  padding-left: 0.3rem;
`

const Row = styled.div`
  display: flex;
`

const SnackbarComponent = styled(Snackbar)`
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    min-width: 90vw;
  }
  z-index: ${zIndex.toastNotification};
`

const StyledAlert = styled(Alert)<StyledAlertProps>`
  display: flex;
  justify-content: space-between;
  ${(p) => {
    switch (p.variant) {
      case ToastNotificationVariant.SUCCESS:
        return `
          background-color: ${p.theme.colors.green};
          color: #ffffff;
        `
      case ToastNotificationVariant.ERROR:
        return `
          background-color: ${p.theme.colors.red};
          color: #ffffff;
        `
      case ToastNotificationVariant.WARNING:
        return `
          background-color: ${p.theme.colors.warningBackground};
        `
      case ToastNotificationVariant.INFO:
        return `
          background-color: ${p.theme.colors.informationBackground};
        `
      default:
        return ''
    }
  }};
  border: none;
  border-radius: 3px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  min-width: 614px;
  @media screen and (max-width: ${breakpoints.mobileLarge}px) {
    min-width: 90vw;
  }
  width: auto;
`
