export const esMx = {
  accessoriesSuppliesAndTools: 'Accesorios, suministros y herramientas',
  account: 'Cuenta',
  add: 'Añadir',
  addA: 'Añadir a',
  addACustomName: 'Añadir un nombre personalizado',
  addItems: 'Añadir artículos',
  addItemsToList: 'Añadir artículos a la lista',
  additionalDetails: 'Detalles adicionales',
  addJust: 'Añadir sólo',
  addNewList: 'Añadir nueva lista',
  addParts: 'Añadir piezas',
  addPartToList: 'Añadir pieza a listas',
  addQuoteToCart: 'Añadir cotización a la cesta',
  updateAndAddToCart: 'Update And Add To Cart', //TO DO
  addToCart: 'Añadir a la cesta',
  addToList: 'Añadir a lista',
  addVehicle: 'Añadir vehículo',
  adhesives: 'Adhesivos',
  advancedSearch: 'Búsqueda Avanzada',
  aftermarketPartAvailable: 'Recambios disponibles',
  all: 'Todos',
  all31Days: 'Todos (31 días)',
  allCarts: 'todos los carros',
  allItemsFromEachCartWillBeRemovedAndCannotBeUndone:
    'Todos los artículos de cada carro se eliminarán y no se podrán deshacer.',
  allianceConnect: 'Alliance Connect',
  allLines: 'Todas las líneas',
  allOrders: 'Todos los pedidos',
  allParts: 'Todas las piezas',
  allProducts: 'Todos los productos',
  allRightsReserved: 'Todos Los Derechos Reservados',
  allVehicles: 'Todos los vehículos',
  allVehiclesWereSavedAsQuotes:
    'Todos los vehículos guardados como cotizaciones',
  alternateFor: 'Alternar por',
  amount: 'cantidad',
  amountRequestedCanBeOrderedButWillTakeLongerToBeDelivered:
    'La cantidad solicitada puede pedirse pero tardará más tiempo en ser entregada',
  amountRequestedUnavailableWillBeOrderedFromLocation:
    'cantidad solicitada no disponible, se pedirá a partir de %.',
  anAdminWillBeReachingOutToYou:
    'Un administrador se pondrá en contacto contigo. Si no recibe respuesta, póngase en contacto con el servicio de atención al cliente.',
  applyChanges: 'Aplicar cambios',
  areNotAvailableInOurDatabase:
    'no están disponibles en nuestra base de datos.',
  areYouSureYouWantTo: '¿Está seguro de que desea',
  areYouSureYouWantToDelete: '¿Seguro que desea eliminar?',
  areYouSureYouWantToEmptyYour: '¿Está seguro de que quieres vaciar tu',
  areYouSureYouWantToEmptyYourCart: '¿Está seguro que desea vaciar su cesta?',
  areYouSureYouWantToRemove: '¿Está seguro que quiere eliminar',
  areYouSureYouWantToRemoveYour: '¿Está seguro que desea eliminar su',
  areYouSureYouWantToUpdate: '¿Está seguro que desea actualizar',
  arrivedOn: 'llegó el %',
  at: 'en',
  automaticEntry: 'entrada automática.',
  automaticEntryYouHave: 'entrada automática. Usted tiene ',
  availability: 'Disponibilidad',
  available: 'disponible',
  aVehicleWasNotFound:
    'No se ha encontrado ningún vehículo. Por favor, seleccione el vehículo manualmente.',
  back: 'Volver',
  backToAllDiagrams: 'Volver a todos los diagramas',
  backToCart: 'Volver a la cesta',
  backToLogin: 'Volver al inicio de sesión',
  backToOrders: 'Volver a Historial de pedidos',
  backToPreviousPage: 'Back to Previous Page', //TODO
  backToReviewItems: 'Volver a Revisar artículos',
  backToSavedQuotes: 'Volver a Cotizaciones guardadas',
  backToTop: 'Volver al inicio',
  bestEmailToReachYouAt:
    'Mejor correo electrónico para comunicarse con usted en',
  brand: 'Marca',
  brandAZ: 'Marca (A-Z)',
  brandZA: 'Marca (Z-A)',
  brands: 'Marcas',
  browseFile: 'Examinar archivo',
  buyAgain: 'Comprar otra vez',
  callForAvailability: 'Llamar para disponibilidad',
  callForPrice: 'Llamar para precio',
  cancel: 'Cancelar',
  cannotDeleteCategory: 'La categoría no puede ser eliminada',
  cart: 'Carrito',
  cartOnlyHasLaborInIt: '% de carros con mano de obra',
  cartsOnlyHaveLaborInThem: '% de carros con mano de obra',
  cartTotal: 'Total de carros',
  cat: 'Cat',
  catalogDiagram: 'Catálogo Diagrama',
  categories: 'Categorías',
  category: 'Categoría',
  categoryDeletionError: 'Error de eliminación de categoría',
  categoryNameAlreadyExists: 'El nombre de la categoría ya existe',
  categoryNameExceedsChars:
    'El nombre de la categoría supera los 30 caracteres',
  changedStoreDueToAvailability:
    'Cambio de tienda debido a la disponibilidad del artículo',
  changeGraphic: 'Modificar gráfico',
  changeLoaction: 'Cambiar acción',
  changeLocation: 'Cambiar ubicación',
  changeVehicle: 'Cambiar vehículo',
  chemicals: 'Productos químicos',
  chooseVehicleSystem: 'Elegir sistema de vehículo',
  clearAll: 'Borrar todo',
  clearAllFilters: 'Borrar todos los filtros',
  clearFilters: 'Borrar filtros',
  clearSearch: 'Borrar búsqueda',
  clearSelections: 'Borrar selecciones',
  click: 'Haga clic en',
  clickingThisPartWillChangeGraphic:
    'Al hacer clic en esta parte cambiará el gráfico que se muestra actualmente',
  clickPartToSelectAndHighlight:
    'Haga clic en la pieza para seleccionarla y resaltarla en la lista siguiente',
  clickToViewAvailableMfgList:
    'Haga clic para ver la lista de fabricantes disponibles',
  clickToViewPrintPartNumbers:
    'Haga clic para ver/imprimir los números de pieza',
  close: 'Cerrar',
  closeGroups: 'Cerrar Grupos',
  code: 'Código',
  comment: 'Comentario (Opcional)',
  comments: 'Comentarios',
  confirmationId: 'Confirmación Id',
  confirmNewPassword: 'Confirmar nueva contraseña',
  congratulationsThisOrderHasQualifiedFor:
    '¡Felicidades! Este pedido se ha clasificado para',
  congratulationsThisOrderQualifiesFor:
    '¡Felicidades! Este pedido clasifica para',
  contactAdminToRequestPasswordReset:
    'Comuníquese con el administrador para solicitar el restablecimiento de la contraseña',
  contactUs: 'Contáctenos',
  core: 'Básico',
  coreCost: 'Costo básico',
  coreFee: 'Cuota básica',
  coreList: 'Lista básica',
  corePrice: 'Precios básicos',
  cost: 'Costo',
  costBreakdown: 'Desglose de costos',
  costDetails: 'Detalle de costos',
  costHighLow: 'Costo (Alto-Bajo)',
  costLowHigh: 'Costo (Bajo-Alto)',
  costSummary: 'Resumen de costos',
  createCategory: 'Crear Categoría',
  createdDate: 'Fecha de creación',
  createdOn: 'Creado el',
  createGroup: 'Crear Grupo',
  createList: 'Crear lista',
  current: 'Actual',
  customerName: 'Nombre del cliente',
  customizeSearchPreferences: 'Personalizar las preferencias de búsqueda',
  customizeSearch: 'Personalizar búsqueda',
  customName: 'Nombre personalizado',
  dateRange: 'Intervalo de fechas',
  delete: 'Borrar',
  deleteCategory: 'Borrar categoría',
  deleteGroup: 'Borrar grupo',
  deleteItemFromList: 'Eliminar elementos de listas',
  deleteItems: 'Eliminar elementos',
  deleteList: 'Borrar lista',
  deleteMessage: '¿Está seguro que desea eliminar',
  deleteQuote: 'Borrar cotización',
  deleteQuotes: 'Borrar cotizaciones',
  deleteQuotesOlderThan: 'Borrar cotizaciones anteriores a',
  deleteVehicle: 'Borrar vehículo',
  delivered: 'Entregado',
  deliveryStatus: 'Estado de la entrega',
  deliveryStatusUnavailable: 'Delivery Status Unavailable', // TODO
  deliveryStatusUnavailableOrderPlacedByOtherMeans:
    'Delivery status unavailable. This order was placed via phone, walk-in, or through another application.', // TODO
  description: 'Descripción',
  descriptions: 'Descripciones',
  details: 'Detalles',
  diagram: 'Diagrama',
  diagrams: 'Diagramas',
  dismiss: 'Descartar',
  doingSoWillRemoveAllItems: 'Al hacerlo, se eliminarán todos los elementos.',
  dragAndDrop: 'o arrástrelo y suéltelo aquí',
  dragAndRearrangeTheItemsInThisList:
    'Arrastre y reorganice los elementos de esta lista',
  duplicateVehicle: 'Duplicar vehículo',
  eachAndEveryPerfectStopProductPurchaseearnsanenrty:
    'Todas y cada una de las compras de productos de parada perfecta ganan una entrada',
  edit: 'Editar',
  editCustomName: 'Editar nombre personalizado',
  editFitmentConditions: 'Editar condiciones de montaje',
  editItem: 'Editar Artículo',
  editList: 'Editar Lista',
  editParts: 'Editar Piezas',
  editSearch: 'Editar búsqueda',
  editUnknownItem: 'Editar artículo desconocido',
  editVehicle: 'Editar vehículo',
  email: 'Correo electrónico',
  emailAddress: 'Dirección de correo electrónico',
  emailRequired: 'Correo electrónico requerido',
  emptyCart: 'Carrito vacío',
  engine: 'Motor',
  english: 'Español',
  enterAPlateNumber: 'Introduzca un número de matrícula',
  enterCode: 'Introducir código',
  enterNewPassword: 'Introducir nueva contraseña',
  enterPart: 'Introduzca el número de pieza para intercambiar o buscar',
  enterVehicle: '', // TODO
  enterVin: 'Introduzca VIN',
  entryAddjust: 'entrada(s). Añadir sólo ',
  entryToDate: 'entrada(s) hasta la fecha.',
  entryYouHaveReachedTheMaximumNumberOfEntriesYouHave:
    'entrada(s). Ha alcanzado el número máximo de entradas. Ha ocurrido ',
  errorAddingProductsToTheCart: 'Error al añadir productos al carrito',
  errorDeletingVehicle: 'Error al borrar un vehículo',
  errorLoadingBannerPromotion: 'Error al cargar banner de promoción',
  errorLoadingEntries: 'Error al cargar entradas',
  errorMovingList: 'Error al mover la lista',
  errorOccurredWhileUpdatingVehicleName:
    'Se ha producido un error al actualizar el nombre del vehículo',
  errorProcessingOrders: 'Error al procesar pedidos',
  errorUpdatingProductQuantity: 'Error al actualizar la cantidad de productos',
  errorValidatingList: 'Se ha producido un error al validar la lista.',
  estimatedDelivery: 'Entrega estimada',
  existingCartWarningModal:
    'Usted tiene un vehículo existente en su carro que coincide con estas especificaciones. ¿Desea iniciar un nuevo carro o fusionarlo con el carro % existente?',
  expandAll: 'Expandir todo',
  experianAutocheck: 'Autocomprobación de Experian',
  exportList: 'Exportar lista',
  extraDetails: 'Extra Details', // TODO
  favorites: 'Favoritos',
  failedToLogin: 'Error al iniciar sesión',
  fieldsForShopUse: 'Campos para uso del taller',
  filter: 'Filtrar',
  filterBy: 'Filtrar por',
  filterByDateRange: 'Filtrar por rango de fechas',
  filterResults: 'Filtrar resultados',
  filterSelectLines: 'Filtrar',
  filterSort: 'Filter & Sort', // TODO
  fitmentConditions: 'Condiciones de montaje',
  fitsYears: 'Para todos los años',
  forFullSweepstakesDetails: 'para ver todos los detalles del sorteo',
  forgotPassword: 'Contraseña olvidada',
  formIcomplete: 'Formulario incompleto',
  forShopUse: 'Sólo para uso en tienda',
  french: 'Francés',
  general: 'General',
  getLaborData: 'Obtener datos laborales',
  gfx: 'GFX',
  gfxCatalog: 'Catálogo GFX',
  gfxSpecificAndAttributeConditions: 'GFX Condiciones específicas y atributos',
  gfxUnavailableForThisVehicle: 'GFX no disponible para este vehículo',
  giveaway: 'Regalar',
  go: 'Ir a',
  goToAccessoriesSuppliesAndTools:
    'Ir a accesorios, suministros y herramientas (AST)',
  goToCart: 'Ir al carrito',
  goToFirstPage: 'Ir a la primera página',
  goToNextPage: 'Ir a la página siguiente',
  goToPreviousPage: 'Ir a la página anterior',
  grandTotal: 'Total general',
  graphicId: 'ID gráfico',
  group: 'Grupo',
  groupName: 'Nombre del grupo',
  groupNameAlreadyExists: 'El nombre del grupo ya existe',
  groups: 'Grupos',
  hashTagParts: '# Piezas',
  here: 'aquí',
  hidden: 'oculto',
  hideMore: 'Ocultar más',
  home: 'Inicio',
  homePage: 'Página de Inicio',
  hours: 'Horas',
  howToReadThisGraphic: 'Cómo leer este gráfico',
  ifYouNeedThisGraphic:
    'si necesita este gráfico y trabajaremos en su solicitud lo antes posible.',
  import: 'Importar',
  importantMessageAboutItemsInYourCart:
    'Mensaje importante sobre los artículos de su cesta',
  importComplete: 'Importación completada',
  importList: 'Importar lista',
  importListDescription:
    'Importe múltiples artículos a una lista desde un archivo CSV. Debe incluir columnas para Grupo, MFR, Número de Parte, Cantidad de Inventario y Comentarios.',
  importListTemplateCSV: 'Utilice nuestra plantilla de lista (CSV)',
  inCart: 'En la Cesta',
  increasedFromTo: '% pasó de % a %',
  interchange: 'Intercambio',
  interchangeDisclaimer:
    'Intercambio es sólo para referencia. La aplicación debe ser verificada.',
  interchangeFor: 'Intercambio por',
  invalidEmailAddress: 'Dirección de correo electrónico no válida',
  invalidVin: 'VIN no válido',
  isNotAvailableInDatabase: 'no está disponible en nuestra base de datos.',
  issueInAddingQuoteToCart:
    'There was an issue in adding your items to the cart. Please check the cart page for details', //TODO
  item: 'Artículo',
  itemInYourCartCantBeFulfilled: '1 artículo en su carrito no se puede cumplir',
  itemInYourCartHasChangedPrice: '1 artículo de su cesta ha cambiado de precio',
  itemInYourCartHaveInsufficientQuantity:
    '1 artículo de su cesta tiene una cantidad insuficiente',
  items: 'Artículos',
  itemsAddedToTheCart: 'artículos añadidos a la cesta',
  itemsAvailability: '% de 8 disponibles',
  itemsInYourCartCantBeFulfilled:
    '% de artículos en su cesta no se pueden completar',
  itemWasnotAddedToYourCart: 'Item was Not added to your Cart', //TODO
  itemsInYourCartHaveChangedPrice:
    '% de artículos en su cesta han cambiado de precio',
  itemsInYourCartHaveInsufficientQuantity:
    '% artículos en su cesta tienen cantidad insuficiente',
  itemsPerPage: 'artículos por página',
  itemsReflectTheCurrentPrices: 'Los artículos reflejan los precios actuales',
  itemsRequireYourAttention: 'artículos que requieren su atención',
  itemsRestock: 'Artículos que se reponen cada cuatro meses',
  itemsSelected: 'artículos seleccionados a través de 1 lista',
  itemsWereNotTransferred: '% artículos no transferidos',
  itemUnavailable: 'Artículo no disponible en nuestra base de datos.',
  itemUnavailableAtAllLocations:
    'Artículo no disponible en todas las ubicaciones.',
  itemvalidated: 'Artículo validado',
  itemWasNotAdded: 'artículos no añadidos a su cesta',
  itemWasNotTransferred: '1 artículo no fue transferido',
  jobs: 'Trabajos',
  justAMoment: 'Sólo un momento',
  labor: 'Mano de obra',
  laborCost: 'Costo de mano de obra',
  laborData: 'Datos de mano de obra',
  laborId: 'ID de mano de obra',
  laborLookup: 'Búsqueda de mano de obra',
  laborOperationsDescriptions: 'Descripción de Operaciones Laborales',
  laborRate: 'Tarifa laboral',
  laborRatePerHour: 'Tarifa laboral por hora',
  laborsUpdated: 'Mano de obra actualizada',
  last7Days: 'Últimos 7 días',
  last12Months: 'Últimos 12 meses',
  last30Days: 'Últimos 30 días',
  lastUsed: 'Último utilizado',
  license: 'Licencia',
  licensePlate: 'Matrícula',
  lines: 'Lineas',
  lineCode: 'Código de línea',
  list: 'Lista',
  listDeletionError: 'Error de borrado de lista',
  listHighLow: 'Lista (Alto-Bajo)',
  listImported: 'Lista importada con éxito',
  listImportedError: '% líneas contenían errores y no pudieron ser importadas',
  listImportedSuccessfully: 'Su lista se ha importado correctamente (% líneas)',
  listLowHigh: 'Lista (Bajo-Alto)',
  listName: 'Nombre de lista',
  listNameAlreadyExists: 'El nombre de lista ya existe',
  listPrice: 'Precio de lista',
  listPriceBreakdown: 'Desglose del precio de catálogo',
  lists: 'Listas',
  location: 'Localización',
  locationChangedTo: 'Ubicación cambiada a',
  lookupDate: 'Buscar fecha',
  lookupItems: 'Buscar artículos',
  lookupSource: 'Fuente de búsqueda',
  lookupType: 'Tipo de búsqueda',
  lostSaleSuccess: 'Informe de venta perdida enviado',
  make: 'Marca',
  makeThisMyDefaultState: 'Hacer que este sea mi estado por defecto',
  manufacturer: 'Fabricante',
  manufacturers: 'Fabricantes',
  markAsFavorite: 'Marcar como favorito',
  markedAsFavorite: 'Marcar como favorito',
  mayNotFitYour: 'Puede que no se ajuste a su',
  mergeWithExistingCart: 'Fusionar con carrito existente',
  message: 'Mensaje',
  messageSent: '¡Mensaje enviado!',
  messageSentSuccess:
    'Gracias por su mensaje, alguien se pondrá en contacto con usted en función de su prioridad.',
  method: 'Method', // TODO
  mfr: 'Mfr',
  mileage: 'Kilometraje',
  minQuantity: 'Cantidad mínima',
  miscellaneous: 'Misceláneo',
  model: 'Modelo',
  more: 'más',
  moreOptions: 'Más opciones',
  move: 'Mover',
  moveGroup: 'Grupo Move',
  moveList: 'Mover lista',
  moveToAnotherList: 'Mover a otra lista',
  mustIncludeAPartToOrderThisCart:
    'debe incluir una parte para pedir este carro',
  multiPartInquiry: 'consulta de varias partes',
  multiPartInquiryValidationError:
    'Código de línea no válido, los caracteres especiales no están permitidos/la longitud del código de línea debe tener un mínimo de 2 caracteres y un máximo de 3 caracteres',
  myStore: 'Mi tienda',
  name: 'Nombre',
  needHelpResettingYourPassword:
    '¿Necesita ayuda para restablecer su contraseña?',
  new: 'Nuevo',
  newCategory: 'Nueva categoría',
  newGroup: 'Grupo Nuevo',
  newList: 'Lista Nueva',
  newLists: 'Listas Nuevas',
  newParts: 'Piezas Nuevas',
  newPassword: 'Contraseña nueva',
  next: 'Siguiente',
  nextChooseList: 'Siguiente',
  nextSelectGroup: 'SIGUIENTE',
  nextSelectDescription: 'SIGUIENTE: seleccionar descripcion',
  nextSelectPartTypes: 'SIGUIENTE',
  noAdditionalDetails: 'No hay detalles adicionales',
  noData: 'No hay datos disponibles',
  noImage: 'No hay imagen disponible',
  noMoreResults: 'No hay más resultados',
  noOrdersAvailable: 'No hay pedidos disponibles',
  noPartsFoundForThisGroup: 'No se han encontrado piezas en este grupo',
  noQuotesAvailable: 'No hay cotizaciones disponibles',
  noResults: 'No Results', //To DO
  noResultsFound: 'No se han encontrado resultados',
  noResultsFoundForThisVehicle:
    'No se han encontrado resultados para este vehículo',
  normal: 'Normal',
  note: 'Nota',
  notes: 'notas',
  noteToStore: 'Nota a la tienda',
  notesToStore: 'Notas a la tienda',
  noVehicleHistoryAvailable: 'No hay historial disponible',
  oePart: 'Pieza OE',
  oeParts: 'oeParts', //TODO
  oePartNumbersDisclaimer:
    'Los números de pieza OE son sólo para referencia. La aplicación debe ser verificada.',
  okIUnderstand: 'Ok, entiendo',
  onThisOrderFor: 'en este pedido para',
  onThisOrderForAnAutomaticEntry: 'en este pedido para una entrada automática.',
  oneYear: '1 año',
  onlineCatalog: 'Catálogo en línea',
  onlyLaborResultsAreAddedToCart: 'Only Labor Results are added to cart', //TODO
  onlyOutOfRequestedAreAvailable: 'Sólo % de % solicitados están disponibles',

  onlySpecsAreAddedToCart: 'Only specifications are added to cart', //TODO
  onlySpecsLaborResultsAreAddedToCart:
    'Only Specifications and Labor Results are added to cart', //TODO
  onlyPriced: 'Solo Precio',
  onlyStocked: 'Solo Disponible',
  openGroups: 'Grupos abiertos',
  or: 'O',
  order: 'Pedir',
  orderAllCarts: 'Pedir todos los carros',
  orderConfirmation: 'Confirmación del pedido',
  orderDate: 'Order Date', // TODO
  orderDetails: 'Detalles del pedido',
  ordered: 'Pedido',
  orderedOn: 'Pedido en %',
  orderForm: 'Formulario de pedido',
  orderFormIsIncomplete: 'El formulario de pedido está incompleto.',
  orderHistory: 'Historial de pedidos',
  orderNotFound: 'Pedido no encontrado',
  orderNumber: 'Número de pedido',
  orders: 'Pedidos',
  orderSummary: 'Resumen del pedido',
  orderThisCart: 'PEDIR ESTE CARRITO',
  orderTotal: 'Total del pedido',
  orderType: 'Tipo de pedido',
  orderConfirmationNumber: 'Order Confirmation Id', //TODO
  other: 'Otro (Especifique)',
  outOf: 'agotado',
  outOfStock: 'Fuera de stock',
  outOfStockAtAllLocationsWillBeOrderedFromOtherStore:
    'agotado en todas las ubicaciones, se pedirá a %.',
  outOfStockAtUpdatedTo: 'agotado en %, actualizado a %.',
  packed: 'Envasado',
  page: 'Página',
  part: 'Pieza',
  partAvailableInAnotherGroup: 'Pieza disponible en otro grupo',
  partAvailableInCurrentScreen: 'Pieza disponible en la pantalla actual',
  partDescription: 'Descripción de la pieza',
  partDescriptionAZ: 'Descripción de la pieza (A-Z)',
  partDescriptionZA: 'Descripción de la pieza (Z-A)',
  partNum: 'Número de pieza',
  partNumber: '# de pieza',
  partNumberAZ: 'Número de pieza (A-Z)',
  partNumberZA: 'Número de pieza (Z-A)',
  partsCatalog: 'Catálogo de piezas',
  partSelected: 'Pieza seleccionada',
  partType: 'Tipo de pieza',
  partTypes: 'Tipo de pieza',
  partWasAddedToYourCart: '%fue añadido a su carrito',
  partWasRemovedFromYourCart: '%fue removido de su carrito',
  partsHaveBeenAddedToYourCart: 'Se han agregado piezas a su carrito',
  password: 'Contraseña',
  passwordReset: 'Restablecer contraseña',
  passwordsMustMatch: 'Las contraseñas deben coincidir',
  perCarQty: 'Cantidad por vehículo',
  personalNote: 'Nota personal',
  phoneNumber: 'Número de teléfono',
  phoneNumberRequired: 'Número de teléfono requerido',
  pleaseContactForInformation: 'Comuníquese con % para obtener información',
  pleaseEnterTheCodeThatWasSentTo:
    'Por favor, ingrese el código que se envió a %.',
  pleaseEnterYourContactDetailsAndAnAdminWillBeInContactWithYou:
    'Por favor, ingrese su nombre de usuario, dirección de correo electrónico y número de teléfono, y un administrador se comunicará con usted',
  pleaseEnterYourScreenNameToSendVerificationCode:
    'Favor de ingresar su nombre de usuario para enviar un código de verificación a su correo electrónico',
  pleaseUploadValidCsvFile: 'Cargue un archivo CSV válido',
  pleaseUploadCsvFile: 'por favor cargue el archivo csv',
  po: 'OC',
  poNumber: 'Número de pedido',
  previousSearches: 'Búsquedas anteriores',
  price: 'Precio',
  pricePerItem: 'Precio por artículo',
  primary: 'Primaria',
  print: 'Imprimir',
  printCatalogDiagram: 'Print Catalog Diagram', //TODO
  priority: 'Prioridad',
  productCantBeFulfilledFromSelectedLocation:
    "Product can't be fulfilled from the selected location", //"TO DO"
  productCantBeFulfilled:
    'El producto no se puede suministrar desde ninguna ubicación',
  productToThisOrderForAnAutomaticEntry:
    'producto a este pedido para una entrada automática.',
  products: 'Productos',
  productsWereAddedToTheCart: 'Los productos se han añadido a la cesta',
  promoPolicy:
    'para conocer todos los detalles del Sorteo. *Estimación. El recuento real de participaciones en MyPlace4Parts se actualizará en un plazo de 24-48 horas.',
  purolatorCutoffTimeMessage: 'If ordered by % - Monday through Friday', // TODO
  purolatorDisclaimerMessage1:
    'Tenga en cuenta que los costos de envío son aproximados y pueden variar según el peso real y las dimensiones de los artículos.', // TODO
  purolatorDisclaimerMessage2:
    'Puede aplicarse un recargo por flete a todos los artículos clasificados como MERCANCÍAS PELIGROSAS.',
  purolatorDisclaimerMessage3:
    'Los pedidos realizados en sábado o domingo se tratarán como pedidos de lunes.',
  purolatorShippingEstimate: 'Estimación de envío de Purolator',
  qty: 'Cantidad',
  qtyPriceBreak: 'Cantidad Precio',
  quantity: 'Cantidad',
  quantityPriceBreak: 'Cantidad Precio de ruptura',
  quantitySelectedIsUnavailableAt:
    'La cantidad seleccionada no está disponible en',
  quantityRequestedIsLessThanMinimum: 'Quantity requested is less than minimum', //TODO
  quoteDeletedSuccessfully: 'El presupuesto se ha eliminado correctamente',
  quoteDetails: 'Detalles de la oferta',
  quoteId: 'Cotización ID',
  quoteNotFound: 'Presupuesto no encontrado',
  quotes: 'Cotizaciones',
  quotesOlderThan: 'cotizaciones anteriores a',
  quoteSummary: 'Resumen de la cotización',
  quoteVehicleNotFound:
    'Información del vehículo de la cotización no encontrada',
  rate: 'Valore',
  readLess: 'Leer menos',
  readMore: 'Leer Más',
  reason: 'Razón',
  recentOrders: 'Pedidos recientes',
  recentSearches: 'Búsquedas recientes',
  recentVehicles: 'Vehículos recientes',
  recommended: 'Recomendado',
  recommendedServices: 'Servicios Recomendados',
  removeAll: 'Eliminar todo',
  removeAllCarts: 'Quitar todos los carros',
  removeCart: 'Eliminar carro',
  removeYour: 'Eliminar su',
  rename: 'Renombrar',
  reName: 'Renombrar',
  renameCategory: 'Renombrar Categoría',
  reNameGroup: 'Renombrar grupo',
  renameList: 'Renombrar lista',
  replacementFor: 'Sustitución de',
  reportLostSale: 'Informar de venta perdida',
  requestNewCode: 'Solicitar un nuevo código',
  requestPasswordReset: 'Solicitar restablecimiento de contraseña',
  resetYourPassword: 'Restablecer contraseña',
  resortItems: 'Reordenar elementos',
  results: 'Resultados',
  returnToThePartsCatalogToKeepShopping:
    'Volver al catálogo de piezas para seguir comprando.',
  returnToVehicleHistory: 'Return to vehicle history', // TODO
  reviewItemsaddedtocart: 'Revisar artículos añadidos a la cesta',
  rollOverImageToMagnify: 'Roll over image to magnify', // TODO
  saveAll: 'Guardar todo',
  saveAsQuote: 'Guardar como presupuesto',
  saveChanges: 'Guardar cambios',
  savePreferences: 'Guardar Preferencias',
  screenName: 'Nombre de pantalla',
  screenNameRequired: 'Nombre de pantalla requerido',
  searchAllOrdersByPOPartNumberVehicle:
    'Buscar todos los pedidos (por OC, número de pieza, vehículo)',
  searchAllQuotesByPOPartNumberNotesLocation:
    'Buscar todos los presupuestos (por pedido, número de pieza, notas, ubicación)',
  searchAllQuotesBy:
    'Buscar todas las cotizaciones (por orden de compra, número de pieza, notas, ubicación)',
  searchByLicensePlate: 'Buscar por matrícula',
  searchByVin: 'Buscar por VIN',
  searchByYMME: 'Buscar por año, marca, modelo',
  searchDate: 'Buscar por fecha',
  searchForVehicles:
    'Búsqueda de vehículos (nombre personalizado, matrícula...)',
  searchManufacturers: 'Buscar por fabricante',
  searchPartBrandName: 'Buscar nº de pieza, marca o nombre de pieza',
  searchParts: 'Buscar piezas',
  secondary: 'Secundaria',
  seeLess: 'Ver menos',
  seeMore: 'Ver más',
  select: 'Seleccione',
  selectAConditionToChangeGraphic:
    'Seleccione una condición para cambiar el gráfico mostrado',
  selectAll: 'Seleccionar todo',
  selectAllItemsInThisList: 'Seleccione todos los artículos en esta lista',
  selectAVehicle: 'Seleccione un vehículo',
  selectCartToAddItem: 'Seleccionar cesta para añadir artículo',
  selectCategory: 'Seleccionar categoría',
  selected: 'seleccionado',
  selectedAcross: 'seleccionado a través de',
  selectedLines: 'Líneas seleccionadas',
  selectGfxAndFitmentConditions: 'Seleccione GFX y condiciones de montaje',
  selectGroup: 'Seleccionar grupo',
  selectManufactureLines:
    'Seleccione las líneas de fabricantes con las que desea filtrar los resultados.',
  selectManufacturersUpto: 'Puede seleccionar hasta 10 fabricantes.',
  selectPartType: 'Seleccione el tipo de pieza',
  selectPartTypes: 'selectPartTypes', // TODO
  selectVehicleEngine: 'Seleccione el motor del vehículo',
  sendAMessageTo: 'Enviar un mensaje a',
  sendAnother: 'Enviar otro',
  sendCode: 'Enviar Código',
  sendMessage: 'Enviar Mensaje',
  separateMultiplePartNumbers: 'Separe varios números de pieza con espacios',
  separateMultiplePartNumbersWithSpaces:
    'Separe varios números de pieza con espacios',
  serviceIntervals: 'Intervalos de servicio',
  servicesIntervalType: 'Tipo de Intervalo de Servicios',
  setLaborRate: 'Establecer tarifa de mano de obra',
  severe: 'Severo',
  shipByCourier: 'Enviar por mensajería',
  shipTo: 'Enviar a',
  shipment: 'Envío',
  shipmentCost: 'Gastos de envío',
  shipped: 'Enviado',
  shippedFrom: 'Enviado desde',
  shipping: 'Envío',
  shippingCostIsUpdated:
    'Su costo de envío ha cambiado según la cantidad actualizada',
  shippingId: 'ID Envío',
  // i.e: 'Mostrando 1-10 de 391 Pedidos',
  showAll: 'Mostrar Todo',
  showGraphic: 'Mostrando gráfico',
  showingResultsFor: 'Mostrando resultados de',
  showingXOutOfXItems: 'Mostrando % De % %',
  showResults: 'Mostrar resultados',
  skillLevel: 'Nivel de habilidad',
  sort: 'Ordenar',
  sortBy: 'Ordenar Por',
  spanish: 'Español',
  specifications: 'Especificaciones',
  specificationSearch: 'Buscar especificaciones',
  specificationUpdate: 'Especificaciones actualizadas',
  specify: 'Especifique si es otro',
  startNewCart: 'Iniciar nuevo carro',
  state: 'Estado',
  status: 'Estado',
  statusUnavailable: 'Status Unavailable', // TODO
  stock: 'Existencias',
  subject: 'Asunto',
  subOperation: 'Sub Operación',
  subModel: 'Submodel', //To DO
  successfullyCreatedCategory: 'Categoría creada con éxito',
  successfullyCreatedGroup: 'Grupo creado con éxito',
  successfullyCreatedList: 'Lista creada con éxito',
  successfullyDeletedCategory: 'Categoría eliminada con éxito',
  successfullyDeletedGroup: 'Grupo eliminado con éxito',
  successfullyDeletedList: 'Lista eliminada con éxito',
  successfullyListMoved: 'Lista movida correctamente',
  successfullyUpdatedCategory: 'Categoría actualizada correctamente',
  successfullyUpdatedGroup: 'Grupo actualizado correctamente',
  successfullyUpdatedList: 'Lista actualizada correctamente',
  summary: 'Resumen',
  theFollowingItemsWereLoaded:
    'Los siguientes elementos se cargaron después de aplicar una ordenación personalizada. Para aplicar su ordenación a los elementos a continuación, haga clic en reordenar elementos',
  theMinimumOrderQuantityIs: 'La cantidad mínima de pedido es %.',
  thenBy: 'Luego por',
  thePartWasAddedToYourCart: 'La pieza ha sido añadida a su cesta',
  fewOfThePartsWereNotAddedToCart:
    'few of the parts were not added to the cart', //TODO
  NoPartWasNotAddedToTheCart: 'No part Was added to your Cart', //TODO
  thePartWasRemovedFromYourCart: 'La pieza ha sido eliminada de su cesta',
  theQuantityRequestedIsNotAvailable:
    'La cantidad solicitada no está disponible',
  theseItemsWereUnavailableFromOurDatabase:
    'Estos artículos no estaban disponibles en nuestra base de datos',
  thesePartsMayNotFitYour: 'Puede que estas piezas no se ajusten a su',
  theseTicketsWillNotBeSentOnTheTicketToTheWarehouse:
    'Estos campos no se enviarán en el ticket al almacén.',
  thisCannotBeUndone: 'Esto no se puede deshacer.',
  thisIncludesTheFollowingCarts: 'Esto incluye los siguientes carros',
  thisItemWasUnavailableFromOurDatabase:
    'Este artículo no estaba disponible en nuestra base de datos',
  thisOrderHasQualifiedFor: 'Este pedido ha calificado para',
  thisQuoteCantBeFulfilled: 'This Quote can not be fulfilled', //TODO
  thisVinIsInvalidTryAgain:
    'Este número VIN no es válido. Favor de verificar el número VIN e inténtelo de nuevo',
  thisWeek: 'Esta semana',
  thisWillRemoveAllItemsFromTheCartAndCannotBeUndone:
    'Esto eliminará todos los artículos de la cesta y no se puede deshacer.',
  today: 'Hoy',
  tomorrow: 'Mañana',
  total: 'Total',
  totalApplications: 'Total de solicitudes',
  totalCost: 'Coste total',
  totalHours: 'Total Horas',
  totalList: 'Total Lista',
  totalQty: 'Total CANT',
  trackingHistory: 'Historial de seguimiento',
  trackingShipment: 'Seguimiento de envíos',
  transfer: 'Transferencia',
  transport: 'Transporte',
  tryAdjustingYourFilters: 'Intente ajustar sus filtros',
  tryAdjustingYourSearchOrLookupPartsInOurDatabase:
    'Intente ajustar su búsqueda o buscar piezas en nuestra base de datos',
  tryANewSearchOrEditYourFitmentConditions:
    'Intente una nueva búsqueda o edite sus condiciones de montaje',
  tryANewSearchOrUseThePartsCatalog:
    'Intente una nueva búsqueda o utilice el catálogo de piezas',
  trySelectingDifferentGroups:
    'Intente seleccionar grupos diferentes o buscar otro vehículo',
  type: 'Escriba',
  typeANameForThisVehicle: 'Escriba un nombre para este vehículo',
  unableToPlaceOrder: 'No se puede realizar el pedido',
  unableToPlaceOrderPleaseContactTheAdministrator:
    'No se ha podido realizar el pedido. Póngase en contacto con el administrador.',
  unavailableGroupGraphic: 'Gráfico de grupo no disponible',
  unavailablePartTypes: 'Tipos de piezas no disponibles',
  unavailableProductDetails: 'Detalles del producto no disponibles',
  unDone: 'Esto no se puede deshacer.',
  unknownAftermarketAvailability: 'Disponibilidad desconocida',
  unknownError: 'Un error desconocido ocurrió',
  unknownErrorPurolatorEstimates:
    'Se produjo un error desconocido al obtener estimaciones de purolator',
  update: 'Actualizar',
  updateCart: 'Actualizar cesta',
  updateList: 'Actualizar Lista',
  updateQuote: 'Actualizar presupuesto',
  updateVehicle: 'Actualizar vehículo',
  updatingYourVehicleWillChangeSearch:
    'Actualizar su vehículo cambiará los resultados de su búsqueda actual.¿Desea actualizar?',
  urgent: 'Urgente',
  user: 'Usuario',
  useTemplateCsv: 'Utilice nuestra lista de plantillas (CSV)',
  vehicle: 'Vehículo',
  vehicleDeleted: 'Vehículo eliminado',
  vehicleDeletedSuccessfully: 'Vehículo eliminado con éxito',
  vehicleFitment: 'Equipamiento del vehículo',
  vehicleFitmentUnavailable:
    'El equipamiento del vehículo no está disponible para este producto.',
  vehicleHistory: 'Historial del vehículo',
  vehicleIncomplete: 'Vehículo incompleto',
  vehicleInformationWasIncomplete:
    'La información del vehículo estaba incompleta. Por favor, añada los datos restantes del vehículo',
  vehicleInfoRetrievalError: '', // TODO
  vehicleLicensePlate: 'Matrícula',
  vehicleNotFound: 'Vehículo no encontrado',
  vehicleQuickFind: '', // TODO
  vehicleRequired: 'Vehículo requerido',
  vehicleRequiredForSearch: 'Por favor, seleccione un vehículo para continuar.',
  vehicles: 'Vehículos',
  vehicleSearch: 'Buscar vehículo',
  vehicleSpecification: 'Especificación',
  vehicleSuccessfullyDeleted: 'Vehículo eliminado con éxito',
  vehicleType: 'Tipo de vehículo',
  vehicleVIN: 'VIN',
  vehicleYMME: 'Año/Marca/Modelo/Motor',
  verifyingQuantityAvailable: 'Verificando cantidad disponible...',
  verifyingTheItemsInOurDatabase:
    'Verificando artículos en nuestra base de datos...',
  verifyingTheLocationForItems:
    'Verificación de la ubicación de los artículos...',
  viewCart: 'Ver Cesta',
  viewDetails: 'Ver detalles',
  viewLists: 'Ver listas',
  viewMore: 'Más información',
  viewOnly: 'Ver sólo',
  viewOrderDetails: 'Ver detalles del pedido',
  viewOrPrint: 'Ver o imprimir recibo',
  viewOrPrintQuote: 'Ver o imprimir presupuesto',
  viewPartAvailabilityInListBelow:
    'Ver disponibilidad de piezas en la siguiente lista',
  viewPreviousSearches: 'Ver búsquedas anteriores',
  viewQuoteDetails: 'Ver detalles de presupuestos',
  viewVinDetails: 'Ver detalles Vin',
  vin: 'VIN',
  vinCode: 'Código VIN',
  vinDetails: 'Detalles del VIN',
  vinMustContain17Characters: 'VIN debe contener 17 caracteres',
  vinPattern: 'Patrón VIN',
  visitTheWebsiteForOrderDetails:
    'Visite el sitio web para detalles de la orden',
  warrantyHours: 'Horas de garantía',
  warrantyInfo: 'Información de Garantía',
  wasAddedToList: 'fue añadido a la lista',
  weAreValidatingAvailability: 'Estamos validando la disponibilidad.',
  weUpdatedYourLocationTo: 'Hemos actualizado su ubicación a',
  weight: 'Peso',
  wereSorryAGraphicFor: 'Lo sentimos, un gráfico para',
  wereSorryTheProductDetailsFor:
    '% de artículos en su carrito no se puede cumplir',
  wtyHours: 'Horas WTY',
  xMonths: '% Meses',
  year: 'Año',
  years: 'Años',
  yesEmpty: 'Sí, vacío',
  yesIUnderstand: 'Sí, entiendo',
  yesterday: 'Ayer',
  youHaveReachedTheMaximumNumberOfEntries:
    'Ha alcanzado el número máximo de entradas',
  yourCartIsEmpty: 'Su cesta está vacía',
  yourCartWasSuccessfullyEmptied: 'Tu cesta se ha vaciado correctamente.',
  yourCost: 'Su coste',
  yourFiltersReturnedNoResults: 'Sus filtros no han dado ningún resultado',
  yourFirstItemWillBeYourDefault: 'Su primer artículo será el predeterminado',
  yourSearchReturnedNoResults: 'Su búsqueda no ha dado ningún resultado',
  zeroAvailableAtAllLocations: '0 disponible en todos los lugares',
  zeroAvailableAtLocation: '0 disponible en %',
}
