import {
  AccountCircle,
  KeyboardArrowDown,
  LocalPhone,
} from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { ReactElement, useRef, useState } from 'react'
import { Translate } from 'src/i18n'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useUserStore } from 'src/store/user/UserStore'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import impersonationImg from '../../../../assets/images/tabler_ghost.png'
import { AccountMenu } from './AccountMenu'

const iconStyle = { fontSize: 33, color: '#000' }
const iconStyleMobile = { fontSize: 25, color: '#fff' }

const UserAccountMenu = (): ReactElement => {
  const [showAccountMenu, setShowAccountMenu] = useState(false)
  const { setShowContactDrawer } = useUiStateStore()
  const userStore = useUserStore()
  const menuButton = useRef(null)

  return (
    <>
      <LocalPhoneIconButton
        aria-label="LocalPhone"
        onClick={() => setShowContactDrawer(true)}
      >
        <LocalPhone style={iconStyleMobile} />
      </LocalPhoneIconButton>
      <UserWrapper ref={menuButton} onClick={() => setShowAccountMenu(true)}>
        {userStore.getIsImpersonating() ? (
          <img src={impersonationImg} alt="user impersonating" />
        ) : (
          <IconButton aria-label="AccountCircle">
            <AccountCircleIcon style={iconStyle} />
            <AccountCircleIconMobile style={iconStyleMobile} />
          </IconButton>
        )}
        <AccountNameWrapper>
          <AccountText>{Translate('account')}</AccountText>
          <UsernameText className="test-class">
            {userStore?.preferences?.orgName} <KeyboardArrowDown />
          </UsernameText>
          <OrganizationNameText>
            {userStore?.preferences?.screenName}
          </OrganizationNameText>
        </AccountNameWrapper>
      </UserWrapper>
      <AccountMenu
        onClose={() => setShowAccountMenu(false)}
        parentRef={menuButton}
        open={showAccountMenu}
        accountName={userStore?.preferences?.orgName}
      />
    </>
  )
}

export default UserAccountMenu

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
`
const AccountNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
`
const AccountText = styled.span`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #050505;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`
const UsernameText = styled.span`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const AccountCircleIcon = styled(AccountCircle)`
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const AccountCircleIconMobile = styled(AccountCircle)`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: block;
  }
`

const LocalPhoneIconButton = styled(IconButton)`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: block;
  }
`

const OrganizationNameText = styled.div`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`
