import { VerifiedUser } from '@mui/icons-material'
import { Config } from 'src/config/ConfigManager'
import { ReactElement } from 'react'
import styled from 'styled-components'
import { Translate } from 'src/i18n'
interface IExperianAutocheckButtonProps {
  vin?: string
}

export const ExperianAutocheckButton = (
  props: IExperianAutocheckButtonProps
): ReactElement => {
  const { vin } = props
  const vinParameter = vin ? `&vin=${vin}` : ''
  const url = Config.urls.experianAutocheck + vinParameter

  return (
    <Anchor href={url} target="_blank" rel="noopener noreferrer">
      <VerifiedUser fontSize="inherit" />
      {Translate('experianAutocheck')}
    </Anchor>
  )
}

const Anchor = styled.a`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: ${(p) => p.theme.colors.link};
  position: relative;
  margin-right: 17.7px;
  :hover {
    color: ${(p) => p.theme.colors.link};
  }
  svg {
    margin-right: 6px;
  }
  display: flex;
  flex-direction: row;
  gap: 13px;
`
