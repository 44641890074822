import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import { Config } from 'src/config/ConfigManager'
import VehicleServiceProvider from 'src/services/VehicleServiceProvider'
import AxiosInstance from '../../api/axios'
import { Vehicle } from '../models/Vehicles'
import { VehicleHistoryItem, VehicleDetail } from './interfaces'

export interface IVehicleInfo {
  name?: string
  favorite?: boolean
}

const apiEndpointBase = Config.urls.vehicle
export class VehicleHistoryStore {
  public vehicleHistoryLoading = true

  public vehicleCustomNameUpdating = false

  public vehicleHistory: Array<VehicleHistoryItem> = []

  public initialVehicleHistory: Array<VehicleHistoryItem> = []

  public vehicleDetail: VehicleDetail = null

  public deleteVehicleModal = false

  public vehicleDetailLoading = false

  public lookupSourceFilter = 'All'

  public dateFilter = 'All'

  public multipleParametersFilter = ''

  public selectedVehicle: VehicleHistoryItem

  public mobileModal = false

  public editCustomNameModal = false

  public vinDetailsMobileModal = false

  public deleteVehicleHistorySuccess = false

  public deleteVehicleHistoryFail = false

  public recentSearchesModal = false

  public filterByModal = false

  public selectedSort: 'lookupSource' | 'LookupDate' = undefined

  public hasMoreResults = true

  constructor() {
    makeAutoObservable(this)
  }

  public setFilter = (
    type: 'lookupSourceFilter' | 'dateFilter' | 'multipleParametersFilter',
    value: string
  ): void => {
    this[type] = value
  }

  public runFilters = (): void => {
    let initialList = this.initialVehicleHistory
    /* Filter By Date ("All" implicit = Not filtering) */
    if (this.dateFilter !== 'All') {
      /* Today Implicit */
      const date = new Date()
      date.setHours(0, 0, 0, 0)

      const secondDate = new Date()
      secondDate.setHours(0, 0, 0, 0)

      if (this.dateFilter === 'Today') {
        const firstDate = date
        initialList = initialList.filter(
          (item) => new Date(item.lookupDate) >= new Date(firstDate)
        )
      } else if (this.dateFilter === 'Yesterday') {
        date.setDate(date.getDate() - 1)
        initialList = initialList.filter(
          (item) =>
            new Date(item.lookupDate) >= date &&
            new Date(item.lookupDate) < secondDate
        )
      } else if (this.dateFilter === 'Week') {
        date.setDate(date.getDate() - 7)

        initialList = initialList.filter(
          (item) =>
            new Date(item.lookupDate) >= date &&
            new Date(item.lookupDate) < secondDate
        )
      }
    }
    /* Filter By Lookup Type ("All" implicit = Not filtering) */
    if (this.lookupSourceFilter !== 'All') {
      initialList = initialList.filter(
        (item) => item.lookupSource === this.lookupSourceFilter
      )
    }
    /* Filter by text multiple parameters */
    if (
      this.multipleParametersFilter !== '' &&
      this.multipleParametersFilter !== null &&
      this.multipleParametersFilter !== undefined
    ) {
      initialList = initialList.filter(
        (item) =>
          item?.plate
            ?.toLowerCase()
            .indexOf(this.multipleParametersFilter?.toLowerCase()) > -1 ||
          item?.lookupSource
            ?.toLowerCase()
            .indexOf(this.multipleParametersFilter?.toLowerCase()) > -1 ||
          item?.make.value
            ?.toLowerCase()
            .indexOf(this.multipleParametersFilter?.toLowerCase()) > -1 ||
          item?.engine.value
            ?.toLowerCase()
            .indexOf(this.multipleParametersFilter?.toLowerCase()) > -1 ||
          item?.year.value
            ?.toLowerCase()
            .indexOf(this.multipleParametersFilter?.toLowerCase()) > -1 ||
          item?.model.value
            ?.toLowerCase()
            .indexOf(this.multipleParametersFilter?.toLowerCase()) > -1 ||
          item?.vin
            ?.toLowerCase()
            .indexOf(this.multipleParametersFilter?.toLowerCase()) > -1 ||
          item?.name
            ?.toLowerCase()
            .indexOf(this.multipleParametersFilter?.toLowerCase()) > -1
      )
    }

    this.vehicleHistory = initialList
    const emptyMultipleParameters =
      this.multipleParametersFilter === '' ||
      this.multipleParametersFilter === undefined ||
      this.multipleParametersFilter === null
    if (
      this.lookupSourceFilter === 'All' &&
      this.dateFilter === 'All' &&
      emptyMultipleParameters
    ) {
      this.fetchVehicleHistoryList()
    }
  }

  public setVehicleCustomNameUpdating = (update: boolean): void => {
    this.vehicleCustomNameUpdating = update
  }

  public setSelectedSort(sort: 'lookupSource' | 'LookupDate'): void {
    if (this.selectedSort === sort) {
      this.selectedSort = undefined
      this.fetchVehicleHistoryList()
    } else {
      this.selectedSort = sort
      if (this.selectedSort === 'lookupSource') {
        this.sortBylookupSource()
      } else if (this.selectedSort === 'LookupDate') {
        this.sortByLookupDate()
      }
    }
  }

  public isItemFavorite = (vehicle: Vehicle): boolean => {
    return vehicle?.favorite
  }

  public handleDeleteVehicleCloseModal = (): void => {
    this.deleteVehicleModal = false
  }

  public handleDeleteVehicleOpenModal = (vehicle: VehicleHistoryItem): void => {
    this.selectedVehicle = vehicle
    this.deleteVehicleModal = true
  }

  public handleOpenModal(vehicle: VehicleHistoryItem): void {
    this.selectedVehicle = vehicle
    this.mobileModal = true
  }

  public handleEditCustomNameOpenModal(): void {
    this.editCustomNameModal = true
  }

  public async handlevinDetailsMobileModal(vin: string): Promise<void> {
    this.vinDetailsMobileModal = true
    this.vehicleDetailLoading = true

    try {
      const data = await VehicleServiceProvider.getVehicleDetails(vin)

      this.vehicleDetail = data
      this.vehicleDetailLoading = false
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }

  public handleCloseModal = (): void => {
    this.mobileModal = false
  }

  public handleCloseEditCustomNameModal = (): void => {
    this.editCustomNameModal = false
  }

  public handleCloseVinDetailsMobileModal = (): void => {
    this.vinDetailsMobileModal = false
  }

  public handleResetToast = (): void => {
    this.deleteVehicleHistorySuccess = false
    this.deleteVehicleHistoryFail = false
  }

  public changeRecentSearchesModal = (open: boolean): void => {
    this.mobileModal = !open
    this.recentSearchesModal = open
  }

  public changeFilterByModal = (open: boolean): void => {
    this.filterByModal = open
  }

  public sortBylookupSource = (): void => {
    const filter = this.vehicleHistory
    filter.sort((a, b) => {
      const fa = a.lookupSource.toLowerCase()
      const fb = b.lookupSource.toLowerCase()

      if (fa > fb) {
        return -1
      }
      if (fa < fb) {
        return 1
      }
      return 0
    })
    this.vehicleHistory = filter
  }

  public sortByLookupDate = (): void => {
    const filter = this.vehicleHistory
    filter.sort((a, b) => {
      const fa = new Date(a.lookupDate)
      const fb = new Date(b.lookupDate)

      if (fa < fb) {
        return -1
      }
      if (fa > fb) {
        return 1
      }
      return 0
    })
    this.vehicleHistory = filter
  }

  public setUpdatedVehicleInfo = (
    id: string,
    name?: string,
    favoriteType?: boolean
  ): void => {
    const vehicleToUpdate = this.vehicleHistory.find((item) => item.id === id)
    vehicleToUpdate.favorite = favoriteType
    vehicleToUpdate.name = name
  }

  public fetchVehicleHistoryList = async (
    displayLoading?: boolean
  ): Promise<void> => {
    this.vehicleHistoryLoading = displayLoading ? false : true
    let data: VehicleHistoryItem[] = []
    try {
      data = await VehicleServiceProvider.getVehicleHistory()
    } catch (e) {
      // do nothing for now
    } finally {
      this.vehicleHistory = data
      this.initialVehicleHistory = data
      this.vehicleHistoryLoading = false
    }
  }

  public handleUpdate = (): void => {
    this.dateFilter = 'All'
    this.lookupSourceFilter = 'All'
    this.multipleParametersFilter = ''
    this.fetchVehicleHistoryList()
  }

  public async deleteVehicleHistoryItem(id: string): Promise<void> {
    try {
      await AxiosInstance.post(`${apiEndpointBase}/vehicle/delete/`, {
        ids: [id],
      })
      await this.fetchVehicleHistoryList()

      this.runFilters()
      this.deleteVehicleHistorySuccess = true
    } catch (error) {
      this.deleteVehicleHistoryFail = true
    }
  }
}

export const VehicleHistoryContext =
  createContext<VehicleHistoryStore>(undefined)

export const useVehicleHistoryStore = (): VehicleHistoryStore => {
  return useContext(VehicleHistoryContext)
}
