import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import styled from 'styled-components'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useVehicleHistoryStore } from 'src/store/vehicleHistory/VehicleHistoryStore'
import { defaultTheme } from 'src/theme'
import { Spinner } from 'react-bootstrap'
import { Box } from '@mui/material'
import { Translate } from 'src/i18n'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'
import { ExperianAutocheckButton } from 'src/features/customizeSearch/components/ExperianAutocheckButton'

export const VinDetails = observer((): ReactElement => {
  const uiStore = useUiStateStore()
  const { vehicleDetail, loadingVehicleDetail } = uiStore
  const vehicleHistoryStore = useVehicleHistoryStore()
  const drawerBody = (
    <>
      {loadingVehicleDetail && !vehicleDetail && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner animation="border" />
        </Box>
      )}
      {!vehicleHistoryStore?.vehicleDetailLoading && vehicleDetail && (
        <>
          {vehicleDetail?.year &&
            vehicleDetail?.make &&
            vehicleDetail?.model && (
              <HeaderTitle>{`${vehicleDetail?.year} ${vehicleDetail?.make} ${vehicleDetail?.model}`}</HeaderTitle>
            )}

          {vehicleDetail?.engine && (
            <HeaderSubtitle>
              {vehicleDetail?.engine}{' '}
              <ExperianAutocheckButton vin={vehicleDetail?.vin} />
            </HeaderSubtitle>
          )}

          <TablesContainer>
            <Table>
              <Row>
                <TableHead>{Translate('VIN').toUpperCase()}</TableHead>
                <TableValue>
                  <span>
                    <GrayVIN>{vehicleDetail?.vin.substring(0, 9)}</GrayVIN>
                    {vehicleDetail?.vin.substring(9, 17)}
                  </span>
                </TableValue>
              </Row>
              <Row>
                <TableHead>{Translate('vinPattern')}</TableHead>
                <TableValue>{vehicleDetail?.vinPattern}</TableValue>
              </Row>
              <Row>
                <TableHead>{Translate('licensePlate')}</TableHead>
                <TableValue>{vehicleDetail?.plate}</TableValue>
              </Row>
              <Row>
                <TableHead>{Translate('state')}</TableHead>
                <TableValue>{vehicleDetail?.state}</TableValue>
              </Row>
            </Table>
            <Table>
              {vehicleDetail?.vehicleSpecs?.map((item, index) => (
                <Row key={index}>
                  <TableHead>{item?.label}</TableHead>
                  <TableValue>{item?.value}</TableValue>
                </Row>
              ))}
            </Table>
          </TablesContainer>
        </>
      )}
    </>
  )

  return (
    <BaseDrawer
      open={uiStore.showVinDetailsModal.show}
      width="476px"
      title="vinDetails"
      body={drawerBody}
      onClose={uiStore.closeVinDetailsModal}
    />
  )
})

const HeaderTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: 16px 0 0 0;
`
const HeaderSubtitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #9b9b9b;
  margin-bottom: 8px;
  justify-content: space-between;
`
const TablesContainer = styled.div`
  padding: 12px 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80%;
`

const Row = styled.div`
  display: flex;
`

const Table = styled.div`
  width: 412px;
  margin-bottom: 16px;
  border: 0.5px solid ${defaultTheme.colors.greyBorder};
`

const GrayVIN = styled.span`
  color: ${defaultTheme.colors.columnBorder};
`

const TableHead = styled.div`
  border-bottom: 0.5px solid ${defaultTheme.colors.greyBorder};
  border-right: 0.5px solid ${defaultTheme.colors.greyBorder};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 10px;
  width: 184px;
  height: auto;
  background: #fafafa;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #050505;
`
const TableValue = styled.div`
  border-bottom: 0.5px solid ${defaultTheme.colors.greyBorder};
  border-right: 0.5px solid ${defaultTheme.colors.greyBorder};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 10px;
  width: 228px;
  height: auto;
  background: #ffffff;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #050505;
`
