import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect, useState } from 'react'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { Box, Grid } from '@mui/material'
import styled from 'styled-components'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'
import { Translate } from 'src/i18n'
import { LaborItem } from 'src/store/models/LaborModel'
import { Button } from 'src/ui-components'
import { defaultTheme } from 'src/theme'
import { useHistory } from 'react-router-dom'
import LaborResultBody from './LaborResultBody'
import LaborResultHeader from './LaborResultHeader'
import { useLaborLookup } from 'src/store/labor/LaborStore'
import { useSearchStore } from 'src/store/search/SearchStore'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import { breakpoints } from 'src/theme/breakpoints'
import { isMobile } from 'react-device-detect'
import LaborResultBodyMobile from './LaborResultBodyMobile'

interface LaborResultDrawerProps {
  open: boolean
  setOpen: (open: boolean) => void
  setLaborOpen: (laborOpen: boolean) => void
}

const LaborResultDrawer = observer(
  ({ open, setOpen, setLaborOpen }: LaborResultDrawerProps): ReactElement => {
    const history = useHistory()
    const { displaySuccessNotification } = useUiStateStore()
    const { fetchLaborResult } = useLaborLookup()
    const { currentVehicle } = useSearchStore()
    const { setLaborItems, getLaborItems } = useShoppingCart()
    const [selectedLaborResults, setSelectedLaborResults] = useState<
      LaborItem[]
    >([])
    const cartLaborResults = getLaborItems(currentVehicle)

    useEffect(() => {
      fetchLaborResult()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
      if (cartLaborResults && cartLaborResults.length > 0) {
        setSelectedLaborResults(cartLaborResults)
      } else {
        setSelectedLaborResults([])
      }
    }, [cartLaborResults])

    const handleOnClose = () => {
      setOpen(false)
    }

    const specificationUpdateText = Translate('laborsUpdated')
    const viewCartLink = {
      text: Translate('viewCart'),
      action: () => {
        history.push('/cart')
      },
    }

    const handleAddToCart = () => {
      const laborItems = selectedLaborResults
      setSelectedLaborResults([])
      setLaborItems(currentVehicle, laborItems)
      displaySuccessNotification(specificationUpdateText, viewCartLink)
      setOpen(false)
    }

    const handleOnBackClick = () => {
      setLaborOpen(true)
      setOpen(false)
    }

    const drawerBody = isMobile ? (
      <LaborResultBodyMobile
        onLaborResultChange={(laborResults) => {
          setSelectedLaborResults(laborResults)
        }}
        selectedLaborResults={selectedLaborResults}
      />
    ) : (
      <LaborResultBody
        onLaborResultChange={(laborResults) => {
          setSelectedLaborResults(laborResults)
        }}
        selectedLaborResults={selectedLaborResults}
      />
    )
    const updateAddButton =
      cartLaborResults?.length > 0
        ? Translate('updateCart')
        : Translate('addToCart')

    const modalFooter = (
      <FooterGrid container rowSpacing={5} columnSpacing={1} className="mt-1">
        <Grid item xs={12} paddingTop={1}>
          <ButtonContainer display="flex" justifyContent="flex-end">
            <BackButton
              variant="secondary"
              text="back"
              onClick={handleOnBackClick}
            />
            <AddToCartButton
              onClick={handleAddToCart}
              text={updateAddButton}
              disabled={selectedLaborResults.length === 0}
            />
          </ButtonContainer>
        </Grid>
      </FooterGrid>
    )

    const modalHeader = <LaborResultHeader />

    return (
      <BaseDrawer
        open={open}
        title={Translate('laborData')}
        header={modalHeader}
        body={drawerBody}
        footer={modalFooter}
        onClose={handleOnClose}
        width={window.innerWidth < breakpoints.tabletLarge ? '100%' : '1150px'}
        height={window.innerWidth < breakpoints.tabletLarge ? '90vh' : '100%'}
        paddingX="32px"
      />
    )
  }
)

export default LaborResultDrawer

const FooterGrid = styled(Grid)`
  position: sticky;
  background-color: ${defaultTheme.colors.white};
`
const ButtonContainer = styled(Box)`
  @media (max-width: ${breakpoints.mobileLarge}px) {
    display: flex;
    display: flex;
    flex-direction: column;
  }
`
const BackButton = styled(Button)`
  @media (max-width: ${breakpoints.mobileLarge}px) {
    order: 2;
  }
`

const AddToCartButton = styled(Button)`
  @media (max-width: ${breakpoints.mobileLarge}px) {
    order: 1;
  }
`
