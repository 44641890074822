import { KeyboardEvent, ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { TextInput, Checkbox, SearchIconBtn } from 'src/ui-components'
import { MutablePreference, useUserStore } from 'src/store/user/UserStore'
import { Translate } from 'src/i18n'
import { defaultTheme } from 'src/theme'
import { Spinner } from 'react-bootstrap'
import { vehicleWidgetStore } from '../store/VehicleWidgetStore'
import { usePartsCatalog } from 'src/store/partsCatalog/PartsCatalog'
import { useSearchStore } from 'src/store/search/SearchStore'

export const LicensePlateSearch = observer((): ReactElement => {
  const history = useHistory()
  const userStore = useUserStore()
  const statePreference = userStore.getStatePreference()
  const catalog = usePartsCatalog()
  const [plate, setPlate] = useState('')
  const [stateSelection, setStateSelection] = useState(statePreference)
  const { getStatesWithCountryId, states, statesLoading } = useSearchStore()

  const stateList = states ?? []
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchByPlate = async () => {
    setIsLoading(true)
    const foundVehicle = await vehicleWidgetStore.lookupVehicle({
      licencePlate: `${stateSelection}:${plate}`,
    })
    setIsLoading(false)
    if (foundVehicle) {
      vehicleWidgetStore.setIsDrawerOpened(false)
      catalog.resetStore()
      history.push('/partsCatalog')
    }
  }

  const submitIfEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchByPlate()
    }
  }

  useEffect(() => {
    getStatesWithCountryId()
  }, [getStatesWithCountryId])

  const isDefaultState = userStore.getStatePreference() === stateSelection

  return (
    <InputWrapper>
      <Row>
        <StyledFormControl sx={{ border: 0 }}>
          {statesLoading ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner animation="border" />
            </Box>
          ) : (
            <StyledSelect
              sx={{ border: 0 }}
              onChange={(e) => setStateSelection(e.target.value as string)}
              value={stateSelection}
            >
              {stateList.map((option) => (
                <MenuItem key={option.regionCode} value={option.regionCode}>
                  {option.regionCode}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        </StyledFormControl>
        <StyledInput
          onChange={(text) => {
            setPlate(text)
          }}
          onKeyPress={submitIfEnter}
          value={plate}
          width="100%"
          placeholder="enterAPlateNumber"
          noMargin
        />

        <SearchIconBtn
          variant="attached"
          onClick={handleSearchByPlate}
          loading={isLoading}
          disabled={plate.length < 2}
        />
      </Row>
      <StateSelection>
        <Checkbox
          onClick={() => {
            userStore.updateUserPreferenceAndSyncToCloud(
              MutablePreference.EPEFEATURES_DEFAULTSTATE,
              stateSelection
            )
          }}
          value="makeDefault"
          active={isDefaultState}
          label={Translate('makeThisMyDefaultState')}
        />
      </StateSelection>
    </InputWrapper>
  )
})

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
`

const StateSelection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .checkbox-wrapper {
    span {
      color: ${defaultTheme.colors.disabledButtonText};
    }
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
`

const StyledInput = styled(TextInput)`
  flex-grow: 1;
  width: 100%;
  margin-right: 30px;
  margin-left: 0;
`

const StyledFormControl = styled(FormControl)`
  border: 1px solid ${(p) => p.theme.colors.border};
  min-width: 120px;
  background-color: #fff;
  height: 50px;
  border-right: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 0;
`
const StyledSelect = styled(Select)`
  border: none !important;
  margin-top: -4px;
  padding: 0;
`
