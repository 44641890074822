import { FormikProps } from 'formik'
import { makeAutoObservable, reaction } from 'mobx'
import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
} from 'react'
import { EmbeddedCommunicationsManager } from '../../api/embedded/EmbeddedCommunicationsManager'
import {
  LocationTransports,
  OrderResponse,
  OrderResponseDetail,
  Transport,
  Vehicle as VehicleResponse,
} from '../../api/cart/interfaces'
import { Optional } from '../../common-interfaces/generic-interfaces'
import { Config, IS_INTEGRATED_VERSION } from '../../config/ConfigManager'
import { CantBeFulfilledException } from '../../exceptions/CantBeFulfilledException'
import { OrderFormValidationException } from '../../exceptions/OrderFormValidationException'
import miscellaneousVehicle from '../../features/search/Results/utils/miscellaneousVehicle'
import { LocationType } from '../../helpers/locationType'
import { getTotalPerOrder } from '../../helpers/orderConfirmationUtils'
import { timeout } from '../../theme/timeout'
import {
  GaTrackOption,
  GoogleTagManager,
} from '../../config/analytics/GoogleTagManager'
import { LaborItem } from '../models/LaborModel'
import { Region } from '../models/Regions'
import {
  CartMode,
  CartPartDetails,
  CartVehicle,
  OrderFormData,
  OrderSelection,
  ShoppingCartData,
  ShoppingCartOnlyData,
  ShoppingCartProduct,
} from '../models/ShoppingCartModels'
import { VehicleSpecificationCartItem } from '../models/VehicleSpecification'
import { StoreInstances } from '../StoreInstancesContainer'
import {
  buildAvailabilityError,
  buildAvailabilityRequest,
  fetchProduct,
  validatePrices,
} from './CartValidations'
import {
  currentLocationHasQuantity,
  findOrderSelection,
  getLocationById,
  isBuyDirectLocation,
  makeNewCartVehicle,
  productsMatch,
  selectLocationByQty,
} from './Utils'
import { LocationInfoResponse, PartInfoResponse } from './ValidationInterfaces'
import {
  AvailabilityErrorType,
  ProductLocationModel,
  ProductModel,
} from '../models/ProductModel'
import { Vehicle } from '../models/Vehicles'
import { VehicleWidgetDisplayState } from 'src/features/search/VehicleSearch/store/VehicleWidgetStore'
import ShipmentServiceProvider from 'src/services/ShipmentServiceProvider'
import OrderServiceProvider from 'src/services/OrderServiceProvider'
import { IEstimateReqData } from 'src/api/shipment/interfaces'
import { PUROLATOR } from 'src/features/cart/components/Purolator/constants'
import authManager from '../../api/security/Auth'

const getCartStorageKey = () => {
  return `cart_${authManager.getUserId()}`
}

export const ShoppingCartContext = createContext<ShoppingCart | undefined>(
  undefined
)

export const useShoppingCart = (): ShoppingCart => {
  const shoppingCartStore = useContext(ShoppingCartContext)
  if (!shoppingCartStore) {
    throw new Error(
      'No ShoppingCartContext.Provider found when calling useShoppingCart.'
    )
  }
  return shoppingCartStore
}

interface SetQtyProps {
  product: ProductModel
  locationId: string
  quantity: number
  onLocationChange?: (selectedLocation: ProductLocationModel) => void
  autoLocationChange?: boolean
  vehicle: Vehicle
  setLocationChangeTooltip?: Dispatch<SetStateAction<boolean>>
  collectErrors?: boolean
}

export class ShoppingCart {
  private data: ShoppingCartData

  public cartValidations: Record<
    string,
    MutableRefObject<FormikProps<OrderFormData>>
  >

  commonPoNumber = ''

  commonNoteToStore = ''

  displayOrderModal = false

  disableStores = false

  onLocationChangeTooltip = false

  locationTooltipForProduct: { partNumber: string; active: boolean }

  replacedLocationName?: string = undefined

  replacementLocationName?: string = undefined

  quantitySelected = 0

  checkAvailabilityLoaded = false

  removeAllVehiclesModal = false

  availableTransports: Array<LocationTransports> = []

  /** Property to track the selected vehicle while redirecting to the product details page from cart. */
  private selectedVehicle?: Vehicle

  orderFormData: OrderFormData = {
    poNumber: '',
    noteToStore: '',
    customerName: '',
    personalNote: '',
  }

  cartPartDetails?: CartPartDetails

  processingOrder = false

  purolatorEligibileLocations: Array<number> = null

  constructor() {
    makeAutoObservable(this)
    this.data = {
      version: Config.cartVersion,
      vehicle: undefined,
      vehicles: [],
    }
    this.cartValidations = {}
    this.locationTooltipForProduct = { partNumber: '', active: false }
  }

  getMode = (): CartMode => {
    if (!Config.isNotCartOnlyMode) {
      return CartMode.CART_ONLY
    }
    if (IS_INTEGRATED_VERSION) {
      return CartMode.SINGLE_VEHICLE
    }
    if (StoreInstances.userStore.preferences.cart_multiCart === 'true') {
      return CartMode.MULTI_VEHICLE
    }
    return CartMode.SINGLE_VEHICLE
  }

  public fetchTransports = async (): Promise<void> => {
    this.availableTransports = await OrderServiceProvider.getTransports()
    this.availableTransports = this.availableTransports?.map(
      (locationTransport) => {
        return {
          ...locationTransport,
          transports: locationTransport.transports?.map((t) => {
            const id = this.hashTransport(t)
            return { ...t, id }
          }),
        }
      }
    )
  }

  public getSelectedVehicle(): Vehicle | undefined {
    return this.selectedVehicle
  }

  public setLocationTooltipForProduct(
    partNumber: string,
    active: boolean
  ): void {
    this.locationTooltipForProduct = { partNumber, active }
  }

  public setSelectedVehicle(selectedVehicle?: Vehicle): void {
    this.selectedVehicle = selectedVehicle
  }

  public setProcessingOrder = (state: boolean): void => {
    this.processingOrder = state
  }

  public setVehicles = (vehicles: Array<CartVehicle>): void => {
    this.data = { ...this.data, vehicles }
  }

  public setTestPo = (value: string): void => {
    this.commonPoNumber = value
  }

  public emptyTestPo = (): void => {
    this.commonPoNumber = ''
  }

  public setCommonNoteToStore = (value: string): void => {
    this.commonNoteToStore = value
  }

  public emptyCommonNoteToStore = (): void => {
    this.commonNoteToStore = ''
  }

  public getYmmeStringFromVehicle = (vehicle: Vehicle): string => {
    return `${vehicle.year?.id}-${vehicle.make?.id}-${vehicle.model?.id}-${vehicle.engine?.id}`
  }

  public saveAllAsQuotes = (): void => {
    this.data.vehicles.forEach((cartVehicle) => {
      StoreInstances.quoteStore.saveAsQuote(cartVehicle)
    })
  }

  public addVehicleToCart = (vehicle: Vehicle): void => {
    function arrayMove(arr, fromIndex, toIndex) {
      const element = arr[fromIndex]
      arr.splice(fromIndex, 1)
      arr.splice(toIndex, 0, element)
    }

    const reuseCart =
      this.getMode() === CartMode.SINGLE_VEHICLE &&
      this.data?.vehicles?.length > 0
    if (reuseCart) {
      const v = this.data.vehicles[0]
      v.vehicle = vehicle
      return
    }

    const vehicleAlreadyInTheList = this.getCurrentVehicleIndex(vehicle)
    if (vehicleAlreadyInTheList > -1) {
      arrayMove(this.data.vehicles, vehicleAlreadyInTheList, 0)
    } else {
      const newCartVehicle = makeNewCartVehicle(vehicle)
      this.data.vehicles.unshift(newCartVehicle)
    }
    StoreInstances.vehicleWidget.setIsDrawerOpened(false)
    StoreInstances.vehicleWidget.setDisplayState(VehicleWidgetDisplayState.view)
  }

  public setOrderFormDataFieldValue = (field: string, value: string): void => {
    this.orderFormData[field] = value
  }

  public setDisplayOrderModal = (show: boolean): void => {
    this.displayOrderModal = show
  }

  public setFieldOnVehicleCart = (
    field: string,
    vehicle: Vehicle,
    value: string
  ): void => {
    this.data.vehicles[this.getCurrentVehicleIndex(vehicle)].orderFormData[
      field
    ] = value
  }

  public validateForm = async (
    ref: MutableRefObject<FormikProps<OrderFormData>>
  ): Promise<void> => {
    const form = ref.current
    const validation = await ref.current.validateForm()

    if (Object.keys(validation).length > 0) {
      form.setTouched({
        ...form.touched,
        poNumber: true,
        noteToStore: true,
      })
      throw new OrderFormValidationException('orderFormIsIncomplete')
    }
  }

  public orderAllCarts = async (): Promise<OrderResponse> => {
    const cartsWithProducts = this.data.vehicles.filter(this.isCartOrderAble)
    const validations = cartsWithProducts.map(
      (c) => this.cartValidations[c.vehicle?.id]
    )
    await Promise.all(validations.map(this.validateForm))

    const orderResponse = await this.placeOrder(cartsWithProducts)
    this.removeVehicleCarts(cartsWithProducts)
    return orderResponse
  }

  public setRemoveAllVehiclesModal = (show: boolean): void => {
    this.removeAllVehiclesModal = show
  }

  public getGoogleAnalyticsCategory = (
    allianceTerminologyID: string,
    partDescription: string
  ): string => {
    // Mapping category to part type description to align with B2C model
    const selectedPartTypeValue =
      StoreInstances.searchStore.findSelectedPartTypeById(
        allianceTerminologyID
      )?.value

    return selectedPartTypeValue || partDescription // Using part description as backup MP4P-1212
  }

  public addSpecificationAndLaborToOrderResponse = (
    currentOrder: OrderResponse
  ): OrderResponse => {
    return {
      ...currentOrder,
      orderDetails: currentOrder.orderDetails.map((orderDetail) => {
        const cartVehicle =
          this.vehicles[
            this.findVehicleIndexByOrderResponse(orderDetail.vehicle)
          ]

        return {
          ...orderDetail,
          specifications:
            cartVehicle?.specifications?.length > 0
              ? cartVehicle.specifications
              : [],
          laborData:
            cartVehicle?.laborResults?.length > 0
              ? cartVehicle.laborResults
              : [],
        }
      }),
    }
  }

  private findVehicleIndexByOrderResponse = (
    vehicleResponse: VehicleResponse
  ): number => {
    return (this.data.vehicles || []).findIndex((item) => {
      return (
        item?.vehicle?.year?.value === vehicleResponse?.year &&
        item?.vehicle?.make?.value === vehicleResponse?.make &&
        item?.vehicle?.model?.value === vehicleResponse?.model &&
        item?.vehicle?.engine?.value === vehicleResponse?.engine
      )
    })
  }

  public placeOrder = async (
    vehicles: CartVehicle[]
  ): Promise<OrderResponse> => {
    const response = await OrderServiceProvider.makeOrder(vehicles)
    const responseWithSpecsAndLabor =
      this.addSpecificationAndLaborToOrderResponse(response)

    this.trackPurchase(responseWithSpecsAndLabor.orderDetails) // before emptying the cart because it uses the cart to complete the vehicle information
    if (IS_INTEGRATED_VERSION) {
      EmbeddedCommunicationsManager.transferOrderResponse(
        StoreInstances.cart,
        responseWithSpecsAndLabor
      )
    }
    return responseWithSpecsAndLabor
  }

  // Use this method to track transferred items only, not ordered items
  public trackCartTransfer = (): void => {
    const { countryCode } = StoreInstances.userStore.country || {}
    const regionId = Region[countryCode]
    this.data.vehicles.forEach((v) => {
      const gaItems = v.products.map((p) => {
        const category = this.getGoogleAnalyticsCategory(
          p?.allianceterminologyId ?? '',
          p?.description ?? ''
        )
        const gaProductName = GoogleTagManager.buildProductName(p)
        const loc = getLocationById(p, p.orderSelections?.[0]?.locationId)
        return {
          id: p.allianceProductId, // aka SKU
          name: gaProductName,
          brand: p.brandName ?? p.manufacturerName,
          location: loc.called,
          price: loc.cost,
          category,
          quantity: p.orderSelections?.[0]?.quantityRequested,
        }
      })
      const cartTotal =
        this.cartVehicleCostSubTotal(v.vehicle) +
        this.cartVehicleCostCoreTotal(v.vehicle)
      GoogleTagManager.setVehicleDimensions(v.vehicle, regionId)
      GoogleTagManager.trackEvent('begin_checkout', {
        value: cartTotal,
        items: gaItems,
        // Hardcoding below values for now as requested in MP4P-830
        coupon: 0,
        currency: 'USD', // Harcoded until internacionalization is implemented
        shipping: 0,
        tax: 0,
      })
    })
  }

  public trackPurchase = (orders: Array<OrderResponseDetail>): void => {
    const { countryCode } = StoreInstances.userStore.country
    const regionId = Region[countryCode]
    orders.forEach((order) => {
      let fullVehicleInfo = this.data.vehicles.find(
        (vehicleInCart) =>
          vehicleInCart.vehicle?.year?.value === order.vehicle.year &&
          vehicleInCart.vehicle?.make?.value === order.vehicle.make &&
          vehicleInCart.vehicle?.model?.value === order.vehicle.model &&
          vehicleInCart.vehicle?.engine?.value === order.vehicle.engine
      )
      if (!fullVehicleInfo) {
        fullVehicleInfo = { vehicle: miscellaneousVehicle, products: [] }
      }
      order.locations.forEach((location) =>
        location.orders.forEach((orderPerLocation) => {
          const gaItems = orderPerLocation.parts.map((part) => {
            return {
              id: part.allianceProductId, // aka SKU
              name: GoogleTagManager.buildProductName(part),
              brand: part.manufacturerName, // The brand name is missing in the API response, the manufacturer name equivalent
              // MP4P-1212 - the allianceTerminologyID returns whatever value we previously sent to the API, so we send the category description instead of the ID to avoid some extra steps
              category: part?.allianceTerminologyId?.toString(),
              price: part.unitListPrice,
              quantity: part.quantityRequested,
            }
          })
          const orderTotal = getTotalPerOrder(orderPerLocation)
          GoogleTagManager.setVehicleDimensions(
            fullVehicleInfo.vehicle,
            regionId
          )
          GoogleTagManager.trackEvent('purchase', {
            transaction_id: orderPerLocation.orderNumber,
            affiliation: location.locationDescription,
            items: gaItems,
            value: orderTotal,
            // Hardcoding below values for now as requested in MP4P-830
            coupon: 0,
            currency: 'USD', // Harcoded until internacionalization is implemented
            shipping: 0,
            tax: 0,
          })
        })
      )
    })
  }

  public placeIndividualVehicleOrder = async (
    vehicle: CartVehicle
  ): Promise<OrderResponse> => {
    const resp = await this.placeOrder([vehicle])
    this.removeVehicleCart(vehicle.vehicle, GaTrackOption.doNotTrack)
    return resp
  }

  public decreaseQtyAtLocation = (
    product: ProductModel,
    locationId: string,
    quantityToRemove = 1,
    vehicle: Vehicle
  ): void => {
    const currentQty = this.getQtyAtLocation(product, locationId, vehicle)
    this.setQtyAtLocation({
      product,
      locationId,
      quantity: currentQty - quantityToRemove,
      autoLocationChange: false,
      vehicle,
    })
  }

  private addProductToCart = (
    product: ShoppingCartProduct,
    vehicle: Vehicle
  ): void => {
    const vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    this.data.vehicles[vehicleIdx].products.push(product)
  }

  private updateLocationPrices = (
    oldLocation: ProductLocationModel,
    newLocation: LocationInfoResponse
  ): ProductLocationModel => {
    const newCost = newLocation?.unitCostPrice ?? 0
    const newCoreCost = newLocation?.unitCorePrice ?? 0
    const newCoreList = newLocation?.unitListCore ?? 0
    const newList = newLocation?.unitListPrice ?? 0

    return {
      ...oldLocation,
      cost: newCost,
      coreCost: newCoreCost,
      coreList: newCoreList,
      list: newList,
    }
  }

  private mapLocationInfo = (
    location: LocationInfoResponse
  ): ProductLocationModel => {
    return {
      isSelected: location.isSelected,
      locationId: location.locationId,
      called: location.locationDescription,
      cost: location.unitCostPrice ?? 0,
      coreCost: location.unitCorePrice ?? 0,
      coreList: location.unitListCore ?? 0,
      list: location.unitListPrice ?? 0,
      qtyAvailable: location.quantityAvailable ?? 0,
      locType:
        location.locationId === '100'
          ? LocationType.PRIMARY
          : location.locationStatus,
    }
  }

  private updateLocationInfo = (
    oldPart: ProductModel,
    newPart: PartInfoResponse
  ): ProductModel => {
    const updatedLocations = oldPart.location.map(
      (location): ProductLocationModel => {
        const updatedLocationInfo: LocationInfoResponse =
          newPart.locations.find(
            (l) => Number(l.locationId) === Number(location.locationId)
          )

        const updatedLocation = this.updateLocationPrices(
          location,
          updatedLocationInfo
        )

        updatedLocation.locType =
          updatedLocationInfo?.locationId === '100'
            ? LocationType.PRIMARY
            : updatedLocation.locType

        updatedLocation.qtyAvailable =
          updatedLocationInfo?.quantityAvailable ?? 0

        updatedLocation.minQty = updatedLocationInfo?.minOrderQty ?? 1
        return updatedLocation
      }
    )
    newPart.locations.forEach((location) => {
      if (
        updatedLocations.filter((l) => l.locationId === location.locationId)
          .length < 1
      )
        updatedLocations.push(this.mapLocationInfo(location))
    })

    /* When coming from addQuoteToCart we are not having primary location at 
    updatedLocations[0]. Hence we are rearranging the updatedLocations as below to have 
    primary location at index 0.
     */
    const primaryLocationIndex = updatedLocations.findIndex(
      (location) => location.locationId.toString() === '100'
    )
    const primaryLocation = updatedLocations[primaryLocationIndex]
    updatedLocations.splice(primaryLocationIndex, 1)
    updatedLocations.unshift(primaryLocation)
    oldPart.weight = newPart.weight
    oldPart.height = newPart.height
    oldPart.width = newPart.width
    oldPart.length = newPart.length
    return { ...oldPart, location: updatedLocations }
  }

  public updatePartInfo = async (
    oldPart: ProductModel,
    requestedQty: number,
    location: ProductLocationModel
  ): Promise<ProductModel> => {
    const reqData = buildAvailabilityRequest(oldPart, requestedQty, location)
    const updatedPartInfo = await fetchProduct(reqData)
    return this.updateLocationInfo(oldPart, updatedPartInfo)
  }

  public setQtyAtLocation = async ({
    product,
    locationId,
    quantity,
    onLocationChange,
    autoLocationChange = true,
    vehicle,
    setLocationChangeTooltip,
    collectErrors = false,
  }: SetQtyProps): Promise<void> => {
    // Part can't be added to cart without a location.
    if (locationId === undefined || locationId === '') {
      throw new Error('No location selected')
    }

    //Remove the part from cart if the quantity is invalid.
    if (quantity <= 0) {
      this.removeProductFromLocation(product, locationId, vehicle)
      return
    }

    const originalLocation = getLocationById(product, locationId)

    // We need to get the updated part if we going to collect the errors.
    const updatedProduct: ShoppingCartProduct = collectErrors
      ? await this.updatePartInfo(product, quantity, originalLocation)
      : product
    let locationToSet = locationId
    if (autoLocationChange) {
      const firstLocationWithQty = selectLocationByQty(updatedProduct, quantity)
      /* Here we can see the ALS(II)/ALS(II.a)/ALS(II.a2) being applied */
      if (
        firstLocationWithQty.qtyAvailable < quantity &&
        !this.allowOrderWithoutAvailableInventory(firstLocationWithQty) &&
        !IS_INTEGRATED_VERSION
      ) {
        if (collectErrors) {
          updatedProduct.availabilityError = buildAvailabilityError(
            AvailabilityErrorType.CANT_BE_FULFILLED
          )
          this.addProductToCart(updatedProduct, vehicle)
        }
        throw new CantBeFulfilledException('productCantBeFulfilled')
      }
      /* In this conditional below, you can see the ALS(I) being checked.
      if the location manually selected (locationId) has enough quantity
      (currentLocationHasQuantity(updatedProduct, quantity, locationId)),
      the flow will not enter the conditional, therefore the location will
      be selected. ALS(I.a) or if the location doesn't have enough quantity,
      the conditional will check for new locations ALS(I.b)
      */
      if (
        firstLocationWithQty.locationId !== locationId &&
        !currentLocationHasQuantity(updatedProduct, quantity, locationId)
      ) {
        this.removeProductFromLocation(updatedProduct, locationId, vehicle)
        if (onLocationChange) {
          onLocationChange(firstLocationWithQty)
        }
        locationToSet = firstLocationWithQty.locationId
        if (collectErrors) {
          updatedProduct.availabilityError = buildAvailabilityError(
            AvailabilityErrorType.LOCATION_OVERRIDE
          )
        }
        const replacedLocationName = getLocationById(updatedProduct, locationId)
        const replacementLocationName = getLocationById(
          updatedProduct,
          locationToSet
        )
        this.replacedLocationName = replacedLocationName?.called
        this.replacementLocationName = replacementLocationName?.called
        if (setLocationChangeTooltip) {
          this.setLocationTooltipForProduct(product?.partNumber, true)
          setLocationChangeTooltip(true)
        }
        setTimeout(() => {
          if (setLocationChangeTooltip) {
            this.setLocationTooltipForProduct(product?.partNumber, false)
            setLocationChangeTooltip(false)
          }
        }, timeout.locationChangeTooltip)
      }
    }

    const location = getLocationById(updatedProduct, locationToSet)

    if (
      location.qtyAvailable < quantity &&
      !this.allowOrderWithoutAvailableInventory(location) &&
      !IS_INTEGRATED_VERSION
    ) {
      // Check if the quantity is available at other locations
      const findIfProductAvaliableInAnyLocation =
        updatedProduct.location.filter((item) => item.qtyAvailable >= quantity)

      if (updatedProduct.validationStatus === 'INVALID') {
        updatedProduct.availabilityError = buildAvailabilityError(
          AvailabilityErrorType.CANT_BE_FULFILLED
        )
      } else if (collectErrors && !findIfProductAvaliableInAnyLocation.length) {
        updatedProduct.availabilityError = buildAvailabilityError(
          AvailabilityErrorType.CANT_BE_FULFILLED
        )
      } else {
        updatedProduct.availabilityError = buildAvailabilityError(
          AvailabilityErrorType.CANT_BE_FULFILLED_FROM_SELECTED_LOCATION
        )
      }
      this.addProductToCart(updatedProduct, vehicle)
    }

    if (
      location.qtyAvailable < quantity &&
      this.allowOrderWithoutAvailableInventory(location) &&
      collectErrors
    ) {
      updatedProduct.availabilityError = buildAvailabilityError(
        AvailabilityErrorType.OUT_OF_STOCK_ALL_LOCATIONS
      )
    }
    // @TODO: This makes sense for the current UI, because there is no way to select multiple locations. This will eventually need to be replaced.
    const previouslyAddedProduct = this.findProductMatch(
      updatedProduct,
      vehicle
    )
    if (previouslyAddedProduct?.orderSelections?.length) {
      this.removeProductFromLocation(
        updatedProduct,
        previouslyAddedProduct.orderSelections[0].locationId,
        vehicle
      )
    } else {
      this.trackAddToCart(updatedProduct, vehicle, location, quantity)
    }

    /*TODO: We need to make the availabilityError in ShoppingCartProduct interface from 
    `AvailabilityError` type to `Array<AvailabilityError>` type. Because in the current setup we are 
    overriding the each error with next error.
    Ticket is raised: https://praxent.atlassian.net/browse/MP4P-1236
    */

    if (collectErrors) {
      const priceValidations = validatePrices(originalLocation, location)
      priceValidations.forEach((val) => {
        updatedProduct.availabilityError = val
      })
    }
    /* Here is where the ALS(III.a) is called */
    this.setOrderSelection(
      updatedProduct,
      location,
      quantity,
      vehicle,
      collectErrors
    )

    // @TODO: Revisit this logic after MVP.  Since we will support multiple 'sub-carts' for different vehicles,
    // each cart needs to be associated with a vehicle, but only one vehicle will ever be active in the search
    // context at a time, and that vehicle might be edited by the user after they have items in the cart.
    // Updating the cart vehicle with the vehicle in the current search context each time an item is added
    // may make sense, but there may be a better way to approach this.
    this.setVehicle(StoreInstances.searchStore.currentVehicle)
  }

  private setOrderSelection(
    product: ShoppingCartProduct,
    location: ProductLocationModel,
    qtyToSet: number,
    vehicle: Vehicle,
    collectErrors: boolean
  ): void {
    const { locationId, minQty } = location
    if (qtyToSet < minQty && qtyToSet !== 0) {
      if (collectErrors) {
        product.availabilityError = buildAvailabilityError(
          AvailabilityErrorType.MIN_QTY_CHANGE
        )
        /*Even though we are adding a product not having minQty to cartVehicle, it will not be rendered on cartPage.
        Because we are not setting orderSelections for this product. We are adding it to CartVehicle only to collect 
        minQty error and read that error in addToCart method in QuoteStore*/

        this.findOrAddCartProduct(product, locationId, vehicle)
      }
      throw new Error('quantityRequestedIsLessThanMinimum')
    }
    const cartProduct = this.findOrAddCartProduct(product, locationId, vehicle)
    let orderSelection = findOrderSelection(cartProduct, locationId)
    if (!orderSelection) {
      orderSelection = {
        locationId,
        quantityRequested: qtyToSet,
      }
      const orderSelections = []
      orderSelections.push(orderSelection)
      cartProduct.orderSelections = orderSelections
    }
    orderSelection.quantityRequested = qtyToSet
  }

  public isThereOnlyTheMiscellaneousCart = (): boolean => {
    const currentVehicleCheck = this.data.vehicles.some(
      (vehicles) =>
        vehicles.vehicle.engine.id ===
        StoreInstances.searchStore.currentVehicle?.engine?.id
    )
    if (currentVehicleCheck) return false
    if (this.vehicles?.length > 1) return false
    return true
  }

  public switchLocation = async (
    product: ShoppingCartProduct | ProductModel,
    orderSelection: OrderSelection,
    toLocation: ProductLocationModel,
    vehicle: Vehicle,
    setLocationChangeTooltip?: Dispatch<SetStateAction<boolean>>
  ): Promise<void> => {
    await this.setQtyAtLocation({
      product,
      locationId: toLocation.locationId,
      /* Here you can see ALS(II.a1)/ALS(II.a3) being applied.
      If the selected location is the primary and the user has a
      orderIfNotAvailable preference, then the autoLocationChange will be
      skipped and the primary location will be selected.
      */
      autoLocationChange: !this.allowOrderWithoutAvailableInventory(toLocation),
      quantity: orderSelection.quantityRequested,
      vehicle,
      setLocationChangeTooltip,
    })
    // this.removeProductFromLocation(product, orderSelection.locationId, vehicle)
    // this.setVehicles(this.vehicles) // I know! but it'll force the vehicle cart UI to refresh
    this.save()
  }

  public removeProductFromLocation(
    product: ProductModel,
    locationId: string,
    vehicle: Vehicle
  ): void {
    const cartProduct = this.findProductMatch(product, vehicle)
    if (!cartProduct) {
      return
    }
    cartProduct.orderSelections = cartProduct.orderSelections?.filter(
      (selection) => selection.locationId !== locationId
    )
  }

  public cleanEmptyCarts = (): void => {
    this.data.vehicles = this.data.vehicles?.filter((vehicle) => {
      return (
        !this.isCartEmpty(vehicle) ||
        this.vehiclesMatch(
          vehicle.vehicle,
          StoreInstances.searchStore.currentVehicle
        )
      )
    })
  }

  public cleanAllCarts = (trackOption: GaTrackOption): void => {
    if (trackOption === GaTrackOption.track) {
      this.data.vehicles.forEach((v) =>
        v.products.forEach((p) => this.trackRemoveFromCart(p, v.vehicle))
      )
    }
    this.data.vehicles = []
  }

  public removeVehicleCart = (
    vehicle: Vehicle,
    trackOption: GaTrackOption
  ): void => {
    const vehicleIndex = this.getCurrentVehicleIndex(vehicle)
    if (trackOption === GaTrackOption.track) {
      this.data.vehicles?.[vehicleIndex]?.products?.forEach((p) =>
        this.trackRemoveFromCart(p, vehicle)
      )
    }
    this.data.vehicles?.splice(vehicleIndex, 1)
    delete this.cartValidations[vehicle.id]
  }

  public removeVehicleCarts = (vehicles: Array<CartVehicle>): void => {
    vehicles.forEach(({ vehicle }) =>
      this.removeVehicleCart(vehicle, GaTrackOption.doNotTrack)
    )
  }

  public getQtyAtLocation = (
    product: ProductModel,
    locationId: string,
    vehicle: Vehicle
  ): number => {
    const matchingProduct = this.findProductMatch(product, vehicle)
    if (matchingProduct) {
      const matchingOrderSelection = matchingProduct.orderSelections.find(
        (l) => l.locationId.toString() === locationId.toString()
      )
      return matchingOrderSelection?.quantityRequested ?? 0
    }
    return 0
  }

  public getMinQtyAtLocation = (
    product: ProductModel,
    locationId: string,
    vehicle: Vehicle
  ): number => {
    const matchingProduct = this.findProductMatch(product, vehicle)
    if (matchingProduct) {
      const minQtyOfSelectedLocation = matchingProduct.location.find(
        (location) => location.locationId.toString() === locationId.toString()
      )
      return minQtyOfSelectedLocation?.minQty ?? 1
    }
    return 1
  }

  getTotalProductQuantityInCart = (
    product: ProductModel,
    vehicle: Vehicle
  ): number => {
    const match = this.findProductMatch(product, vehicle)
    let total = 0
    if (!match) {
      return total
    }
    match.orderSelections.forEach((os) => {
      total += os.quantityRequested
    })
    return total
  }

  getTotalQuantityAvailableOnLocations = (product: ProductModel): number => {
    const locations = product?.location || []
    return locations.reduce((acc, location) => {
      const qtyAvailable = location?.qtyAvailable
      const total = acc + qtyAvailable
      return total
    }, 0)
  }

  getDisableStore = (qtyAvailable: number): boolean => {
    return qtyAvailable < this.quantitySelected
  }

  setDisableStores = (value: boolean): void => {
    this.disableStores = value
  }

  setQuantitySelected = (value: number): void => {
    this.quantitySelected = value
  }

  public allowOrderWithoutAvailableInventory = (
    location: ProductLocationModel
  ): boolean => {
    /* This function is used to switch the ALS(II.a)
    part of the flow to ALS(II.a1) or ALS(II.a2), which means,
    detecting if a location change should be overrided based on
    if the user has the user preference findit_orderIfNotAvail and
    the location that he's manually trying to select is the primary
    location of a product */
    return (
      StoreInstances.userStore?.preferences?.findit_orderIfNotAvail ===
        'true' && location.locType === LocationType.PRIMARY
    )
  }

  /*

  validateAndCleanCart {
    // @TODO: An endpoint should be added to the API to verify that all items in the cart are still available for purchase
  }

  */

  public setVehicleSpecification = (
    vehicle: Vehicle,
    specifications: Array<VehicleSpecificationCartItem>
  ): void => {
    let vehicleIdx = this.getCurrentVehicleIndex(vehicle)

    if (vehicleIdx < 0) {
      this.addVehicleToCart(vehicle)
      vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    }

    this.data.vehicles[vehicleIdx] = {
      ...this.data.vehicles[vehicleIdx],
      specifications,
    }

    this.save()
  }

  public getVehicleSpecification = (
    vehicle: Vehicle
  ): Array<VehicleSpecificationCartItem> => {
    return this.data.vehicles[this.getCurrentVehicleIndex(vehicle)]
      ?.specifications
  }

  public setLaborItems = (
    vehicle: Vehicle,
    selectedLaborsResults: LaborItem[]
  ): void => {
    let vehicleIdx = this.getCurrentVehicleIndex(vehicle)

    if (vehicleIdx < 0) {
      this.addVehicleToCart(vehicle)
      vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    }

    this.data.vehicles[vehicleIdx] = {
      ...this.data.vehicles[vehicleIdx],
      laborResults: selectedLaborsResults,
    }

    this.save()
  }

  public getLaborItems = (vehicle: Vehicle): LaborItem[] => {
    const vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    return this.data?.vehicles?.[vehicleIdx]?.laborResults
  }

  public areAllCartsEmpty = (): boolean => {
    return this.data.vehicles?.find((v) => !this.isCartEmpty(v)) === undefined
  }

  public allProductsHaveNoOrderSelections = (vehicle: CartVehicle): boolean => {
    return (
      vehicle.products?.filter(
        (product) => product?.orderSelections?.length > 0
      ).length === 0
    )
  }

  public isCartEmpty = (vehicle: CartVehicle): boolean => {
    return (
      this.allProductsHaveNoOrderSelections(vehicle) &&
      vehicle.specifications?.length === 0 &&
      vehicle.laborResults?.length === 0
    )
  }

  public getCountOfOrderAbleCarts = (): number => {
    const orderAbleCarts = this.data.vehicles.filter(this.isCartOrderAble)
    return orderAbleCarts?.length ?? 0
  }

  public isCartOrderAble = (vehicle: CartVehicle): boolean => {
    return !this.allProductsHaveNoOrderSelections(vehicle)
  }

  public vehicleProducts = (vehicle: Vehicle): Array<ShoppingCartProduct> => {
    const vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    const vehicleCart = this.data.vehicles?.[vehicleIdx]
    return vehicleCart?.products.filter(
      (p) =>
        p.availabilityError?.errorType !==
          AvailabilityErrorType.NOT_TRANSFERRED &&
        p.availabilityError?.errorType !==
          AvailabilityErrorType.CANT_BE_FULFILLED &&
        p.availabilityError?.errorType !==
          AvailabilityErrorType.CANT_BE_FULFILLED_FROM_SELECTED_LOCATION
    )
  }

  public getVehicleFormData = (vehicle: Vehicle): OrderFormData => {
    const vehicleCart = this.findCartVehicle(vehicle)

    return vehicleCart.orderFormData
  }

  public removeProductFromVehicle = (
    vehicle: Vehicle,
    product: ShoppingCartProduct
  ): void => {
    const cartVehicle = this.findCartVehicle(vehicle)
    const productIndex = this.findProductIndex(cartVehicle, product)
    cartVehicle.products.splice(productIndex, 1)
    if (this.isCartEmpty(cartVehicle)) {
      this.removeVehicleCart(vehicle, GaTrackOption.track)
    }

    this.trackRemoveFromCart(product, vehicle)
  }

  public trackAddToCart = (
    product: ProductModel,
    vehicle: Vehicle,
    location: ProductLocationModel,
    quantity: number
  ): void => {
    const { countryCode } = StoreInstances.userStore.country
    const regionId = Region[countryCode]
    const category = this.getGoogleAnalyticsCategory(
      product?.allianceterminologyId ?? '',
      product?.description ?? ''
    )

    GoogleTagManager.setVehicleDimensions(vehicle, regionId)
    GoogleTagManager.trackEvent('add_to_cart', {
      items: [
        {
          id: product.allianceProductId, // aka SKU
          name: GoogleTagManager.buildProductName(product),
          brand: product.brandName ?? product.manufacturerName,
          category,
          price: location.cost,
          quantity,
        },
      ],
    })
  }

  public trackRemoveFromCart = (
    product: ShoppingCartProduct,
    vehicle: Vehicle
  ): void => {
    const { countryCode } = StoreInstances.userStore.country
    const regionId = Region[countryCode]
    const category = this.getGoogleAnalyticsCategory(
      product?.allianceterminologyId ?? '',
      product?.description ?? ''
    )
    GoogleTagManager.setVehicleDimensions(vehicle, regionId)
    GoogleTagManager.trackEvent('remove_from_cart', {
      items: [
        {
          id: product.allianceProductId, // aka SKU
          name: GoogleTagManager.buildProductName(product),
          brand: product.brandName ?? product.manufacturerName,
          category,
          price: product.location?.[0]?.cost,
          quantity: product.perCarQty,
        },
      ],
    })
  }

  public findProductIndex = (
    cartVehicle: CartVehicle,
    product: ShoppingCartProduct
  ): number => {
    return cartVehicle.products.findIndex((p) => productsMatch(p, product))
  }

  public getProductAvailabilityErrors = (
    vehicle: Vehicle
  ): Array<ShoppingCartProduct> => {
    return this.findCartVehicle(vehicle).products.filter(
      (p) => p.availabilityError
    )
  }

  get totalCartQty(): number {
    return this.cartAccumulator((_p, os) => {
      return os.quantityRequested
    })
  }

  get cartCostSubTotal(): number {
    return this.cartAccumulator((p, os) => {
      const loc = getLocationById(p, os.locationId)

      const yourCostAfterPriceBreaks = () => {
        if (loc?.priceBreaks?.length > 0) {
          const lastPriceBreak = loc?.priceBreaks[loc?.priceBreaks?.length - 1]
          for (const priceBreak of loc?.priceBreaks || []) {
            if (
              priceBreak.minQty <= os.quantityRequested &&
              priceBreak.maxQty >= os.quantityRequested
            ) {
              return priceBreak.unitCost
            }
            if (
              lastPriceBreak?.maxQty &&
              os?.quantityRequested > lastPriceBreak?.maxQty
            ) {
              return lastPriceBreak?.unitCost
            }
          }
        }
        return loc?.cost
      }
      return os.quantityRequested * yourCostAfterPriceBreaks()
    })
  }

  get cartLaborCostSubtotal(): number {
    let laborCostSubtotal = 0
    this.data.vehicles.forEach((cartVehicle) => {
      if (cartVehicle.laborResults) {
        laborCostSubtotal +=
          StoreInstances.laborStore.calculateLaborCostFromItems(
            cartVehicle.laborResults
          )
      }
    })
    return laborCostSubtotal
  }

  public cartVehicleCostSubTotal(vehicle: Vehicle): number {
    return this.cartAccumulatorVehicle((p, os) => {
      const loc = getLocationById(p, os.locationId)

      const yourCostAfterPriceBreaks = () => {
        if (loc?.priceBreaks?.length > 0) {
          const lastPriceBreak = loc?.priceBreaks[loc?.priceBreaks?.length - 1]
          for (const priceBreak of loc?.priceBreaks || []) {
            if (
              priceBreak.minQty <= os.quantityRequested &&
              priceBreak.maxQty >= os.quantityRequested
            ) {
              return priceBreak.unitCost
            }
            if (
              lastPriceBreak?.maxQty &&
              os?.quantityRequested > lastPriceBreak?.maxQty
            ) {
              return lastPriceBreak?.unitCost
            }
          }
        }
        return loc?.cost
      }
      return os.quantityRequested * yourCostAfterPriceBreaks()
    }, vehicle)
  }

  public cartCostTotal = (currentVehicle: Vehicle): number => {
    return (
      this.cartVehicleCostSubTotal(currentVehicle) +
      this.cartVehicleCostCoreTotal(currentVehicle) +
      this.cartLaborTotal(currentVehicle)
    )
  }

  public cartLaborTotal = (currentVehicle: Vehicle): number => {
    const vehicleIndex = this.getCurrentVehicleIndex(currentVehicle)
    const laborResults = this.data.vehicles?.[vehicleIndex]?.laborResults
    return laborResults
      ? StoreInstances.laborStore.calculateLaborCostFromItems(laborResults)
      : 0
  }

  public cartShipmentTotal = (currentVehicle: Vehicle): number => {
    const vehicleIndex = this.getCurrentVehicleIndex(currentVehicle)
    let shipmentTotal = 0
    ;(this.data.vehicles?.[vehicleIndex]?.locations || []).forEach(
      (location) => {
        if (
          location.transportId === PUROLATOR &&
          !isNaN(location.carrierService?.totalPrice)
        ) {
          shipmentTotal += location.carrierService?.totalPrice
        }
      }
    )
    return shipmentTotal
  }

  get allCartsTotalShipmentCost(): number {
    let grandShipmentCost = 0
    this.vehicles.forEach(
      (vehicle) =>
        (grandShipmentCost += this.cartShipmentTotal(vehicle.vehicle))
    )
    return grandShipmentCost
  }

  get cartCostCoreTotal(): number {
    return this.cartAccumulator((p, os) => {
      const loc = getLocationById(p, os.locationId)
      return os.quantityRequested * loc.coreCost
    })
  }

  public cartVehicleCostCoreTotal(vehicle: Vehicle): number {
    return this.cartAccumulatorVehicle((p, os) => {
      const loc = getLocationById(p, os.locationId)
      return os.quantityRequested * loc.coreCost
    }, vehicle)
  }

  get cartListSubTotal(): number {
    return this.cartAccumulator((p, os) => {
      const loc = getLocationById(p, os.locationId)
      return os.quantityRequested * loc.list
    })
  }

  get cartListCoreTotal(): number {
    return this.cartAccumulator((p, os) => {
      const loc = getLocationById(p, os.locationId)
      return os.quantityRequested * loc.coreList
    })
  }

  private cartAccumulator = (
    orderSelectionFunc: (prod, orderSelection) => number
  ): number => {
    let sum = 0
    this.data.vehicles?.forEach((v) => {
      v.products?.forEach((p) => {
        p.orderSelections?.forEach((os) => {
          sum += orderSelectionFunc(p, os)
        })
      })
    })
    return sum
  }

  private cartAccumulatorVehicle = (
    orderSelectionFunc: (prod, orderSelection) => number,
    vehicle: Vehicle
  ): number => {
    let sum = 0
    this.data.vehicles?.[
      this.getCurrentVehicleIndex(vehicle)
    ]?.products.forEach((p) => {
      p.orderSelections?.forEach((os) => {
        sum += orderSelectionFunc(p, os)
      })
    })
    return sum
  }

  public findProductMatch = (
    product: ProductModel,
    vehicle: Vehicle
  ): Optional<ShoppingCartProduct> => {
    return this.data.vehicles?.[
      this.getCurrentVehicleIndex(vehicle)
    ]?.products.find((p2) => productsMatch(product, p2))
  }

  public findNumberOfEntries = (coverage: string): number => {
    let counterEntries = 0
    this.data.vehicles?.forEach((vehicle) => {
      vehicle?.products?.forEach((product) => {
        const matchCoverage = coverage
          ?.split(',')
          .includes(String(product?.orderNumber))
        if (matchCoverage) {
          product.orderSelections.forEach((orderSelection) => {
            const quantityRequested = orderSelection.quantityRequested
            counterEntries = counterEntries + quantityRequested
          })
        }
      })
    })
    return counterEntries
  }

  getCurrentVehicleIndex = (vehicle: Vehicle): number => {
    const index = this.data.vehicles.findIndex(
      (item) =>
        item?.vehicle?.year?.id === vehicle?.year?.id &&
        item?.vehicle?.make?.id === vehicle?.make?.id &&
        item?.vehicle?.model?.id === vehicle?.model?.id &&
        item?.vehicle?.engine?.id === vehicle?.engine?.id &&
        // TODO: check if comparing ID is enough.
        item?.vehicle?.id === vehicle?.id
    )
    return index
  }

  findFirstSimilarVehicleIndex = (vehicle: Vehicle): number => {
    const index = this.data.vehicles.findIndex(
      (item) =>
        item?.vehicle?.year?.id === vehicle?.year?.id &&
        item?.vehicle?.make?.id === vehicle?.make?.id &&
        item?.vehicle?.model?.id === vehicle?.model?.id &&
        item?.vehicle?.engine?.id === vehicle?.engine?.id
    )
    return index
  }

  findCartVehicle = (vehicle: Vehicle): CartVehicle => {
    const vehicleIdx = this.getCurrentVehicleIndex(vehicle)
    if (vehicleIdx >= 0) return this.data.vehicles[vehicleIdx]
    return undefined
  }

  findFirstSimilarCartVehicle = (vehicle: Vehicle): CartVehicle => {
    const vehicleIdx = this.findFirstSimilarVehicleIndex(vehicle)
    return vehicleIdx > -1 ? this.data.vehicles[vehicleIdx] : undefined
  }

  findCartVehicleById = (vehicleId: string): CartVehicle => {
    return this.data.vehicles.find((v) => v.vehicle?.id === vehicleId)
  }

  private findOrAddCartProduct = (
    product: ProductModel,
    locationId: string,
    vehicle: Vehicle
  ): ShoppingCartProduct => {
    const match = this.findProductMatch(product, vehicle)

    if (match) {
      return match
    }

    const cartProduct: ShoppingCartProduct = {
      ...product,
      activeLocationId: locationId,
      orderSelections: [],
    }
    this.data.vehicles[this.getCurrentVehicleIndex(vehicle)].products.push(
      cartProduct
    )
    return this.findProductMatch(cartProduct, vehicle)
  }

  public setVehicle = (vehicle: Vehicle): void => {
    this.data.vehicle = vehicle
  }

  public removeAllVehicles = (): void => {
    this.data.vehicles.forEach((v) =>
      v.products.forEach((p) => this.trackRemoveFromCart(p, v.vehicle))
    )
    this.data.vehicles = []
    this.cartValidations = {}
  }

  get vehicles(): Array<CartVehicle> {
    return this.data.vehicles
  }

  get vehicle(): Vehicle {
    if (!this.data.vehicle) {
      return {
        year: { id: undefined, value: '' },
        make: { id: undefined, value: '' },
        model: { id: undefined, value: '' },
        engine: { id: undefined, value: '' },
      }
    }
    return this.data.vehicle
  }

  public getDataForTransfer = (): ShoppingCartData => {
    return this.data
  }

  public save = (): void => {
    localStorage.setItem(getCartStorageKey(), JSON.stringify(this.data))
  }

  public restore = (): void => {
    const savedData = localStorage.getItem(getCartStorageKey())
    if (!savedData) {
      this.data = { version: Config.cartVersion, vehicles: [] }
      return
    }

    const parsedData = JSON.parse(savedData)
    // For now, only restore if the versions match, to avoid any errors related to schema changes
    if (parsedData.version === this.data.version) {
      this.data = {
        ...parsedData,
        vehicles: (parsedData as ShoppingCartData).vehicles.map((vehicle) => ({
          ...vehicle,
          locations: vehicle.locations ? vehicle.locations : [],
        })),
      }
    }
    this.cleanEmptyCarts()
    this.updateShipmentEstimates()
  }

  /**
   * Populate the cart with data from an incoming PartsBasket.  Currently only used for 'cart only' mode.
   * @param data
   */
  public fromEmbedded = (data: ShoppingCartOnlyData): void => {
    this.data = data
  }

  public setCheckAvailabilityLoaded = (loaded: boolean): void => {
    this.checkAvailabilityLoaded = loaded
  }

  /**
   * Vehicles match if the id, year, make, model, and engine all match
   */
  private vehiclesMatch = (v1: Vehicle, v2: Vehicle): boolean => {
    return (
      v1?.year?.id === v2?.year?.id &&
      v1?.make?.id === v2?.make?.id &&
      v1?.model?.id === v2?.model?.id &&
      v1?.engine?.id === v2?.engine?.id &&
      v1?.id === v2?.id
    )
  }

  public initReactions = (): void => {
    /** Save to local storage any time a value in the shopping cart data changes */
    reaction(
      () => StoreInstances.userStore.userHasLoaded,
      async () => {
        if (
          StoreInstances.userStore.userHasLoaded === true &&
          Config.isNotCartOnlyMode
        ) {
          await this.fetchTransports()
          this.restore() // Load from local storage
        }
      }
    )

    /** Save to local storage any time a value in the shopping cart data changes */
    reaction(
      () => StoreInstances.cart.totalCartQty,
      () => {
        StoreInstances.cart.save()
      }
    )

    /** Save to local storage any time a vehicle is added or removed */
    reaction(
      () => StoreInstances.cart.data.vehicles.length,
      () => {
        StoreInstances.cart.save()
      }
    )
  }

  public setCartPartDetails = (
    shoppingCartProduct: ShoppingCartProduct,
    yourCost?: number,
    selectedLocation?: ProductLocationModel,
    total?: number
  ): void => {
    this.cartPartDetails = {
      shoppingCartProduct,
      yourCost,
      selectedLocation,
      total,
    }
  }

  public getCartProductSelectedLocation = (
    product: ProductModel,
    cartVehicleSelection?: Vehicle
  ): ProductLocationModel => {
    const selectedCartVehicle =
      StoreInstances.searchStore.currentVehicle?.engine || IS_INTEGRATED_VERSION
        ? StoreInstances.searchStore.currentVehicle
        : miscellaneousVehicle
    const products = this.vehicleProducts(
      cartVehicleSelection ? cartVehicleSelection : selectedCartVehicle
    )

    const commonPartOrderSelections = products.find((item) =>
      productsMatch(item, product)
    )?.orderSelections

    const selectedLocation = getLocationById(
      product,
      commonPartOrderSelections[0]?.locationId
    )
    return selectedLocation
  }

  public getAvailableTransports = (
    locationId: string,
    orderType: string
  ): Transport[] => {
    return this.availableTransports
      ?.find((lt) => Number(lt.locationId) === Number(locationId))
      ?.transports.filter((t) => t.deliveryMethod === orderType)
  }

  public getAvailableOrderTypes = (locationId: string): string[] => {
    const orderTypes = this.availableTransports
      ?.find((lt) => Number(lt.locationId) === Number(locationId))
      ?.transports.map((transport) => transport.deliveryMethod)
    //returning unique orderTypes
    return Array.from(new Set(orderTypes))
  }

  public getAllLocationsFromTheCart = (
    vehicle: CartVehicle
  ): ProductLocationModel[] => {
    const allLocations: ProductLocationModel[] = []
    vehicle.products?.forEach((p) =>
      p.location?.forEach((l) => {
        if (l.locationId === p.orderSelections?.[0]?.locationId)
          allLocations.push(l)
      })
    )
    const uniqueLocations = allLocations.filter(
      (l, index, arr) =>
        arr.findIndex((l2) => l2.locationId === l.locationId) === index
    )
    return uniqueLocations
  }

  public hasPartsFromBuyDirectLocations = (vehicle: CartVehicle): boolean => {
    const uniqueLocations = this.getAllLocationsFromTheCart(vehicle)
    return uniqueLocations.findIndex(isBuyDirectLocation) > -1
  }

  public getPurolatorEligibility = async (
    locationIds: number[]
  ): Promise<void> => {
    const locationPurolatorEligibility =
      await ShipmentServiceProvider.fetchPurolatorEligibility(locationIds)
    this.purolatorEligibileLocations = locationPurolatorEligibility
      .filter((location) => location.eligible === true)
      .map((location) => location.sellnwLocation)
  }

  public checkPurolatorEligibilityOfCartVehicle = (
    locationIds: number[]
  ): boolean => {
    return (
      this.purolatorEligibileLocations?.find((location) =>
        locationIds.includes(location)
      ) !== undefined
    )
  }

  public checkPurolatorEligibilityOfLocation = (
    locationId: number
  ): boolean => {
    return this.purolatorEligibileLocations?.includes(Number(locationId))
  }

  public hashTransport = (transport: Transport): string => {
    const seqNo = transport.seqNo?.toString()?.replace(/\s/g, '')?.toLowerCase()
    const carrier = transport.carrier?.replace(/\s/g, '')?.toLowerCase()
    const shipCode = transport.shipCode?.replace(/\s/g, '')?.toLowerCase()
    const releaseCode = transport.releaseCode?.replace(/\s/g, '')?.toLowerCase()
    return `${carrier}_${shipCode}_${releaseCode}_${seqNo}`
  }

  public updateShipmentEstimates(): Promise<unknown> {
    return Promise.all(
      this.data.vehicles.map((vehicle) =>
        this.updateShipmentEstimatesForCart(vehicle)
      )
    )
  }

  getPuralatorEstimateForCartLocations(
    cartVehicle: CartVehicle,
    eligibleLocations: ProductLocationModel[]
  ): IEstimateReqData[] {
    return eligibleLocations.map((location) => {
      const { locationId } = location
      const { products } = cartVehicle
      const parts = products.filter(
        (product) =>
          product.orderSelections.find(
            (orderSelection) => orderSelection.locationId === locationId
          ) !== undefined
      )

      const estimatePartData = parts.map((part) => {
        const { partNumber, lineCode, height, weight, width } = part
        const { quantityRequested } = part.orderSelections.find(
          (orderSelection) => orderSelection.locationId === locationId
        )
        return {
          partNumber,
          linecode: lineCode,
          reqQty: quantityRequested,
          height,
          weight,
          length: 0,
          width,
        }
      })
      return {
        sellnwLocation: Number(locationId),
        estimatePartData,
      }
    })
  }

  public updateShipmentEstimatesForCart(
    cartVehicle: CartVehicle
  ): Promise<boolean> {
    cartVehicle.purolator.loadingEstimates = true
    // Check if Puralator is selected for any location in this cart
    const uniqueLocation = this.getAllLocationsFromTheCart(cartVehicle)
    const puralatorAddedLocations: ProductLocationModel[] =
      cartVehicle.locations
        .filter((location) => location.transportId === PUROLATOR)
        .map((location) => location.locationId)
        .map((locationId) =>
          uniqueLocation.find(
            (location) =>
              location.locationId.toString() === locationId.toString()
          )
        )
        .filter((productLocation) => productLocation !== undefined)

    if (puralatorAddedLocations.length === 0) {
      cartVehicle.purolator.loadingEstimates = false
      return Promise.resolve(false)
    }
    // Get the list of parts from those locations.
    // Call getEstimates with the parts info
    const estimateRequest = this.getPuralatorEstimateForCartLocations(
      cartVehicle,
      puralatorAddedLocations
    )

    return ShipmentServiceProvider.fetchPurolatorEstimates(
      estimateRequest
    ).then((purolatorEstimates) => {
      // Update the locations with new estimates.
      purolatorEstimates.estimateResponseData.forEach((estimate) => {
        const cartLocation = (cartVehicle.locations || []).find(
          (location) =>
            location.locationId.toString() ===
            estimate.sellnwLocation.toString()
        )
        cartLocation.carrierService = estimate.shipmentEstimates.find(
          (shipmentEstimate) =>
            shipmentEstimate.serviceId === cartLocation.carrierService.serviceId
        )
      })
      cartVehicle.purolator.loadingEstimates = false
      return true
    })
  }

  /*
    Returns true if for atleast one cart we are fetching purolator estimates. i.e., API call is in progress state
  */
  public getStatusOfPurolatorEstimates = (): boolean => {
    return (
      this.vehicles.find(
        (vehicle) => vehicle.purolator.loadingEstimates === true
      ) !== undefined
    )
  }

  public getProductsFromLocation = (
    vehicle: Vehicle,
    locationId: string
  ): ShoppingCartProduct[] => {
    const allValidProducts: ShoppingCartProduct[] =
      this.vehicleProducts(vehicle)
    const productsFromLocation = allValidProducts.filter(
      (product) => findOrderSelection(product, locationId) !== undefined
    )
    return productsFromLocation
  }

  public removeAnOrderLocation = (
    vehicle: Vehicle,
    locationId: string
  ): void => {
    const cartVehicle = this.findCartVehicle(vehicle)
    const locationIndex = cartVehicle.locations.findIndex(
      (location) => location.locationId === locationId
    )
    cartVehicle.locations.splice(locationIndex, 1)
  }
}
