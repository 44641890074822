import { AxiosError } from 'axios'
import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import { PartMatchingResponse } from 'src/api/lists/interfaces'
import miscellaneousVehicle from 'src/features/search/Results/utils/miscellaneousVehicle'
import ListServiceProvider, {
  ListImportResponse,
} from 'src/services/ListServiceProvider'
import { breakpoints } from 'src/theme/breakpoints'
import { StoreInstances } from '../StoreInstancesContainer'
import { IdNamePair, IdValuePair } from '../models/KeyValuePair'
import { ProductLocationModel } from '../models/ProductModel'
import { PartAttributes } from '../models/SearchModels'
import { Vehicle } from '../models/Vehicles'
import ListNode from './ListNode'
import {
  AddGroupPart,
  AddPartToList,
  CheckedListParts,
  DeleteGroupParts,
  GroupPart,
  GroupPartResponse,
  ListImportDetails,
  ListResponseError,
  MoveGroupParts,
  Product,
  RequestBodyNewList,
  UpdateGroupPart,
  ValidStatus,
  ValidateListPart,
  ValidatePartsRequest,
  VerifyStockedPartsRequest,
  VerifyStockedPartsResponse,
} from './interfaces'
import history from 'src/helpers/history'

interface SelectedList {
  id: number
  label: string
}

interface SelectedCategory {
  id: number
  label: string
}

interface MoveSelectedList {
  id: number
  label: string
  category: number
}

interface NameSelectedList {
  id?: number
  label?: string
  category: string
}

interface IUpdateGroupPartPaylod {
  isSelected?: boolean
  stockQty?: number
}

export class ListsStore {
  public categoryNameField = ''

  public categoryNameModal = false

  public categoryNameModalType: 'new' | 'rename'

  public categoryToRename: IdValuePair<string>

  public deleteCategoryModal = false

  public deleteListModal = false

  public deleteSelectedCategory: SelectedCategory

  public deleteSelectedList: SelectedList

  public deleteSelectedGroupPart: SelectedList

  public listNameModalType: 'new' | 'rename'

  public showListGroups = false

  public loadingCategories = false

  public loadingGroups = false

  public moveListModal = false

  public matchingFilteredSearchTerms: PartMatchingResponse = []

  public moveSelectedList: MoveSelectedList

  public nameListModal = false

  public nameListNameField = ''

  public nameListNotesField = ''

  public nameSelectedList: NameSelectedList

  public selectedList: ListNode = undefined

  public selectedCategory: ListNode = undefined

  public selectedGroup: ListNode = undefined

  public showAddProductsToGroupsModal = false

  public showBottomDrawer = false

  public showDeleteItemsFromListsModal = false

  public selectedItems = 0

  public selectedItemsList: GroupPart[] = []

  public checkedListParts: Array<CheckedListParts> = []

  public changeLocationParts: Array<ProductLocationModel> = []

  public validateListParts: Array<ValidateListPart> = []

  public validParts: Array<ValidateListPart> = []

  public invalidParts: Array<ValidateListPart> = []

  public inSufficientParts: Array<ValidateListPart> = []

  public cartSelectorModal = false

  public root: ListNode = new ListNode(0, 'root')

  public listImportRespData: ListImportResponse

  public selectedVehicle: Vehicle = miscellaneousVehicle

  public importListPartsLoading = false

  public newListCategoryId = undefined

  public showAddEditGroupModal = false

  public selectedListGroups = []

  public selectedGroupValue = 0

  /**
   *  selectedGroupParts property refers to the parts that are fetched from API for a given list and group
   */
  public selectedGroupParts: Array<GroupPart> = []

  public selectedGroupPartsCount = 0

  public selectedPartsList: Array<GroupPart> = []

  public groupPartsLoading = false

  public searchTerm = undefined

  public moveListGroups: Array<IdNamePair<number, string>> = []

  public thumbnails = []

  public currentPage = 0

  public itemsPerPage = 10

  public sortBy = null

  public sortOrder = null

  public enableSortIcons = ''

  public listImportStatus = false

  public listImportDetails: ListImportDetails = undefined

  onCategoryDeleted = false

  onListDeleted = false

  public arePartsBeingAddedToCart = false

  constructor() {
    makeAutoObservable(this)
  }

  public handlePaginationLimit = (start: number, limit: number): void => {
    this.currentPage = start
    this.itemsPerPage = limit
  }

  public handleSortReset = (): void => {
    this.sortBy = null
    this.sortOrder = null
    this.currentPage = 0
    this.itemsPerPage = 10
    this.enableSortIcons = ''
  }

  public handleEnableSortIcons = (sort: string): void => {
    this.enableSortIcons = sort
  }

  public handleCloseCartSelectorModal = (): void => {
    this.cartSelectorModal = false
  }

  public setThumbnails = (thumbnails: Array<PartAttributes>): void => {
    this.thumbnails = thumbnails
  }

  public getThumbnailUrl = (partNumber: string, lineCode: string): string => {
    const thumbnailUrl = this.thumbnails?.find(
      (item) =>
        item?.partNumber === partNumber &&
        item?.lineCode?.toUpperCase() === lineCode?.toUpperCase()
    )

    if (thumbnailUrl) {
      return thumbnailUrl?.partImages?.[0]?.url
    }
    return ''
  }

  public setShowListGroups = (show: boolean): void => {
    this.showListGroups = show
  }

  public setSearchTerm = async (searchTerm: string): Promise<void> => {
    if (searchTerm === '') {
      this.resetListSearchResults()
      this.getCategories()
    } else {
      this.searchTerm = searchTerm
      this.loadingCategories = true
      this.matchingFilteredSearchTerms =
        await ListServiceProvider.getMatchingParts(searchTerm)
      this.handleListCategories()
    }
  }

  public resetListSearchResults = (): void => {
    this.searchTerm = undefined
    this.matchingFilteredSearchTerms = []
    this.selectedPartsList = []
  }

  public resetSelectedPartsList = (): void => {
    this.selectedPartsList = []
  }

  private handleListCategories = (): void => {
    this.root.deleteAllChildren()
    this.matchingFilteredSearchTerms.forEach((list) => {
      let matchingCategoryNode = this.root.findChildren(
        list.category.id,
        list.category.name
      )
      if (!matchingCategoryNode) {
        matchingCategoryNode = new ListNode(
          list.category.id,
          list.category.name,
          'category'
        )
        this.root.addChild(matchingCategoryNode)
      }
      const listNode = new ListNode(list.id, list.name, 'list')
      this.root
        .findChildren(list.category.id, list.category.name)
        .addChild(listNode)
    })

    this.loadingCategories = false
  }

  public setCartSelectorModal = (set: boolean): void => {
    this.cartSelectorModal = set
  }

  public setShowAddProductsToGroupsModal = (modal: boolean): void => {
    this.showAddProductsToGroupsModal = modal
  }

  public setSelectedVehicle = (vehicle: Vehicle): void => {
    this.selectedVehicle = vehicle
  }

  public setShowBottomDrawer = (modal: boolean): void => {
    this.showBottomDrawer = modal
  }

  public setSelectedItems = (selectedItems?: number): void => {
    this.selectedItems = selectedItems
  }

  public setShowDeleteItemsFromListsModal = (modal: boolean): void => {
    this.showDeleteItemsFromListsModal = modal
  }

  public setShowSelectedItemsList = (selectedItemsList: GroupPart[]): void => {
    this.selectedItemsList = selectedItemsList
  }

  public setDeleteListModal = (modal: boolean): void => {
    this.deleteListModal = modal
  }

  public setDeleteCategoryModal = (modal: boolean): void => {
    this.deleteCategoryModal = modal
  }

  public setNameListNameField = (nameString: string): void => {
    this.nameListNameField = nameString
  }

  public setCategoryNameModal = (modal: boolean): void => {
    if (!modal) {
      this.categoryNameField = ''
      this.categoryToRename = null
    }
    this.categoryNameModal = modal
  }

  public setNameListNotesField = (notes: string): void => {
    this.nameListNotesField = notes
  }

  public setCategoryNameModalType = (type: 'new' | 'rename'): void => {
    this.categoryNameModalType = type
  }

  public setListNameModalType = (type: 'new' | 'rename'): void => {
    this.listNameModalType = type
    if (type === 'new') {
      this.nameListNameField = ''
      this.nameListNotesField = ''

      /*  In below step is necessary because, when we create a new list, we are resetting the fields to empty. So we are overriding
      previosly selected list's values in nameListNameField, nameListNameField with empty strings. To get those values in these properties we need
      to reselect the list. Then only a fresh API call(getListDetails) will happen and these values will be set.

      Otherwise we should make a fresh API call(getListDetails) for the selectedList to restore the nameListNameField & nameListNotesField values after we clonse new list modal.
       */
      if (this.selectedList) {
        this.selectedList = undefined
      }
    }
  }

  public setCategoryNameField = (name: string): void => {
    this.categoryNameField = name
  }

  public setNameListModal = (modal: boolean): void => {
    this.nameListModal = modal
  }

  public setMoveListModal = (modal: boolean): void => {
    this.moveListModal = modal
  }

  public setSelectedList = (selected: ListNode): void => {
    this.selectedList = selected
    this.resetListSearchResults()
    if (selected) {
      this.getListGroupDetails(this.selectedList.id)
    }
    if (this.selectedListGroups.length > 0) {
      this.setSelectedGroupValue(0)
    }
  }

  public setSelectedCategory = (selected: ListNode): void => {
    this.selectedCategory = selected
  }

  public setSelectedGroup = (selected: ListNode): void => {
    this.selectedGroup = selected
    /* @TODO add get products endpoint */

    if (StoreInstances.uiStore.showAddToListDrawer === false) {
      this.getListGroupParts()
    }
  }

  public setSelectedGroupValue = (newValue: number): void => {
    this.selectedGroupValue = newValue
  }

  public setDeleteSelectedList = (selected: SelectedList): void => {
    this.deleteSelectedList = selected
  }

  public setDeleteSelectedCategory = (selected: SelectedCategory): void => {
    this.deleteSelectedCategory = selected
  }

  public setMoveSelectedList = (selected: MoveSelectedList): void => {
    this.moveSelectedList = selected
  }

  public setNameSelectedList = (selected: NameSelectedList): void => {
    this.nameSelectedList = selected
  }

  public setCategoryToRename = (category: IdValuePair<string>): void => {
    this.categoryToRename = category
  }

  public onDeleteList = async (selected: number): Promise<void> => {
    this.deleteListModal = false
    this.deleteSelectedList = null

    try {
      const resp = await ListServiceProvider.deleteList(selected)

      if (resp.status === 200) {
        this.setListDeleted(true)
        this.getCategories()
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully deleted list'
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('listDeletionError')
      }
    }
  }

  public onDeleteGroupPart = async (
    deletingPartIds: Array<number>
  ): Promise<void> => {
    const partIdDelete: DeleteGroupParts = {
      partIds: deletingPartIds,
    }
    this.deleteSelectedGroupPart = null

    try {
      const status = await ListServiceProvider.deleteGroupPart(partIdDelete)

      if (status === 200) {
        this.getListGroupParts()
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully deleted part'
        )
        if (this.isInSearchMode() !== undefined) {
          this.setSearchTerm(this.searchTerm)
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('listDeletionError')
      }
    }
  }

  public onDeleteCategory = async (selected: number): Promise<void> => {
    const category = this.deleteSelectedCategory
    this.deleteCategoryModal = false
    this.deleteSelectedCategory = null

    try {
      const resp = await ListServiceProvider.deleteCategory(selected)

      if (resp.status === 200) {
        this.setCategoryDeleted(true)
        this.getCategories()
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyDeletedCategory'
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      const message = 'cannotDeleteCategory'
      const messageParams = [category?.label]
      StoreInstances.uiStore.displayErrorNotification(
        // TODO: refactor the toast notification to accept message parameters because it's too risky right now
        message,
        undefined, // undefined link
        undefined, // undefined time
        messageParams
      )
    }
  }

  public onMoveList = (listId: number, targetCategoryId: number): void => {
    this.moveListToAnotherCategory(listId, targetCategoryId)
  }

  public moveListToAnotherCategory = async (
    listId: number,
    targetCategoryId: number
  ): Promise<void> => {
    try {
      const status = await ListServiceProvider.moveListToAnotherCategory(
        listId,
        targetCategoryId
      )

      if (status === 200) {
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyListMoved'
        )

        this.moveListModal = false
        this.moveSelectedList = null

        this.setListDeleted(true)
        this.getCategories()
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('listNameAlreadyExists')
      } else {
        StoreInstances.uiStore.displayErrorNotification('errorMovingList')
      }
    }
  }

  public onNameList = (selected?: number): void => {
    if (this.listNameModalType === 'rename') {
      this.updateList(selected)
    } else {
      this.selectedGroup = undefined
      this.selectedListGroups = []
      this.createList()
    }
  }

  public createCategory = async (name: string): Promise<void> => {
    try {
      const { id } = await ListServiceProvider.createCategory(name)
      if (id !== null && id > 0) {
        this.setCategoryNameField('')
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully created category'
        )
      }
      this.getCategories()
      this.categoryNameModal = false
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      const { message }: ListResponseError = (e as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public updateCategory = async (
    name: string,
    categoryId: number
  ): Promise<void> => {
    try {
      const { id } = await ListServiceProvider.updateCategory(name, categoryId)
      if (id !== null && id > 0) {
        this.setCategoryNameField('')
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully updated category'
        )
      }
      this.getCategories()
      this.categoryNameModal = false
      this.categoryToRename = null
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      const { message }: ListResponseError = (e as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public setNewListCategoryId = (categoryId: number): void => {
    this.newListCategoryId = categoryId
  }

  public createList = async (): Promise<void> => {
    const requestData: RequestBodyNewList = {
      name: this.nameListNameField,
      note: this.nameListNotesField,
      categoryId: this.newListCategoryId,
    }
    try {
      const newlyCreatedList = await ListServiceProvider.createList(requestData)
      const updatedListNode = new ListNode(
        newlyCreatedList?.id,
        newlyCreatedList?.name,
        'list'
      )

      this.setSelectedList(updatedListNode)
      if (newlyCreatedList?.id !== null && newlyCreatedList?.id > 0) {
        this.getCategories()
        this.setNameListNameField('')
        this.setNameListNotesField('')
        this.nameListModal = false
        this.nameSelectedList = null
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyCreatedList'
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 400) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('listNameAlreadyExists')
      }
    }
  }

  public cutText = (text: string): string => {
    const maxLength = 4
    let cutText = text.substr(0, maxLength)

    // Check if the cut text ends with a period
    if (cutText.endsWith('.')) {
      return cutText
    }

    cutText = cutText.trim()

    // Check if there are spaces within the cut text
    const lastSpaceIndex = cutText.lastIndexOf(' ')
    if (lastSpaceIndex !== -1) {
      cutText = cutText.substr(0, lastSpaceIndex)
    }

    return cutText + '...'
  }

  public createListAndGroup = async (
    requestData: {
      name: string
      note: string
      categoryId: number
    },
    groupData: string,
    req: Array<AddPartToList>
  ): Promise<void> => {
    try {
      const responseCreateList = await ListServiceProvider.createList(
        requestData
      )
      let groupDefaultId = responseCreateList?.groups[0]?.id

      if (responseCreateList.id !== null && responseCreateList.id > 0) {
        this.getCategories()
        this.setNameListNameField('')
        this.setNameListNotesField('')
        this.nameListModal = false
        this.nameSelectedList = null

        if (groupData) {
          const { id: groupId } = await ListServiceProvider.createGroup(
            responseCreateList.id,
            groupData
          )
          groupDefaultId = groupId
        }

        const status = await ListServiceProvider.addPartToGroup(
          responseCreateList.id,
          groupDefaultId,
          req
        )
        if (status === 200 || status === 206) {
          let description = `${req[0]?.partDescription} (${req[0]?.partNumber})`
          const isTablet = window.innerWidth < breakpoints.tablet
          if (isTablet) {
            description = this.cutText(description)
          }
          StoreInstances.uiStore.displaySuccessNotification(
            `${description} was added to list: ${this.selectedList?.value} `,
            {
              text: 'View Lists',
              action: req[0]?.handleListLocation,
            }
          )
          this.setShowAddProductsToGroupsModal(false)
          this.getListGroupParts()
          req[0]?.handleClose()
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 400) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }
      if ((e as AxiosError).response.status === 403) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        const responseMessage = message.split(':')
        StoreInstances.uiStore.displayErrorNotification(responseMessage[1])
      }
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification(
          'List name already exists'
        )
      }
    }
  }

  public updateList = async (listId: number): Promise<void> => {
    const requestData: RequestBodyNewList = {
      name: this.nameListNameField,
      note: this.nameListNotesField,
    }

    try {
      const { id } = await ListServiceProvider.updateList(requestData, listId)
      if (id !== null && id > 0) {
        this.getCategories(true)
        this.nameListModal = false

        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyUpdatedList'
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 400) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }

      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('listNameAlreadyExists')
      }
    }
  }

  public createGroup = async (listId: number, name: string): Promise<void> => {
    try {
      const { id } = await ListServiceProvider.createGroup(listId, name)
      if (id !== null && id > 0) {
        this.getListGroupDetails(listId, true)
        this.setShowAddEditGroupModal(false)
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyCreatedGroup'
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 400) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }
      if ((e as AxiosError).response.status === 403) {
        const { message }: ListResponseError = (e as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }

      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification(
          'groupNameAlreadyExists'
        )
      }
    }
  }

  public updateGroup = async (name: string): Promise<void> => {
    try {
      const { id } = await ListServiceProvider.updateGroup(
        name,
        this.selectedGroup.id
      )
      if (id !== null && id > 0) {
        this.getListGroupDetails(this.selectedList.id, true)
        this.setShowAddEditGroupModal(false)
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyUpdatedGroup'
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      const { message }: ListResponseError = (e as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public deleteGroup = async (): Promise<void> => {
    try {
      const status = await ListServiceProvider.deleteGroup(
        this.selectedGroup.id
      )

      if (status === 200) {
        this.getListGroupDetails(this.selectedList.id)
        StoreInstances.uiStore.displaySuccessNotification(
          'successfullyDeletedGroup'
        )
        this.setSelectedGroupValue(0)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      const { message }: ListResponseError = (e as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public onNameCategory = (id?: number): void => {
    if (this.categoryNameModalType === 'rename') {
      this.updateCategory(this.categoryNameField, id)
    } else {
      this.createCategory(this.categoryNameField)
    }
  }

  public getListGroupDetails = async (
    listId: number,
    noGroupSelection = false
  ): Promise<void> => {
    this.selectedList.deleteAllChildren()
    try {
      const { groups, name, note } = await ListServiceProvider.getListDetails(
        this.selectedCategory.id,
        listId
      )
      this.setNameListNameField(name)
      this.setNameListNotesField(note)
      groups.forEach((group) => {
        const groupNode = new ListNode(group.id, group.name)
        this.selectedList.addChild(groupNode)
      })
      this.selectedListGroups = this.selectedList.getChildren()
      if (noGroupSelection === false) {
        this.setSelectedGroup(this.selectedList.getFirstChild())
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      StoreInstances.uiStore.displayErrorNotification(JSON.stringify(e.message))
    }
  }

  public getListGroups = async (
    categoryId: number,
    listId: number
  ): Promise<void> => {
    try {
      const { groups } = await ListServiceProvider.getListDetails(
        categoryId,
        listId
      )
      this.moveListGroups = groups
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      StoreInstances.uiStore.displayErrorNotification(JSON.stringify(e.message))
    }
  }

  public onMoveGroupParts = async (
    targetGroupId: number,
    movingGroupPartIds: Array<number>
  ): Promise<void> => {
    const movingGroupParts: MoveGroupParts = {
      partIds: movingGroupPartIds,
    }

    try {
      const status = await ListServiceProvider.movePartsToAnotherGroup(
        targetGroupId,
        movingGroupParts
      )

      if (status === 200) {
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully parts have been moved'
        )
        if (this.isInSearchMode() !== undefined) {
          this.setSearchTerm(this.searchTerm)
          return
        }
        this.getListGroupDetails(this.selectedList.id)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      if ((e as AxiosError).response.status === 409) {
        StoreInstances.uiStore.displayErrorNotification('Error moving parts')
      }
    }
  }

  public getCategories = async (isListUpdated?: boolean): Promise<void> => {
    /* GET for the side panel */
    this.loadingCategories = true
    const { categories } =
      await ListServiceProvider.getCategoriesAndLists() /* @TODO: get categories list */
    this.root.deleteAllChildren()
    for (const category of categories) {
      const categoryNode = new ListNode(category.id, category.name, 'category')
      if (categoryNode?.id === this.selectedCategory?.id)
        categoryNode.open = true
      for (const list of category.lists) {
        const listNode = new ListNode(list.id, list.name, 'list')
        categoryNode.addChild(listNode)
      }
      this.root.addChild(categoryNode)
    }
    const allCategories: ListNode[] = this.root.getChildren()
    const firstNonEmptyCategory: ListNode = allCategories.find(
      (eachCategory) => eachCategory.getChildren().length !== 0
    )
    if (!this.selectedCategory && firstNonEmptyCategory) {
      firstNonEmptyCategory.open = true
      this.setSelectedCategory(firstNonEmptyCategory)
    }

    const firstList = firstNonEmptyCategory?.getFirstChild()
    if (!this.selectedList && firstList) this.setSelectedList(firstList)

    // this is required to re-render updated list label and also to maintain selected category and list as per (MP4P-954)
    if (isListUpdated) {
      const upDatedList = categories
        ?.find((item) => item.id === this.selectedCategory?.id)
        ?.lists.find((item) => item.id === this.selectedList?.id)
      const updatedListNode = new ListNode(
        upDatedList.id,
        upDatedList.name,
        'list'
      )
      this.setSelectedList(updatedListNode)
    }

    if (this.onCategoryDeleted === true || this.onListDeleted === true) {
      this.setSelectedCategory(firstNonEmptyCategory)
      this.setSelectedList(firstList)
    }

    this.loadingCategories = false
  }

  public setCategoryDeleted = (value: boolean): void => {
    this.onCategoryDeleted = value
  }

  public setListDeleted = (value: boolean): void => {
    this.onListDeleted = value
  }

  public getSelectedCategoryLabel = (): string => {
    return this.selectedCategory?.value
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
  public getSelectedCategoryItems = (): any => {
    return this.selectedCategory?.getChildren()
  }

  public getSelectedListLabel = (): string => {
    return this.selectedList?.value
  }

  public getListGroupParts = async (
    sortBy?: string,
    sortOrder?: string
  ): Promise<void> => {
    try {
      this.setGroupPartsLoading(true)
      if (!this.selectedList || !this.selectedGroup) {
        this.setGroupPartsLoading(false)
        return
      }
      if (sortBy && sortOrder) {
        this.sortBy = sortBy
        this.sortOrder = sortOrder
      }
      const fetchParts = async () => {
        return await ListServiceProvider.getGroupDetails(
          this.selectedList.id,
          this.selectedGroup.id,
          {
            start: this.currentPage,
            limit: this.itemsPerPage,
            sortBy: this.sortBy,
            sortOrder: this.sortOrder,
          }
        )
      }
      let response = await fetchParts()

      /*
      The below code block till the end of the `if` block is written for bug/MPV3-2423.
      After adding a part without linecode(MFR) to a given group under a list, we need to validate 
      the part to add parts with same part number and multiple MFRs to the Group. For example if a part with 
      id 121949779 is added to group without MFR, then if we validate the part, then a new set of parts 
      be created with Ids from 121949780 onwards and 121949779 part will be deleted from the group. The new 
      set of parts will have all the possible lineCodes(MFRs). This is my observation from the code behaviour.
      */
      const partsWithNoLineCode = response.items
        .filter((item) => !item.lineCode)
        .map((item) => item.id)
      if (partsWithNoLineCode && partsWithNoLineCode.length > 0) {
        await ListServiceProvider.listPartsValidation({
          partIds: partsWithNoLineCode,
        })
        response = await fetchParts()
      }
      const { items, totalCount } = response
      this.setGroupPartsLoading(false)
      const temp = items.map((part) => {
        return {
          ...part,
          isSelected: this.selectedPartsList.some(
            (sPart) => sPart.id === part.id
          ),
        }
      })
      this.selectedGroupParts = temp
      if (totalCount === undefined) {
        this.selectedGroupPartsCount = 0
      } else {
        this.selectedGroupPartsCount = totalCount
      }
    } catch (error) {
      const isEmpty = Object.entries(error).length === 0

      if (isEmpty) {
        StoreInstances.uiStore.displayErrorNotification(
          'No parts in this group'
        )
        this.selectedGroupParts = []
        this.selectedGroupPartsCount = 0
      } else {
        const { message }: ListResponseError = (error as AxiosError).response
          .data as ListResponseError
        StoreInstances.uiStore.displayErrorNotification(message)
      }
    }
    this.setCategoryDeleted(false)
    this.setListDeleted(false)
    this.setGroupPartsLoading(false)
  }

  public resetSelectedGroupParts = (): void => {
    this.selectedGroupParts = []
    this.selectedPartsList = []
  }

  public isInSearchMode = (): boolean => {
    return this.searchTerm
  }

  public getMultiSearchParts = (): (Product & {
    isSelected: boolean
  })[] => {
    const allParts = []
    this.matchingFilteredSearchTerms.forEach((list) => {
      list.groups.forEach((group) => {
        group.items.forEach((part) => {
          allParts.push(part)
        })
      })
    })
    return allParts
  }

  public getMultiSearchSelectedParts = (): (Product & {
    isSelected: boolean
  })[] => {
    const selectedParts = this.getMultiSearchParts()?.filter(
      (part) => part.isSelected === true
    )
    return selectedParts
  }

  private setGroupPartsLoading = (isLoading: boolean) => {
    this.groupPartsLoading = isLoading
  }

  public addPartToGroup = async (req: Array<AddGroupPart>): Promise<void> => {
    try {
      const status = await ListServiceProvider.addPartToGroup(
        this.selectedList.id,
        this.selectedGroup.id,
        req
      )
      if (status === 200 || status === 206) {
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully added item to group'
        )
        this.setShowAddProductsToGroupsModal(false)
        this.getListGroupParts()
      }
    } catch (error) {
      const { message }: ListResponseError = (error as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public addPartToList = async (req: Array<AddPartToList>): Promise<void> => {
    try {
      const status = await ListServiceProvider.addPartToGroup(
        this.selectedList.id,
        this.selectedGroup.id,
        req
      )
      if (status === 200 || status === 206) {
        let description = `${req[0]?.partDescription} (${req[0]?.partNumber})`
        const isTablet = window.innerWidth < breakpoints.tablet
        if (isTablet) {
          description = this.cutText(description)
        }
        StoreInstances.uiStore.displaySuccessNotification(
          `${description} was added to list: ${this.selectedList?.value} `,
          {
            text: 'View Lists',
            action: req[0]?.handleListLocation,
          }
        )
        this.setShowAddProductsToGroupsModal(false)

        this.getListGroupParts()
        req[0]?.handleClose()
      }
    } catch (error) {
      const { message }: ListResponseError = (error as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
    }
  }

  public updatePartInGroup = async (
    req: Array<UpdateGroupPart>
  ): Promise<void> => {
    try {
      const status = await ListServiceProvider.updatePartInGroup(
        this.selectedList.id,
        this.selectedGroup.id,
        req
      )
      if (status === 200 || status === 206) {
        StoreInstances.uiStore.displaySuccessNotification(
          'Successfully updated part'
        )
        this.getListGroupParts()
      }
    } catch (error) {
      const { message }: ListResponseError = (error as AxiosError).response
        .data as ListResponseError
      StoreInstances.uiStore.displayErrorNotification(message)
      throw error
    }
  }

  public sortGroupParts = (sortList: Array<GroupPart>): void => {
    this.selectedGroupParts = sortList
  }

  public selectingAllGroupParts = (selection: boolean): void => {
    const temp = this.selectedGroupParts.map((part) => {
      return {
        ...part,
        isSelected: selection,
      }
    })
    this.selectedGroupParts = temp

    if (selection) {
      let result
      if (!this.selectedPartsList.length) {
        result = temp
      } else {
        result = temp.filter(
          (o1) => !this.selectedPartsList.some((o2) => o1?.id === o2?.id)
        )
      }
      this.selectedPartsList = [...this.selectedPartsList, ...result]
    } else {
      const result = this.selectedPartsList.filter(
        (o1) => !temp.some((o2) => o1?.id === o2?.id)
      )
      this.selectedPartsList = [...result]
    }
  }

  public selectAllPartsFromAList = (
    isSelected: boolean,
    listId: number
  ): void => {
    const temp = this.matchingFilteredSearchTerms.map((list) => {
      if (list.id === listId) {
        return {
          ...list,
          groups: list.groups.map((group) => {
            return {
              ...group,
              items: group.items.map((part) => {
                return { ...part, isSelected }
              }),
            }
          }),
        }
      }
      return list
    })
    this.matchingFilteredSearchTerms = temp
  }

  public countAllPartsInAList = (listId: number): number => {
    let count = 0
    this.matchingFilteredSearchTerms.forEach((list) => {
      if (list.id === listId) {
        list.groups.forEach((group) => {
          group.items.forEach(() => {
            count += 1
          })
        })
      }
    })
    return count
  }

  public addOrRemoveParts = (
    id: number,
    selection: boolean,
    part: GroupPart
  ): void => {
    const idx = this.selectedPartsList.findIndex((part) => part.id === id)
    if (selection) {
      if (idx < 0) {
        this.selectedPartsList = [...this.selectedPartsList, part]
      }
    } else {
      if (idx >= 0) {
        this.selectedPartsList = this.selectedPartsList.filter(
          (part) => part.id !== id
        )
      }
    }
  }

  public onSelectGroupPart = (id: number, isSelected: boolean): void => {
    const updatedPart = this.updateGroupPart(id, { isSelected })
    this.addOrRemoveParts(id, isSelected, updatedPart)
  }

  private updateGroupPart = (
    id: number,
    payload: IUpdateGroupPartPaylod
  ): GroupPart => {
    let updatedPart: GroupPart
    if (this.searchTerm) {
      const temp = this.matchingFilteredSearchTerms.map((list) => {
        return {
          ...list,
          groups: list.groups.map((group) => {
            return {
              ...group,
              items: group.items.map((part) => {
                if (part.id === id) {
                  updatedPart = { ...part, ...payload }
                  return updatedPart
                }
                return part
              }),
            }
          }),
        }
      })
      this.matchingFilteredSearchTerms = temp
    } else {
      const temp = this.selectedGroupParts.map((part) => {
        if (part.id === id) {
          updatedPart = { ...part, ...payload }
          return updatedPart
        }
        return part
      })
      this.selectedGroupParts = temp
    }
    return updatedPart
  }

  public updatePartQty = (stockQty: number, id: number): void => {
    this.updateGroupPart(id, { stockQty })
  }

  public sortListParts = (sortList: Array<CheckedListParts>): void => {
    this.checkedListParts = sortList
  }

  public selectAllListParts = (checked: boolean): void => {
    const temp = this.checkedListParts.map((part) => {
      return {
        ...part,
        checked,
      }
    })
    this.checkedListParts = temp
  }

  public selectedListPart = (id: number, checked: boolean): void => {
    const temp = this.checkedListParts.map((part) => {
      if (part.id === id) {
        return { ...part, checked }
      }
      return part
    })
    this.checkedListParts = temp
  }

  setImportListPartsLoading = (loading: boolean): void => {
    this.importListPartsLoading = loading
  }

  public importListParts = async (
    listId: string,
    fileToImport: File
  ): Promise<void> => {
    try {
      this.setImportListPartsLoading(true)
      this.listImportRespData = await ListServiceProvider.importListParts(
        listId,
        fileToImport
      )
      setTimeout(() => {
        this.importListDetails()
      }, 2500)
    } catch (e) {
      this.listImportRespData = {
        queueId: '',
      }
      this.setImportListPartsLoading(false)
    }
  }

  public importListDetails = async (): Promise<void> => {
    const resp = await ListServiceProvider.getListImportDetails(
      this.listImportRespData?.queueId
    )

    this.listImportStatus = resp?.some((respObj) =>
      respObj.status.includes('success')
    )

    if (this.listImportStatus === true) {
      this.listImportDetails = resp?.find(
        (respObj) => respObj.status === 'processing'
      )
      this.getListGroupDetails(this.selectedList.id)
      await ListServiceProvider.deleteListQueue(
        this.listImportRespData?.queueId
      )
    } else {
      const message = resp?.find(
        (respObj) => respObj?.status === 'failed'
      )?.message
      StoreInstances.uiStore.displayErrorNotification(message)
    }

    this.setImportListPartsLoading(false)
  }

  public resetImportListStatus = (): void => {
    this.listImportStatus = false
  }

  resetSelectedListParts = (): void => {
    this.validParts = []
    this.invalidParts = []
    this.inSufficientParts = []
  }

  private isAutoLocationChangeEnabled = (): boolean => {
    return (
      StoreInstances.userStore?.preferences?.findit_orderIfNotAvail === 'true'
    )
  }

  public handleAddToCart = (): void => {
    const { cart, uiStore } = StoreInstances
    cart.addVehicleToCart(this.selectedVehicle)
    const allParts = this.isInSearchMode()
      ? this.getMultiSearchSelectedParts()
      : this.selectedPartsList
    const listParts = [
      ...this.validParts,
      ...this.inSufficientParts,
      ...this.invalidParts,
    ]
    try {
      Promise.all(
        listParts.map((part) => {
          const quantity = allParts.find((p) => p.id === part.id).stockQty
          return cart.setQtyAtLocation({
            product: part,
            locationId: part.location?.[0]?.locationId,
            quantity,
            vehicle: this.selectedVehicle,
            autoLocationChange: this.isAutoLocationChangeEnabled(),
            collectErrors: true,
          })
        })
      ).then(() => {
        this.setListPartsToCartLoading(false)
        if (
          this.validParts.length === 0 &&
          this.inSufficientParts.length === 0 &&
          this.invalidParts.length > 0
        ) {
          uiStore.displayErrorNotification('No part Was added to your Cart')
          return
        }
        if (
          this.invalidParts.length === 0 &&
          this.validParts.length &&
          this.inSufficientParts.length == 0
        ) {
          uiStore.displaySuccessNotification('thePartWasAddedToYourCart')
        } else if (
          this.invalidParts.length &&
          this.validParts.length &&
          this.inSufficientParts.length
        ) {
          uiStore.displayWarningNotification('fewOfThePartsWereNotAddedToCart')
        }
        history.push('/cart')
        this.resetAllValidations()
        this.resetSelectedGroupParts()
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      this.setListPartsToCartLoading(false)
      uiStore.displayErrorNotification(e.message)
    }
  }

  public isThereOnlyTheMiscellaneousCart = (): boolean => {
    const { cart, searchStore } = StoreInstances
    if (searchStore.currentVehicle?.engine) return false
    if (cart?.vehicles?.length > 1) return false
    return true
  }

  public resetAllValidations = (): void => {
    this.validateListParts = []
  }

  public validateListPartsAPI = async (
    req: ValidatePartsRequest
  ): Promise<void> => {
    this.resetSelectedListParts()
    try {
      const resp = await ListServiceProvider.listPartsValidation(req)
      this.validateListParts = resp?.parts

      this.validateListParts.forEach((part) => {
        if (part.validationStatus === ValidStatus.VALID) {
          this.validParts.push(part)
        }
        if (part.validationStatus === ValidStatus.INVALID) {
          this.invalidParts.push(part)
        }
        if (part.validationStatus === ValidStatus.VALID_INSUFFICIENT_QTY) {
          this.inSufficientParts.push(part)
        }
        return null
      })
      if (this.validParts.length > 0 || this.inSufficientParts.length > 0) {
        this.handleAddToCart()
      } else {
        StoreInstances.uiStore.displayErrorNotification(
          `No Part was added to the cart`
        )
        this.setListPartsToCartLoading(false)
      }
    } catch (e) {
      this.resetSelectedListParts()
      throw e
    }
  }

  public setChangeLocationParts = (
    changeLocationParts: Array<ProductLocationModel>
  ): void => {
    this.changeLocationParts = changeLocationParts
  }

  public setShowAddEditGroupModal = (value: boolean): void => {
    this.showAddEditGroupModal = value
  }

  public getListByIdAndGroupId = async (
    listId: number,
    groupId: number
  ): Promise<GroupPartResponse> => {
    this.groupPartsLoading = true
    const groupDetails = await ListServiceProvider.getGroupDetails(
      listId,
      groupId,
      {
        start: 0,
        limit: 1000,
      }
    )
    this.groupPartsLoading = false
    return groupDetails
  }

  public toggleCategory = (category: ListNode): void => {
    const cat = this.root?.getChildren()?.find((c) => c.id === category.id)
    cat.open = !cat?.open
  }

  public verifyStockedParts = async (
    verifyStockedParts: VerifyStockedPartsRequest[]
  ): Promise<VerifyStockedPartsResponse[]> =>
    await ListServiceProvider.verifyStockedParts(verifyStockedParts)

  public resetSelectedListGroups = (): void => {
    this.selectedListGroups = []
  }

  public addListPartsToCart = (): void => {
    const partsToValidate = this.isInSearchMode()
      ? this.getMultiSearchSelectedParts()?.map((part) => part.id) || []
      : this.selectedPartsList?.map((part) => part.id) || []
    this.validateListPartsAPI({
      partIds: partsToValidate,
    }).catch(() => {
      StoreInstances.uiStore.displayErrorNotification('errorValidatingList')
    })
  }

  public setListPartsToCartLoading = (value: boolean): void => {
    this.arePartsBeingAddedToCart = value
  }
}

export const ListsStoreContext = createContext<ListsStore>(undefined)

export const useListsStore = (): ListsStore => {
  return useContext(ListsStoreContext)
}
