import axios, { AxiosRequestHeaders } from 'axios'
import {
  Config,
  IS_INTEGRATED_VERSION,
  MYPLACE_SERVICE_NAME,
} from '../config/ConfigManager'

import authManager from '../api/security/Auth'
import history from 'src/helpers/history'
import { CatalogIndexTracker } from './metrics/CatalogIndexTracker'
import { EmbeddedCommunicationsManager } from './embedded/EmbeddedCommunicationsManager'
import { defaultCredentials } from 'src/aesInit'

const selectedVehicle = sessionStorage.getItem('selected-vehicle')
const type = selectedVehicle && JSON.parse(selectedVehicle)?.type

interface IUrlData {
  service: string
  version: string
  endPoint: string
}

const decodeRequestUrl = (url: string): IUrlData | undefined => {
  let partUrl = ''
  if (url.startsWith(Config.urls.base)) {
    partUrl = url.replace(`${Config.urls.base}/`, '')
  }
  if (url.startsWith(Config.urls.baseOp)) {
    partUrl = url.replace(`${Config.urls.baseOp}/`, '')
  }
  if (partUrl === '') {
    return undefined
  }

  const [service, version, ...endPoint] = partUrl.split('/')

  return { service, version, endPoint: endPoint.join('/') }
}

/**
 * Helper to check if we need to add the `buyerId` as a param the request.
 * @param service The service being called.
 * @param endPoint The endpoint in the Serive being called.
 * @returns whether to add the `buyerId` as a param to the request.
 */
const shouldAddBuyerId = (service: string, endPoint: string): boolean => {
  if (
    !IS_INTEGRATED_VERSION ||
    !EmbeddedCommunicationsManager.integrationInfo.allowAnyBuyer
  ) {
    return false
  }

  if (
    service === 'part' ||
    (service === 'list' && endPoint.startsWith('part/validate')) ||
    (service === 'order' && endPoint.startsWith('multivehicleorder'))
  ) {
    return true
  }
  return false
}

/**
 * @deprecated
 * Use the BaseServiceProvider and related services that will handle the auth and other things.
 */
const instance = axios.create({
  timeout: 60000,
  params: {
    catalogId: 111,
    ...(type && {
      catalogId: type?.id,
    }),
  },
})

instance.interceptors.request.use(async (config) => {
  let currentToken
  try {
    currentToken = await authManager.getCurrentToken()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.trace(e, 'failed to fetch token')
    history.push('/login')
    return config
  }

  let headersToSend: { [key: string]: unknown } = {
    Authorization: currentToken,
  }

  const { service, endPoint } = decodeRequestUrl(config.url) || {}

  if (shouldAddBuyerId(service, endPoint)) {
    config.params.buyerId =
      EmbeddedCommunicationsManager.getPartsBasketCreds().buyerId
  }
  // Temp variable. Remove once migration to services is done.
  const tempUrl = config.baseURL + config.url
  if (
    config.url &&
    !tempUrl.includes('support/') &&
    !tempUrl.includes('embedded/') &&
    !tempUrl.includes('sayt/')
  ) {
    headersToSend = {
      ...headersToSend,
      'Service-Name': MYPLACE_SERVICE_NAME,
      'Catalog-Index': CatalogIndexTracker.index,
      'Partner-Name': IS_INTEGRATED_VERSION
        ? EmbeddedCommunicationsManager.getPartsBasketCreds().partnerId
        : defaultCredentials.partnerId,
    }
  }
  // https://github.com/axios/axios/issues/5034#issuecomment-1408548737
  return {
    ...config,
    headers: { ...headersToSend, ...config.headers } as AxiosRequestHeaders,
  }
})

export default instance
