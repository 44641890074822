import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { SimpleModal } from 'src/ui-components'
import { Sanitized } from 'src/features/search/Results/utils/Sanitized'
import { Translate } from 'src/i18n'
import { useUiStateStore } from '../uiState/UiStateStore'

export enum DuplicateVehicleActions {
  START_NEW = 'START NEW',
  MERGE = 'MERGE',
  CANCEL = 'CANCEL',
}

const DuplicateVehicleModal = observer((): ReactElement => {
  const uiStore = useUiStateStore()
  const { promise, vehicle } = uiStore.duplicateVehicleModalParams || {}
  const vehicleName = `${vehicle?.year?.value} ${vehicle?.make?.value} ${vehicle?.model?.value} ${vehicle?.engine?.value}`
  const boldVehicleName = `<b>${vehicleName}</b>`

  return (
    <SimpleModal
      title="duplicateVehicle"
      primaryActionText="startNewCart"
      secondaryActionText="mergeWithExistingCart"
      onPrimaryAction={() => {
        promise.resolve(DuplicateVehicleActions.START_NEW)
      }}
      onSecondaryAction={() => {
        promise.resolve(DuplicateVehicleActions.MERGE)
      }}
      onCancel={() => {
        promise.reject(DuplicateVehicleActions.CANCEL)
      }}
      disablePrimaryButton={false}
      show={uiStore.showDuplicateVehicleModal}
    >
      <Sanitized
        html={Translate('existingCartWarningModal', [boldVehicleName])}
      />
    </SimpleModal>
  )
})

export default DuplicateVehicleModal
