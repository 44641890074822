import { UserAccessDetails } from 'src/api/user/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'

class UserInfoServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'userinfo', '1.0', '')
  }

  getUserAccess = async (userId: string): Promise<UserAccessDetails> => {
    // Using axios as this API doesn't require authentication.
    const { data } = await this.get<UserAccessDetails>('useraccess', {
      params: { userId },
    })
    return data
  }
}

export default new UserInfoServiceProvider()
