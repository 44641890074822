import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { useSearchStore } from 'src/store/search/SearchStore'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'
import DrawerBody from './DrawerBody'
import DrawerFooter from './DrawerFooter'
import DrawerHeader from './DrawerHeader'
import { breakpoints } from 'src/theme/breakpoints'
import { isMobile } from 'react-device-detect'
import { DrawerSizes } from 'src/theme/drawer-size'

interface LaborLookupDrawerProps {
  open: boolean
  setLaborLookup: (laborLookup: boolean) => void
  setLaborResult: (laborResult: boolean) => void
}

const LaborLookupDrawer = observer(
  ({
    open,
    setLaborLookup,
    setLaborResult,
  }: LaborLookupDrawerProps): ReactElement => {
    const { currentVehicle: vehicle } = useSearchStore()

    const yearMakeModel = `${vehicle?.year?.value} ${vehicle?.make?.value} ${vehicle?.model?.value}`
    const engine = vehicle?.engine?.value || ''

    const header = <DrawerHeader {...{ yearMakeModel, engine }} />

    const body = <DrawerBody />

    const footer = (
      <DrawerFooter
        setLaborLookup={setLaborLookup}
        setLaborResult={setLaborResult}
      />
    )

    const showDividerLines = {
      showHeaderDivider: true,
      showFooterDivider: true,
    }

    const handleClose = () => {
      setLaborLookup(false)
    }

    return (
      <BaseDrawer
        open={open}
        title={isMobile ? ' ' : 'laborLookup'}
        header={isMobile ? null : header}
        body={body}
        footer={footer}
        onClose={handleClose}
        {...showDividerLines}
        width={window.innerWidth < breakpoints.tabletLarge ? '100%' : 'auto'}
        height={window.innerWidth < breakpoints.tabletLarge ? '90vh' : '100%'}
        minWidth={
          window.innerWidth < breakpoints.tabletLarge
            ? '100%'
            : DrawerSizes.medium
        }
      />
    )
  }
)

export default LaborLookupDrawer
