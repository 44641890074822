import {
  IPartItemResponse,
  IdGroupsResponse,
  IdsEnginesResponse,
} from 'src/features/partsCatalog/Selections/interfaces'
import { CatalogAPI } from './ApiInterface'
import AxiosInstance from '../../../api/axios'
import { CatalogTreeParser } from '../CatalogTreeParser'
import { CatalogNode } from '../CatalogNode'
import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import { Vehicle } from 'src/store/models/Vehicles'
import { Config } from 'src/config/ConfigManager'
import { StoreInstances } from 'src/store/StoreInstancesContainer'

export class PartsCatalogAPI implements CatalogAPI {
  CATEGORY_ENDPOINT

  GROUP_ENDPOINT

  TERMINOLOGY_ENDPOINT

  ENDPOINT_BASE

  SESSION_STORAGE_KEY

  constructor() {
    this.ENDPOINT_BASE = Config.urls.catalog
    this.CATEGORY_ENDPOINT = 'categories'
    this.GROUP_ENDPOINT = 'groups'
    this.TERMINOLOGY_ENDPOINT = 'terminologies'
    this.SESSION_STORAGE_KEY = 'selected-parts-catalog-tree'
  }

  fetchCategories = async (
    vehicle?: Vehicle
  ): Promise<Array<IdValueGeneric<string, string>>> => {
    const resp = await AxiosInstance.post<
      Array<IdValueGeneric<string, string>>
    >(`${this.ENDPOINT_BASE}/${this.CATEGORY_ENDPOINT}`, vehicle, {
      params: {
        catalogId: StoreInstances.searchStore.currentVehicle?.type
          ? StoreInstances.searchStore.currentVehicle?.type.id
          : 111,
      },
    })
    return resp.data
  }

  fetchGroups = async (
    categories: Array<IdValueGeneric<string, string>>,
    vehicle?: Vehicle
  ): Promise<IdGroupsResponse> => {
    const resp = await AxiosInstance.post<IdGroupsResponse>(
      `${this.ENDPOINT_BASE}/${this.GROUP_ENDPOINT}`,
      {
        categories,
        vehicle,
      },
      {
        params: {
          catalogId: StoreInstances.searchStore.currentVehicle?.type
            ? StoreInstances.searchStore.currentVehicle?.type.id
            : 111,
        },
      }
    )
    return resp.data
  }

  fetchPartTypes = async (
    groups: Array<IdValueGeneric<string, string>>,
    vehicle: Vehicle
  ): Promise<IPartItemResponse | IdsEnginesResponse> => {
    const resp = await AxiosInstance.post<
      IPartItemResponse | IdsEnginesResponse
    >(
      `${this.ENDPOINT_BASE}/${this.TERMINOLOGY_ENDPOINT}`,
      {
        groups,
        vehicle,
      },
      {
        params: {
          catalogId: StoreInstances.searchStore.currentVehicle?.type
            ? StoreInstances.searchStore.currentVehicle?.type.id
            : 111,
        },
      }
    )
    return resp.data
  }

  retrieveSelectionsFromSession = (parser: CatalogTreeParser): CatalogNode => {
    const savedJson = sessionStorage.getItem(this.SESSION_STORAGE_KEY)
    if (savedJson?.length > 3) return parser.parse(savedJson)
    return undefined
  }

  storeSelectionsOnSession = (selections: string): void => {
    sessionStorage.setItem(this.SESSION_STORAGE_KEY, selections)
  }

  isGfxSupported = (): boolean => {
    return true
  }
}
