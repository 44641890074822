import {
  EntriesUrl,
  KeywordsAdsUrl,
  PromotionCoverageResponse,
  PromotionResponse,
} from 'src/api/promotion/interface'
import { BaseServiceProvider } from './BaseServiceProvider'

class PromotionServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'promotion', '1.1', '')
  }

  bannerPromotionApi = async (): Promise<PromotionResponse[]> => {
    const resp = await this.get<PromotionResponse[]>('promotion/active')
    return resp.data
  }

  bannerKeywordsApi = async (
    orderNumbers: unknown,
    aaiaBrands: unknown
  ): Promise<KeywordsAdsUrl> => {
    try {
      const resp = await this.post<KeywordsAdsUrl>('ad/banners', {
        orderNumbers,
        aaiaBrands,
      })
      return resp.data
    } catch (error) {
      return { adbanners: '' }
    }
  }

  getEntries = async (): Promise<EntriesUrl> => {
    try {
      const promotionData = await this.bannerPromotionApi()
      const resp = await this.get<EntriesUrl>(
        `promotion/${promotionData[0]?.promoId}/entries`
      )
      return resp.data
    } catch (error) {
      return {
        numberOfEntries: 0,
      }
    }
  }

  bannerPromotionCoverageApi = async (
    promoId: number
  ): Promise<PromotionCoverageResponse> => {
    try {
      const resp = await this.get<PromotionCoverageResponse>(
        `promotion/${promoId}/coverage`
      )
      return resp.data
    } catch (error) {
      return {
        coverage: '',
      }
    }
  }
}

export default new PromotionServiceProvider()
