import { ReactElement, useState } from 'react'
import { Button, TextInput } from 'src/ui-components'
import styled from 'styled-components'
import defaultLogoUrl from 'src/assets/images/logo.png'
import { I18n, Translate } from 'src/i18n'
import { useUserStore } from 'src/store/user/UserStore'
import { Hyperlink } from 'src/ui-components/util/Hyperlink'
import { useHistory } from 'react-router-dom'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { LogoImage } from '../navigation/TopNavigation/components/CommonMenuItems'

const SendCodeScreen = (): ReactElement => {
  const userStore = useUserStore()
  const [username, setUsername] = useState('')
  const [loading, setLoading] = useState(false)
  const [logoUrl] = useState(defaultLogoUrl)
  const { displayErrorNotification } = StoreInstances.uiStore
  const history = useHistory()

  const handleSendCode = async (): Promise<void> => {
    try {
      setLoading(true)
      const response = await userStore.sendCode(username)
      userStore.resetCredentialsUserName = username
      userStore.resetCredentialsDestination =
        response?.CodeDeliveryDetails?.Destination
      history.push('/resetPassword')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (error: any) {
      displayErrorNotification(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleContactAdmin = async (): Promise<void> => {
    history.push('/contactAdmin')
  }

  return (
    <Wrapper>
      <Content>
        <>
          <LogoImageWrapper
            src={logoUrl}
            alt={Translate('myPlaceForPartsLogo')}
          />
          <h3>
            <I18n name="forgotPassword" textReplacement={['']} />
          </h3>
          <InstructionsSpan>
            <I18n name="pleaseEnterYourScreenNameToSendVerificationCode" />
          </InstructionsSpan>
          <TextInputWrapper>
            <TextInput
              placeholder="username"
              label="screenName"
              value={username}
              onChange={(value) => setUsername(value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSendCode()
                }
              }}
              width="100%"
              noMargin
            />
          </TextInputWrapper>
          <SendCodeButton
            onClick={handleSendCode}
            text="sendCode"
            loading={loading}
          />
          {import.meta.env.VITE_FEATURE_CONTACT_ADMIN_ENABLED === 'true' && (
            <ContactAdminSection>
              <StyledText>
                <I18n name="needHelpResettingYourPassword" />
              </StyledText>
              <StyledText>
                <Hyperlink action={handleContactAdmin} underlined={false}>
                  <I18n name="contactAdminToRequestPasswordReset" />
                </Hyperlink>
              </StyledText>
            </ContactAdminSection>
          )}
        </>
      </Content>
    </Wrapper>
  )
}

export default SendCodeScreen

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const Content = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SendCodeButton = styled(Button)`
  margin-top: 15px;
  width: 100%;
`

const LogoImageWrapper = styled(LogoImage)`
  margin-bottom: 1.5rem;
`

const StyledText = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const ContactAdminSection = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
`

const InstructionsSpan = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`

const TextInputWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`
