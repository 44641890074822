export interface Link {
  text: string
  action: () => void
}

// we can use an external library later for an extensive list
export enum KeyBoardKeys {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  Enter = 'Enter',
  Tab = 'Tab',
}
