import { ReactElement, useEffect, useRef, useState } from 'react'
import { Overlay } from 'react-bootstrap'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { useClickOutsideDetector } from 'src/hooks/useClickOutsideDetector'
import { Box, Grow } from '@mui/material'
import { KeyBoardKeys } from 'src/ui-components'
import { TablePickerProps } from '../interfaces'
import { zIndex } from 'src/theme/zIndex.ts'

export const TablePicker = (tableProps: TablePickerProps): ReactElement => {
  const {
    label,
    isActive,
    onSelectItem,
    onCancel,
    columns,
    parentRef,
    selectedOption,
    tableRef,
  } = tableProps

  const buttonRef = useRef(null)
  useClickOutsideDetector([parentRef, tableRef], isActive, onCancel)

  useEffect(() => {
    if (
      columns?.length === 1 &&
      columns[0].options.length === 1 &&
      isActive &&
      label === 'engine'
    ) {
      const firstElement = columns[0].options[0]
      onSelectItem({
        selection: firstElement,
        selectionType: {
          id: firstElement.typeId,
          value: firstElement.typeName,
        },
      })
    }
    setDropdownOptions(columns)
    // eslint-disable-next-line
  }, [columns, isActive])

  const isLoading = columns.length === 0

  const [dropdownOptions, setDropdownOptions] = useState(columns)

  const handleKeyUp = (e): void => {
    e.preventDefault()
    e.stopPropagation()
    const currentElement = e.currentTarget
    const previousSibling = currentElement.previousSibling
    const nextSibling = currentElement.nextSibling
    const row = currentElement.dataset.row

    if (e.key === KeyBoardKeys.ArrowUp) {
      if (previousSibling) {
        previousSibling.focus()
      } else {
        const column = Number(currentElement.dataset.column) - 1
        const row =
          currentElement?.parentElement?.previousElementSibling?.getElementsByTagName(
            '*'
          ).length - 1
        const previousColumn = document.getElementById(`${column}-${row}`)
        if (previousColumn) {
          previousColumn.focus()
        }
      }
    } else if (e.key === KeyBoardKeys.ArrowDown) {
      if (nextSibling) {
        nextSibling.focus()
      } else {
        const column = Number(currentElement.dataset.column) + 1
        const nextColumn = document.getElementById(`${column}-0`)
        if (nextColumn) {
          nextColumn.focus()
        }
      }
    } else if (e.key === KeyBoardKeys.Enter || e.key === KeyBoardKeys.Tab) {
      currentElement?.click()
    } else if (e.key === KeyBoardKeys.ArrowLeft) {
      const column = Number(currentElement.dataset.column) - 1
      const previousCell = document.getElementById(`${column}-${row}`)
      if (previousCell) {
        previousCell.focus()
      }
    } else if (e.key === KeyBoardKeys.ArrowRight) {
      const column = Number(currentElement.dataset.column) + 1
      const nextCell = document.getElementById(`${column}-${row}`)
      if (nextCell) {
        nextCell.focus()
      }
    }
  }

  const handleOnTableFocus = (): void => {
    buttonRef?.current?.focus()
  }

  const handleOnMenuOptionFocus = (e): void => {
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <Overlay
      show={isActive}
      placement="bottom-start"
      popperConfig={{ strategy: 'absolute' }}
      target={parentRef.current}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars -- Bulk disabling. Fix if possible. */}
      {({ placement, arrowProps, show: _show, popper, ...props }) => (
        <Wrapper
          {...props}
          style={{
            ...props.style,
            zIndex: zIndex.dropdown,
          }}
        >
          <Box>
            <Grow in={!isLoading}>
              <InnerWrapper
                ref={tableRef}
                tabIndex={0}
                onFocus={handleOnTableFocus}
              >
                {dropdownOptions.map((col, colIndex) => {
                  if (col.options.length === 0) return <span key={col.id} />
                  return (
                    <div key={`${col.id}_${col.header}`}>
                      {label !== 'year' && <Header>{col.header}</Header>}
                      {col.options.map((option, rowIndex) => {
                        const isSelected =
                          option.id === selectedOption?.id &&
                          option.value === selectedOption?.value
                        const ret = {
                          selection: option,
                          selectionType: {
                            id: option.typeId ?? col.id,
                            value: option.typeName ?? col.header,
                          },
                        }
                        return (
                          <MenuOption
                            ref={
                              colIndex === 0 && rowIndex === 0
                                ? buttonRef
                                : null
                            }
                            data-column={`${colIndex}`}
                            data-row={`${rowIndex}`}
                            id={`${colIndex}-${rowIndex}`}
                            key={`${option.id}_${option.typeId}`}
                            selected={isSelected}
                            onClick={() =>
                              isSelected ? onCancel() : onSelectItem(ret)
                            }
                            tabIndex={0}
                            onKeyUp={handleKeyUp}
                            onKeyDown={(e) => e.preventDefault()}
                            onFocus={handleOnMenuOptionFocus}
                          >
                            {option.value}
                          </MenuOption>
                        )
                      })}
                    </div>
                  )
                })}
              </InnerWrapper>
            </Grow>
          </Box>
        </Wrapper>
      )}
    </Overlay>
  )
}

interface MenuOptionProps {
  selected: boolean
}

const Header = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin: 10px;

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
    margin-left: 12px;
    padding: 4px;
  }
`

const MenuOption = styled.div<MenuOptionProps>`
  padding: 4px;
  margin: 6px;
  font-weight: 400;
  font-size: 12px;
  background-color: ${(p) => (p.selected ? p.theme.colors.primary : 'none')};
  color: ${(p) => p.theme.colors.black};
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${(p) => p.theme.colors.disabledBackground};
    border: none;
    outline-style: none;
  }
  &:active {
    background: #777777;
  }

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    margin-left: 12px;
  }
`

const Wrapper = styled.div`
  display: inline-block;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    width: 92%;
  }
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  background-color: #fff;
  color: ${(p) => p.theme.colors.text};
  border-radius: 3px;
  border: ${(p) => p.theme.fieldBorder};
  max-height: 350px;
  overflow: auto;

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    flex-direction: column;
    max-height: 225px;
    overflow: auto;
  }
`
