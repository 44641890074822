import { ReactElement, useContext, useState, KeyboardEvent } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { SearchStoreContext } from 'src/store/search/SearchStore'
import { SearchIconBtn, TextInput } from 'src/ui-components'
import {
  VehicleWidgetErrorState,
  vehicleWidgetStore,
} from '../store/VehicleWidgetStore'
import VinValidator from '../VinValidator'
import { VinCheckMessage } from './VinCheckMessage'
import { usePartsCatalog } from 'src/store/partsCatalog/PartsCatalog'

export const VinSearch = (): ReactElement => {
  const history = useHistory()
  const searchStore = useContext(SearchStoreContext)
  const catalog = usePartsCatalog()
  const [vin, setVin] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchByVin = async () => {
    if (!VinValidator.isValid(vin)) {
      vehicleWidgetStore.setErrorState(
        VehicleWidgetErrorState.vinDigitCheckFailed
      )
      return
    }
    setIsLoading(true)
    const foundVehicle = await vehicleWidgetStore.lookupVehicle({ vin })
    setIsLoading(false)
    if (foundVehicle) {
      vehicleWidgetStore.setIsDrawerOpened(false)
      catalog.resetStore()
      history.push('/partsCatalog')
    }
    searchStore.setShowVehicleTransition(true)
  }

  const submitIfEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchByVin()
    }
  }

  const sanitizeAndSetVin = (text: string) => {
    const sanitized = VinValidator.sanitize(text)
    setVin(sanitized)
  }

  return (
    <Wrapper>
      <Col>
        <Row>
          <TextInput
            onChange={sanitizeAndSetVin}
            value={vin}
            onKeyPress={submitIfEnter}
            noMargin
            placeholder="enterVin"
          />
          <SearchIconBtn
            onClick={handleSearchByVin}
            loading={isLoading}
            disabled={vin.length < 17}
            variant="attached"
          />
        </Row>
        <VinCheckMessage vin={vin} />
      </Col>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  .field-group-content {
    align-items: flex-start;
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

const Col = styled.div`
  flex-grow: 1;
  flex-direction: column;
`
