import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { UserCredentials } from 'src/api/user/interfaces'
import { getAwsConfig } from 'src/aws-exports'
import { StorageKeys } from 'src/config/constants'
import queryParser from 'src/helpers/QueryParser'
import { I18n } from 'src/i18n'
import UserInfoServiceOpProvider from 'src/services/UserInfoServiceOpProvider'
import { StoreInstances } from 'src/store/StoreInstancesContainer'

// Set the user session in localstorage based in the credentials received.
const setUserSession = (data: UserCredentials, impersonated: string): void => {
  const awsConfig = getAwsConfig()

  const cognitoKeyBase = `CognitoIdentityServiceProvider.${awsConfig.aws_user_pools_web_client_id}`

  localStorage.setItem(`${cognitoKeyBase}.LastAuthUser`, data.userId)
  const cognitoUserKeyBase = `${cognitoKeyBase}.${data.userId}`

  localStorage.setItem(
    `${cognitoUserKeyBase}.accessToken`,
    data.tokens.accessToken
  )
  localStorage.setItem(`${cognitoUserKeyBase}.idToken`, data.tokens.idToken)
  localStorage.setItem(
    `${cognitoUserKeyBase}.refreshToken`,
    data.tokens.refreshToken
  )

  localStorage.setItem(
    StorageKeys.AUTO_LOGIN_IMPERSONATION_KEY,
    (impersonated === 'true').toString()
  )

  localStorage.setItem(StorageKeys.AUTO_LOGIN_ORIGIN_KEY, data.domain)

  localStorage.setItem(`${cognitoUserKeyBase}.deviceKey`, data.tokens.deviceKey)
}

const AutoLogin = (): JSX.Element => {
  const [token, impersonate] = queryParser.getMultipleParams([
    'token',
    'impersonate',
  ])

  const { refetch, isLoading, isError } = useQuery(
    ['getCredentialsfromtoken'],
    () => UserInfoServiceOpProvider.getCredentialsfromtoken(token),
    {
      retry: 4,
      enabled: false,
      onSuccess: (credsFromToken) => {
        if (credsFromToken?.status === 'success') {
          setUserSession(credsFromToken, impersonate)
          window.location.href = `${window.location.origin}/home`
        }
      },
      onError: () => {
        StoreInstances.uiStore.displayErrorNotification('failedToLogin')
      },
    }
  )

  useEffect(() => {
    if (!token) {
      return
    }
    refetch()
  }, [token, refetch])

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      {isLoading && <Spinner />}
      {isError && (
        <span className="fw-bold">
          <I18n name="failedToLogin" />
        </span>
      )}
    </div>
  )
}

export default AutoLogin
