import { LocationType } from 'src/helpers/locationType'
import { ProductLocationModel, ProductModel } from '../models/ProductModel'
import {
  CartVehicle,
  OrderLocation,
  OrderSelection,
  ShoppingCartProduct,
} from '../models/ShoppingCartModels'
import {
  OrderLabourInfo,
  OrderPart,
  SpecsOrderInfo,
} from 'src/api/cart/interfaces'
import { StoreInstances } from '../StoreInstancesContainer'
import { Optional } from 'src/common-interfaces/generic-interfaces'
import { Vehicle } from '../models/Vehicles'
import { LaborItem } from '../models/LaborModel'
import { VehicleSpecificationCartItem } from '../models/VehicleSpecification'

export const selectLocationByQty = (
  product: ProductModel,
  qtyRequested: number
): ProductLocationModel => {
  const locations = product.location ?? []

  const locationsCopy = [...locations]
  const match = locationsCopy.find(
    (location) =>
      location.qtyAvailable >= qtyRequested &&
      location.locType !== LocationType.VIEW_ONLY
  )
  if (match) {
    return match
  }
  /* Here you can see how we're setting the primary
  location as default if the user doesn't have any available locations
  ALS(II.a4)
  */
  return locations[0]
}

export const currentLocationHasQuantity = (
  product: ProductModel,
  qtyRequested: number,
  locationId: string
): boolean => {
  const locations = product.location ?? []

  const selectedLocation = locations.find(
    (item) => item.locationId === locationId
  )
  return selectedLocation && selectedLocation.qtyAvailable
    ? selectedLocation.qtyAvailable >= qtyRequested
    : false
}

export const makeNewCartVehicle = (vehicle: Vehicle): CartVehicle => {
  return {
    vehicle,
    products: [],
    specifications: [],
    laborResults: [],
    orderFormData: {
      poNumber: '',
      customerName: '',
      noteToStore: '',
      personalNote: '',
    },
    locations: [],
    purolator: {
      loadingEstimates: false,
      operation: { operationType: null, product: null },
    },
  }
}

export const isBuyDirectLocation = (
  location: ProductLocationModel
): boolean => {
  return (
    location?.locType === LocationType.BUY_DIRECT ||
    location?.locType === LocationType.VIRTUAL_BUY_DIRECT
  )
}
export const getLocationById = (
  product: ProductModel,
  locationId: string
): Optional<ProductLocationModel> => {
  return product.location.find(
    (location) => location.locationId.toString() === locationId.toString()
  )
}
export const mapCartProductsWithManyLocations = (
  cartProducts: Array<ShoppingCartProduct>,
  cartLocations: OrderLocation[]
): Array<OrderPart> => {
  const mapped: OrderPart[] = []
  let count = 0
  cartProducts.forEach((cartPart: ShoppingCartProduct) => {
    cartPart.orderSelections.forEach((locationSelection: OrderSelection) => {
      count += 1
      const { locationId, quantityRequested } = locationSelection
      const { orderType, transportId } = cartLocations.find(
        (location) => Number(location.locationId) === Number(locationId)
      )
      const location = getLocationById(cartPart, locationId)
      const category = StoreInstances.cart.getGoogleAnalyticsCategory(
        cartPart?.allianceterminologyId ?? '',
        cartPart?.description ?? ''
      )

      const line: OrderPart = {
        allianceProductId: cartPart.allianceProductId,
        // MP4P-1212 - Sending the category description instead of the alliance terminology ID
        // TODO: ask the API side to rename this field to GACategory (for the order endpoint only)
        allianceTerminologyId: category,
        brand: cartPart.brand,
        description: cartPart.description,
        lineNo: count,
        lineCode: cartPart.lineCode,
        locationDescription: location.called,
        locationId,
        partNumber: cartPart.partNumber,
        manufacturerName: cartPart.manufacturerName,
        isPriceOverride: false,
        priceOverrideMsg: '',
        quantityRequested,
        quantityAvailable: location.qtyAvailable,
        unitCorePrice: location.coreCost,
        unitCostPrice: location.cost,
        unitListCore: location.coreList,
        unitListPrice: location.list,
        unitOfMeasure: location.unitOfMeasure,
        orderType,
        transportId,
        isBuyDirectLocation: isBuyDirectLocation(location),
      }
      mapped.push(line)
    })
  })
  return mapped
}

export const mapCartVehicleLaborsToLaborsInOrderRequestBody = (
  cartLabors: Array<LaborItem>
): Array<OrderLabourInfo> => {
  const labors: Array<OrderLabourInfo> = []
  cartLabors.forEach((cartLabor) => {
    const orderLabor = {
      details: cartLabor.details,
      hours: cartLabor.hours,
      laborId: cartLabor.laborId,
      description: cartLabor.laborDescription,
      rate: cartLabor.rate,
      notes: cartLabor.miscellaneousText,
      skillLevel: cartLabor.skillLevel,
      warrantyHours: cartLabor.warrantyHrs,
    }
    labors.push(orderLabor)
  })
  return labors
}

export const mapCartVehicleSpecsToSpecsInOrderRequestBody = (
  cartSpecifications: Array<VehicleSpecificationCartItem>
): Array<SpecsOrderInfo> => {
  const specifications: Array<SpecsOrderInfo> = []
  cartSpecifications.forEach((cartSpecification) => {
    const orderSpec = {
      ...cartSpecification,
      miscellaneousTexts: cartSpecification.miscellaneousTexts.join(', '),
      id: 0,
      orderId: 0,
    }
    specifications.push(orderSpec)
  })
  return specifications
}

export const findOrderSelection = (
  product: ShoppingCartProduct,
  locationId: string
): Optional<OrderSelection> => {
  if (!product?.orderSelections) {
    return null
  }
  return product.orderSelections.find(
    (selection: OrderSelection) =>
      selection.locationId.toString() === locationId.toString()
  )
}

export const getActiveLocation = (
  product: ShoppingCartProduct
): ProductLocationModel => {
  if (product.activeLocationId === undefined) {
    return product.location[0]
  }
  return getLocationById(product, product.activeLocationId)
}

/**
 * Products match if the partNumber, lineCode, description, and year all match
 */
export const productsMatch = (p1: ProductModel, p2: ProductModel): boolean => {
  return (
    p1.partNumber === p2.partNumber &&
    p1.lineCode === p2.lineCode &&
    p1.description === p2.description &&
    p1.fromYear === p2.fromYear
  )
}
