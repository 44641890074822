import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import { Detail } from 'src/store/orderHistory/interfaces.ts'

export class RmaStore {
  selectedOrderItems: Detail[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setSelectedOrderItems = (items: Detail[]): void => {
    this.selectedOrderItems = items
  }
}

export const RMAContext = createContext<RmaStore>(undefined)

export const useRMAStore = (): RmaStore => useContext(RMAContext)
