import { makeAutoObservable } from 'mobx'
import {
  AccumulatorVisitor,
  DeselectVisitor,
  Filter,
  SelectVisitor,
  Visitor,
} from './visitors'
import {
  CatalogNodeClickHandler,
  CatalogNodeType,
  ChildrenSelectionState,
} from './interfaces'
import { PartTypeItem } from 'src/features/partsCatalog/Selections/interfaces'

interface CatalogNodeOptions {
  id: string
  value: string
  type: CatalogNodeType
  selected?: boolean
  clickHandler?: CatalogNodeClickHandler
  isConsolidated?: boolean
  children?: Array<CatalogNode>
}

export class CatalogNode {
  public selected: boolean

  public isConsolidated: boolean

  public id: string

  public value: string

  public type: CatalogNodeType

  readonly children: Array<CatalogNode>

  private parent: CatalogNode

  private clickHandler: CatalogNodeClickHandler

  constructor(options: CatalogNodeOptions) {
    this.children = options.children ?? ([] as Array<CatalogNode>)
    this.selected = options.selected ?? false
    this.id = options.id
    this.value = options.value
    this.type = options.type
    this.clickHandler = options.clickHandler
    this.isConsolidated =
      options.isConsolidated !== undefined ? options.isConsolidated : false
    makeAutoObservable(this)
  }

  public deleteAllChildren(): void {
    this.children.splice(0, this.children.length)
  }

  public addChild(child: CatalogNode): void {
    child.parent = this
    this.children.push(child)
  }

  public addChildrenFromAPI(
    children: Array<PartTypeItem>,
    type: CatalogNodeType,
    clickHandler: CatalogNodeClickHandler
  ): void {
    for (const child of children) {
      this.addChild(
        new CatalogNode({
          id: child.id,
          value: child.value,
          type,
          clickHandler,
          isConsolidated: child.isConsolidated,
        })
      )
    }
  }

  public getChildren(): Array<CatalogNode> {
    return this.children
  }

  public get items(): Array<CatalogNode> {
    return this.getChildren()
  }

  public getParent(): CatalogNode {
    return this.parent
  }

  public onClick = (): void => {
    this.clickHandler(this)
  }

  public get isSelected(): boolean {
    return this.selected
  }

  public select(): void {
    this.selected = true
  }

  public selectAllChildren(): void {
    const visitor = new SelectVisitor()
    for (const child of this.getChildren()) {
      visitor.visit(child)
    }
  }

  public deselect(): void {
    this.selected = false
  }

  public deselectAllChildren(): void {
    this.getChildren().forEach((c) => c.deselect())
  }

  public toggle(): void {
    if (this.isSelected) {
      this.deselect()
      this.deselectAllDescendants()
    } else {
      this.select()
    }
  }

  public findNode(id: string, type: CatalogNodeType): CatalogNode {
    const results = this.getAllDescendants(
      (node) => node.type === type && node.id.toString() === id?.toString()
    )
    return results[0]
  }

  public visit(visitor: Visitor): void {
    visitor.visit(this)
  }

  public getAllDescendants(filter: Filter | null = null): Array<CatalogNode> {
    const accumulator = new AccumulatorVisitor(filter)
    this.traverseAllDescendants(accumulator)
    return accumulator.getAll()
  }

  public deselectAllDescendants(): void {
    const deselector = new DeselectVisitor()
    this.traverseAllDescendants(deselector)
  }

  public getChildCumulativeState = (): ChildrenSelectionState => {
    const selectedCount = this.getChildren().filter((c) => c.selected).length
    if (selectedCount === 0) {
      return ChildrenSelectionState.NONE_SELECTED
    }
    return selectedCount === this.getChildren().length
      ? ChildrenSelectionState.ALL_SELECTED
      : ChildrenSelectionState.MIXED
  }

  public traverseAllDescendants(
    visitor: Visitor,
    aChild: CatalogNode = this
  ): Array<CatalogNode> {
    if (!aChild?.getChildren()?.length) {
      return null
    }
    const descendants = []
    for (const child of aChild.getChildren()) {
      const children = this.traverseAllDescendants(visitor, child)
      visitor.visit(child)
      descendants.push(child)
      if (children != null) {
        descendants.push(...children)
      }
    }
    return descendants
  }

  public unselectAllDescendantsRecursively = (): void => {
    this.getAllDescendants().forEach((node) => {
      if (node) node.unselectAllDescendantsRecursively()
    })
    this.deselect()
  }
}
