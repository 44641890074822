import { Transport } from 'src/api/cart/interfaces'
import { Vehicle } from './Vehicles'
import {
  AvailabilityError,
  ProductLocationModel,
  ProductModel,
} from './ProductModel'
import { VehicleSpecificationCartItem } from './VehicleSpecification'
import { LaborItem } from './LaborModel'
import { IShipmentEstimate } from 'src/api/shipment/interfaces'

export interface CartVehicle {
  vehicle: Vehicle
  products: Array<ShoppingCartProduct>
  quoteSourceId?: string
  orderFormData?: OrderFormData
  specifications?: Array<VehicleSpecificationCartItem>
  laborResults?: Array<LaborItem>
  locations?: OrderLocation[]
  purolator?: IPurolator
}

export interface IPurolator {
  loadingEstimates: boolean
  operation?: CartProductOperation
  payload?: string //This is the JSON.stringify() of payload sent to fetch purolator estimates
}

export interface CartProductOperation {
  product?: string
  operationType?: CartOperation
}

export enum CartOperation {
  QTY_CHANGE = 'QTY_CHANGE',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  ADD_PRODUCT = 'ADD_PRODUCT',
  LOCATION_CHANGE = 'LOCATION_CHANGE',
}

export interface OrderLocation {
  locationId: string
  carrierService?: IShipmentEstimate
  orderType?: string
  transportId?: string
}

export enum CartMode {
  MULTI_VEHICLE = 'multi-vehicle',
  SINGLE_VEHICLE = 'single-vehicle',
  CART_ONLY = 'cart-only',
}

export interface ShoppingCartData {
  version: string
  vehicle?: Vehicle
  vehicles?: Array<CartVehicle>
}

export interface ShoppingCartOnlyData {
  version: string
  vehicle?: Vehicle
  vehicles: Array<CartVehicle>
}

export interface ShoppingCartProduct extends ProductModel {
  activeLocationId?: string
  orderSelections?: Array<OrderSelection>
  availabilityError?: AvailabilityError
  validationStatus?: string
}

export interface OrderSelection {
  locationId: string
  // orderType?: string
  // transportId?: string
  quantityRequested: number
}

export interface OrderFormData {
  poNumber: string
  noteToStore: string
  customerName: string
  personalNote: string
}

export interface SelectedLocationTransports {
  locationId: string
  transport: Transport
}

export interface CartPartDetails {
  shoppingCartProduct: ShoppingCartProduct
  yourCost?: number
  selectedLocation?: ProductLocationModel
  total?: number
}
