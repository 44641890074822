import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { IdValuePair } from 'src/store/models/KeyValuePair'
import { breakpoints } from 'src/theme/breakpoints'

interface RadioI {
  id: string
  name: string
  options: Array<IdValuePair<string>>
  onChange: (selected: IdValuePair<string>) => void
  initialValue?: string
  className?: string
}

export const RadioGroup = (props: RadioI): ReactElement => {
  const {
    id,
    name,
    options: choices,
    onChange,
    initialValue,
    className,
  } = props

  const initialState = choices.find((o) => o.id === initialValue) ?? {
    id: '',
    value: '',
  }

  const [selected, setSelected] = useState<IdValuePair<string>>(initialState)

  const handleOnChange = (_v: unknown, choice: IdValuePair<string>) => {
    setSelected(choice)
    onChange(choice)
  }

  return (
    <>
      {choices.map((choice) => {
        return (
          <ItemWrapper key={choice.id}>
            <Item
              key={choice.id}
              onClick={(v): void => handleOnChange(v, choice)}
              className={className}
            >
              <RadioButton
                id={id}
                type="radio"
                name={name + id} // Name alone isn't unique enough, some groups get shared
                value={choice.id}
                checked={selected.id === choice.id}
                onChange={(v): void => handleOnChange(v, choice)}
              />
              <RadioButtonLabel>
                <InnerRadioButtonCircle />
              </RadioButtonLabel>
              <div>{choice.value}</div>
            </Item>
          </ItemWrapper>
        )
      })}
    </>
  )
}

RadioGroup.defaultProps = {
  initialValue: undefined,
  className: undefined,
}

const ItemWrapper = styled.div`
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    padding: 20px 0px 20px 24px;
    border-top: 1px solid #e5e4e1;
    width: 100%;
  }
`

const Item = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`

const InnerRadioButtonCircle = styled.div`
  position: absolute;
  margin: 15%;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: white;
`

const RadioButtonLabel = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: auto;
  border: 2px solid #000000;
`
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  &:checked + ${RadioButtonLabel} > ${InnerRadioButtonCircle} {
    background: ${(p) => p.theme.colors.primary};
  }
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    margin-bottom: 0;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`
