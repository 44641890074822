import { getUserAttributes, updateUserAttributes } from './embedded/GraphQlAPI'
import { getTokens } from './security/SecurityAPI'

export const API = {
  GraphQL: {
    getUserAttributes,
    saveUserAttributesToAWS: updateUserAttributes,
  },
  security: {
    getTokens,
  },
}
