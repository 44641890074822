import { getAwsConfig } from 'src/aws-exports'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { Vehicle } from 'src/store/models/Vehicles'
import authManager from '../api/security/Auth'

// TODO: Replace with lodash.
export const flatten = <T>(arr: T[][]): T[] =>
  arr.reduce<T[]>((flat, toFlatten) => [...flat, ...toFlatten], [])

export const random = (): string => {
  return Math.random().toString(36).slice(2)
}

export const getYMMENumberFromVehicle = (vehicle: Vehicle): string =>
  `${vehicle.year?.id},${vehicle.make?.id},${vehicle.model?.id},${vehicle.engine?.id}`

export const getYMMEFromVehicle = (vehicle: Vehicle): string =>
  `${vehicle.year?.value},${vehicle.make?.value},${vehicle.model?.value},${vehicle.engine?.value}`

export const getLaborRateFromCurrencyString = (laborRate: string): string =>
  laborRate.replace('$', '')

export const redirectToAllianceConnect = async (url: string): Promise<void> => {
  const awsConfig = getAwsConfig()
  const cognitoKeyBase = `CognitoIdentityServiceProvider.${awsConfig.aws_user_pools_web_client_id}`
  const cognitoUserKeyBase = `${cognitoKeyBase}.${authManager.getUserId()}`

  const lastAuthUserValue = localStorage.getItem(
    `${cognitoKeyBase}.LastAuthUser`
  )

  const deviceKeyValue = localStorage.getItem(`${cognitoUserKeyBase}.deviceKey`)
  const accessTokenValue = localStorage.getItem(
    `${cognitoUserKeyBase}.accessToken`
  )
  const refreshTokenValue = localStorage.getItem(
    `${cognitoUserKeyBase}.refreshToken`
  )
  const idTokenValue = localStorage.getItem(`${cognitoUserKeyBase}.idToken`)

  const clockDriftValue = localStorage.getItem(
    `${cognitoUserKeyBase}.clockDrift`
  )

  const deviceGroupKeyValue = localStorage.getItem(
    `${cognitoUserKeyBase}.deviceGroupKey`
  )

  const randomPasswordKeyValue = localStorage.getItem(
    `${cognitoUserKeyBase}.randomPasswordKey`
  )

  const form = document.createElement('form')

  const orgId = document.createElement('input')
  const userId = document.createElement('input')
  const LastAuthUser = document.createElement('input')
  const clockDrift = document.createElement('input')
  const deviceGroupKey = document.createElement('input')
  const deviceKey = document.createElement('input')
  const accessToken = document.createElement('input')
  const refreshToken = document.createElement('input')
  const idToken = document.createElement('input')
  const userscreenName = document.createElement('input')
  const userLanguage = document.createElement('input')
  const randomPasswordKey = document.createElement('input')

  orgId.value = StoreInstances.userStore.preferences.wdorgId
  orgId.name = 'orgId'
  form.appendChild(orgId)

  userId.value = authManager.getUserId()
  userId.name = 'userId'
  form.appendChild(userId)

  LastAuthUser.value = lastAuthUserValue
  LastAuthUser.name = 'LastAuthUser'
  form.appendChild(LastAuthUser)

  refreshToken.value = refreshTokenValue
  refreshToken.name = 'refreshToken'
  form.appendChild(refreshToken)

  clockDrift.value = clockDriftValue
  clockDrift.name = 'clockDrift'
  form.appendChild(clockDrift)

  deviceGroupKey.value = deviceGroupKeyValue
  deviceGroupKey.name = 'deviceGroupKey'
  form.appendChild(deviceGroupKey)

  deviceKey.value = deviceKeyValue
  deviceKey.name = 'deviceKey'
  form.appendChild(deviceKey)

  accessToken.value = accessTokenValue
  accessToken.name = 'accessToken'
  form.appendChild(accessToken)

  randomPasswordKey.value = randomPasswordKeyValue
  randomPasswordKey.name = 'randomPasswordKey'
  form.appendChild(randomPasswordKey)

  idToken.value = idTokenValue
  idToken.name = 'idToken'
  form.appendChild(idToken)

  userscreenName.value = StoreInstances.userStore.preferences.screenName
  userscreenName.name = 'screenName'
  form.appendChild(userscreenName)

  userLanguage.value = StoreInstances.userStore.preferences.language
  userLanguage.name = 'userLangId'
  form.appendChild(userLanguage)

  document.body.appendChild(form)
  form.hidden = true
  form.target = '_parent'
  form.method = 'POST'
  form.action = url
  form.submit()
}
