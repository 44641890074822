import React, { ReactElement } from 'react'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'

interface HyperlinkProps {
  action: () => void
  underlined?: boolean
  bold?: boolean
}

export const Hyperlink = (
  props: React.PropsWithChildren<HyperlinkProps>
): ReactElement => {
  const { action, underlined, bold, children } = props
  return (
    <ButtonWrapper onClick={action} underlined={underlined} bold={bold}>
      {children}
    </ButtonWrapper>
  )
}

Hyperlink.defaultProps = {
  underlined: true,
  bold: false,
}

interface ButtonWrapperProps {
  underlined?: boolean
  bold?: boolean
}

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  width: fit-content;
  color: ${defaultTheme.colors.link};
  cursor: pointer;
  font-family: Roboto, serif;
  font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};
  border-bottom: ${(p) =>
    p.underlined ? `1px solid ${defaultTheme.colors.link}` : 'none'};

  @media screen and (max-width: ${breakpoints.tablet}px) {
    align-self: flex-start;
    border-bottom: none;
  }

  @media print {
    display: none;
  }
`
