import { ReactElement, useEffect, useState } from 'react'
import {
  Size,
  desktopMultipleLines,
  mobileMultipleLines,
  useWindowSize,
} from 'src/hooks'
import { I18n } from 'src/i18n'
import UserDataServiceProvider from 'src/services/UserDataServiceProvider'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { Sanitized } from '../search/Results/utils/Sanitized'

const NUM_OF_SPACE_BETWEEN_MESSAGES = 1

export const BannerMessage = (): ReactElement => {
  const [bannerMessageString, setBannerMessageString] = useState('')

  const size: Size = useWindowSize()

  const [charactersLength, setcharactersLength] = useState(desktopMultipleLines)

  const [showAnchor, setShowAnchor] = useState(false)
  const [expandState, setExpandState] = useState(false)
  const [bannerMessage, setBannerMessage] = useState('')
  const [messages, setMessages] = useState([''])
  const spaceSeparator = '&nbsp;'.repeat(NUM_OF_SPACE_BETWEEN_MESSAGES)

  useEffect(() => {
    UserDataServiceProvider.getMessages().then((response) => {
      setMessages(response?.messages)
      setBannerMessageString(
        messages && messages.length ? messages.join(spaceSeparator) : ''
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((size?.width ?? 0) < breakpoints.mobileLarge) {
      setcharactersLength(mobileMultipleLines)
    } else {
      setcharactersLength(desktopMultipleLines)
    }

    if (bannerMessageString.length >= charactersLength) {
      setBannerMessage(bannerMessageString.substring(0, charactersLength))
      setShowAnchor(true)
    }
  }, [bannerMessageString, charactersLength, size.width])

  useEffect(() => {
    if (!expandState) {
      setBannerMessage(bannerMessageString.substring(0, charactersLength))
    } else {
      setBannerMessage(
        bannerMessageString.substring(0, bannerMessageString.length)
      )
    }
  }, [bannerMessageString, charactersLength, expandState])

  useEffect(() => {
    if (expandState) setBannerMessageString(messages.join('<br/><br/> '))
    else
      setBannerMessageString(
        messages && messages.length ? messages.join(spaceSeparator) : ''
      )
  }, [expandState, messages, spaceSeparator])

  if (!bannerMessage) return null
  return (
    <Container>
      <ScrollTextContainer>
        <StyledScrollText>
          <Marquee textClick={expandState}>
            <Sanitized html={bannerMessage} />
            {showAnchor && (
              <StyledTextWrapper
                onClick={() => {
                  setExpandState(!expandState)
                }}
              />
            )}
            {expandState && (
              <CollapseTextWrapper onClick={() => setExpandState(false)}>
                <I18n
                  name="seeLess"
                  style={{ textDecorationLine: 'underline' }}
                />
              </CollapseTextWrapper>
            )}
          </Marquee>
        </StyledScrollText>
      </ScrollTextContainer>
      {!expandState && (
        <SeeMoreText onClick={() => setExpandState(true)}>
          ...
          <I18n
            name="seeMore"
            style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
          />
        </SeeMoreText>
      )}
    </Container>
  )
}

interface MarqueeOptions {
  textClick?: boolean
}

const Container = styled.div`
  width: 100%;
  font-family: Roboto;
  text-size: 12px;
  background-color: ${defaultTheme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 53px;
  padding: 1rem;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    height: 64px;
    overflow: visible;
  }
  @media print {
    display: none;
  }
`

const ScrollTextContainer = styled.div`
  width: 90%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`
const SeeMoreText = styled.div`
  flex-shrink: 1;
`

const StyledTextWrapper = styled.span`
  overflow: hidden;
`

const Marquee = styled.div<MarqueeOptions>`
  ${(p) =>
    p.textClick === true
      ? `-webkit-animation: none;
  white-space: normal;`
      : `
  
  -moz-animation: marquee 20s linear infinite;
  -webkit-animation: marquee 20s linear infinite;
  animation: marquee 30s linear infinite;
  @-moz-keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @-webkit-keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes marquee {
    0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
  `}
`

const StyledScrollText = styled.span`
  white-space: nowrap;
  cursor: pointer;
  text-align: left;
`

const CollapseTextWrapper = styled.div`
  padding-top: 2rem;
`
