import { Check, Close } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import React, { ReactElement, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { GfxConditionType } from 'src/api/gfx/interfaces'
import { BreadCrumbsSelection } from 'src/features/partsCatalog/Selections/BreadCrumbsSelection'
import { I18n } from 'src/i18n'
import CatalogServiceProvider from 'src/services/CatalogServiceProvider'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { usePartsCatalog } from 'src/store/partsCatalog/PartsCatalog'
import { useSearchStore } from 'src/store/search/SearchStore'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { Button, InlineError, RadioGroup } from 'src/ui-components'
import styled from 'styled-components'
import { SelectDiagram } from './DiagramSelector'
import { GfxQuestionList } from './GfxQuestionList'

const VEHICLE_SYSTEM = 'VEHICLE SYSTEM'

export interface QuestionState {
  questionId: string
  hasAnswered: boolean
  ref: React.RefObject<HTMLElement>
}

export const GfxSpecificConditionsModal = observer((): ReactElement => {
  const searchStore = useSearchStore()
  const uiStore = useUiStateStore()
  const history = useHistory()
  const topRef = useRef(null)
  const {
    specificConditionQuestions: normalSpecificConditionQuestions,
    areAllQuestionsAnswered: areAllNormalSpecificConditionQuestionsAnswered,
    processQuestions: processNormalSpecificConditionQuesitons,
  } = useSpecificConditionStore()

  const [enableButton, setEnableButton] = useState(
    searchStore.hasAnsweredAllGfxQuestions &&
      areAllNormalSpecificConditionQuestionsAnswered()
  )

  const [inlineError, setInlineError] = useState('')

  const partsCatalog = usePartsCatalog()

  const vehicleSystem = searchStore?.gfxData?.attributes?.find(
    (attr) => attr.code === VEHICLE_SYSTEM
  )

  const vehicleSystemOptions =
    vehicleSystem?.gfxConditions.map((system) => ({
      id: system.code,
      value: system.label,
    })) ?? []

  const selectedVehicleSystem = searchStore.getGfxAnswer(
    GfxConditionType.Attribute,
    VEHICLE_SYSTEM
  )

  const hasAnsweredVehicleSystem =
    selectedVehicleSystem !== undefined &&
    vehicleSystemOptions.findIndex(
      (system) => system.id === selectedVehicleSystem
    ) > -1

  const attributes =
    searchStore?.gfxData?.attributes?.filter(
      (attr) => attr.code !== VEHICLE_SYSTEM
    ) ?? []

  const gfxConditions = searchStore?.gfxData?.specificConditions ?? []

  const normalSpecificConditions = normalSpecificConditionQuestions ?? []

  const skipStep2 =
    attributes.length === 0 &&
    gfxConditions.length === 0 &&
    normalSpecificConditions.length === 0

  const handleAnswerGfxQuestion = (
    type: GfxConditionType,
    questionCode: string,
    answerCode: string
  ) => {
    searchStore.answerGfxQuestion(type, questionCode, answerCode)
  }

  const onShowGraphic = async () => {
    const selectedDiagram = SelectDiagram(searchStore)
    const { selectedPartsGroup } = searchStore.gfxData
    processNormalSpecificConditionQuesitons()
    try {
      searchStore.gfxSvg = await CatalogServiceProvider.getGFX({
        vehicle: searchStore.currentVehicle,
        group: {
          id: selectedPartsGroup.id,
          value: selectedPartsGroup.value,
        },
        gfxAssetNbr: selectedDiagram,
        browserType: 1,
      })
      searchStore.resetGfx()
      searchStore.searchGfxByNavigation()
      BreadCrumbsSelection(searchStore, partsCatalog, () =>
        StoreInstances.searchStore.searchGfxByNavigation()
      )
      uiStore.closeGfxConditions()
      history.push('/searchResults')
    } catch (e) {
      setInlineError(
        "We couldn't find a graphic for these conditions. Please try again."
      )
      topRef.current?.scrollIntoView()
    }
  }

  return (
    <Modal
      size="lg"
      centered
      show={uiStore.showGfxConditions}
      className="gfx-modal"
    >
      <Header>
        <HeaderTitle>
          <div>
            <I18n name="gfxCatalog" />:{' '}
            {searchStore.gfxData?.selectedPartsGroup?.value}
          </div>
          <Close
            style={{ color: '#0F172A', cursor: 'pointer' }}
            onClick={() => uiStore.closeGfxConditions()}
          />
        </HeaderTitle>
        {!skipStep2 && (
          <CustomStepper>
            <CustomStep active={uiStore.gfxConditionsActiveStep === 1}>
              <StepBox active={uiStore.gfxConditionsActiveStep === 1}>
                {uiStore.gfxConditionsActiveStep === 1 ? (
                  1
                ) : (
                  <Check fontSize="small" style={{ opacity: 0.3 }} />
                )}
              </StepBox>
              <I18n name="chooseVehicleSystem" />
            </CustomStep>
            <StepDash />
            <CustomStep active={uiStore.gfxConditionsActiveStep === 2}>
              <StepBox active={uiStore.gfxConditionsActiveStep === 2}>
                2
              </StepBox>
              <I18n name="selectGfxAndFitmentConditions" />
            </CustomStep>
          </CustomStepper>
        )}
      </Header>

      <Body>
        <ContentWrapper>
          <Content>
            {uiStore.gfxConditionsActiveStep === 1 && (
              <>
                <StepTitle>
                  <I18n name="chooseVehicleSystem" />
                </StepTitle>
                <RadioGroup
                  id={VEHICLE_SYSTEM}
                  name="chooseVehicleSystem"
                  options={vehicleSystemOptions}
                  onChange={(selected) => {
                    handleAnswerGfxQuestion(
                      GfxConditionType.Attribute,
                      VEHICLE_SYSTEM,
                      selected.id
                    )
                  }}
                  initialValue={selectedVehicleSystem}
                />
              </>
            )}
            {uiStore.gfxConditionsActiveStep === 2 && (
              <>
                <StepTitle ref={topRef}>
                  <I18n name="selectGfxAndFitmentConditions" />
                </StepTitle>
                {inlineError !== '' && <InlineError message={inlineError} />}
                {(attributes.length > 0 || gfxConditions.length > 0) && (
                  <SubTitle>
                    <I18n name="gfxSpecificAndAttributeConditions" />
                  </SubTitle>
                )}
                <GfxQuestionList
                  attributes={attributes}
                  gfxConditions={gfxConditions}
                  normalSpecificConditions={normalSpecificConditions}
                  onAnswerQuestion={() => {
                    const hasAwnseredAll =
                      searchStore.hasAnsweredAllGfxQuestions &&
                      areAllNormalSpecificConditionQuestionsAnswered()
                    setEnableButton(hasAwnseredAll)
                    setInlineError('')
                  }}
                />
              </>
            )}
          </Content>
        </ContentWrapper>
      </Body>
      {uiStore.gfxConditionsActiveStep === 1 && !skipStep2 ? (
        <FooterRight>
          <Button
            text="next"
            onClick={() => uiStore.setGfxConditionsActiveStep(2)}
            disabled={!hasAnsweredVehicleSystem}
          />
        </FooterRight>
      ) : (
        <Footer>
          {!skipStep2 ? (
            <>
              <Button
                text="back"
                onClick={() => uiStore.setGfxConditionsActiveStep(1)}
                variant="secondary"
              />
              <Button
                text="showGraphic"
                onClick={onShowGraphic}
                disabled={!enableButton}
              />
            </>
          ) : (
            <>
              <div />
              <Button
                text="showGraphic"
                onClick={onShowGraphic}
                disabled={!hasAnsweredVehicleSystem}
              />
            </>
          )}
        </Footer>
      )}
    </Modal>
  )
})

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  box-shadow: inset 0px -1px 0px #d3d2cf;
`

const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`

const Body = styled(Modal.Body)`
  padding: 0;
  margin: 0;
`

const StepTitle = styled.div`
  font-family: Oswald, serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin-top: 12px;
  margin-bottom: 12px;
`

const SubTitle = styled(StepTitle)`
  font-size: 16px;
  line-height: 26px;
`

const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
  box-shadow: inset 0 1px 0 #d3d2cf;
`

const FooterRight = styled(Footer)`
  justify-content: flex-end;
`

const Content = styled.div`
  padding-left: 25px;
  padding-right: 25px;
`

const ContentWrapper = styled.div`
  overflow: auto;
  height: 60vh;
`

const CustomStepper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
`

interface CustomStepProps {
  active: boolean
}
const CustomStep = styled.div<CustomStepProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(p) =>
    p.active ? p.theme.colors.almostBlack : p.theme.colors.disabledButtonText};
`

const StepDash = styled.div`
  display: inline-flex;
  align-self: center;
  width: 100px;
  height: 1px;
  border: 1px solid ${(p) => p.theme.colors.disabledButtonText};
  margin-left: 20px;
  margin-right: 20px;
`

const StepBox = styled.div<CustomStepProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${(p) =>
    p.active ? p.theme.colors.primary : p.theme.colors.disabledBackground};
  color: ${(p) =>
    p.active ? p.theme.colors.almostBlack : p.theme.colors.disabledButtonText};
  border-radius: 3px;
  margin-right: 10px;
  font-weight: bold;
`
