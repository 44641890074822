import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
} from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { useSearchStore } from 'src/store/search/SearchStore'
import styled from 'styled-components'
import {
  CatalogNodeType,
  ChildrenSelectionState,
} from 'src/store/partsCatalog/interfaces'
import { isMobileOnly } from 'react-device-detect'
import { defaultTheme } from 'src/theme'
import { CheckboxListGroupProps } from '../interfaces'
import { Checkbox } from './Checkbox'
import { CheckboxList } from './CheckboxList'

export const CheckboxListGroup = observer(
  (props: CheckboxListGroupProps): ReactElement => {
    const searchStore = useSearchStore()
    const vehicleYear = searchStore?.currentVehicle?.year?.id ?? 0

    const { group, type, partsCatalogStore } = props
    const { onClickCollapse } = partsCatalogStore

    const showGFX = partsCatalogStore.isGfxAvailable(type, vehicleYear)

    // removing duplicate part types in groups
    const filteredGroupItems = group.items.filter(
      (v, index, arr) => arr.findIndex((v2) => v2.id === v.id) === index
    )

    return (
      <StyledAccordion defaultExpanded elevation={0} key={group.value} square>
        <StyledAccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                pointerEvents: 'initial',
                cursor: 'pointer',
                width: '100%',
                color: 'rgb(5,5,5)',
              }}
            />
          }
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
          sx={{
            minHeight: 48,
            pointerEvents: 'none',
            cursor: 'pointer',
            alignItems: 'flex-start',
          }}
        >
          <FormControl
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              pointerEvents: 'initial',
            }}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
          >
            <Checkbox
              hideSelector={type === CatalogNodeType.GROUP}
              selected={
                group.getChildCumulativeState() !==
                ChildrenSelectionState.NONE_SELECTED
              }
              value={group.id}
              onChange={() => onClickCollapse(group)}
              label={`${group.value}`}
              isCheckMixed={
                group.getChildCumulativeState() === ChildrenSelectionState.MIXED
              }
              isTitle
            />
            <Count>{`${
              filteredGroupItems.filter((item) => item.selected).length
            }/${filteredGroupItems.length}`}</Count>
          </FormControl>
        </StyledAccordionSummary>
        <AccordionDetails
          sx={{
            background: isMobileOnly
              ? defaultTheme.colors.rowFeaturedBackground
              : defaultTheme.colors.partsCatalogDarkBackground,
            borderTop: `1px solid ${defaultTheme.colors.greyLight}`,
          }}
        >
          <CheckboxList
            groupId={group.id}
            list={filteredGroupItems}
            type={type}
            showGFX={showGFX}
          />
        </AccordionDetails>
      </StyledAccordion>
    )
  }
)
const Count = styled.span`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0 !important;
  margin-left: 6px !important;
  padding: 0;
  line-height: 24px;
  margin-right: 12px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  cursor: default;
  color: ${defaultTheme.colors.greyDark};
`

const StyledAccordion = styled(Accordion)`
  display: block;
  width: 100%;
  background: ${defaultTheme.colors.rowFeaturedBackground};
  margin: 0;
  font-weight: bold;
  border-top: 1px solid ${defaultTheme.colors.greyLight};
  align-items: flex-start;
`

const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 48px;
  pointer-events: none;
  cursor: pointer;
  align-items: center;
`
