import {
  ChangeEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react'
import { breakpoints } from 'src/theme/breakpoints'
import { defaultTheme } from 'src/theme'
import styled from 'styled-components'
import { ArrowDropDown, ArrowDropUp, Search } from '@mui/icons-material'
import { I18n } from 'src/i18n'
import { Spinner } from 'react-bootstrap'
import { observer } from 'mobx-react-lite'
import { TablePicker } from './TablePicker'
import {
  TableColumnOption,
  TableColumn,
  VehicleDropdownProps,
  VehicleDropdownOnChangeProps,
} from '../interfaces'
import { VehicleDropdownKeys } from '../store/VehicleDropdownKeys'

const MAX_FILTERED_RESULTS_TO_DISPLAY = 10

const flattenOptions = (cols: Array<TableColumn>): Array<TableColumn> => {
  const options: TableColumnOption[] = []
  cols.forEach((col) => {
    if (Array.isArray(col?.options))
      col?.options.forEach((option) => {
        options.push({
          id: option.id,
          value: option.value,
          typeId: col.id,
          typeName: col.header,
        })
      })
  })
  return [{ id: 999999, header: '', options }]
}

export const VehicleDropdown = observer(
  (props: VehicleDropdownProps): ReactElement => {
    const {
      label,
      selection,
      active,
      disabled,
      onChange,
      onClick,
      onCancel,
      options: availableOptions,
      enableSearch,
    } = props

    const onSelectItem = (newSelection: VehicleDropdownOnChangeProps) => {
      setFilterTerm(newSelection.selection.value)
      onChange(newSelection)
    }

    const [filteredOpitons, setFilteredOpitons] = useState(
      new Array<TableColumn>()
    )
    const [filterTerm, setFilterTerm] = useState('')
    const inputSearchRef = useRef<HTMLInputElement>(null)
    const fieldRef = useRef<HTMLInputElement>(null)
    const tablePickerRef = useRef<HTMLDivElement>(null)
    const flatOptions = flattenOptions(availableOptions)

    useEffect(() => {
      if (selection?.value === undefined && active) {
        setFilterTerm('')
      } else {
        setFilterTerm(selection?.value)
      }
    }, [selection?.value, active])

    useEffect(() => {
      if (active) {
        inputSearchRef?.current?.focus()
      }
    }, [active, filterTerm, label])

    useEffect(() => {
      if (!selection) {
        setFilterTerm('')
      }
    }, [selection])

    useEffect(() => {
      setFilteredOpitons(availableOptions)
    }, [availableOptions])

    const isLoading =
      availableOptions.length === 0 && active && label !== 'vehicleType'

    const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value
      setFilterTerm(value)
      if (value === '') {
        setFilteredOpitons(availableOptions)
        return
      }

      const filteredOptions = flatOptions.map((item) => {
        return {
          id: item.id,
          header: item.header,
          options: item.options.filter((option) =>
            option.value?.toLowerCase().includes(value?.toLowerCase())
          ),
        }
      })

      filteredOptions[0].options = filteredOptions?.[0]?.options?.slice(
        0,
        MAX_FILTERED_RESULTS_TO_DISPLAY
      )

      setFilteredOpitons(filteredOptions)
    }

    const handleOnClick = () => {
      if (!disabled) {
        setFilterTerm('')
        onClick()
      }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
      const typeId = filteredOpitons[0]?.id
      const typeName = filteredOpitons[0]?.header
      const firstOption = filteredOpitons[0]?.options[0]
      const newSelection = {
        selection: firstOption,
        selectionType: {
          id: firstOption?.typeId ?? typeId,
          value: firstOption?.typeName ?? typeName,
        },
      }
      onSelectItem(newSelection)
      if (e.keyCode === VehicleDropdownKeys.SEARCH_KEYCODE) {
        tablePickerRef?.current?.focus()
      }
    }

    const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
      if (e.keyCode === VehicleDropdownKeys.SEARCH_KEYCODE) {
        tablePickerRef?.current?.focus()
      }
    }

    const handleKeyType = (e: KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === VehicleDropdownKeys.SEARCH_KEY_TAB) {
        e.preventDefault()
        handleKeyDown(e)
      }
      if (e.key === VehicleDropdownKeys.SEARCH_KEY_ENTER) {
        handleKeyDown(e)
      }
    }

    return (
      <>
        <Element ref={fieldRef}>
          <Wrapper onClick={handleOnClick} onFocus={handleOnClick}>
            <Label>
              <I18n name={label} />
            </Label>
            <FieldButtonWrapper>
              {enableSearch && (
                <SearchContainer>
                  <SerchInput
                    type="text"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleSearchInput(e)
                      onClick()
                    }}
                    onFocus={onClick}
                    value={filterTerm || ''}
                    ref={inputSearchRef}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyType}
                  />
                  <SearchIcon />
                </SearchContainer>
              )}
              {!enableSearch && (
                <FieldButton disabled={disabled}>
                  {!selection?.value && (disabled || !active) && (
                    <LabelMobile>
                      <I18n name={label} />
                    </LabelMobile>
                  )}
                  {selection?.value}&nbsp;
                  {isLoading && (
                    <SpinnerContainer>
                      <Spinner animation="border" size="sm" />
                    </SpinnerContainer>
                  )}
                  {active ? (
                    <ArrowDropUp />
                  ) : (
                    <ArrowDropDown
                      htmlColor={
                        disabled
                          ? defaultTheme.colors.disabledButtonText
                          : defaultTheme.colors.black
                      }
                    />
                  )}
                </FieldButton>
              )}
            </FieldButtonWrapper>
          </Wrapper>
        </Element>

        <TablePicker
          label={label}
          parentRef={fieldRef}
          isActive={active}
          onSelectItem={onSelectItem}
          columns={filteredOpitons}
          selectedOption={selection}
          onCancel={onCancel}
          userIsTyping={filterTerm !== ''}
          tableRef={tablePickerRef}
        />
      </>
    )
  }
)

VehicleDropdown.displayName = 'VehicleDropdown'

const Element = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    width: 100%;
  }
`

const Wrapper = styled.span`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`

const Label = styled.div`
  font-weight: bold;
  font-family: Roboto;
  font-size: 14px;
  margin: 4px;
  text-align: left;

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const LabelMobile = styled.div`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: block;
    text-transform: capitalize;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: ${defaultTheme.colors.greyLight};
  }
`

interface FieldButtonProps {
  disabled: boolean
}

const dropdownSpacing = '6px'

const FieldButton = styled.span<FieldButtonProps>`
  border: ${(props) => props.theme.fieldBorder};
  display: flex;
  font-size: 14px;
  background-color: ${(p) =>
    p.disabled ? p.theme.colors.disabledBackground : '#FFF'};
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  @media (min-width: 1300px) {
    min-width: 200px;
  }
  margin-right: ${dropdownSpacing};
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    margin: 0px;
    font-weight: bold;
  }
`

const FieldButtonWrapper = styled.span`
  flex-grow: 1;
`

const SpinnerContainer = styled.div`
  width: calc(100% - ${dropdownSpacing});
  z-index: 2;
  position: absolute;
  left: 0;
`

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: ${dropdownSpacing};
  cursor: pointer;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    margin: 0px;
    font-weight: bold;
  }
`

const SerchInput = styled.input`
  width: 100%;
  height: 48px;
  border: 1px solid ${defaultTheme.colors.almostBlack};
  border-radius: 3px;
  display: block;
  font-family: Roboto;
  font-size: 14px;
  padding-left: 1rem;
`
const SearchIcon = styled(Search)`
  position: absolute;
  right: 18px;
  font-size: 20px;
  cursor: 'pointer';
`
