import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import PromotionServiceProvider from 'src/services/PromotionServiceProvider'
import { timeout } from 'src/theme/timeout'
import {
  EntriesUrl,
  KeywordsAdsUrl,
  PromotionCoverageResponse,
  PromotionResponse,
} from '../../api/promotion/interface'

interface EntryData {
  totalEntriesWinned: number
  remaningEntryValue: string
}

export class BannerPromotionStore {
  public promotionData: PromotionResponse[] = []

  public entriesData: EntriesUrl | null = null

  public promotionCoverageData: PromotionCoverageResponse | null = null

  public promoOrderNumber: number | null = null

  public matchCoverage = false

  public bannerKeywords: KeywordsAdsUrl | null = null

  public showBannerPromotionModal = false

  public showBottomBannerPromotion = false

  public expandPromoDrawer = false

  public timeOutPromoDrawer: number | null = null

  public entryData: EntryData | null = null

  public expandPromoDrawerCart = false

  public timeOutPromoDrawerCart: number | null = null

  public handleCoverageMatch = (orderNumber: number): void => {
    this.promoOrderNumber = orderNumber
    const coverageData = this.promotionCoverageData
    const coverage: string[] = coverageData?.coverage.split(',') || []
    this.matchCoverage = coverage.includes(String(this.promoOrderNumber))
  }

  public handleBannerPromoDrawerOpenExpanded = (): void => {
    this.expandPromoDrawer = true
    this.timeOutPromoDrawer = window.setTimeout(() => {
      this.handleCloseBannerPromoDrawer()
    }, timeout.closePromoBanner)
  }

  public handleBannerPromoDrawerCartOpenExpanded = (): void => {
    this.expandPromoDrawerCart = true
    this.timeOutPromoDrawerCart = window.setTimeout(() => {
      if (this.timeOutPromoDrawerCart) {
        this.handleCloseBannerPromoCartDrawer()
      }
    }, timeout.closePromoBannerCart)
  }

  public handleCloseBannerPromoDrawer = (): void => {
    if (this.timeOutPromoDrawer) {
      clearInterval(this.timeOutPromoDrawer)
    }
    this.expandPromoDrawer = false
  }

  public handleCloseBannerPromoCartDrawer = (): void => {
    if (this.timeOutPromoDrawerCart) {
      clearInterval(this.timeOutPromoDrawerCart)
    }
    this.expandPromoDrawerCart = false
  }

  constructor() {
    makeAutoObservable(this)
  }

  public saveDateToLocalStorage = (): void => {
    localStorage.setItem('bannerPromoDate', this.getCurrentDate())
  }

  public getCurrentDate = (): string => {
    return new Date().toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  }

  roundOfTwoDigits = (num: number): string => {
    return num.toString().padStart(2, '0')
  }

  convertMsToHrs = (milliseconds: number): string => {
    let seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)

    seconds %= 60
    minutes %= 60

    return `${this.roundOfTwoDigits(hours)}:${this.roundOfTwoDigits(
      minutes
    )}:${this.roundOfTwoDigits(seconds)}`
  }

  fetchCoverageData = async (promoId: number): Promise<void> => {
    try {
      this.promotionCoverageData =
        await PromotionServiceProvider.bannerPromotionCoverageApi(promoId)
    } catch (e) {
      this.showBannerPromotionModal = false
      this.showBottomBannerPromotion = false
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log('Error loading banner promotion coverage data', e)
    }
  }

  fetchPromotionData = async (): Promise<void> => {
    try {
      this.promotionData = await PromotionServiceProvider.bannerPromotionApi()

      if (this.promotionData[0]?.promoId > 0) {
        this.saveDateToLocalStorage()
        this.fetchCoverageData(this.promotionData[0]?.promoId)
      }

      if (this.promotionData[0]?.status === 'Active') {
        this.setShowBottomBannerPromotion(true)
      }
    } catch (e) {
      this.showBannerPromotionModal = false
      this.showBottomBannerPromotion = false
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log('Error loading banner promotion', e)
    }
  }

  fetchEntriesData = async (): Promise<void> => {
    try {
      this.entriesData = await PromotionServiceProvider.getEntries()
    } catch (e) {
      this.showBannerPromotionModal = false
      this.showBottomBannerPromotion = false
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log('Error loading banner promotion entries', e)
    }
  }

  fetchKeywordsAds = async (
    orderNumbers: string,
    aaiaBrands: string
  ): Promise<void> => {
    try {
      this.bannerKeywords = await PromotionServiceProvider.bannerKeywordsApi(
        orderNumbers,
        aaiaBrands
      )
    } catch (e) {
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log('Error loading banner promotion entries', e)
    }
  }

  public setShowBannerPromotionModal = (value: boolean): void => {
    this.showBannerPromotionModal = value
  }

  public setShowBottomBannerPromotion = (value: boolean): void => {
    this.showBottomBannerPromotion = value
  }

  public setEntryData = (data: EntryData): void => {
    this.entryData = data
  }

  public showBannerOnce = async (): Promise<void> => {
    const bannerPromoDate = localStorage.getItem('bannerPromoDate')

    if (bannerPromoDate === null) {
      await this.fetchPromotionData()
      await this.fetchEntriesData()
      if (this.promotionData?.length > 0) {
        this.setShowBannerPromotionModal(true)
      }
    } else {
      const date1 = new Date(bannerPromoDate)
      const date2 = new Date(this.getCurrentDate())

      const hrsDiff = this.convertMsToHrs(
        date2.getTime() - date1.getTime()
      )?.substring(0, 2)

      if (parseInt(hrsDiff, 10) >= 23) {
        await this.fetchEntriesData()
        await this.fetchPromotionData()
        if (this.promotionData?.length > 0) {
          this.setShowBannerPromotionModal(true)
        }
      }
    }
  }
}
export const BannerPromotionContext = createContext<
  BannerPromotionStore | undefined
>(undefined)

export const useBannerPromotionStore = (): BannerPromotionStore => {
  const bannerPromotionStore = useContext(BannerPromotionContext)
  if (!bannerPromotionStore) {
    throw new Error(
      'No BannerPromotionContext.Provider found when calling useBannerPromotionStore.'
    )
  }
  return bannerPromotionStore
}
