import { observer } from 'mobx-react-lite'
import { ReactElement, Suspense, lazy, useCallback, useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Config, IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { useUserStore } from '../store/user/UserStore'
import { useScrollToTop } from './useScrollToTop'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'

import { RouteRequiresLogin } from './RouteRequiresLogin'
import { RouteUnauthenticated } from './RouteUnauthenticated'
// Not lazy loading auto login page intentionally.
import AutoLogin from 'src/routes/AutoLogin'
// TODO: convert signout into an util or a custom hook.
import SignOut from 'src/routes/SignOut'

// These pages are so small that including them in the main bundle is better than lazy loading them.
import Login from 'src/routes/Login'
import SendCode from 'src/routes/SendCode'
import ResetPassword from 'src/routes/ResetPassword'
import ContactAdmin from 'src/routes/ContactAdmin'
import { useSearchStore } from 'src/store/search/SearchStore'
import { EmbeddedCommunicationsManager } from '../api/embedded/EmbeddedCommunicationsManager'

// Pages
const Home = lazy(() => import('src/routes/Home'))
const PartsCatalog = lazy(() => import('src/routes/PartsCatalog'))
const AstPartsCatalog = lazy(() => import('src/routes/AstPartsCatalog'))
const Cart = lazy(() => import('src/routes/CartRoute'))
const SearchResults = lazy(() => import('src/routes/SearchResults'))
const ProductDetails = lazy(
  () => import('../features/search/Results/ProductDetails')
)
const VehicleHistory = lazy(() => import('src/routes/VehicleHistory'))
const OrderHistory = lazy(() => import('src/features/orderHistory'))
const OrderConfirmation = lazy(
  () => import('src/features/orderConfirmation/components')
)
const OrderDetails = lazy(
  () => import('src/features/orderHistory/components/OrderDetails')
)

const Lists = lazy(() => import('src/features/lists'))

const QuotesPage = lazy(() => import('src/routes/Quotes'))
const QuotesDetails = lazy(() => import('src/routes/QuotesDetails'))

const ComponentsPage = lazy(
  () => import('../features/navigation/pages/ComponentsPage')
)

export const Routes = observer((): ReactElement => {
  const userStore = useUserStore()
  const cartStore = useShoppingCart()
  const searchStore = useSearchStore()

  useScrollToTop()

  const appHasLoaded = useMemo(
    () =>
      Config.isNotCartOnlyMode
        ? userStore.userHasLoaded
        : cartStore.checkAvailabilityLoaded && userStore.userHasLoaded,
    [cartStore.checkAvailabilityLoaded, userStore.userHasLoaded]
  )

  // TODO: Look into replacing useCallback with useMemo.
  const getLandingPage = useCallback((): string => {
    if (IS_INTEGRATED_VERSION) {
      // The order of the conditions matter here.
      if (!Config.isNotCartOnlyMode) {
        return '/cart'
      }
      if (EmbeddedCommunicationsManager.isAstPunchOut) {
        return '/astPartsCatalog'
      }

      if (searchStore.currentVehicle && searchStore.currentVehicle.engine?.id) {
        return '/partsCatalog'
      }
    }
    return '/home'
  }, [searchStore.currentVehicle])

  const landingPage: string = useMemo(
    () => (appHasLoaded ? getLandingPage() : '/home'),
    [appHasLoaded, getLandingPage]
  )

  return (
    <Suspense
      fallback={
        <Wrapper>
          <Spinner animation="border" />
        </Wrapper>
      }
    >
      <Switch>
        <RouteUnauthenticated exact path="/login">
          <Login />
        </RouteUnauthenticated>
        <RouteUnauthenticated exact path="/sendCode">
          <SendCode />
        </RouteUnauthenticated>
        <RouteUnauthenticated exact path="/resetPassword">
          <ResetPassword />
        </RouteUnauthenticated>
        <RouteUnauthenticated exact path="/contactAdmin">
          <ContactAdmin />
        </RouteUnauthenticated>
        <RouteUnauthenticated exact path="/auto-login">
          <AutoLogin />
        </RouteUnauthenticated>
        {userStore?.redirectToLogin && <Redirect to="/login" />}

        {appHasLoaded ? (
          <>
            <RouteRequiresLogin exact path="/">
              <Redirect to={landingPage} />
            </RouteRequiresLogin>
            <RouteRequiresLogin exact path="/home">
              {EmbeddedCommunicationsManager.isAstPunchOut && (
                <Redirect to={landingPage} />
              )}
              {!EmbeddedCommunicationsManager.isAstPunchOut && <Home />}
            </RouteRequiresLogin>
            <Route exact path="/signOut">
              <SignOut />
            </Route>
            <RouteRequiresLogin exact path="/partsCatalog">
              <PartsCatalog />
            </RouteRequiresLogin>
            <RouteRequiresLogin exact path="/astPartsCatalog">
              <AstPartsCatalog />
            </RouteRequiresLogin>
            <RouteRequiresLogin exact path="/cart">
              <Cart />
            </RouteRequiresLogin>
            {/* <RouteRequiresLogin exact path="/profile">
              This will be the future user profile screen
            </RouteRequiresLogin> */}
            <RouteRequiresLogin exact path="/components">
              <ComponentsPage />
            </RouteRequiresLogin>
            <RouteRequiresLogin exact path="/searchResults">
              <SearchResults />
            </RouteRequiresLogin>
            <RouteRequiresLogin exact path="/vehicleHistory">
              <VehicleHistory />
            </RouteRequiresLogin>
            <RouteRequiresLogin path="/orderHistory">
              <OrderHistory />
            </RouteRequiresLogin>
            <RouteRequiresLogin path="/quotes">
              <QuotesPage />
            </RouteRequiresLogin>

            <RouteRequiresLogin path="/quotes-details/:id">
              <QuotesDetails />
            </RouteRequiresLogin>
            <RouteRequiresLogin exact path="/lists">
              <Lists />
            </RouteRequiresLogin>
            {import.meta.env.VITE_FEATURE_ORDER_DETAILS_ENABLED === 'true' && (
              <RouteRequiresLogin path="/orderDetails/:orderId">
                <OrderDetails />
              </RouteRequiresLogin>
            )}
            <RouteRequiresLogin exact path="/product">
              <ProductDetails />
            </RouteRequiresLogin>
            <RouteRequiresLogin exact path="/orderConfirmation">
              <OrderConfirmation />
            </RouteRequiresLogin>
          </>
        ) : (
          <Wrapper>
            <Spinner animation="border" />
          </Wrapper>
        )}
      </Switch>
    </Suspense>
  )
})

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`
