import { AxiosRequestConfig } from 'axios'
import {
  GfxRecordsData,
  GfxRequest,
  GfxResponse,
  GfxSpecificConditionsRequest,
  GfxSpecificConditionsResponse,
  GfxSvgRecord,
} from 'src/api/gfx/interfaces'
import {
  MileageOptions,
  PartImageRequestItem,
  PartImageResponseItem,
  ServiceIntervalsResponse,
} from 'src/api/searchResults/interfaces'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { LaborItem } from 'src/store/models/LaborModel'
import { PartType } from 'src/store/models/PartsCatalogStoreModels'
import {
  OEGFXPartsRequest,
  OeParts,
  PartVehiclesFitmentRequest,
  PartVehiclesResponse,
  SearchLaborResultRequest,
  SearchResultsResponse,
} from 'src/store/models/SearchModels'
import { SpecificConditionI } from 'src/store/models/SpecificConditions'
import { Vehicle } from 'src/store/models/Vehicles'
import { PartsCatalogType } from 'src/store/partsCatalog/PartsCatalog'
import { BaseServiceProvider } from './BaseServiceProvider'
import PartServiceProvider from './PartServiceProvider'
import { TableColumn } from 'src/features'
import { IdValueGeneric } from 'src/store/models/KeyValuePair'
import { ModelResponse } from 'src/api/vehicle/interfaces'

class CatalogServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'catalog', '1.1', '')
  }

  getGFXSpecificConditions = async (
    body: GfxSpecificConditionsRequest
  ): Promise<GfxRecordsData | undefined> => {
    const resp = await this.post<GfxSpecificConditionsResponse>(
      'gfxSpecificConditions',
      body
    )
    if (resp.status === 200) {
      return resp?.data?.gfxRecords
    }
    if (resp.status === 204) {
      throw new Error('No specific conditions records')
    }
    return resp?.data?.gfxRecords
  }

  getGFX = async (body: GfxRequest): Promise<GfxSvgRecord> => {
    const resp = await this.post<GfxResponse>('gfx', body)
    return resp?.data?.gfxsvgRecord
  }

  getPartImages = async (
    partImageRequestBody: Array<PartImageRequestItem>
  ): Promise<Array<PartImageResponseItem>> => {
    try {
      const resp = await this.post<Array<PartImageResponseItem>>(
        'part/images',
        partImageRequestBody
      )
      if (resp.status === 200) return resp.data
    } catch (error) {
      return null
    }

    return null
  }

  handleMissedImages = async (data: SearchResultsResponse): Promise<void> => {
    const missedImagesBatch: PartImageRequestItem[] = []
    data.parts?.forEach((item) => {
      if (!item?.partImages || item?.partImages?.length < 1) {
        missedImagesBatch.push({
          orderNumber: item.orderNumber,
          partNumber: item.partNumber,
        })
      }
      item?.replacementParts?.forEach((replacementItem) => {
        if (
          !replacementItem?.partImages ||
          replacementItem?.partImages?.length < 1
        ) {
          missedImagesBatch.push({
            orderNumber: replacementItem.orderNumber,
            partNumber: replacementItem.partNumber,
          })
        }
      })
    })

    // Stop if there are no missing images.
    if (missedImagesBatch.length === 0) {
      return
    }

    const partImages = await this.getPartImages(missedImagesBatch)

    data.parts = data.parts?.map((item) => {
      if (!item?.partImages || item?.partImages?.length < 1) {
        const image = partImages.find(
          (img) => img.partNumber === item.partNumber
        )

        item.replacementParts = item?.replacementParts?.map(
          (replacementItem) => {
            const replacementImage = partImages.find(
              (img) => img.partNumber === replacementItem.partNumber
            )

            replacementItem.partImages = replacementImage?.partImages
            return replacementItem
          }
        )

        item.partImages = image?.partImages
      }
      return item
    })
  }

  getLaborSearchResults = async (
    reqData: SearchLaborResultRequest
  ): Promise<LaborItem[]> => {
    const resp = await this.post<LaborItem[]>('laborResult', reqData, {
      params: {
        partDomain: PartServiceProvider.partDomain(),
      },
    })
    return resp.data
  }

  getGfxOeParts = async (reqData: OEGFXPartsRequest): Promise<OeParts> => {
    const resp = await this.post<OeParts>('part/oe', reqData, {
      params: {
        partDomain: PartServiceProvider.partDomain(),
      },
    })
    return resp.data
  }

  getPartVehiclesFitmentInterchange = async (
    reqData: PartVehiclesFitmentRequest
  ): Promise<PartVehiclesResponse> => {
    const resp = await this.post<PartVehiclesResponse>(`part/vehicles`, reqData)

    return resp.data
  }

  getServiceIntervals = async (
    requestParameter: { intervalType: number; severeCode: string },
    requestBody: Vehicle
  ): Promise<Array<MileageOptions>> => {
    const resp = await this.post<MileageOptions[]>(
      `serviceIntervals?intervalType=${requestParameter.intervalType}&severeCode=${requestParameter.severeCode}`,
      requestBody
    )
    if (resp.status === 200) return resp.data

    return null
  }

  getRecommendedServices = async (
    requestParameter: {
      intervalType: number
      severeCode: string
      mileage: string
    },
    requestBody: Vehicle
  ): Promise<Array<ServiceIntervalsResponse>> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    const resp = await this.post<any>(
      `serviceIntervalsData?intervalType=${requestParameter.intervalType}&severeCode=${requestParameter.severeCode}&mileage=${requestParameter.mileage}`,
      requestBody
    )
    if (resp.status === 200) return resp.data
    return null
  }

  public beforeEachRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      params: {
        catalogId:
          StoreInstances.searchStore?.currentVehicle?.type?.id === undefined
            ? 111
            : StoreInstances.searchStore.currentVehicle.type.id,
        covListId: StoreInstances.userStore.preferences?.mclOrgId,
        countryId: StoreInstances.userStore.country.countryCode,
        mclOrgId: StoreInstances.userStore.preferences?.mclOrgId,
        ...config.params,
      },
    }
  }

  getSpecificConditions = async (
    vehicle: Vehicle,
    terminologies: Array<PartType>,
    catalogType: PartsCatalogType
  ): Promise<Array<SpecificConditionI>> => {
    const endPoint = this.getEndpoint(catalogType)
    const resp = await this.post<Array<SpecificConditionI>>(endPoint, {
      vehicle,
      terminologies,
    })
    if (resp.status === 200) {
      return resp.data
    }
    if (resp.status === 204) {
      return []
    }
    return []
  }

  getEndpoint = (catalogType: PartsCatalogType): string => {
    if (catalogType === PartsCatalogType.LABOR_SEARCH)
      return 'laborSpecificConditions'
    return 'specificConditions'
  }

  /**
   * Helper method to generate an empty column structure
   * @param numCols
   */
  initTableCols = (numCols) => {
    const cols = []
    for (let i = 0; i < numCols; i += 1) {
      cols.push({
        id: `${i}`,
        header: '',
        options: [],
      })
    }
    return cols
  }

  /**
   * Returns TableColumns for all vehicle years, sorted and arranged by decade
   */
  getYearOptions = async (): Promise<Array<TableColumn>> => {
    let yearList = []
    try {
      const resp = await this.post<Array<IdValueGeneric<number, string>>>(
        'years',
        undefined,
        {
          params: {
            catalogId:
              StoreInstances.searchStore.currentFieldsSelections.type?.id,
          },
        }
      )
      yearList = resp.data
    } catch (e) {
      return []
    }
    const cols: Array<TableColumn> = this.initTableCols(10)

    // Split by decade
    yearList.forEach((pair) => {
      const bucket = 9 - ((Math.floor(pair.value / 10) - 3) % 10) // This order should work well until 2030
      cols[bucket].options.push({ value: pair.value, id: pair.id })
    })

    return cols
  }

  getIdValueYearOptions = async (): Promise<
    Array<IdValueGeneric<number, string>>
  > => {
    let yearList = []
    try {
      const resp = await this.post<Array<IdValueGeneric<number, string>>>(
        'years',
        undefined,
        {
          params: {
            catalogId:
              StoreInstances.searchStore.currentFieldsSelections.type?.id,
          },
        }
      )
      yearList = resp.data
      return yearList
    } catch (e) {
      return []
    }
  }

  /**
   * Returns a list of TableColumns of make options given the currently selected year
   * @param vehicle
   */
  getMakeOptions = async (vehicle: Vehicle): Promise<Array<TableColumn>> => {
    /* Formatting data for api (key -> id) with no param reassign */
    const body = {
      year: {
        id: vehicle.year.id,
        value: vehicle.year.value,
      },
    }
    let makeList = []
    try {
      const resp = await this.post<Array<IdValueGeneric<number, string>>>(
        'makes',
        body,
        {
          params: {
            catalogId:
              StoreInstances.searchStore.currentFieldsSelections.type?.id,
          },
        }
      )
      makeList = resp.data
    } catch (e) {
      return []
    }

    makeList = makeList.sort((a, b) => a.value.localeCompare(b.value))
    const cols: Array<TableColumn> = this.initTableCols(4)
    const maxInCol = Math.ceil(makeList.length / cols.length)
    makeList.forEach((make, index) => {
      const bucket = Math.floor(index / maxInCol)
      cols[bucket].options.push({ value: make.value, id: make.id })
    })

    return cols
  }

  /**
   * Returns a list of IdValue of make options given the currently selected year
   * @param vehicle
   */
  getIdValueMakeOptions = async (
    vehicle: Vehicle
  ): Promise<Array<IdValueGeneric<number, string>>> => {
    /* Formatting data for api (key -> id) with no param reassign */
    const body = {
      year: {
        id: vehicle.year.id,
        value: vehicle.year.value,
      },
    }
    let makeList = []
    try {
      const resp = await this.post<Array<IdValueGeneric<number, string>>>(
        'makes',
        body
      )
      makeList = resp.data
      return makeList
    } catch (e) {
      return []
    }
  }

  /**
   * Returns a list of TableColumns for the models available for the currently selected make
   * @param vehicle
   */
  getModelOptions = async (vehicle: Vehicle): Promise<Array<TableColumn>> => {
    /* Formatting data for api (key -> id) with no param reassign */
    const body = {
      year: {
        id: vehicle?.year?.id,
        value: vehicle?.year?.value,
      },
      make: {
        id: vehicle?.make?.id,
        value: vehicle?.make?.value,
      },
    }
    let modelTypes
    try {
      const resp = await this.post<Array<ModelResponse>>('models', body, {
        params: {
          catalogId:
            StoreInstances.searchStore.currentFieldsSelections.type?.id,
        },
      })
      modelTypes = resp.data
    } catch (e) {
      return []
    }

    const cols = this.initTableCols(modelTypes.length)
    modelTypes.forEach((modelType: ModelResponse, index) => {
      const { models } = modelType
      cols[index].id = modelType.modelTypeId
      cols[index].header = modelType.value
      cols[index].options = models
    })
    return cols
  }

  /**
   * Returns a list of IdValue for the models available for the currently selected make
   * @param vehicle
   */
  getIdValueModelOptions = async (
    vehicle: Vehicle
  ): Promise<Array<ModelResponse>> => {
    /* Formatting data for api (key -> id) with no param reassign */
    const body = {
      year: {
        id: vehicle.year.id,
        value: vehicle.year.value,
      },
      make: {
        id: vehicle.make.id,
        value: vehicle.make.value,
      },
    }
    let modelTypes
    try {
      const resp = await this.post<Array<ModelResponse>>('models', body)
      modelTypes = resp.data
      return modelTypes
    } catch (e) {
      return []
    }
  }

  /**
   * Returns a single TableColumn containing a list of engine options for the currently selected model
   * @param vehicle
   */
  getEngineOptions = async (vehicle: Vehicle): Promise<Array<TableColumn>> => {
    const body = { ...vehicle, initialized: undefined }
    let engineList
    try {
      const resp = await this.post<Array<IdValueGeneric<number, string>>>(
        'engines',
        body,
        {
          params: {
            catalogId:
              StoreInstances.searchStore.currentFieldsSelections.type?.id,
          },
        }
      )
      engineList = resp.data
    } catch (e) {
      return [] // @TODO: Add better error handling... might want to let the exception propagate
    }
    const cols = this.initTableCols(1)
    cols[0].options = engineList

    return cols
  }

  /**
   * Returns a single IdValue containing a list of engine options for the currently selected model
   * @param vehicle
   */
  getIdValueEngineOptions = async (
    vehicle: Vehicle
  ): Promise<Array<IdValueGeneric<number, string>>> => {
    const body = { ...vehicle, initialized: undefined }
    let engineList
    try {
      const resp = await this.post<Array<IdValueGeneric<number, string>>>(
        'engines',
        body
      )
      engineList = resp.data
      return engineList
    } catch (e) {
      return [] // @TODO: Add better error handling... might want to let the exception propagate
    }
  }

  /**
   * Returns a single TableColumn containing a list of engine options for the currently selected model
   * @param vehicle
   */
  getEngineOptionsPlain = async (
    vehicle: Vehicle
  ): Promise<Array<TableColumn>> => {
    const body = { ...vehicle, initialized: undefined }
    let engineList
    try {
      const resp = await this.post<Array<IdValueGeneric<number, string>>>(
        'engines',
        body
      )
      engineList = resp.data.filter((item) => item.value !== 'All Engine')
    } catch (e) {
      return [] // @TODO: Add better error handling... might want to let the exception propagate
    }
    return engineList
  }
}

export default new CatalogServiceProvider()
