import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { I18n, Translate } from 'src/i18n'
import { breakpoints } from 'src/theme/breakpoints'

interface ThumbnailProps {
  urlOrBase64?: string
  alt?: string
  width: number
  height: number
  placeholder?: boolean
}

export const Thumbnail = ({
  urlOrBase64,
  width,
  height,
  alt,
  placeholder,
}: ThumbnailProps): ReactElement => {
  const [imageLoadFailed, setImageLoadFailed] = useState<boolean>(false)

  const isBase64 = !urlOrBase64?.startsWith('http')
  const src = isBase64 ? `data:image/jpeg;base64,${urlOrBase64}` : urlOrBase64
  const translatedAlt = Translate(alt || '')

  const Placeholder = () => (
    <NoImage width={width} height={height}>
      <I18n name="noImage" />
    </NoImage>
  )

  if (imageLoadFailed || (!urlOrBase64 && placeholder)) {
    return <Placeholder />
  }
  return (
    <ThumbnailWrapper width={width} height={height}>
      <StyledImg
        alt={translatedAlt}
        src={src}
        width={width}
        height={height}
        onError={() => setImageLoadFailed(true)}
      />
    </ThumbnailWrapper>
  )
}

Thumbnail.defaultProps = {
  urlOrBase64: null,
  alt: 'Thumbnail',
  placeholder: false,
}

interface ImgSize {
  width: number
  height: number
}

const ThumbnailWrapper = styled.div<ImgSize>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: ${breakpoints.mobileLarge}px) {
    width: ${(p) => p.width / 2}px;
    height: ${(p) => p.height / 2}px;
  }
`

const StyledImg = styled.img<ImgSize>`
  height: auto;
  width: auto;
  max-width: ${(p) => p.width}px;
  max-height: ${(p) => p.height}px;
  pointer-events: none;
  @media (max-width: ${breakpoints.mobileLarge}px) {
    width: ${(p) => p.width / 2}px;
    height: ${(p) => p.height / 2}px;
    max-width: ${(p) => p.width / 2}px;
    max-height: ${(p) => p.height / 2}px;
    object-fit: contain;
  }
  @media print {
    width: 50px;
    height: 50px;
  }
`

const NoImage = styled.div<ImgSize>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  background: #eee;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: ${breakpoints.mobileLarge}px) {
    width: ${(p) => p.width / 2}px;
    height: ${(p) => p.height / 2}px;
    font-size: 10px;
  }
  @media print {
    width: 50px;
    height: 50px;
    font-size: 10px;
  }
`
