import { useContext } from 'react'
import { LanguageContext } from 'src/store/language/LanguageContext'
import { enUs } from './en_us'
import { esMx } from './es_mx'
import { frCa } from './fr_ca'

const showMissingTranslations =
  import.meta.env.VITE_SHOW_MISSING_TRANSLATIONS === 'true'

export const Translate = (
  key: string,
  textReplacement?: Array<string | number>
): string => {
  const { selectedLanguage } = useContext(LanguageContext)

  let translated
  switch (selectedLanguage) {
    case 'en_US':
      translated = enUs[key]
      break
    case 'es_MX':
      translated = esMx[key]
      break
    case 'fr_CA':
      translated = frCa[key]
      break
    default:
      translated = enUs[key]
  }
  if (
    translated &&
    translated.indexOf('%') > -1 &&
    textReplacement !== undefined
  ) {
    for (let i = 0; i < textReplacement.length; i += 1) {
      translated = translated.replace('%', textReplacement[i])
    }
  }
  if (showMissingTranslations && translated) {
    return `TRANSLATION -> ${key}`
  }
  return translated === undefined ? key : translated
}
