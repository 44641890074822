import { CatalogNode } from '../CatalogNode'
import { PartsCatalogSelections } from '../../models/PartsCatalogModels'
import { CatalogNodeType } from '../interfaces'

export class FlattenVisitor {
  private readonly selectedPartTypes: Array<CatalogNode>

  constructor() {
    this.selectedPartTypes = []
  }

  public visit(node: CatalogNode): void {
    if (node.type === CatalogNodeType.PART_TYPE) {
      if (node.isSelected) {
        this.selectedPartTypes.push(node)
      }
    }
  }

  public getCGTsForTransport(): PartsCatalogSelections {
    const cgts = []
    for (const partType of this.selectedPartTypes) {
      const group = partType.getParent()
      const category = group.getParent()
      const terminology = partType
      cgts.push({
        category: {
          id: category.id,
          value: category.value,
        },
        group: {
          id: group.id,
          value: group.value,
        },
        terminology: {
          id: terminology.id,
          value: terminology.value,
        },
      })
    }
    return cgts
  }
}
