import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Select, { InputActionMeta, components } from 'react-select'
import { debounce } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import { VehicleSuggestionData } from 'src/api/vehicle/interfaces'
import { useVehicleWidgetStore } from 'src/features/search/VehicleSearch/store/VehicleWidgetStore.ts'
import { I18n, Translate } from 'src/i18n'
import VehicleSaytServiceProvider from 'src/services/VehicleSaytServiceProvider'
import VehicleServiceProvider from 'src/services/VehicleServiceProvider'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { usePartsCatalog } from 'src/store/partsCatalog/PartsCatalog'
import { useSpecificConditionStore } from 'src/store/specificConditions/SpecificConditionsStore'
import styled from 'styled-components'
import { breakpoints } from 'src/theme/breakpoints'
import styles from './index.module.scss'

interface VehicleQuickFindInterface {
  onVehicleSelected: (vehicle?: VehicleSuggestionData) => void
  vehicleLookup: boolean
  vehicleInputText?: string
}

const getVehicleSuggestions = async (
  query: string
): Promise<VehicleSuggestionData[]> => {
  try {
    const response = await VehicleSaytServiceProvider.getVehicleSuggestion(
      query
    )
    return response.Data.vehicles
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching vehicle suggestions:', error)
    throw error
  }
}

const CustomLoadingIndicator = () => (
  <div className={styles.loading_wrapper}>
    <Spinner className={styles.spinner} />
  </div>
)

const CustomDropdownIndicator = (props) => {
  if (props.selectProps.inputValue || props.selectProps.loading) {
    return null
  }
  return (
    <components.DropdownIndicator {...props}>
      <span className="material-icons md-dark">search</span>
    </components.DropdownIndicator>
  )
}

export const VehicleQuickFind = observer(
  (props: VehicleQuickFindInterface): ReactElement => {
    const { uiStore } = StoreInstances
    const { onVehicleSelected, vehicleLookup, vehicleInputText } = props
    const vehicleWidgetStore = useVehicleWidgetStore()
    const { resetStore } = usePartsCatalog()
    const { clearAnswers: clearSpecificConditionAnswers } =
      useSpecificConditionStore()

    const [inputValue, setInputValue] = useState(vehicleInputText ?? '')
    const [options, setOptions] = useState<VehicleSuggestionData[]>([])
    const [loading, setLoading] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptions = useCallback(
      debounce(async (inputValue) => {
        setLoading(true)
        const newOptions = await getVehicleSuggestions(inputValue)
        setOptions(newOptions)
        setLoading(false)
      }, 700),
      []
    )

    useEffect(() => {
      if (inputValue) {
        loadOptions(inputValue)
      }
    }, [inputValue, loadOptions])

    const onInputChange = (newValue: string, actionMeta?: InputActionMeta) => {
      if (!(actionMeta?.action === 'set-value' && newValue === '')) {
        setInputValue(newValue)
      }
    }

    const onVehicleSelect = async (vehicle) => {
      if (vehicleLookup) {
        const { Year, Make, Model, Engine } = vehicle

        try {
          setLoading(true)
          const suggestedYMME = [Year, Make, Model, Engine].join(',')
          const decodedVehicle =
            await VehicleServiceProvider.getDecodedAndValidatedYMME({
              ymme: suggestedYMME,
            })
          if (
            decodedVehicle &&
            vehicleWidgetStore.isVehicleComplete(decodedVehicle)
          ) {
            await vehicleWidgetStore.setCurrentVehicle(decodedVehicle)
            resetStore()
            clearSpecificConditionAnswers()
            onVehicleSelected()
          } else {
            throw new Error(
              'An error occurred while trying to retrieve the vehicle information.'
            )
          }
        } catch (error) {
          uiStore.displayErrorNotification('vehicleInfoRetrievalError')
        }
      } else {
        onVehicleSelected(vehicle)
      }
    }

    const isCompleteVehicle = (vehicle: VehicleSuggestionData): boolean => {
      return !!(
        vehicle?.Engine &&
        vehicle?.Make &&
        vehicle?.Model &&
        vehicle?.Year
      )
    }

    return (
      <>
        <Label>
          <I18n name={'vehicleQuickFind'} />
        </Label>
        <Select
          components={{
            LoadingIndicator: CustomLoadingIndicator,
            DropdownIndicator: CustomDropdownIndicator,
          }}
          classNames={{
            control: (state) => {
              let style = state.isFocused
                ? `${styles.controlClass} ${styles.focused}`
                : styles.controlClass
              if (!vehicleLookup) {
                style = `${style} ${styles.advancedSearch}`
              }
              return style
            },
            dropdownIndicator: () => styles.dropdownIndicator_class,
            indicatorSeparator: () => styles.indicatorSeparator_class,
            option: () => styles.option_class,
            clearIndicator: () => styles.clearIndicator_class,
          }}
          options={options}
          filterOption={() => true}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => JSON.stringify(option)}
          placeholder={Translate('enterVehicle')}
          isLoading={loading}
          onChange={(value) => {
            if (isCompleteVehicle(value)) {
              onVehicleSelect(value)
            } else {
              onInputChange(value?.name)
            }
          }}
          closeMenuOnSelect={isCompleteVehicle(options[0])} // We should show the menu options till we select a complete vehicle. If options[0] is a complete vehicle, then all other options are also complete vehicles.
          inputValue={inputValue}
          onInputChange={onInputChange}
          isClearable={true}
          escapeClearsValue={true}
        />
      </>
    )
  }
)

const Label = styled.div`
  font-weight: bold;
  font-family: Roboto;
  font-size: 14px;
  margin: 4px;
  text-align: left;

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`
