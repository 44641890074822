import { ExpandMore } from '@mui/icons-material'
import { ReactElement, useRef, useState } from 'react'
import styled from 'styled-components'
import { useClickOutsideDetector } from 'src/hooks'
import { breakpoints } from 'src/theme/breakpoints'
import { MutablePreference, useUserStore } from 'src/store/user/UserStore'
import { observer } from 'mobx-react-lite'
import { zIndex } from 'src/theme/zIndex'
import US from 'src/assets/images/US.png'
import MX from 'src/assets/images/MX.png'
import CA from 'src/assets/images/CA.png'

export const LanguageDropdown = observer((): ReactElement => {
  const userStore = useUserStore()
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const toggling = () => {
    setIsOpen(!isOpen)
  }

  const onOptionClicked = (value) => () => {
    userStore.updateUserPreferenceAndSyncToCloud(
      MutablePreference.LANGUAGE,
      value
    )
    setIsOpen(false)
  }
  /* Fix to be done: we should use svgs instead of emojis */
  const options = [
    {
      label: 'english',
      key: 'en_US',
      imgPath: US,
    },
    {
      label: 'spanish',
      key: 'es_MX',
      imgPath: MX,
    },
    {
      label: 'french',
      key: 'fr_CA',
      imgPath: CA,
    },
  ]

  const selectedOption =
    options.find((option) => userStore.preferences.language === option.key) ??
    options[0]

  useClickOutsideDetector([ref], isOpen, () => setIsOpen(false))

  return (
    <DropDownContainer>
      <DropDownHeader onClick={toggling}>
        <FlagImg src={selectedOption.imgPath} alt="My place 4 parts logo" />
        <Label className="pl-3">{selectedOption.label}</Label>
        <ExpandMore />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer ref={ref}>
          <DropDownList>
            {options.map((option) => (
              <ListItem onClick={onOptionClicked(option.key)} key={option.key}>
                <FlagImg src={option.imgPath} alt="My place 4 parts logo" />
                <Label className="pl-3">{option.label}</Label>
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  )
})

const DropDownContainer = styled('div')`
  cursor: pointer;
`

const DropDownHeader = styled('div')`
  padding: 0.4em 2em 0.4em 0;
  background: #ffffff;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const DropDownListContainer = styled('div')`
  position: absolute;
  z-index: ${zIndex.dropdown};
`

const DropDownList = styled('ul')`
  margin: 0;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #d3d2cf;
  border-radius: 3px;
  box-sizing: border-box;
  &:first-child {
    padding-top: 0.8em;
  }
`

const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0.8em;
  text-align: left;
`
const Label = styled('span')`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-left: 10px;
  text-transform: uppercase;
  color: #050505;
`

const FlagImg = styled('img')`
  width: 20px;
`
