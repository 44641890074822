import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import { hash } from 'src/features/specificConditions/utils'
import CatalogServiceProvider from 'src/services/CatalogServiceProvider'
import { StoreInstances } from '../StoreInstancesContainer'
import { PartType } from '../models/PartsCatalogStoreModels'
import {
  CommonSpecificCondition,
  SpecificConditionAnswer,
  SpecificConditionI,
} from '../models/SpecificConditions'
import { Vehicle } from '../models/Vehicles'
import { PartsCatalogType } from '../partsCatalog/PartsCatalog'

const FITMENT_CONDITIONS_KEY = 'fitment-conditions'

export class SpecificConditionsStore {
  specificConditionsLoading = false

  specificConditionQuestions: Array<SpecificConditionI> = []

  specificConditionAnswers: Array<SpecificConditionAnswer> = []

  constructor() {
    makeAutoObservable(this)
    this.restoreAnswersFromSession()
  }

  public findAnswer = (
    answer: CommonSpecificCondition
  ): SpecificConditionAnswer => {
    return this.specificConditionAnswers.find((a) => a.hash === answer.hash)
  }

  public findQuestion = (
    question: CommonSpecificCondition
  ): SpecificConditionI => {
    return this.specificConditionQuestions.find((q) => q.hash === question.hash)
  }

  /*
    Stores the answer in both arrays:
    * the history array
    * the vehicle array
  */
  public answerSpecificCondition = (answer: SpecificConditionAnswer): void => {
    const condition = this.findAnswer(answer)
    if (condition) condition.choice = answer.choice
    else this.specificConditionAnswers.push(answer)
    StoreInstances.searchStore.currentVehicle.specificConditions.push(answer)
  }

  public processQuestions = (): void => {
    if (!this.areAllQuestionsAnswered()) return
    StoreInstances.searchStore.currentVehicle.specificConditions = []
    this.specificConditionQuestions.forEach((q) => {
      this.answerSpecificCondition({
        ...q,
        choice: q.choice,
      })
    })
    StoreInstances.searchStore.persistCurrentVehicle()
    this.persistAnswers()
  }

  public updateSpecificConditions = (
    specificConditions: Array<SpecificConditionAnswer>
  ): void => {
    this.specificConditionAnswers = specificConditions
    StoreInstances.searchStore.currentVehicle.specificConditions =
      specificConditions
    StoreInstances.searchStore.persistCurrentVehicle()
    this.persistAnswers()
  }

  public collectAnswers = (
    questions: Array<SpecificConditionI>
  ): Array<SpecificConditionI> => {
    return questions.map((q) => {
      const answer = this.findAnswer(q)
      if (answer) return { ...q, choice: answer.choice }
      return q
    })
  }

  public areAllQuestionsAnswered = (): boolean => {
    return !this.specificConditionQuestions.find(
      (q) => q.choice?.id === undefined
    )
  }

  public setSpecificConditionsLoading = (value: boolean): void => {
    this.specificConditionsLoading = value
  }

  public fetchSpecificConditionQuestions = async (
    vehicle: Vehicle,
    terminologies: Array<PartType>,
    catalogType: PartsCatalogType,
    errorNotification = true
  ): Promise<Array<SpecificConditionI>> => {
    try {
      const questions = await CatalogServiceProvider.getSpecificConditions(
        vehicle,
        terminologies,
        catalogType
      )
      const hashQuestions = questions.map((q) => hash(q))
      this.specificConditionQuestions = this.collectAnswers(hashQuestions)
      return this.specificConditionQuestions
    } catch (error) {
      if (errorNotification) {
        StoreInstances.uiStore.displayErrorNotification(
          'Unable to retrieve specific conditions questions'
        )
      }
      throw new Error()
    }
  }

  public setSpecificConditionQuestions = (
    questions: Array<SpecificConditionI>
  ): void => {
    this.specificConditionQuestions = questions
  }

  public clearAnswers = (): void => {
    this.specificConditionAnswers = []
    this.persistAnswers()
  }

  private persistAnswers = (): void => {
    sessionStorage.setItem(
      FITMENT_CONDITIONS_KEY,
      JSON.stringify(this.specificConditionAnswers)
    )
  }

  private restoreAnswersFromSession = () => {
    const savedAnswersJson =
      sessionStorage.getItem(FITMENT_CONDITIONS_KEY) ?? '[]'
    this.specificConditionAnswers = JSON.parse(savedAnswersJson)
  }
}

export const SpecificConditionsContext =
  createContext<SpecificConditionsStore>(undefined)

export const useSpecificConditionStore = (): SpecificConditionsStore => {
  return useContext(SpecificConditionsContext)
}
