import { observer } from 'mobx-react-lite'
import { ReactElement, useMemo, useRef } from 'react'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useSearchStore } from 'src/store/search/SearchStore'
import { Button } from 'src/ui-components'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'
import styled from 'styled-components'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { defaultTheme } from 'src/theme'
import { I18n } from 'src/i18n'
import { FieldArray, Form, Formik } from 'formik'
import CustomField from 'src/ui-components/forms/formik/InputField'
import { Box, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { MultiPartInquiry } from '../interfaces'
import { zIndex } from 'src/theme/zIndex'
import { useHistory } from 'react-router-dom'

const makeRow = (): MultiPartInquiry => {
  return {
    lineCode: '',
    partNumber: '',
    stockQty: 1,
    comment: '',
  }
}

const validateLengthIntervalRequired = (
  value,
  rowHasEntries = false,
  min: number,
  max: number
) => {
  if ((value === undefined || value === '') && rowHasEntries) {
    return 'Required'
  }
  if ((value?.length < min || value?.length > max) && rowHasEntries) {
    return `Min ${min} and max ${max} characters`
  }
  return undefined
}

const validateNumberIntervalRequired = (
  value,
  rowHasEntries = false,
  min: number,
  max: number
) => {
  if ((value === undefined || value === '') && rowHasEntries) {
    return 'Required'
  }
  if ((value < min || value > max) && rowHasEntries) {
    return `Min ${min} and max ${max} characters`
  }
  return undefined
}

export const MultiPartInquiryDrawer = observer((): ReactElement => {
  const history = useHistory()
  const { searchMultiPartInquiry } = useSearchStore()
  const {
    showMultiPartInquiryDrawer,
    setShowMultiPartInquiryDrawer,
    setShowMultiPartInquiryImportDrawer,
  } = useUiStateStore()
  const ref = useRef<HTMLInputElement>(null)

  const initialValues: Array<MultiPartInquiry> = useMemo(
    () => [...Array(5)].map(makeRow),
    []
  )

  const TableHeader = () => {
    return (
      <HeaderRow>
        <ColHeader width="15%">
          <I18n name="MFR" />
        </ColHeader>
        <ColHeader width="34%">
          <I18n name="PART #" />
        </ColHeader>
        <ColHeader width="12%">
          <I18n name="QTY" />
        </ColHeader>
        <ColHeader width="37%">
          <I18n name="COMMENTS" />
        </ColHeader>
        <ColHeader width="5%" />
      </HeaderRow>
    )
  }

  const drawerBody = (
    <BodyWrapper>
      <TableHeader />
      <Container ref={ref}>
        <Formik
          initialValues={{
            items: initialValues,
          }}
          onSubmit={(notTrimmedValues) => {
            const partsToSearch = notTrimmedValues.items
              .map((item) => {
                return {
                  lineCode: item.lineCode.trim().toUpperCase(),
                  partNumber: item.partNumber.trim(),
                  stockQty: item.stockQty,
                  comment: item.comment.trim(),
                }
              })
              .filter((item) => item.partNumber !== '')

            if (partsToSearch?.length < 1) {
              StoreInstances.uiStore.displayErrorNotification(
                'addAtleastOneItemToList'
              )
            } else {
              searchMultiPartInquiry(partsToSearch)
              history.push('/searchResults')
              setShowMultiPartInquiryDrawer(false)
            }
          }}
          render={({ values }) => {
            const lastItem = values?.items[values?.items?.length - 1]
            if (
              !!lastItem?.partNumber ||
              lastItem?.stockQty !== 1 ||
              !!lastItem?.comment
            ) {
              values.items.push(makeRow())
            }
            return (
              <Form>
                <Box display="flex" flexDirection="column">
                  <Content>
                    <Rows>
                      <FieldArray
                        name="items"
                        render={(arrayHelpers) => {
                          return values.items.map((item, index) => {
                            const rowHasEntries =
                              !!item.partNumber ||
                              item.stockQty !== 1 ||
                              !!item.comment

                            return (
                              <InputWrapper key={`multipartinquery_${index}`}>
                                <TextInputWrapper width="15%">
                                  <CustomField
                                    name={`items[${index}].lineCode`}
                                    type="text"
                                    style={{ textTransform: 'uppercase' }}
                                    maxSize={3}
                                  />
                                </TextInputWrapper>
                                <TextInputWrapper width="34%">
                                  <CustomField
                                    name={`items[${index}].partNumber`}
                                    validate={(value) =>
                                      validateLengthIntervalRequired(
                                        value,
                                        rowHasEntries,
                                        1,
                                        20
                                      )
                                    }
                                    type="text"
                                  />
                                </TextInputWrapper>
                                <TextInputWrapper width="14%">
                                  <CustomField
                                    name={`items[${index}].stockQty`}
                                    validate={(value) =>
                                      validateNumberIntervalRequired(
                                        value,
                                        rowHasEntries,
                                        1,
                                        999
                                      )
                                    }
                                    type="number"
                                    maxSize={3}
                                    min={1}
                                  />
                                </TextInputWrapper>
                                <TextInputWrapper width="35%">
                                  <CustomField
                                    name={`items[${index}].comment`}
                                    type="textarea"
                                    maxSize={45}
                                  />
                                </TextInputWrapper>
                                <TextInputWrapper width="5%">
                                  <IconButton
                                    onClick={() => {
                                      return arrayHelpers.remove(index)
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                </TextInputWrapper>
                              </InputWrapper>
                            )
                          })
                        }}
                      />
                    </Rows>
                  </Content>
                  <ButtonsWrapper>
                    <Button
                      text="importList"
                      onClick={() => {
                        setShowMultiPartInquiryDrawer(false)
                        setShowMultiPartInquiryImportDrawer(true)
                      }}
                      variant="secondary"
                      icon={<StyledFileUploadIcon />}
                    />
                    <Button
                      text="searchParts"
                      variant="primary"
                      type="submit"
                    />
                  </ButtonsWrapper>
                </Box>
              </Form>
            )
          }}
        />
      </Container>
    </BodyWrapper>
  )

  return (
    <BaseDrawer
      open={showMultiPartInquiryDrawer}
      title="partInquiry"
      body={drawerBody}
      onClose={() => {
        setShowMultiPartInquiryDrawer(false)
      }}
      width="900px"
    />
  )
})

const ButtonsWrapper = styled.div`
  justify-content: end;
  align-items: center;
  display: flex;
  margin-top: 16px;
`
const StyledFileUploadIcon = styled(FileUploadIcon)`
  margin: 0px 5px 2px 0px;
`

const HeaderRow = styled.div`
  background: ${defaultTheme.colors.rowFeaturedBackground};
  box-shadow: inset 0px -1px 0px ${defaultTheme.colors.borderTable},
    inset 0px 1px 0px ${defaultTheme.colors.borderTable};
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`

const ColHeader = styled.div<HeaderRowProps>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on;
  width: ${(p) => p.width};
  color: ${defaultTheme.colors.columnBorder};
  padding-right: 24px;
`
interface HeaderRowProps {
  width?: string
}

const BodyWrapper = styled.div`
  flex-direction: column;
  overflow: hidden;
`

interface TextInputWrapperProps {
  width: string
}
const TextInputWrapper = styled.div<TextInputWrapperProps>`
  padding: 16px 16px 16px 0px;
  width: ${(p) => p.width};
`
const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid ${defaultTheme.colors.borderTable};
`
const Content = styled.div``

const Rows = styled.div`
  display: block;
  height: 100%;
  max-width: 750px;
`

const Container = styled.div`
  background-color: ${defaultTheme.colors.componentsBackground};
  z-index: ${zIndex.modal};
`
