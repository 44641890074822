import { Route, useHistory, useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import { IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { Translate } from 'src/i18n'
import { useUserStore } from 'src/store/user/UserStore'
import authManager from '../../src/api/security/Auth'

export const RouteUnauthenticated: React.FC<
  React.ComponentProps<typeof Route>
> = ({ children, ...props }) => {
  const userStore = useUserStore()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    function hasUserCredentials() {
      authManager
        .getCurrentToken()
        .then(() => {
          userStore.setRedirectToLogin(false)
          history.push('/home')
        })
        .catch(() => {
          userStore.setRedirectToLogin(true)
        })
    }

    if (location.pathname !== '/auto-login') {
      hasUserCredentials()
    }
  }, [history, location.pathname, userStore])

  // TODO: Should we display the `contactAdmin` page link?
  if (IS_INTEGRATED_VERSION) {
    return <>{Translate('invalidCredentials')}</>
  }

  return <Route {...props}>{children}</Route>
}
