import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import {
  IPartItemResponse,
  IdGroupsItem,
  IdGroupsResponse,
  IdsEnginesResponse,
} from 'src/features/partsCatalog/Selections/interfaces'
import { StoreInstances } from '../StoreInstancesContainer'
import { IdValueGeneric, IdValuePair } from '../models/KeyValuePair'
import { PartsCatalogSelections } from '../models/PartsCatalogModels'
import {
  Category,
  PartType,
  PartTypes,
} from '../models/PartsCatalogStoreModels'
import { UserAttributeKey } from '../user/interfaces'
import { CGPs } from '../vehicleHistory/interfaces'
import { CatalogNode } from './CatalogNode'
import { CatalogTreeParser } from './CatalogTreeParser'
import { CatalogTreeSaver } from './CatalogTreeSaver'
import { CatalogAPI } from './api/ApiInterface'
import { CatalogNodeType, ChildrenSelectionState } from './interfaces'
import { DepthCheckVisitor, DeselectVisitor, FlattenVisitor } from './visitors'

export const PartsCatalogContext = createContext<PartsCatalog>(undefined)

export const usePartsCatalog = (): PartsCatalog =>
  useContext(PartsCatalogContext)

export enum VisibleStep {
  START,
  CATEGORY,
  GROUP,
  PART_TYPE,
}

export enum PartsCatalogType {
  PARTS_SEARCH,
  LABOR_SEARCH,
  AST_PUNCH_OUT_SEARCH,
}

export class PartsCatalog {
  root: CatalogNode

  loadingCategories = true

  loadingCategoryGroups = true

  loadingGroupPartTypes = true

  showSelectEngineDrawer = false

  engineOptions: IdsEnginesResponse = []

  API: CatalogAPI

  constructor(api: CatalogAPI) {
    makeAutoObservable(this)
    this.API = api
    this.resetStore()
    this.retrieveSelectionsFromSession()
  }

  private retrieveSelectionsFromSession = () => {
    const parser = new CatalogTreeParser(
      this.onClickCategory,
      this.onClickGroup,
      this.onClickPartType
    )
    const selections = this.API.retrieveSelectionsFromSession(parser)
    if (selections) this.root = selections
  }

  public findNode(id: string, type: CatalogNodeType): CatalogNode {
    return this.root.findNode(id, type)
  }

  public selectElement(id: string, type: CatalogNodeType): CatalogNode {
    const node = this.root.findNode(id, type)
    if (node) {
      node.select()
    }
    return node
  }

  public unSelectElement(id: string, type: CatalogNodeType): CatalogNode {
    const node = this.root.findNode(id, type)
    if (node) {
      node.deselect()
    }
    return node
  }

  get disabledButton(): boolean {
    const selectedPartTypes = this.root.getAllDescendants(
      (node) => node.type === CatalogNodeType.PART_TYPE && node.selected
    )
    return selectedPartTypes.length === 0
  }

  public getVisibleStep = (): VisibleStep => {
    const depth = this.getDepth() as number as VisibleStep
    return depth + 1 // Show one level more than the last level with a selection
  }

  public setLoadingCategoryGroups = (value: boolean): void => {
    this.loadingCategoryGroups = value
  }

  public setLoadingGroupPartTypes = (value: boolean): void => {
    this.loadingGroupPartTypes = value
  }

  public setShowSelectEngineDrawer = (value: boolean): void => {
    this.showSelectEngineDrawer = value
  }

  public clearStore = (): void => {
    this.loadingGroupPartTypes = false
    this.loadingCategories = false
    this.loadingCategoryGroups = false
    this.engineOptions = []
    this.showSelectEngineDrawer = false
  }

  public onClickCategory = async (node: CatalogNode): Promise<void> => {
    node.toggle()
    this.onTreeChange()
    if (node.isSelected) {
      await this.fetchGroups()
    }
  }

  public onClickGroup = async (node: CatalogNode): Promise<void> => {
    node.toggle()
    this.onTreeChange()
    if (node.isSelected) {
      await this.fetchPartTypes()
    }
  }

  public onClickPartType = (node: CatalogNode): void => {
    node.toggle()
    this.onTreeChange()
  }

  public selectSingleGroup = async (groupId: string): Promise<void> => {
    const group = this.root.findNode(groupId, CatalogNodeType.GROUP)
    const visitor = new DeselectVisitor(
      (node) => node.type === CatalogNodeType.GROUP
    )
    this.root.traverseAllDescendants(visitor)
    await this.onClickGroup(group)
  }

  public onClickCollapse = (node: CatalogNode): void => {
    if (
      node.getChildCumulativeState() === ChildrenSelectionState.NONE_SELECTED
    ) {
      node.selectAllChildren()
    } else {
      node.deselectAllChildren()
    }
    this.onTreeChange()
  }

  public selectAllPartTypesInGroup = (groupId: string): void => {
    const node = this.root.findNode(groupId, CatalogNodeType.GROUP)
    node.selectAllChildren()
    this.onTreeChange()
  }

  public onTreeChange = (): void => {
    const json = CatalogTreeSaver.getTreeJson(this.root)
    this.API.storeSelectionsOnSession(json)
  }

  public onSelectOption = (node: CatalogNode): void => {
    node.select()
    if (node.type === CatalogNodeType.GROUP) {
      const selectAllPartTypes =
        StoreInstances.userStore?.preferences?.display_partsTypeAll === 'true'
      if (selectAllPartTypes) {
        node.selectAllChildren()
      }
    }
  }

  public getCtgs = (): PartsCatalogSelections => {
    const visitor = new FlattenVisitor()
    if (this.root) {
      this.root.traverseAllDescendants(visitor)
    }
    return visitor.getCGTsForTransport()
  }

  public fetchCategories = async (): Promise<void> => {
    try {
      this.loadingCategories = true
      const categories = await this.API.fetchCategories(
        StoreInstances.searchStore.currentVehicle
      )

      this.root.addChildrenFromAPI(
        categories,
        CatalogNodeType.CATEGORY,
        this.onClickCategory
      )
    } finally {
      this.onTreeChange()
      this.loadingCategories = false
    }
  }

  public fetchGroups = async (): Promise<void> => {
    try {
      this.setLoadingCategoryGroups(true)
      const selectedCategories = this.root.getAllDescendants(
        (node) => node.type === CatalogNodeType.CATEGORY && node.selected
      )
      const newCategories = selectedCategories
        .filter((c) => c.getChildren().length === 0) // No need to download the same data twice
        .map(({ id, value }) => ({
          id,
          value,
        }))

      if (newCategories.length === 0) {
        return
      }

      const groups = await this.API.fetchGroups(
        newCategories,
        StoreInstances.searchStore.currentVehicle
      )
      for (const category of groups) {
        const match = this.root
          .getChildren()
          .find((child) => child.id === category.id)
        match.addChildrenFromAPI(
          category.items,
          CatalogNodeType.GROUP,
          this.onClickGroup
        )
      }
    } finally {
      this.onTreeChange()
      this.setLoadingCategoryGroups(false)
    }
  }

  public fetchPartTypes = async (): Promise<void> => {
    try {
      const selectedGroups = this.root.getAllDescendants(
        (node) => node.type === CatalogNodeType.GROUP && node.selected
      )
      this.setLoadingGroupPartTypes(true)
      const newGroups = selectedGroups
        .filter((c) => c.getChildren().length === 0)
        .map(({ id, value }) => ({
          id,
          value,
        }))

      if (newGroups.length === 0) {
        return
      }

      if (selectedGroups.length === 0) {
        this.setLoadingGroupPartTypes(true)
      }
      const partTypes = await this.API.fetchPartTypes(
        newGroups,
        StoreInstances.searchStore.currentVehicle,
        selectedGroups
      )

      if (this.isResponseParts(partTypes)) {
        for (const groupOfParts of partTypes as IPartItemResponse) {
          const match = this.root.findNode(
            groupOfParts.id,
            CatalogNodeType.GROUP
          )
          match.addChildrenFromAPI(
            groupOfParts.items,
            CatalogNodeType.PART_TYPE,
            this.onClickPartType
          )
        }
      } else {
        this.engineOptions = partTypes as IdsEnginesResponse
        this.showSelectEngineDrawer = true
      }
    } finally {
      this.onTreeChange()
      this.setLoadingGroupPartTypes(false)
    }
  }

  public fetchAndSetCGPsFromPrevVehicleSearch = async (
    selectedCGPs?: CGPs
  ): Promise<void> => {
    this.resetStore()
    const CGPs = selectedCGPs
      ? selectedCGPs
      : StoreInstances.searchStore.retrieveJSONSelections()
    const { categories, groups, parts } = CGPs

    let fetchedCategories: IdValueGeneric<string, string>[]
    let fetchedGroups: IdGroupsResponse
    let fetchedParts: IPartItemResponse | IdsEnginesResponse

    const fetchCategories = async () => {
      fetchedCategories = await this.API.fetchCategories(
        StoreInstances.searchStore.currentVehicle
      )
    }

    const fetchGroups = async () => {
      fetchedGroups = await this.API.fetchGroups(
        categories,
        StoreInstances.searchStore.currentVehicle
      )
    }

    const fetchPartTypes = async () => {
      fetchedParts = await this.API.fetchPartTypes(
        groups,
        StoreInstances.searchStore.currentVehicle
      )
    }

    /* First we fetch all the selections and their children from API*/

    this.loadingCategories = true

    await Promise.all([fetchCategories(), fetchGroups(), fetchPartTypes()])

    this.loadingCategories = false

    /*
    After fetching CGPs we need to construct the CGP tree in the same order(first categories, then groups and then only parts)
    */

    this.root.addChildrenFromAPI(
      fetchedCategories,
      CatalogNodeType.CATEGORY,
      this.onClickCategory
    )

    for (const category of fetchedGroups) {
      const match = this.root
        .getChildren()
        .find((child) => child.id === category.id)
      match.addChildrenFromAPI(
        category.items,
        CatalogNodeType.GROUP,
        this.onClickGroup
      )
    }

    for (const groupOfParts of fetchedParts as IPartItemResponse) {
      const match = this.root.findNode(groupOfParts.id, CatalogNodeType.GROUP)
      match.addChildrenFromAPI(
        groupOfParts.items,
        CatalogNodeType.PART_TYPE,
        this.onClickPartType
      )
    }

    /* Then we need to select(check) the partscatalog selections */
    categories.forEach((category) => {
      this.findNode(category.id, CatalogNodeType.CATEGORY).select()
    })

    groups.forEach((group) => {
      this.findNode(group.id, CatalogNodeType.GROUP).select()
    })

    parts.forEach((part) => {
      this.findNode(part.id, CatalogNodeType.PART_TYPE).select()
    })
    this.onTreeChange()
  }

  public isResponseParts = (
    partOrEngine: IPartItemResponse | IdsEnginesResponse
  ): boolean => {
    return (partOrEngine[0] as IdGroupsItem)?.items?.length >= 0
  }

  // removing duplicates from terminologies on selection
  public get terminologies(): PartType[] {
    return this.root
      .getAllDescendants(
        (node) => node.type === CatalogNodeType.PART_TYPE && node.selected
      )
      .filter(
        (v, index, arr) => arr.findIndex((v2) => v2.id === v.id) === index
      )
      .map((p) => ({
        id: p.id,
        value: p.value,
        groupId: p.getParent().id,
      }))
  }

  public get categories(): Array<Category> {
    // filtered categories to remove duplicates
    const filteredCategories = this.root
      .getChildren()
      .filter(
        (v, index, arr) => arr.findIndex((v2) => v2.id === v.id) === index
      )
    return filteredCategories
  }

  public get selectedCategories(): Array<CatalogNode> {
    return this.root.getChildren().filter((c) => c.isSelected)
  }

  public categoryGroups(): CatalogNode[] {
    // filtered categoryGroups to remove duplicates
    const filteredCategoryGroups = this.root
      .getAllDescendants((node) => node.type === CatalogNodeType.GROUP)
      .filter(
        (v, index, arr) => arr.findIndex((v2) => v2.id === v.id) === index
      )

    return filteredCategoryGroups
  }

  public get selectedGroups(): Array<CatalogNode> {
    return this.categoryGroups().filter((g) => g.selected)
  }

  public get groupPartTypes(): PartTypes {
    return this.root.getAllDescendants(
      (node) => node.type === CatalogNodeType.PART_TYPE
    )
  }

  public get selectedPartTypes(): PartTypes {
    return this.groupPartTypes.filter((pt) => pt.selected)
  }

  public findSelectedPartTypeById(partTypeId: string): PartType {
    return this.selectedPartTypes.filter(
      (pT) => Number(pT.id) === Number(partTypeId)
    )?.[0]
  }

  public resetStore = (): void => {
    this.clearStore()
    this.root = new CatalogNode({
      id: '0',
      value: 'root',
      type: CatalogNodeType.ROOT,
    })
  }

  public getDepth(): CatalogNodeType {
    const depthChecker = new DepthCheckVisitor()
    this.root.traverseAllDescendants(depthChecker)
    return depthChecker.getMaxSelectedDepth()
  }

  public isGfxAvailable = (
    nodeType: CatalogNodeType,
    vehicleYear: number
  ): boolean => {
    return (
      StoreInstances.userStore?.getUserAttribute(
        UserAttributeKey.GFX_Enabled
      ) === 'true' &&
      nodeType === CatalogNodeType.GROUP &&
      vehicleYear >= 2000 &&
      this.API.isGfxSupported()
    )
  }

  public showGfxNotAvailable = (
    nodeType: CatalogNodeType,
    vehicleYear: number
  ): boolean => {
    return (
      StoreInstances.userStore?.getUserAttribute(
        UserAttributeKey.GFX_Enabled
      ) === 'true' &&
      this.API.isGfxSupported() &&
      nodeType === CatalogNodeType.GROUP &&
      vehicleYear < 2000
    )
  }

  public fetchGfxTerminologies = async (
    selectedGroup: Array<IdValueGeneric<string, string>>
  ): Promise<IPartItemResponse | IdsEnginesResponse> => {
    return await this.API.fetchPartTypes(
      selectedGroup,
      StoreInstances.searchStore.currentVehicle
    )
  }

  public editPartSelections = (): void => {
    const savedPartsCatalog = JSON.parse(
      sessionStorage.getItem('selected-parts-catalog-tree')
    )

    const selectedCategories: Array<IdValuePair<string>> =
      savedPartsCatalog?.children
        .filter((item) => item.selected)
        .map((item) => {
          return {
            id: item.id,
            value: item.value,
          }
        })

    const selectedGroups: Array<IdValuePair<string>> =
      savedPartsCatalog?.children
        .filter((item) => item.selected)
        .flatMap((item) => {
          return item.children
            .filter((item) => item.selected)
            .map((item) => {
              return {
                id: item.id,
                value: item.value,
              }
            })
        })

    const selectedTerminologies: Array<IdValuePair<string>> =
      savedPartsCatalog?.children
        .filter((item) => item.selected)
        .flatMap((item) => {
          return item.children
            .filter((item) => item.selected)
            .flatMap((item) => {
              return item.children
                .filter((item) => item.selected)
                .flatMap((item) => {
                  return {
                    id: item.id,
                    value: item.value,
                  }
                })
            })
        })

    const selectedCGPs: CGPs = {
      categories: selectedCategories,
      groups: selectedGroups,
      parts: selectedTerminologies,
    }

    this.fetchAndSetCGPsFromPrevVehicleSearch(selectedCGPs)
  }
}
