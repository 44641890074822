import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { NavigationItemsWrapper } from '../CommonMenuItems'
import { MenuItem, IMenuItem } from './MenuItem'
import { useMenuItems } from 'src/hooks/useMenuItems'

const MenuItems = observer((): ReactElement => {
  const menuItems: IMenuItem[] = useMenuItems()
  return (
    <NavigationItemsWrapper>
      {menuItems.map((menuItem, idx) => {
        const key = `desktop-menu-item-${idx}`
        return <MenuItem key={key} {...menuItem} />
      })}
    </NavigationItemsWrapper>
  )
})

export default MenuItems
