import { format } from 'date-fns'
import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import {
  Part,
  QuoteResponse,
  QuotesFilters,
  QuotesHistoryItem,
} from 'src/api/quote/interface'
import { GaTrackOption } from 'src/config/analytics/GoogleTagManager'
import miscellaneousVehicle from 'src/features/search/Results/utils/miscellaneousVehicle'
import { LocationType } from 'src/helpers/locationType'
import { LookupType } from 'src/helpers/lookupType'
import QuotesServiceProvider from 'src/services/QuotesServiceProvider'
import VehicleServiceProvider from 'src/services/VehicleServiceProvider'
import { Link } from 'src/ui-components'
import { ToastNotificationVariant } from 'src/ui-components/modals/ToastNotification'
import { StoreInstances } from '../StoreInstancesContainer'
import { LaborItem } from '../models/LaborModel'
import {
  AvailabilityErrorType,
  ProductLocationModel,
} from '../models/ProductModel'
import {
  CartVehicle,
  OrderFormData,
  ShoppingCartProduct,
} from '../models/ShoppingCartModels'
import { VehicleSpecificationCartItem } from '../models/VehicleSpecification'
import { Vehicle } from '../models/Vehicles'
import {
  IAddToCartNotification,
  LaborDTO,
  Location,
  PartDTO,
  QuoteBody,
  QuoteRequest,
  SpecificationsDTO,
} from './interfaces'

export class QuoteStore {
  public saveAsQuoteLoading = false

  public quotesHistoryLoading = false

  public totalCount = 0

  public fromDate: Date = undefined

  public toDate: Date = undefined

  public searchTerm = ''

  public quotesHistory: Array<QuotesHistoryItem> = []

  public deleteQuoteLoading = false

  public quoteDetail: QuoteResponse = undefined

  public quoteCart: CartVehicle = undefined

  public quoteDetailLoading = false

  public quoteFormChanged = false

  public updatedQuoteData: OrderFormData | null = null

  public quotesSelectedLabel = undefined

  public showQuoteDetailsMobile = false

  constructor() {
    makeAutoObservable(this)
  }

  public setShowQuoteDetailsMobile = (quoteDetailsMobile: boolean): void => {
    this.showQuoteDetailsMobile = quoteDetailsMobile
  }

  public setQuoteFormChanged = (isChanged: boolean): void => {
    this.quoteFormChanged = isChanged
  }

  public setUpdatedQuoteData = (newQuoteData: OrderFormData): void => {
    this.updatedQuoteData = newQuoteData
  }

  private clearSearchFieldAndFilter = (): void => {
    this.searchTerm = ''
    this.fromDate = undefined
    this.toDate = undefined
  }

  private setSaveAsQuoteLoading = (set: boolean): void => {
    this.saveAsQuoteLoading = set
  }

  public setDateFilters = (
    fromDate: Date | null,
    toDate: Date | null
  ): void => {
    this.fromDate = fromDate
    this.toDate = toDate
    this.getQuotesHistory(undefined, undefined)
  }

  public setSearchTerm = (searchTerm: string): void => {
    this.searchTerm = searchTerm
    this.getQuotesHistory(undefined, undefined)
  }

  private mapLaborsForQuotes = (labors: Array<LaborItem>): Array<LaborDTO> => {
    return labors?.map((labor) => ({
      details: labor.details,
      hours: Number(labor.hours),
      laborId: labor.laborId,
      description: labor.laborDescription,
      rate: labor.rate,
      notes: '',
      skillLevel: labor.skillLevel,
      warrantyHrs: Number(labor.warrantyHrs),
      miscellaneousText: labor.miscellaneousText,
    }))
  }

  private mapSpecificationsForQuotes = (
    specifications: Array<VehicleSpecificationCartItem>
  ): Array<SpecificationsDTO> => {
    return specifications?.map((specification) => ({
      description: specification.description,
      extendedDescription: specification.extendedDescription,
      groupId: specification.groupId,
      miscellaneousTexts: specification.miscellaneousTexts?.join(', '),
      partNumber: specification.partNumber,
      years: specification.years,
    }))
  }

  private mapPartsForQuotes = (
    products: Array<ShoppingCartProduct>
  ): Array<PartDTO> => {
    return products?.map((product) => {
      const locations = []
      product.orderSelections.forEach((orderSelection) => {
        if (orderSelection.quantityRequested > 0) {
          locations.push(
            product.location.find(
              (loc) => loc.locationId === orderSelection.locationId
            )
          )
        }
      })
      return {
        description: product.description,
        lineCode: product.lineCode,
        orderNumber: product.orderNumber?.toString(),
        partNumber: product.partNumber,
        locations: locations.map((location) => ({
          id: location?.id,
          availability: location?.qtyAvailable,
          coreCost: location?.coreCost,
          coreList: location?.coreList,
          cost: location?.cost,
          list: location?.list,
          qty: product.orderSelections[0]?.quantityRequested, // this is required to save/update requested qty
          snCalled: location?.called,
          snSeqNo: location?.locationId,
        })),
        wsSessionId: '',
      }
    })
  }

  private getVehicleById = async (id: number): Promise<Vehicle> => {
    if (id === 0 || id === undefined) return miscellaneousVehicle
    try {
      const vehicleI = await VehicleServiceProvider.getVehicleById(id)
      return {
        year: { id: vehicleI.yearCode, value: vehicleI.year },
        make: { id: vehicleI.makeCode, value: vehicleI.make },
        model: { id: vehicleI.modelCode, value: vehicleI.model },
        engine: { id: vehicleI.engineCode, value: vehicleI.engine },
        modelType: { id: vehicleI.modelTypeCode, value: vehicleI.modelType },
        type: { id: vehicleI.type?.id, value: vehicleI.type?.value },
      }
    } catch (e) {
      throw new Error('quoteVehicleNotFound')
    }
  }

  public getQuoteById = async (id: string): Promise<QuoteResponse> => {
    return QuotesServiceProvider.fetchQuoteById(id)
  }

  private mapLocations = (
    locations: Location[]
  ): Array<ProductLocationModel> => {
    return locations.map(
      (l) =>
        ({
          ...l,
          qtyAvailable: l.availability ?? 0,
          buyQty: l.qty,
          called: l.snCalled,
          locationId: l.snSeqNo.toString(),
          coreCost: l.coreCost ?? 0,
          locType: l.snSeqNo === 100 ? LocationType.PRIMARY : l.locType,
          isSelected: false,
        } as ProductLocationModel)
    )
  }

  private mapToCartProduct = (part: Part): ShoppingCartProduct => {
    const location = this.mapLocations(part.locations)

    return {
      partNumber: part.partNumber,
      description: part.description,
      lineCode: part.lineCode,
      location,
      orderSelections: [
        {
          quantityRequested: location?.[0]?.buyQty,
          locationId: location?.[0]?.locationId?.toString(),
        },
      ],
    }
  }

  public getCartVehicleFromQuote = (
    quote: QuoteResponse,
    vehicle: Vehicle
  ): CartVehicle => {
    const products = quote.parts.map((part) => this.mapToCartProduct(part))

    const cartVehicle: CartVehicle = {
      vehicle,
      products,
      laborResults: quote.labors,
      specifications: quote.specifications?.map((specification) => ({
        hash:
          specification.partNumber +
          specification.years +
          specification.description,
        ...specification,
        miscellaneousTexts: specification.miscellaneousTexts?.split(','),
      })),
    }

    return cartVehicle
  }

  private fetchQuoteInfo = async (
    quoteId: string
  ): Promise<{ quote: QuoteResponse; vehicleCart: CartVehicle }> => {
    const quote = await this.getQuoteById(quoteId)
    const vehicle = await this.getVehicleById(quote.vehicleId)
    const vehicleCart = this.getCartVehicleFromQuote(quote, vehicle)

    this.clearSearchFieldAndFilter()
    return { quote, vehicleCart }
  }

  public updateStoreWithQuoteDetails = async (id: string): Promise<void> => {
    this.quoteDetailLoading = true
    const { quote, vehicleCart } = await this.fetchQuoteInfo(id)
    this.quoteDetail = quote
    this.quoteCart = vehicleCart
    this.quoteDetailLoading = false
  }

  public editQuote = async (
    cartData: CartVehicle,
    quoteId: string,
    clearCart?: boolean
  ): Promise<void> => {
    try {
      this.quoteDetailLoading = true
      const requestBody: QuoteBody = {
        quoteId: Number(quoteId),
        comment: cartData.orderFormData?.personalNote,
        customerName: cartData?.orderFormData?.customerName,
        description: cartData.orderFormData?.noteToStore,
        poNo: cartData.orderFormData?.poNumber,
        type: 'R', //MP4P-1364 -> A default regular order type when saving as quote
        parts: this.mapPartsForQuotes(cartData.products),
        labors: this.mapLaborsForQuotes(
          StoreInstances.cart.getLaborItems(cartData.vehicle)
        ),
        specifications: this.mapSpecificationsForQuotes(
          StoreInstances.cart.getVehicleSpecification(cartData.vehicle)
        ),
      }
      await QuotesServiceProvider.editQuote(requestBody)
      const updatedQoute = await this.fetchQuoteInfo(quoteId)
      this.quoteDetail = updatedQoute.quote
      this.quoteCart = updatedQoute.vehicleCart
      if (clearCart) {
        StoreInstances.cart.removeVehicleCart(
          cartData.vehicle,
          GaTrackOption.doNotTrack // Not tracking save as quote for now
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.error(e)
      StoreInstances.uiStore.displayErrorNotification(JSON.stringify(e.message))
    } finally {
      this.quoteDetailLoading = false
    }
  }

  public saveAsQuote = async (cartData: CartVehicle): Promise<void> => {
    try {
      this.setSaveAsQuoteLoading(true)
      const requestBody: QuoteRequest = {
        lookupType: LookupType.CAR,
        countryId: StoreInstances.userStore.country.countryId,
        quote: {
          comment: cartData.orderFormData?.personalNote,
          customerName: cartData?.orderFormData?.customerName,
          description: cartData.orderFormData?.noteToStore,
          poNo: cartData.orderFormData?.poNumber,
          parts: this.mapPartsForQuotes(cartData.products),
          labors: this.mapLaborsForQuotes(
            StoreInstances.cart.getLaborItems(cartData.vehicle)
          ),
          specifications: this.mapSpecificationsForQuotes(
            StoreInstances.cart.getVehicleSpecification(cartData.vehicle)
          ),
          type: 'R', //MP4P-1364 -> A default regular order type when saving as quote
        },
      }
      if (cartData.vehicle?.engine?.id) {
        requestBody.quote.vehicle = cartData.vehicle
      }
      await QuotesServiceProvider.saveAsQuote(requestBody)
      StoreInstances.cart.removeVehicleCart(
        cartData.vehicle,
        GaTrackOption.doNotTrack // Not tracking save as quote for now
      )
      this.setSaveAsQuoteLoading(false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      // eslint-disable-next-line no-console -- Bulk disabling. Fix if possible.
      console.log(e)
      StoreInstances.uiStore.displayErrorNotification(JSON.stringify(e.message))
    }
  }

  public getQuotesHistory = async (start = 0, limit = 10): Promise<void> => {
    try {
      this.quotesHistoryLoading = true
      const { orgId } = StoreInstances?.userStore?.preferences || {
        orgId: undefined,
      }

      const filters: QuotesFilters = {
        ...(this.fromDate && { fromDate: format(this.fromDate, 'yyyy-MM-dd') }),
        ...(this.toDate && { toDate: format(this.toDate, 'yyyy-MM-dd') }),
        searchTerm: this.searchTerm,
      }

      const response = await QuotesServiceProvider.getQuotesHistory(
        filters,
        { orgId },
        start,
        limit
      )
      this.quotesHistory = response.data
      this.totalCount = response.totalCount
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      StoreInstances.uiStore.displayErrorNotification(e.message)
    } finally {
      this.quotesHistoryLoading = false
    }
  }

  public deleteQuotes = async (
    quoteId?: number,
    months?: number
  ): Promise<void> => {
    try {
      this.deleteQuoteLoading = true
      await QuotesServiceProvider.deleteQuote(quoteId, months)
      StoreInstances.uiStore.displaySuccessNotification(
        'quoteDeletedSuccessfully'
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (e: any) {
      throw new Error(e.toString())
    } finally {
      this.deleteQuoteLoading = false
      this.getQuotesHistory()
    }
  }

  private isAutoLocationChangeEnabled = (): boolean => {
    return (
      StoreInstances.userStore?.preferences?.findit_orderIfNotAvail === 'true'
    )
  }

  public addToCart = async (
    quoteId: string,
    viewCartLink?: Link
  ): Promise<void> => {
    const { vehicleCart, quote } = await this.fetchQuoteInfo(quoteId)
    const { vehicle, products, laborResults, specifications } = vehicleCart

    await StoreInstances?.searchStore?.updateCurrentVehicle(vehicleCart.vehicle)

    if (vehicleCart.vehicle.id !== miscellaneousVehicle.id) {
      vehicleCart.vehicle.id = StoreInstances?.searchStore?.currentVehicle?.id // The new generated id
    }

    const shoppingCartVehicle = StoreInstances?.cart?.findCartVehicle(
      vehicleCart.vehicle
    )
    shoppingCartVehicle.quoteSourceId = quoteId
    shoppingCartVehicle.orderFormData = {
      poNumber: quote?.poNo,
      noteToStore: quote?.description,
      personalNote: quote?.comment,
      customerName: null,
    }

    const addProductToCart = async (product): Promise<void> => {
      try {
        const locationId = product.orderSelections?.[0]?.locationId
        const productToAdd = { ...product, orderSelections: [] } // Must clean order selections first
        await StoreInstances.cart.setQtyAtLocation({
          product: productToAdd,
          locationId,
          quantity: product.orderSelections?.[0]?.quantityRequested,
          vehicle,
          autoLocationChange: this.isAutoLocationChangeEnabled(),
          collectErrors: true,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
      } catch (e: any) {
        /*
        No action, just continue to the next product.
        Erros will be collected without breaking the loop (collectErrors=true)
      */
      }
    }

    StoreInstances.cart.setLaborItems(vehicle, laborResults)
    StoreInstances.cart.setVehicleSpecification(vehicle, specifications)

    await Promise.all(products.map(addProductToCart))

    const addToCartNotification: IAddToCartNotification = {
      toastNotificationType: ToastNotificationVariant.WARNING,
      messages: [],
    }

    let countOfCantBeFulfilledProducts = 0
    let countOfOutOfStockProducts = 0
    let countOfProductsHavingNoMinQty = 0
    let countOfProductsWithLocationChange = 0

    products.forEach((quoteProduct) => {
      const shoppingCartProduct = shoppingCartVehicle.products.find(
        (cartProduct) =>
          cartProduct.partNumber === quoteProduct.partNumber &&
          cartProduct.lineCode === quoteProduct.lineCode
      )
      switch (shoppingCartProduct?.availabilityError?.errorType) {
        case AvailabilityErrorType.CANT_BE_FULFILLED:
          countOfCantBeFulfilledProducts++
          break
        case AvailabilityErrorType.OUT_OF_STOCK_ALL_LOCATIONS:
          countOfOutOfStockProducts++
          break
        case AvailabilityErrorType.MIN_QTY_CHANGE:
          /*These products are not shown in cartPage as we have not set orderSelections for 
          these products in `setOrderSelection` method in ShoppingCart store. The occurence of this error 
          is very rare. This error occurs only when a quote is created and later the store owner increases 
          the minQty. While creating the quote we satisfy the minQty requirement. But when we later add that 
          quote to cart minQty error may arise*/
          countOfProductsHavingNoMinQty++
          break
        case AvailabilityErrorType.LOCATION_OVERRIDE:
          countOfProductsWithLocationChange++
          break
      }
    })

    if (
      countOfProductsHavingNoMinQty + countOfCantBeFulfilledProducts ===
      products.length
    ) {
      if (
        laborResults &&
        specifications &&
        laborResults.length &&
        specifications.length
      ) {
        addToCartNotification.messages.push(
          'onlySpecsLaborResultsAreAddedToCart'
        )
      } else if (laborResults && laborResults.length) {
        addToCartNotification.messages.push('onlyLaborResultsAreAddedToCart')
      } else if (specifications && specifications.length) {
        addToCartNotification.messages.push('onlySpecsAreAddedToCart')
      } else {
        addToCartNotification.toastNotificationType =
          ToastNotificationVariant.ERROR
        addToCartNotification.messages.push('thisQuoteCantBeFulfilled')
        /* When we reuse a `shoppingCartVehicle`, there can be some products with orderselections which were set 
        in a previous addQuoteToCart action. In sunch scenario, if present addQuoteToCart action can't be fullfilled 
        then we should not delete the `shoppingCartVehicle`. Hence the below if block. This if condition checks if 
        all products in `shoppingCartVehicle` have no orderSelections. In such case we will delete `shoppingCartVehicle` 
        and `currentVehicle`
         */
        if (
          StoreInstances?.cart?.allProductsHaveNoOrderSelections(
            shoppingCartVehicle
          )
        ) {
          StoreInstances.cart.removeVehicleCart(
            vehicle,
            GaTrackOption.doNotTrack
          )
          StoreInstances?.searchStore?.deleteCurrentVehicle()
        }
      }
    } else if (
      countOfCantBeFulfilledProducts ||
      countOfOutOfStockProducts ||
      countOfProductsHavingNoMinQty ||
      countOfProductsWithLocationChange
    ) {
      addToCartNotification.messages.push('issueInAddingQuoteToCart')
    } else {
      addToCartNotification.toastNotificationType =
        ToastNotificationVariant.SUCCESS
      addToCartNotification.messages.push('productsWereAddedToTheCart')
    }
    switch (addToCartNotification.toastNotificationType) {
      case ToastNotificationVariant.SUCCESS:
        StoreInstances.uiStore.displaySuccessNotification(
          addToCartNotification?.messages?.[0],
          viewCartLink
        )
        break
      case ToastNotificationVariant.ERROR:
        StoreInstances.uiStore.displayErrorNotification(
          addToCartNotification?.messages?.[0]
        )
        break
      case ToastNotificationVariant.WARNING:
        for (const eachMessage of addToCartNotification.messages) {
          StoreInstances.uiStore.displayWarningNotification(
            eachMessage,
            viewCartLink
          )
        }
        break
      default:
        break
    }
  }
}

export const QuoteContext = createContext<QuoteStore>(undefined)

export const useQuoteStore = (): QuoteStore => {
  return useContext(QuoteContext)
}
