import { Config } from 'src/config/ConfigManager'
import { PartsCatalogAPI } from './PartsCatalogAPI'

export class LaborCatalogAPI extends PartsCatalogAPI {
  apiEndpointAttributesBase = Config.urls.attributes

  constructor() {
    super()
    super.ENDPOINT_BASE = Config.urls.catalog
    super.CATEGORY_ENDPOINT = 'laborCategories'
    super.GROUP_ENDPOINT = 'laborGroups'
    super.TERMINOLOGY_ENDPOINT = 'laborDescriptions'
    super.SESSION_STORAGE_KEY = 'selected-labor-catalog-tree'
  }

  isGfxSupported = (): boolean => {
    return false
  }
}
