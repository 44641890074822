import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import OrderServiceProvider from 'src/services/OrderServiceProvider'
import { StoreInstances } from '../StoreInstancesContainer'
import { IAdvancedSearchForm, OrderHistoryItem } from './interfaces'

export enum OrderStatus {
  ORDERED,
  PACKED,
  SHIPPED,
  DELIVERED,
}

export const defaultAdvancedSearch: IAdvancedSearchForm = {
  po: '',
  partNumber: '',
  vehicle: {
    year: '',
    make: '',
    model: '',
    engine: '',
  },
  confirmationID: '',
  location: '',
  notes: '',
}

const orderHistoryStoreDefaults = {
  showYourCost: true,
  fromDate: undefined,
  toDate: new Date(),
  statusFilter: 'All',
  searchParams: null,
  defaultAdvancedSearch,
}

export class OrderHistoryStore {
  public recentOrdersLoading = false

  public recentOrders: Array<OrderHistoryItem> = []

  public orderHistoryLoading = false

  public orderHistory: Array<OrderHistoryItem> = []

  public showYourCost = orderHistoryStoreDefaults.showYourCost

  public filterByModal = false

  public fromDate = orderHistoryStoreDefaults.fromDate

  public toDate = orderHistoryStoreDefaults.toDate

  public statusFilter = orderHistoryStoreDefaults.statusFilter

  public searchParams = orderHistoryStoreDefaults.searchParams

  public totalCount = 0

  public start = 0

  public limit = 10

  public selectedSort: 'orderedDate' | 'quantity' | 'totalCost' = undefined

  public advancedSearchForm: IAdvancedSearchForm =
    orderHistoryStoreDefaults.defaultAdvancedSearch

  constructor() {
    makeAutoObservable(this)
  }

  public setSelectedSort = (
    sort: 'orderedDate' | 'quantity' | 'totalCost'
  ): void => {
    if (this.selectedSort === sort) {
      this.selectedSort = undefined
    } else {
      this.selectedSort = sort
    }
    this.fetchOrderHistory(undefined, this.limit)
  }

  public getOrderStatus = (status: string): number => {
    /* Using Javed's comment on 
    https://praxent.atlassian.net/browse/MP4P-346
    */

    if (!status || status === '') {
      return OrderStatus.ORDERED
    }
    if (status === 'Pending') {
      return OrderStatus.PACKED
    }
    if (status === 'Order') {
      return OrderStatus.SHIPPED
    }
    if (status === 'Delivered') {
      return OrderStatus.DELIVERED
    }
    return 0
  }

  public setFilter = (
    type: 'searchParams' | 'statusFilter',
    value: string
  ): void => {
    this[type] = value
    if (type === 'searchParams') {
      this.start = 0
      this.fetchOrderHistory(this.start, this.limit)
    } else {
      this.fetchOrderHistory(undefined, this.limit)
    }
  }

  public setDateFilter = (fromDate: Date, toDate: Date): void => {
    this.fromDate = fromDate
    this.toDate = toDate
    this.fetchOrderHistory(undefined, this.limit)
  }

  public changeFilterByModal = (open: boolean): void => {
    this.filterByModal = open
  }

  public setShowYourCost = (show: boolean): void => {
    this.showYourCost = show
  }

  public onAdvancedSearch = (advancedSearchForm: IAdvancedSearchForm): void => {
    this.advancedSearchForm = {
      ...advancedSearchForm,
      vehicle: { ...advancedSearchForm.vehicle },
    }
    this.fetchOrderHistory(undefined, this.limit)
  }

  public fetchOrderHistory = async (start = 0, limit = 10): Promise<void> => {
    this.orderHistory = []
    this.start = start
    this.limit = limit
    this.orderHistoryLoading = true
    const { orgId } = StoreInstances?.userStore?.preferences || {
      orgId: undefined,
    }

    const res = await OrderServiceProvider.getOrderHistory(
      orgId,
      this.start,
      this.limit,
      {
        fromDate: this.fromDate?.toISOString() ?? null,
        toDate: this.toDate?.toISOString() ?? null,
        searchTerm: this.searchParams,
        poNumber: this.advancedSearchForm.po,
        partNumber: this.advancedSearchForm.partNumber,
        vehicle: this.advancedSearchForm.vehicle,
        confirmationNbr: this.advancedSearchForm.confirmationID,
        location: this.advancedSearchForm.location,
        noteTxt: this.advancedSearchForm.notes,
      },
      this.selectedSort
    )
    this.orderHistory = res.orders
    this.totalCount = res.totalCount
    this.orderHistoryLoading = false
  }

  public handleUpdate = (): void => {
    this.fetchOrderHistory(this.start, this.limit)
  }

  /* The below method is not used any where. If not required should be deleted*/

  // public getOrderConfirmationNumber = (order: OrderHistoryItem): string => {
  //   if (order.header?.confirmationNbr.indexOf('-') > -1) {
  //     return order.header?.confirmationNbr
  //       ? order.header.confirmationNbr?.split('-')?.[2] +
  //           order.header.confirmationNbr?.split('-')?.[3]
  //       : ''
  //   }
  //   return order.header?.confirmationNbr
  // }

  public resetFilters = (): void => {
    this.showYourCost = orderHistoryStoreDefaults.showYourCost
    this.fromDate = orderHistoryStoreDefaults.fromDate
    this.toDate = orderHistoryStoreDefaults.toDate
    this.statusFilter = orderHistoryStoreDefaults.statusFilter
    this.searchParams = orderHistoryStoreDefaults.searchParams
    this.advancedSearchForm = orderHistoryStoreDefaults.defaultAdvancedSearch
  }
}

export const OrderHistoryContext = createContext<OrderHistoryStore>(undefined)

export const useOrderHistoryStore = (): OrderHistoryStore => {
  return useContext(OrderHistoryContext)
}
