import { AccountCircle, ExitToApp } from '@mui/icons-material'
import { Menu, MenuItem } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UserAccessDetails } from 'src/api/user/interfaces'
import { IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { redirectToAllianceConnect } from 'src/helpers/utils'
import { Translate } from 'src/i18n'
import UserInfoServiceProvider from 'src/services/UserInfoServiceProvider'
import { useSearchStore } from 'src/store/search/SearchStore'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import authManager from '../../../../api/security/Auth'

interface AccountMenuProps {
  open: boolean
  parentRef: React.RefObject<HTMLElement>
  onClose: () => void
  accountName: string
}

export const AccountMenu = ({
  open,
  parentRef,
  onClose,
  accountName,
}: AccountMenuProps): ReactElement => {
  const history = useHistory()
  const searchStore = useSearchStore()
  const [userAccessDetails, setUserAccessDetails] =
    useState<UserAccessDetails>(undefined)

  const redirectUser = (): void => {
    redirectToAllianceConnect(userAccessDetails.url)
  }

  useEffect(() => {
    if (open === true) {
      UserInfoServiceProvider.getUserAccess(authManager.getUserId()).then(
        (data) => {
          if (data?.isAccess === true) {
            setUserAccessDetails(data)
          }
        }
      )
    }
  }, [open])

  return (
    <StyledMenu
      open={open}
      onClose={onClose}
      anchorEl={parentRef?.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem onClick={onClose}>
        <AccountCircle style={iconStyle} />
        {accountName}
      </MenuItem>

      {userAccessDetails?.isAccess === true && (
        <MenuItem onClick={redirectUser}>
          <BoxWrapper />
          {`${Translate('allianceConnect')}`}
        </MenuItem>
      )}

      {/* AES doesn't have login and logout */}
      {!IS_INTEGRATED_VERSION && (
        <MenuItem
          onClick={() => {
            history.push('/signOut')
            searchStore.removeBreadCrumbs()
          }}
        >
          <ExitToApp style={iconStyle} />
          Logout
        </MenuItem>
      )}
    </StyledMenu>
  )
}

const StyledMenu = styled(Menu)`
  margin-top: 15px;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    margin-top: 12px;
  }
`
const BoxWrapper = styled.div`
  background-color: ${defaultTheme.colors.orange};
  width: 18px;
  height: 18px;
  border: 4px solid rgba(0, 0, 0, 1);
  margin-right: 18px;
  margin-left: 2px;
`

const iconStyle = {
  width: 24,
  height: 24,
  marginRight: 14,
}
