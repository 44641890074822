import { CheckAvailabilityResponseProduct } from './CheckAvailability'
import { Optional } from '../../common-interfaces/generic-interfaces'
import { ShoppingCartProduct } from '../../store/models/ShoppingCartModels'
import { CartOnlyCommunications } from './CartOnlyCommunications'
import { AvailabilityErrorType } from 'src/store/models/ProductModel'

export class LineGroup {
  lineNo: number

  matches: Array<CheckAvailabilityResponseProduct>

  replacements: Array<CheckAvailabilityResponseProduct>

  alternates: Array<CheckAvailabilityResponseProduct>

  notFound: Array<CheckAvailabilityResponseProduct>

  err: Array<CheckAvailabilityResponseProduct>

  constructor() {
    this.matches = []
    this.replacements = []
    this.alternates = []
    this.notFound = []
    this.err = []
  }

  addProduct = (product: CheckAvailabilityResponseProduct): void => {
    switch (product.status) {
      case 'fnd':
        this.matches.push(product)
        break
      case 'rep':
        this.replacements.push(product)
        break
      case 'alt':
        this.alternates.push(product)
        break
      case 'nfd':
        this.notFound.push(product)
        break
      case 'err':
        this.err.push(product)
        break
      default:
        break
    }
  }

  reduce = (): Optional<ShoppingCartProduct> => {
    const order = [this.matches, this.replacements, this.alternates]

    for (const list of order) {
      const first = LineGroup.getFirstWithQuantity(list)
      if (first) {
        return first
      }
    }

    for (const list of order) {
      if (list.length > 0) {
        const locations = CartOnlyCommunications.convertLocations(list)
        const line = list.find((l) => l.locationStatus === 'primary')
        const product = CartOnlyCommunications.convertProduct(line, locations)
        product.availabilityError = {
          errorType: AvailabilityErrorType.OUT_OF_STOCK_ALL_LOCATIONS,
        }
        return product
      }
    }

    if (this.notFound.length > 0) {
      const locations = CartOnlyCommunications.convertLocations(this.notFound)
      const product = CartOnlyCommunications.convertProduct(
        this.notFound[0],
        locations
      )
      product.availabilityError = {
        errorType: AvailabilityErrorType.NOT_TRANSFERRED,
      }
      return product
    }

    return null
  }

  private static getFirstWithQuantity = (
    lineList: Array<CheckAvailabilityResponseProduct>
  ): Optional<ShoppingCartProduct> => {
    if (lineList.length === 0) {
      return null
    }

    const requestedQty = lineList[0].quantityRequested ?? 0
    const locations = CartOnlyCommunications.convertLocations(lineList)
    for (const aLine of lineList) {
      if (aLine.quantityAvailable >= requestedQty) {
        return CartOnlyCommunications.convertProduct(aLine, locations)
      }
    }

    return null
  }
}
