import React from 'react'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { I18n } from '../../i18n'

interface FieldGroupProps {
  title: string
  children: React.ReactNode
}

export const FieldGroup = (props: FieldGroupProps): React.ReactElement => {
  const { title, children } = props
  return (
    <Wrapper>
      <Title>
        <I18n name={title} />
      </Title>
      <ContentWrapper className="field-group-content">
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-grow: 1;
  &:nth-child(2) {
    margin-left: 20px;
  }
  background-color: inherit;
`

const Title = styled.span`
  text-align: left;
  margin-left: 5px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Oswald, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
  padding: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: inherit;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    padding: 0px;
  }
`
