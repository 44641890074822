import React, { MouseEventHandler, ReactElement } from 'react'
import styled from 'styled-components'
import { Check } from '@mui/icons-material'
import { Spinner } from 'react-bootstrap'
import { I18n } from '../../i18n'

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'danger'
  | 'transparent'

export type ButtonSize = 'tiny' | 'small' | 'medium' | 'large' | 'fullwidth'

interface ButtonProps {
  id?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  text: string
  variant?: ButtonVariant
  size?: ButtonSize
  disabled?: boolean
  style?: React.CSSProperties
  loading?: boolean
  minWidth?: string
  icon?: React.ReactElement
  type?: 'button' | 'submit' | 'reset'
  onMouseEnter?: MouseEventHandler<HTMLButtonElement> | undefined
  onMouseLeave?: MouseEventHandler<HTMLButtonElement> | undefined
}

export const Button = (props: ButtonProps): ReactElement => {
  const { text, variant, loading, icon, type } = props

  return (
    <StyledButton {...{ ...props, loading: undefined }} type={type}>
      {variant === 'success' && <StyledCheck />}
      {icon}
      <I18n name={text} />
      {loading && (
        <Spinner
          animation="border"
          style={{ width: '1.3rem', height: '1.3rem', marginLeft: '0.5rem' }}
        />
      )}
    </StyledButton>
  )
}

Button.defaultProps = {
  id: undefined,
  disabled: false,
  variant: 'primary',
  size: 'medium',
  style: {},
  loading: false,
  minWidth: undefined,
  onClick: undefined,
  icon: null,
  type: 'button',
}

const StyledButton = styled.button<ButtonProps>`
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  font-family: ${(p) => (p.size === 'tiny' ? 'Roboto' : 'Oswald')}, serif;
  font-weight: bold;
  font-size: ${(p) => (p.size === 'tiny' ? '11px' : '16px')};
  ${(p) => (p.size === 'tiny' ? 'line-height: 1;' : '')}
  height: ${(p) => {
    if (p.size === 'tiny') {
      return '24px'
    }
    if (p.size === 'small') {
      return '36px'
    }
    if (p.size === 'medium') {
      return '40px'
    }
    if (p.size === 'large' || p.size === 'fullwidth') {
      return '48px'
    }
    return '48px'
  }};
  min-width: ${(p) => {
    if (p.minWidth) return p.minWidth
    if (p.size === 'large') {
      return '128px'
    }
    return 'auto'
  }};
  width: ${(p) => {
    if (p.size === 'fullwidth') {
      return '100%'
    }
    return 'auto'
  }};
  padding-left: ${(p) => (p.size === 'tiny' ? '10px' : '20px')};
  padding-right: ${(p) => (p.size === 'tiny' ? '10px' : '20px')};
  margin: ${(p) => (p.size === 'tiny' ? '0' : '0.25rem')};
  text-transform: uppercase;
  white-space: nowrap;
  ${(p) => {
    if (p.disabled) {
      return `
        background-color: ${p.theme.colors.disabledButton};
        color: ${p.theme.colors.disabledButtonText};
        border: none;
        cursor: not-allowed;
      `
    }
    switch (p.variant) {
      case 'primary':
        return `
          background-color: ${p.theme.colors.primary};
          color: ${p.theme.colors.primaryText};
          border: none;
        `
      case 'tertiary':
        return `
          background-color: #000;
          color: #FFF;
          border: none;
        `
      case 'secondary':
        return `
          background-color: transparent; 
          color: ${p.theme.colors.secondaryText};
          border: 1px solid #000;
          &:hover {
            background-color: #000;
            color: #FFF;
            transition: 0.2s;
          }
        `
      case 'success':
        return `
          background-color: ${p.theme.colors.green};
          color: #fff;
          border: none;
        `
      case 'danger':
        return `
          background-color: ${p.theme.colors.red};
          color: #fff;
          border: none;
        `
      case 'transparent':
        return `
          background-color: transparent; 
          color: ${p.theme.colors.secondaryText};
          border: none;
        `
      default:
        return ''
    }
  }}
`

const StyledCheck = styled(Check)`
  position: relative;
  top: -4px;
  margin-right: 5px;
`
