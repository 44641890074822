import { ReactElement, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSearchStore } from 'src/store/search/SearchStore'
import { FieldGroup } from 'src/ui-components'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { VehicleSelector, VehicleSelectorStep } from 'src/features'
import {
  useVehicleWidgetStore,
  VehicleWidgetDisplayState,
  VehicleWidgetStore,
} from '../store/VehicleWidgetStore'
import { Grid } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { VehicleQuickFind } from 'src/features/search/VehicleSearch/components/VehicleQuickFind'

export const YMMESelector = observer((): ReactElement => {
  const history = useHistory()
  const vehicleWidgetStore = useVehicleWidgetStore()
  const { currentVehicle } = useSearchStore()
  const [selectorStartStep, setSelectorStartStep] =
    useState<VehicleSelectorStep>(
      VehicleWidgetStore.getActiveVehicleStep(currentVehicle)
    )

  const showHeader =
    vehicleWidgetStore.displayState !== VehicleWidgetDisplayState.select

  const handleVehicleSelected = useCallback(() => {
    vehicleWidgetStore.setIsDrawerOpened(false)
    vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.view)
    const afterAddVehicleCallback = vehicleWidgetStore.afterAddVehicle()
    if (!afterAddVehicleCallback) {
      history.push('/partsCatalog')
    } else {
      afterAddVehicleCallback()
      vehicleWidgetStore.setAfterAddVehicle(undefined)
    }
  }, [history, vehicleWidgetStore])

  useEffect(() => {
    setSelectorStartStep(
      VehicleWidgetStore.getActiveVehicleStep(currentVehicle)
    )
  }, [currentVehicle])

  const vehicleSelector = (
    <Grid container>
      {!isMobile && (
        <Grid item xs={3}>
          <VehicleQuickFind
            onVehicleSelected={handleVehicleSelected}
            vehicleLookup={true}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <VehicleSelector
          onVehicleSelected={handleVehicleSelected}
          startStep={selectorStartStep}
        />
      </Grid>
    </Grid>
  )
  return (
    <Wrapper>
      <AddVehicleWrapper>
        {!showHeader ? (
          <FieldGroup title="searchByYMME">{vehicleSelector}</FieldGroup>
        ) : (
          vehicleSelector
        )}
      </AddVehicleWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  font-family: Roboto, serif;
`

const AddVehicleWrapper = styled.div`
  padding-bottom: 24px;
  flex-grow: 1;
  display: block;
`
