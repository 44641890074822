import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { I18n } from 'src/i18n'
import styled from 'styled-components'
import { Search as SearchIcon } from '@mui/icons-material'
import { defaultTheme } from 'src/theme'
import { CatalogNodeType } from 'src/store/partsCatalog/interfaces'
import { CheckboxGroupsProps } from '../interfaces'
import { CheckboxListGroup } from './CheckboxListGroup'
import { VisibleStep } from '../../../../store/partsCatalog/PartsCatalog'

export const CheckboxListGroups = observer(
  (props: CheckboxGroupsProps): ReactElement => {
    const { groups, type, partsCatalogStore } = props
    const availableGroups = groups.filter((group) => group.items?.length > 0)
    const unavailableGroups = groups.filter(
      (group) => group.items?.length === 0
    )
    const visibleStep = partsCatalogStore.getVisibleStep()
    const showUnavailableAllPartTypes =
      type === CatalogNodeType.PART_TYPE &&
      availableGroups.length < 1 &&
      visibleStep === VisibleStep.PART_TYPE
    const showUnavailableMixedPartTypes =
      type === CatalogNodeType.PART_TYPE &&
      availableGroups.length > 0 &&
      unavailableGroups.length > 0 &&
      visibleStep === VisibleStep.PART_TYPE

    return (
      <GroupsWrapper>
        {showUnavailableAllPartTypes && (
          <UnavailableAllPartTypesWrapper>
            <StyledSearchIcon />
            <UnavailableAllPartTypesTitle>
              <I18n name="unavailablePartTypes" />
            </UnavailableAllPartTypesTitle>
            <UnavailableAllPartTypesText>
              <I18n name="trySelectingDifferentGroups" />
            </UnavailableAllPartTypesText>
          </UnavailableAllPartTypesWrapper>
        )}

        {showUnavailableMixedPartTypes && (
          <UnavailablePartTypesWrapper>
            <UnavailablePartTypesTitle>
              <I18n name="unavailablePartTypes" />
            </UnavailablePartTypesTitle>
            {unavailableGroups.map((group) => (
              <UnavailablePartTypesItem key={group.id}>
                {group.value}
              </UnavailablePartTypesItem>
            ))}
          </UnavailablePartTypesWrapper>
        )}
        {groups
          .filter((group) => group.items?.length > 0)
          .map((group) => (
            <CheckboxListGroup
              key={group.value}
              group={group}
              onChange={group.onClick}
              type={type}
              partsCatalogStore={partsCatalogStore}
            />
          ))}
      </GroupsWrapper>
    )
  }
)

const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid #adadaa;
  max-height: 70vh;
  overflow: auto;
`
const UnavailablePartTypesTitle = styled.span`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  margin-bottom: 8px;
  color: #9b9b9b;
`

const UnavailablePartTypesItem = styled.span`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  margin-bottom: 8px;
  color: #9b9b9b;
`

const UnavailablePartTypesWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
`
const UnavailableAllPartTypesWrapper = styled.span`
  display: flex;
  width: 100%;
  height: 70vh;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-left: 80px;
  padding-right: 80px;
`
const UnavailableAllPartTypesTitle = styled.span`
  font-family: 'Oswald', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: black;
`

const UnavailableAllPartTypesText = styled.span`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: black;
`

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 100px !important;
  color: ${defaultTheme.colors.greyMedium};
`
