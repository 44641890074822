import {
  Order,
  OrderPart,
  OrderResponse,
  OrderResponseDetail,
  Vehicle,
} from 'src/api/cart/interfaces'
import { OrderHeaderProps } from 'src/features/orderConfirmation/components/order/OrderHeader'
import { PartDetail } from 'src/features/orderConfirmation/components/order/PartsTableContent'
import { VehicleInfoProps } from 'src/features/SummaryPanel/Interfaces'
import { OrderStatus } from '../store/orderHistory/orderHistoryStore'
import { StoreInstances } from '../store/StoreInstancesContainer'
import { LaborItem } from '../store/models/LaborModel'

const ORDER_CONFIRMATION_KEY = 'order-confirmation-data'

const calcPartSubTotal = (part: PartDetail): number => {
  return (part.yourCost + part.corePrice) * part.qty
}

export const calcOrderSubTotal = (
  parts: Array<PartDetail>,
  laborItems: Array<LaborItem>
): number => {
  const laborSubtotal =
    StoreInstances.laborStore.calculateLaborCostFromItems(laborItems)
  const partsSubtotal = parts.reduce(
    (acc, item) => acc + calcPartSubTotal(item),
    0
  )
  return laborSubtotal + partsSubtotal
}

export const getOrderHeader = (
  vehicle: Vehicle,
  poNumber: string,
  noteToStore: string,
  comment: string,
  order: Order
): OrderHeaderProps => {
  const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`
  const { orderNumber, deliveryDetail } = order
  const orderConfirmationNumber = order.parts[0].orderConfirmationNbr
  const orderType = deliveryDetail?.deliveryMethod
  const orderTransport = deliveryDetail?.carrier
  const customerName = deliveryDetail?.name
  const shipDestination = deliveryDetail?.address?.line1
    ? `${deliveryDetail?.address?.line1} ${deliveryDetail?.address?.line2}, ${deliveryDetail?.address?.city}, ${deliveryDetail?.address?.region}, ${deliveryDetail?.address?.postalCode} `
    : undefined

  return {
    vehicleName,
    orderNumber,
    orderType,
    transport: orderTransport,
    orderStatus: OrderStatus.ORDERED,
    poNumber,
    notesToStore: noteToStore,
    shipDestination,
    customerName,
    personalNote: comment,
    orderConfirmationNumber,
  }
}

export const getPartDetail = (part: OrderPart): PartDetail => {
  const {
    description,
    partNumber,
    lineCode,
    brand,
    unitCostPrice: yourCost,
    unitCorePrice: corePrice,
    unitListPrice: listPrice,
    quantityRequested: qty,
    locationDescription: location,
  } = part
  return {
    thumbnailUrl: undefined, // TODO: ask the API for the thumbnailUrl
    description,
    partNumber,
    lineCode,
    brand,
    location,
    yourCost,
    corePrice,
    listPrice,
    qty,
    total: (yourCost + corePrice) * qty,
  }
}

export const getPartsTableContent = (order: Order): Array<PartDetail> => {
  const parts = []
  order.parts.forEach((part) => parts.push(getPartDetail(part)))

  return parts
}

export const getOrderConfirmation = (): OrderResponse | undefined => {
  const orderConfirmation = localStorage.getItem(ORDER_CONFIRMATION_KEY)
  if (orderConfirmation) {
    return JSON.parse(orderConfirmation)
  }
  return undefined
}

export const setOrderResponses = (response: OrderResponse): void => {
  localStorage.setItem(ORDER_CONFIRMATION_KEY, JSON.stringify(response))
}

export const getPartsTotal = (parts: Array<OrderPart>): number => {
  return parts.reduce(
    (partAcc, part) => partAcc + part.unitCostPrice * part.quantityRequested,
    0
  )
}

export const getPartsCoreTotal = (parts: Array<OrderPart>): number => {
  return parts.reduce(
    (partAcc, part) => partAcc + part.unitCorePrice * part.quantityRequested,
    0
  )
}

const getOrderCostSubtotal = (orders: Array<Order>): number => {
  return orders.reduce(
    (orderAcc, ord) => orderAcc + getPartsTotal(ord.parts),
    0
  )
}

const getOrderCoreCostSubtotal = (orders: Array<Order>): number => {
  return orders.reduce(
    (orderAcc, ord) => orderAcc + getPartsCoreTotal(ord.parts),
    0
  )
}

export const getTotalPerOrder = (order: Order): number => {
  const costSubTotal = getPartsTotal(order.parts)
  const coreSubTotal = getPartsCoreTotal(order.parts)
  return costSubTotal + coreSubTotal
}

export const getVehiclesSummaryInfo = (
  orders: Array<OrderResponseDetail>
): Array<VehicleInfoProps> => {
  const vehiclesInfo = orders?.map((order, orderIndex) => {
    const { vehicle: responseVehicle } = order
    const vehicle = {
      ...{
        year: {
          id: Number(responseVehicle.year),
          value: responseVehicle.year,
        },
        make: {
          id: Number(responseVehicle.make),
          value: responseVehicle.make,
        },
        model: {
          id: Number(responseVehicle.model),
          value: responseVehicle.model,
        },
        engine: {
          id: Number(responseVehicle.engine),
          value: responseVehicle.engine,
        },
      },
    }

    const vehicleHash = StoreInstances.searchStore.getVehicleHash(
      vehicle,
      orderIndex
    )
    const idToCollapse = `collapse-item-totals-${vehicleHash}`
    const anchorTag = `#vehicle_${vehicleHash}`
    const vehicleName = `${vehicle.year.value} ${vehicle.make.value} ${vehicle.model.value}`

    const costSubTotal: number = order.locations.reduce<number>(
      (acc, loc) => acc + getOrderCostSubtotal(loc.orders),
      0
    )

    const coreSubTotal: number = order.locations.reduce<number>(
      (acc, loc) => acc + getOrderCoreCostSubtotal(loc.orders),
      0
    )

    const laborSubTotal: number =
      StoreInstances.laborStore.calculateLaborCostFromItems(order.laborData)

    return {
      idToCollapse,
      anchorTag,
      vehicleName,
      costSubTotal,
      coreSubTotal,
      laborSubTotal,
    }
  })
  return vehiclesInfo
}

export const getSummaryGrandTotal = (
  vehiclesInfo: Array<VehicleInfoProps>
): number => {
  return vehiclesInfo?.reduce(
    (acc, cost) =>
      acc + cost.costSubTotal + cost.coreSubTotal + cost.laborSubTotal,
    0
  )
}
