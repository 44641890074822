import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect } from 'react'
import { Translate } from 'src/i18n'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { SimpleModal } from 'src/ui-components'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import styled from 'styled-components'
import {
  SEARCH_PREFERENCE_VERSION,
  useCustomizeSearchStore,
} from 'src/store/search/CustomizeSearchStore'
import { SearchPreferenceItem } from './SearchPreferenceItem'
import { SearchPreference } from '../interfaces'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { MutablePreference, useUserStore } from 'src/store/user/UserStore'

export const CustomizeSearchModal = observer((): ReactElement => {
  const DragItemsText = 'dragAndRearrangeTheItemsInThisList'
  const DefaultIndicationText = 'yourFirstItemWillBeYourDefault'
  const { showCustomizeSearchModal, setShowCustomizeSearchModal } =
    useUiStateStore()
  const {
    copyOfSearchPreferences,
    getVisibleSearchPreferences,
    getHiddenSearchPreferences,
    setSearchPreferences,
    setCopyOfSearchPreferences,
    resetCopy,
    setSelectedTab,
  } = useCustomizeSearchStore()
  const {
    updateUserPreferenceAndSyncToCloud,
    preferences: accountPreferences,
  } = useUserStore()

  useEffect(() => {
    const userAccountSearchPreferencesStr =
      accountPreferences?.searchPreferences
    const version = accountPreferences?.searchPreferencesVersion
    const isPlateSearchEnabled =
      accountPreferences.epeFeatures_plateToVin === 'true'

    if (
      userAccountSearchPreferencesStr &&
      version === SEARCH_PREFERENCE_VERSION
    ) {
      const userAccountSearchPreferences: SearchPreference[] = JSON.parse(
        userAccountSearchPreferencesStr
      )
      setSearchPreferences(userAccountSearchPreferences)
      if (isPlateSearchEnabled) {
        // Add it in case it is not in the user cache already
        StoreInstances.customizeSearchStore.addLicensePlateOption()
      }

      setSelectedTab(userAccountSearchPreferences[0].id)
    }

    if (!isPlateSearchEnabled) {
      StoreInstances.customizeSearchStore.removeLicensePlateOption()
    }
  }, [accountPreferences, setSearchPreferences, setSelectedTab])

  let visibleSearchPreferences = getVisibleSearchPreferences()
  const hiddenSearchPreferences = getHiddenSearchPreferences()

  const handleOnConfirm = (): void => {
    setShowCustomizeSearchModal(false)
    const allPreferences = visibleSearchPreferences
    allPreferences.push(...hiddenSearchPreferences)
    setSearchPreferences(allPreferences)

    updateUserPreferenceAndSyncToCloud(
      MutablePreference.SEARCH_PREFERENCE,
      JSON.stringify(allPreferences)
    )
    updateUserPreferenceAndSyncToCloud(
      MutablePreference.SEARCH_PREFERENCE_VERSION,
      SEARCH_PREFERENCE_VERSION
    )
  }

  const handleOnCancel = (): void => {
    setShowCustomizeSearchModal(false)
    resetCopy()
  }

  const handleOnHideElement = (item: SearchPreference): void => {
    const items = Array.from(copyOfSearchPreferences)
    const newArr = items.map((sp) => {
      if (sp.id === item.id) return { ...sp, hidden: !item.hidden }
      return sp
    })
    setCopyOfSearchPreferences(newArr)
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    if (!destination) return

    const items = Array.from(visibleSearchPreferences)
    const [newOrder] = items.splice(source.index, 1)
    items.splice(destination.index, 0, newOrder)
    visibleSearchPreferences = items
  }

  return (
    <SimpleModal
      title="customizeSearchPreferences"
      show={showCustomizeSearchModal}
      onSecondaryAction={handleOnCancel}
      onPrimaryAction={handleOnConfirm}
      primaryActionText="savePreferences"
      borderBottomHeader
      borderTopFooter
    >
      <Body>
        <Indications>
          {Translate(DragItemsText)}. {Translate(DefaultIndicationText)}
        </Indications>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="search_preferences">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {visibleSearchPreferences.map((sp, index) => {
                  return (
                    <Draggable key={sp.id} draggableId={sp.id} index={index}>
                      {(provided2) => (
                        <div
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                        >
                          <SearchPreferenceItem
                            key={`visible_${sp.id}`}
                            item={sp}
                            index={index + 1}
                            onHideElement={handleOnHideElement}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {hiddenSearchPreferences.length > 0 && (
          <HiddenHeader>
            <HorizontalLine />
            <HiddenTitleText>{Translate('hidden')}</HiddenTitleText>
            <HorizontalLine />
          </HiddenHeader>
        )}
        <div>
          {hiddenSearchPreferences.map((sp) => (
            <SearchPreferenceItem
              key={`hidden_${sp.id}`}
              item={sp}
              index={1}
              showIndex={false}
              onHideElement={handleOnHideElement}
            />
          ))}
        </div>
      </Body>
    </SimpleModal>
  )
})

const Indications = styled.div`
  padding-bottom: 1.5rem;
`

const HiddenHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #adadaa;
  padding-bottom: 1rem;
`

const HorizontalLine = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid;
  flex-grow: 1;
`

const HiddenTitleText = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;
  text-transform: uppercase;
`

const Body = styled.div`
  padding-top: 1rem;
  min-height: 557px;
`
