export const breakpoints = {
  mobileSmall: 320,
  mobileMedium: 375,
  mobileLarge: 500,
  tablet: 768,
  tabletLarge: 1016,
  laptop: 1024,
  laptopLarge: 1440,
  laptop4k: 2560,
}
