import { ReactElement, useState } from 'react'

import styled from 'styled-components'
import { Delete } from '@mui/icons-material'
import { Button, SimpleModal } from 'src/ui-components'
import { I18n, Translate } from 'src/i18n'
import { useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useSearchStore } from 'src/store/search/SearchStore'
import { breakpoints } from 'src/theme/breakpoints'
import {
  useVehicleWidgetStore,
  VehicleWidgetDisplayState,
} from '../../VehicleSearch/store/VehicleWidgetStore'
import { usePartsCatalog } from 'src/store/partsCatalog/PartsCatalog'
import { Button as ButtonV2 } from 'src/ui-components/forms/ButtonV2'

interface EditControlsProps {
  onConfirm: () => void
  onCancel: () => void
  onClearSelections: () => void
  updateDisabled: boolean
}
export const EditControls = observer(
  (props: EditControlsProps): ReactElement => {
    const { onConfirm, onCancel, onClearSelections, updateDisabled } = props
    const [showConfirm, setShowConfirm] = useState(false)
    const { clearStore } = usePartsCatalog()
    const history = useHistory()
    const location = useLocation()
    const handleUpdate = () => {
      if (location.pathname.indexOf('partsCatalog') > -1) {
        onConfirm()
      } else {
        setShowConfirm(true)
      }
    }
    const vehicleWidgetStore = useVehicleWidgetStore()

    const searchStore = useSearchStore()

    const handleDeleteVehicle = () => {
      searchStore.deleteCurrentVehicle()
      vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.select)
      vehicleWidgetStore.setIsDrawerOpened(false)
    }

    return (
      <>
        <Wrapper>
          <Controls>
            <ClearSelections onClick={onClearSelections}>
              <Delete style={{ fontSize: 28, marginTop: -4 }} />
              <I18n name="clearSelections" />
            </ClearSelections>
            <ButtonDesktop onClick={onCancel} variant="secondary" size="large">
              <span>
                {Translate('cancel')}
                <I18n name="vehicle" style={{ display: 'none' }} />
              </span>
            </ButtonDesktop>
            <ButtonDesktop
              onClick={handleUpdate}
              size="large"
              disabled={updateDisabled}
            >
              <I18n name="update" />
            </ButtonDesktop>

            <ButtonMobile
              onClick={handleDeleteVehicle}
              text="deleteVehicle"
              variant="secondary"
              size="large"
            />
            <ButtonMobile
              onClick={handleUpdate}
              text="save"
              size="large"
              disabled={updateDisabled}
            />
          </Controls>
        </Wrapper>
        <SimpleModal
          show={showConfirm}
          title="updateVehicle"
          onSecondaryAction={onCancel}
          onPrimaryAction={() => {
            onConfirm()
            clearStore()
            history.push('/partsCatalog')
          }}
          secondaryActionText="cancel"
          primaryActionText="update"
        >
          updatingYourVehicleWillChangeSearch
        </SimpleModal>
      </>
    )
  }
)

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    justify-content: center;
  }
`

const ButtonDesktop = styled(ButtonV2)`
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`

const ButtonMobile = styled(Button)`
  display: none;
  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: block;
    width: 100%;
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    flex-direction: column;
    margin: 16px 0px 0px 0px;
    width: 100%;
  }
`

const ClearSelections = styled.div`
  color: ${(p) => p.theme.colors.red};
  font-weight: bold;
  font-family: Oswald;
  font-size: 18px;
  margin-right: 10px;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.tabletLarge}px) {
    display: none;
  }
`
