interface AwsConfig {
  aws_cognito_identity_pool_id: string
  aws_user_pools_id: string
  aws_user_pools_web_client_id: string
  aws_appsync_graphqlEndpoint: string
  aws_appsync_region: string
  aws_appsync_authenticationType: string
  aws_appsync_apiKey: string
}

const awsConfigProduction: AwsConfig = {
  aws_cognito_identity_pool_id:
    'us-east-1:c6487148-84c6-423d-aaf5-a74620dc199c',
  aws_user_pools_id: 'us-east-1_WPhcg35bJ',
  aws_user_pools_web_client_id: '65arl0n1r50mhlfbevgrd1c7qu',
  aws_appsync_graphqlEndpoint:
    'https://n7gvwz7z4vaudmiiiht3qmp764.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'null',
}

const awsConfigQA: AwsConfig = {
  aws_cognito_identity_pool_id:
    'us-east-1:5c6612ca-3604-4cdd-94f8-4954d846665c',
  aws_user_pools_id: 'us-east-1_3OqH3aDJf',
  aws_user_pools_web_client_id: '17spppok77qiakrbul758sudk3',
  aws_appsync_graphqlEndpoint:
    'https://3hickb5nxvarxc6zq3hnsokhzi.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'null',
}

const awsConfigQADR: AwsConfig = {
  aws_cognito_identity_pool_id:
    'us-west-2:03714057-265f-4a59-ab06-a8d7556ee397',
  aws_user_pools_id: 'us-west-2_r9EMqAlU9',
  aws_user_pools_web_client_id: '4nddlm8ih21ncpbph66gdk2opa',
  aws_appsync_graphqlEndpoint:
    'https://gfmd3sakznbdhhqdwglxgj7yly.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'null',
}

const awsConfigStaging: AwsConfig = {
  aws_cognito_identity_pool_id:
    'us-east-1:50c0679d-57eb-473d-a830-7913850af280',
  aws_user_pools_id: 'us-east-1_fjWGqZMP1',
  aws_user_pools_web_client_id: '1cs2gg1a757fu5e22bejfu6m8h',
  aws_appsync_graphqlEndpoint:
    'https://3uyupjzvbvb6dpdts6msvwjfy4.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'null',
}

export const getAwsConfig = (): AwsConfig => {
  if (import.meta.env.VITE_MODE === 'qa') {
    return awsConfigQA
  }
  if (import.meta.env.VITE_MODE === 'qa-dr') {
    return awsConfigQADR
  }
  if (import.meta.env.VITE_MODE === 'production') {
    return awsConfigProduction
  }
  return awsConfigStaging
}
