import { QuoteBody, QuoteRequest } from 'src/store/quote/interfaces'
import { BaseServiceProvider } from './BaseServiceProvider'
import {
  QuoteResponse,
  QuotesFilters,
  QuotesHistoryResponse,
  QuotesParams,
} from 'src/api/quote/interface'

class QuotesServiceProvider extends BaseServiceProvider {
  constructor() {
    super(import.meta.env.VITE_API_BASE_URL, 'quote', '1.1', '')
  }

  saveAsQuote = async (body: QuoteRequest): Promise<QuoteResponse[]> => {
    try {
      const resp = await this.post<QuoteResponse[]>('quote', [body])
      return resp.data
    } catch (error) {
      throw new Error(error.toString())
    }
  }

  editQuote = async (body: QuoteBody): Promise<QuoteResponse[]> => {
    try {
      const resp = await this.put<QuoteResponse[]>('quote', body)
      return resp.data
    } catch (error) {
      throw new Error(error.toString())
    }
  }

  deleteQuote = async (quoteId?: number, months?: number): Promise<void> => {
    try {
      await this.delete('quote', {
        params: {
          quoteId: quoteId && quoteId.toString(),
          months: months && months.toString(),
        },
      })
    } catch (error) {
      throw new Error(error.toString())
    }
  }

  getQuotesHistory = async (
    filters?: QuotesFilters,
    params?: QuotesParams,
    start?: number,
    limit?: number
  ): Promise<QuotesHistoryResponse> => {
    try {
      const resp = await this.post<QuotesHistoryResponse>('search', filters, {
        params: {
          orgId: params.orgId,
          page: start,
          pageSize: limit,
        },
      })
      if (resp.status === 200) {
        return resp.data
      }
      if (resp.status === 204) {
        return {
          totalCount: 0,
          data: [],
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Bulk disabling. Fix if possible.
    } catch (error: any) {
      if (error?.message === 'Request failed with status code 400') {
        throw new Error('searchTermShouldBeMinimum3Characters')
      } else {
        throw new Error(error.toString())
      }
    }
    return {
      totalCount: 0,
      data: [],
    }
  }

  fetchQuoteById = async (quoteId: string): Promise<QuoteResponse> => {
    try {
      const resp = await this.get<QuoteResponse>(`quote/${quoteId}`)
      if (resp.status === 200) {
        resp.data.labors.forEach((labor) => {
          if (labor && labor.description) {
            labor['laborDescription'] = labor['description']
            delete labor['description']
          }
        })
        return resp.data
      }
    } catch (e) {
      throw new Error('quoteNotFound')
    }
    return undefined
  }
}

export default new QuotesServiceProvider()
