import axios from 'axios'
import { PartsBasketCreds, TokensFromAPI } from './interfaces'
import { Config } from 'src/config/ConfigManager'

const securityApi = Config.urls.security
const tokenApiKey = import.meta.env.VITE_TOKEN_API_KEY

export const getTokens = async (
  creds: PartsBasketCreds
): Promise<TokensFromAPI> => {
  return axios
    .post<TokensFromAPI>(`${securityApi}/token`, creds, {
      headers: {
        'x-token-api-key': tokenApiKey,
      },
    })
    .then((resp) => resp?.data)
}
