import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { TopNavigationBar } from 'src/features'
import {
  BlackBackground,
  LogoImage,
  RotatedBlackLine,
  ShoppingCartMenu,
  WhiteBackground,
} from 'src/features/navigation/TopNavigation/components/CommonMenuItems'
import UserAccountMenu from 'src/features/navigation/TopNavigation/components/UserAccountMenu'
import { SearchField } from 'src/features/search/AllProductsSearch/components/SearchField'
import ContactUs from 'src/features/navigation/header/contactUs/ContactUs'
import { LanguageDropdown } from 'src/features/search/AllProductsSearch/LanguagesDropdown'
import { Translate } from 'src/i18n'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { defaultTheme } from 'src/theme'
import styled from 'styled-components'
import { HeaderProps } from '../../../main/HeaderProps'
import AstPunchOutMenuItems from '../TopNavigation/components/MenuItemLinks/AstPunchOutMenuItems'
import {
  CatalogIndexTracker,
  CatalogLookupType,
} from 'src/api/metrics/CatalogIndexTracker'
import { SaytOption } from 'src/api/searchResults/interfaces'
import { isMobile } from 'react-device-detect'

const AstHeader = observer((props: HeaderProps): ReactElement => {
  const { logoUrl } = props
  const cart = useShoppingCart()
  const { searchStore } = StoreInstances
  const { addBreadCrumb, cleanBreadCrumbs, breadCrumbs } = searchStore
  const history = useHistory()

  const handleAddBreadCrumb = (breadcrumb) => {
    if (breadCrumbs.length === 0) {
      addBreadCrumb(breadcrumb)
    } else {
      cleanBreadCrumbs()
      addBreadCrumb(breadcrumb)
    }
  }

  const searchByText = (text: string): void => {
    searchStore.setShowPartsNotFitVehicleLabel(true)
    handleAddBreadCrumb({
      page: 'All Products',
      breadcumb: text,
      url: () => searchStore.searchByText(text),
    })
    CatalogIndexTracker.setIndex(CatalogLookupType.AST_NO_MATCH)
    searchStore.searchByText(text)
    history.push('/searchResults')
  }

  const onSearch = (text: string): void => {
    searchByText(text)
  }

  const onSelectedOption = (saytOption: SaytOption): void => {
    searchByText(saytOption.label)
  }

  return (
    <>
      <TopNavigationBar>
        <BlackBackground>
          {/* <MobileHamburguerMenu /> */}
          <NavLink to="/astPartsCatalog">
            <LogoImage src={logoUrl} alt="My place 4 parts logo" />
          </NavLink>
          <AstPunchOutMenuItems />
        </BlackBackground>
        <RotatedBlackLine />
        <WhiteBackground>
          <UserAccountMenu />
          <ShoppingCartMenu qty={cart.totalCartQty} />
        </WhiteBackground>
      </TopNavigationBar>

      <SubHeaderSection>
        <SearchField
          label={
            isMobile
              ? Translate('ast')
              : Translate('accessoriesSuppliesAndTools')
          }
          placeholder={Translate('searchPartBrandName')}
          onSearch={onSearch}
          onSelectOption={onSelectedOption}
          type="astOnly"
        />
        <RightWrapper>
          <ContactUs />
          <LanguageDropdown />
        </RightWrapper>
      </SubHeaderSection>
    </>
  )
})

export default AstHeader

const SubHeaderSection = styled.div`
  width: 100%;
  height: 100px;
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.3);
  background-color: ${defaultTheme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${isMobile ? '0 15px' : '0 0 0 15px'};
`

const RightWrapper = styled.div`
  display: flex;
  margin: 20px 0px 0px 0px;
  align-items: baseline;
  flex-wrap: wrap;

  p {
    margin: 0px;
  }
`
